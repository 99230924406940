import React, { createContext, useContext, useState, useEffect, ReactNode, FC } from "react";
import { Auth, Hub } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useGetUser, useUpdateUser } from "../hooks/userHooks";
import { UserType, UserUpdateOptions } from "../API";
import { UserWithoutTypeName, useUserDataContext } from "./LeadDataProvider";

interface AuthContextType {
  cognitoUser: CognitoUser | null;
  setCognitoUser: (user: CognitoUser | null) => void;
  user: UserWithoutTypeName | null;
  setUser: (user: any) => void;
  signOut: () => void;
}

const defaultAuthValue: AuthContextType = {
  cognitoUser: null,
  setCognitoUser: () => {},
  user: null,
  setUser: () => {},
  signOut: () => {},
};

const AuthContext = createContext<AuthContextType>(defaultAuthValue);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null);
  const { userData } = useUserDataContext();
  const [user, setUser] = useState<UserWithoutTypeName | null>(null);

  const getUserVariables = {
    variables: {
      //@ts-ignore
      id: cognitoUser?.attributes?.["custom:user_id"],
    },
  };

  const { data: userQueryData, loading: userQueryDataLoading, error: userError } = useGetUser(getUserVariables);
  const { updateUser, data: updateUserData, loading: loadingUpdateUser, error: updateUserError } = useUpdateUser();

  useEffect(() => {
    if (userQueryData?.getUser) {
      setUser(userQueryData.getUser);
    }
  }, [cognitoUser, userQueryData, userQueryDataLoading, userError]);

  useEffect(() => {
    checkCurrentUser();

    // Subscribe to authentication events
    const listener = (data: { payload: { event: any } }) => {
      switch (data.payload.event) {
        case "signIn":
          checkCurrentUser(); // Refresh the user data upon sign-in
          break;
        case "signOut":
          setCognitoUser(null); // Clear user data upon sign-out
          break;
        default:
          break;
      }
    };

    Hub.listen("auth", listener); // Listen for authentication events

    // Clean up listener on unmount
    return () => Hub.remove("auth", listener);
  }, []);

  const checkCurrentUser = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      setCognitoUser(currentUser);
    } catch (error) {
      console.error("Error checking current user", error);
      setCognitoUser(null); // Ensure state is cleared if there is no authenticated user
    }
  };

  // Sign out helper
  const signOut = async () => {
    try {
      await Auth.signOut();
      setCognitoUser(null);
      if (user !== null) {
        setUser(null);
      }
    } catch (error) {
      console.error("Error signing out", error);
    }
  };

  const value = {
    cognitoUser,
    setCognitoUser,
    user,
    setUser,
    signOut,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextType => {
  return useContext(AuthContext);
};
