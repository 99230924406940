import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

interface ApexChartProps {
  data: number[]; // Array of numbers for the chart data
}

const ApexChart: React.FC<ApexChartProps> = ({ data }) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
  const [chartOptions, setChartOptions] = useState<ApexOptions>({
    chart: {
      height: 350,
      type: "line",
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    xaxis: {
      categories: [""],
      tickAmount: 1,
      labels: {
        rotate: -45,
      },
    },
    stroke: {
      width: 5,
      curve: "smooth",
    },
    title: {
      text: "Revenue",
      align: "left",
      style: {
        fontSize: "16px",
        color: "#666",
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        gradientToColors: ["#FDD835"],
        shadeIntensity: 1,
        type: "horizontal",
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100],
      },
    },
    yaxis: {
      min: -10,
      max: 40,
    },
  });

  // Create an array of days for the current month
  const categories = Array.from({ length: daysInMonth }, (_, index) => {
    const date = new Date(currentYear, currentMonth, index + 1);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  });

  const series = [
    {
      name: "Revenue",
      data: data, // Use the data prop for the chart series
    },
  ];

  // Adjust the tickAmount based on the length of the data
  const tickAmount = Math.max(6, Math.floor(categories.length / (data.length || 1)));

  useEffect(() => {
    setChartOptions({
      chart: {
        height: 350,
        type: "line",
        animations: {
          enabled: true,
          easing: "easeinout",
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },
      xaxis: {
        categories: categories.slice(0, data.length), // Use only as many days as we have data for
        tickAmount: tickAmount,
        labels: {
          rotate: -45,
        },
      },
      stroke: {
        width: 5,
        curve: "smooth",
      },
      title: {
        text: "Revenue",
        align: "left",
        style: {
          fontSize: "16px",
          color: "#666",
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          gradientToColors: ["#FDD835"],
          shadeIntensity: 1,
          type: "horizontal",
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100, 100, 100],
        },
      },
      yaxis: {
        min: 0,
        max: 100000,
      },
    });
  }, [data]);

  return <ReactApexChart options={chartOptions} series={series} type="line" height={350} />;
};

export default ApexChart;
