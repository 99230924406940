import React from "react";
import { Box, VStack, Image, Text, Button, Link } from "@chakra-ui/react";
import unAuthorizedSvg from "./Assets/unAuthorized.svg";
import { useNavigate } from "react-router-dom";

const Unauthorized = () => {
  const navigate = useNavigate();
  return (
    <Box w="full" minH="90vh" bg="#f4eee9">
      <VStack w="full" minH="80vh" justifyContent="center" p="10px">
        <Image w="100%" maxW="600px" src={unAuthorizedSvg} mt="10vh" />
        <Text w="full" textAlign="center" fontSize="32px" mt="20px">
          Whoops...
        </Text>
        <Text w="full" textAlign="center">
          Looks like you aren't authorized to visit this page.
        </Text>
        <Text w="full" textAlign="center">
          If you have a Solar Installer account, please log in to your account{" "}
          <Link href="/installer/log-in" color="blue">
            here
          </Link>
          .
        </Text>
        <Button mt="40px" bgColor="black" color="white" _hover={{ bgColor: "gray.700" }} onClick={() => navigate("/LandingPage")}>
          Return Home
        </Button>
      </VStack>
    </Box>
  );
};
export default Unauthorized;
