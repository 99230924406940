import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Text, Center, ChakraProvider, Image, HStack, Progress, Icon, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, Button, VStack, useToast } from '@chakra-ui/react';
import { GrDocumentText } from 'react-icons/gr';
import { useAuth } from '../../Providers/AuthenticationProvider';
import axios from 'axios';

const styles = {
  dropzone: {
    border: "2px dashed white",
    borderRadius: "16px",
    padding: "20px",
    textAlign: "center",
    cursor: "pointer",
    color: "white",
  },
};

const DocumentUpload = ({setFilesHaveBeenUploaded, setErrorUploadingImage}) => {
  const [loadingFileUpload, setLoadingFileUpload] = useState(false);
  const { userData } = useAuth();
  const toast = useToast();

  const handleFileUpload = async (files) => {
    setLoadingFileUpload(true);
    const file = files[0];
    
    if (userData && userData.id) {
      try {
        // Convert file to Base64
        const fileBase64 = await convertToBase64(file);

        // Prepare the request payload
        const payload = {
          userId: userData.id,
          imageBase64: fileBase64,
          imageType: file.type,
        };
        // Send POST request to your Lambda API Gateway endpoint
        const response = await axios.post('https://4zpask5mw0.execute-api.us-east-2.amazonaws.com/dev/add-user-images', payload);
        setFilesHaveBeenUploaded(true);
        if(response){
          setErrorUploadingImage(false);
          setLoadingFileUpload(false);
        }
        if(response.data.statusCode === 501){
          setErrorUploadingImage(true);
          toast({
            title: "Error uploading image",
            description: "File type not allowed.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
        if(response.data.statusCode === 500){
          setErrorUploadingImage(true);
          toast({
            title: "Error uploading image",
            description: "Please make sure the file size is not too large, or try again later.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      } catch (error) {
        setLoadingFileUpload(false);
        toast({
          title: "Error uploading image",
          description: "Please make sure the file is not too large and is in the correct format.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        console.error('Error uploading file:', error);
      }
    }
    // TODO: Add error handling for when installer is not defined
  };

  // Function to convert file to Base64
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]); // Split to get base64 part
      reader.onerror = error => reject(error);
    });
  };

  return (
    <ChakraProvider>
      <Dropzone onFileUpload={handleFileUpload} loadingFileUpload={loadingFileUpload} />
    </ChakraProvider>
  );
}

export default DocumentUpload;


const Dropzone = ({ onFileUpload, loadingFileUpload }) => {
  const [files, setFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(prevFiles => [...prevFiles, ...acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    }))]);

        onFileUpload(acceptedFiles);

  }, [onFileUpload]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true
  });

  useEffect(() => {
    return () => {
      files.forEach(file => URL.revokeObjectURL(file.preview));
    };
  }, [files]);

  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const cancelRef = React.useRef();

  const handleDelete = (file) => {
    setFileToDelete(file);
    setIsAlertDialogOpen(true);
  }

  const confirmDelete = () => {
    if (fileToDelete) {
      setFiles(prevFiles => prevFiles.filter(f => f !== fileToDelete));
      setFileToDelete(null);
    }
    setIsAlertDialogOpen(false);
  }

  const getFileType = (mimeType) => {
    if (mimeType.startsWith('image/')) return 'image';
    if (mimeType.startsWith('text/')) return 'text';
    if (mimeType === 'application/pdf') return 'pdf';
    // ... add other types as needed
    return 'unknown';
  };

  return (
    <Box p="4">
      <Center h="200px" {...getRootProps()} style={styles.dropzone} display="flex" alignItems="center">
        <input {...getInputProps()} />
        {
          isDragActive ?
            <Text>Drop the files here ...</Text> :
            <Text>Drag 'n' drop some files here, or click to select files</Text>
        }
      </Center>
      {loadingFileUpload && <Progress mt={4} isIndeterminate />}
      <VStack>
      {files.length > 0 && (
        <VStack w='full' maxW={{base: 'full', lg: '40vw'}} borderRadius='6px' bgColor='rgba(255,255,255,.2)' mt={5} pt='20px'>
          <Text color='white'>Uploaded documents</Text>
      <HStack w='100%' p='20px' spacing={4} justifyContent='flex-start' flexWrap='wrap'>
        {files.map((file, i) => (
          <Box key={`file.name${i}`} bgColor='white' borderRadius='6px' shadow='rgba(0, 0, 0, 0.1) 0px 5px 15px 0px' w='120px' h='140px' display='flex' flexDirection='column' p='10px'>
            {/* <Box w='100%' display='flex' justifyContent='flex-end'>
            <Icon as={AiOutlineClose} cursor='pointer' onClick={() => handleDelete(file)} />
            </Box> */}
            <Box w='100%' display='flex' justifyContent='center'>
              {getFileType(file.type) === 'image' ? (
              <Image h='120px' src={file.preview} alt={file.name} objectFit="cover" borderRadius="md" />
              ) : (
              <Icon as={GrDocumentText} boxSize='80px' />
              )}
            </Box>
            {/* <Box w='100%' display='flex' justifyContent='center'>
              <Text mt={5} fontSize='10px'>{file.name}</Text>
            </Box> */}
          </Box>
        ))}
    </HStack>
    </VStack>
      )}
    </VStack>
    
      <AlertDialog isOpen={isAlertDialogOpen} leastDestructiveRef={cancelRef} onClose={() => setIsAlertDialogOpen(false)}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete File
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this file? This action cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsAlertDialogOpen(false)}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={confirmDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}