import { useState } from "react";
import {
  Box,
  VStack,
  HStack,
  Text,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  Button,
  NumberInput,
  NumberInputField,
} from "@chakra-ui/react";
import AdminNavBar from "../Components/AdminNavBar";
import { useListUsers } from "../../hooks/userHooks";

const AdminUserImageProcessing = () => {
  const { isOpen: pictureIsOpen, onOpen: pictureOnOpen, onClose: pictureOnClose } = useDisclosure();
  const [pictureSrc, setPictureSrc] = useState<(string | null)[] | null | undefined>([""]);
  const { data: usersList, loading: loadingUsersList, error: usersListError } = useListUsers();

  return (
    <Box w="full" minH="100vh">
      <AdminNavBar />
      <VStack w="full" p="40px">
        {usersList?.listUsers?.items.map((user) => {
          if (user?.user_images && user.user_images.length > 0) {
            return (
              <HStack w="full" align="stretch">
                <HStack
                  w="full"
                  justifyContent="space-between"
                  borderRadius="6px"
                  border="2px solid black"
                  p="20px"
                  cursor="pointer"
                  _hover={{ shadow: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px" }}
                  onClick={() => {
                    setPictureSrc(user.user_images);
                    pictureOnOpen();
                  }}
                >
                  <Text fontWeight="bold">{user?.email}</Text>
                  <Text fontWeight="bold">{user.user_images.length}</Text>
                </HStack>
              </HStack>
            );
          }
        })}
      </VStack>
      <PictureModal src={pictureSrc || [""]} pictureIsOpen={pictureIsOpen} pictureOnClose={pictureOnClose} />
    </Box>
  );
};
export default AdminUserImageProcessing;

const PictureModal = ({ src, pictureIsOpen, pictureOnClose }: { src: (string | null)[] | null | undefined; pictureIsOpen: boolean; pictureOnClose: () => void }) => {
  const [srcIndex, setSrcIndex] = useState(0);
  const [actualUsage, setActualUsage] = useState(0);

  return (
    <Modal isOpen={pictureIsOpen} onClose={pictureOnClose}>
      <ModalOverlay />
      <ModalContent minW="100vw" pt="40px">
        <ModalCloseButton />
        <ModalBody>
          <HStack w="full">
            <HStack w="60vw">
              <Button onClick={() => setSrcIndex(srcIndex - 1)}>left</Button>
              <Image w="full" src={src?.[srcIndex] || "fallback url"} />
              <Button onClick={() => setSrcIndex(srcIndex + 1)}>right</Button>
            </HStack>
            <VStack w="full">
              <VStack>
                <Text fontWeight="bold">User's annual kWh usage:</Text>
                <NumberInput w="full" fontFamily="'eurostile', sans-serif" onChange={(valueString: string) => setActualUsage(Number(valueString))} variant="unstyled" isRequired>
                  <NumberInputField h="60px" border="1px solid black" bg="rgba(255,255,255,.9)" pl="8px" />
                </NumberInput>
              </VStack>
            </VStack>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
