import React, { useState, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Upload, 
  FileText, 
  CheckCircle, 
  AlertCircle, 
  Info,
  X,
  ChevronRight
} from 'lucide-react';
import { useAuth } from '../../Providers/AuthenticationProvider';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const BillUploadSection = () => {
  const [dragActive, setDragActive] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);
  const { userData } = useAuth();
  const navigate = useNavigate();

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    
    const file = e.dataTransfer.files[0];
    handleFile(file);
  };

  const handleFile = async (file) => {
    if (file?.type === "application/pdf" || file?.type.startsWith("image/")) {
      if (file.size > 10 * 1024 * 1024) {
        setError("File size must be less than 10MB");
        return;
      }

      setIsLoading(true);
      setError(null);

      if (userData && userData.id) {
        try {
          const fileBase64 = await convertToBase64(file);
          const payload = {
            userId: userData.id,
            imageBase64: fileBase64,
            imageType: file.type,
          };

          const response = await axios.post('https://4zpask5mw0.execute-api.us-east-2.amazonaws.com/dev/add-user-images', payload);
          
          if (response.data.statusCode === 501) {
            setError("File type not allowed");
            return;
          }
          
          if (response.data.statusCode === 500) {
            setError("Error uploading file. Please try again.");
            return;
          }

          setUploadedFile(file);
          
        } catch (error) {
          setError("Error uploading file. Please try again.");
          console.error('Error uploading file:', error);
        } finally {
          setIsLoading(false);
        }
      }
    } else {
      setError("Please upload a PDF or image file of your electric bill");
    }
  };

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    handleFile(file);
  };

  const handleSubmit = () => {
    if (uploadedFile) {
      navigate('/loan-message')
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]); // Split to get base64 part
      reader.onerror = error => reject(error);
    });
  };

  return (
    <section className="min-h-screen bg-[#121212] flex items-center px-4 py-8">
      <div className="max-w-2xl mx-auto w-full">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="space-y-8"
        >
          {/* Header */}
          <div className="text-center">
            <h1 className="text-4xl font-bold text-blue-50 mb-4">
              Upload Your Electric Bill
            </h1>
            <p className="text-xl text-blue-50/70">
              We'll analyze your usage to design your perfect solar system
            </p>
          </div>

          {/* Upload Area */}
          <div
            className={`relative border-2 border-dashed rounded-lg p-12 text-center transition-colors
              ${dragActive ? 'border-amber-500 bg-amber-500/5' : 'border-white/10 bg-white/5'}
              ${uploadedFile ? 'border-green-500 bg-green-500/5' : ''}`}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          >
            <input
              ref={fileInputRef}
              type="file"
              className="hidden"
              accept="application/pdf,image/*"
              onChange={handleFileInput}
            />

            {uploadedFile ? (
              <motion.div 
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                className="space-y-4"
              >
                <div className="bg-green-500/10 rounded-full p-4 inline-block">
                  <CheckCircle className="w-12 h-12 text-green-500" />
                </div>
                <div>
                  <p className="text-blue-50 text-xl font-semibold mb-2">
                    Bill Uploaded Successfully
                  </p>
                  <p className="text-blue-50/70">
                    {uploadedFile.name}
                  </p>
                </div>
                <button
                  onClick={() => setUploadedFile(null)}
                  className="text-blue-50/70 hover:text-blue-50 transition-colors inline-flex items-center"
                >
                  <X className="w-4 h-4 mr-2" />
                  Choose different file
                </button>
              </motion.div>
            ) : (
              <div className="space-y-6">
                <div className="bg-amber-500/10 rounded-full p-4 inline-block">
                  <Upload className="w-12 h-12 text-amber-500" />
                </div>
                <div>
                  <p className="text-xl text-blue-50 mb-2">
                    Drag and drop your bill here
                  </p>
                  <p className="text-blue-50/70">
                    or{' '}
                    <button
                      onClick={() => fileInputRef.current?.click()}
                      className="text-amber-500 hover:text-amber-400 transition-colors"
                    >
                      browse files
                    </button>
                  </p>
                </div>
                <div className="flex justify-center space-x-4 text-sm text-blue-50/50">
                  <span className="flex items-center">
                    <FileText className="w-4 h-4 mr-1" /> PDF or Images
                  </span>
                  <span className="flex items-center">
                    <Info className="w-4 h-4 mr-1" /> Up to 10MB
                  </span>
                </div>
              </div>
            )}
          </div>

          {/* Error Message */}
          <AnimatePresence>
            {error && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="bg-red-500/10 text-red-400 p-4 rounded-lg flex items-center"
              >
                <AlertCircle className="w-5 h-5 mr-2 flex-shrink-0" />
                {error}
              </motion.div>
            )}
          </AnimatePresence>

          {/* Submit Button */}
          <div className="space-y-4">
            <button
              onClick={handleSubmit}
              disabled={!uploadedFile}
              className={`w-full py-4 rounded-lg font-semibold transition-all duration-300 flex items-center justify-center
                ${uploadedFile 
                  ? 'bg-amber-500 hover:bg-amber-400 text-[#121212] hover:translate-x-2' 
                  : 'bg-white/10 text-blue-50/30 cursor-not-allowed'}`}
            >
              Continue
              <ChevronRight className="ml-2 w-5 h-5" />
            </button>
            
            <p className="text-center text-blue-50/50 text-sm">
              Your bill information is securely processed and encrypted
            </p>
          </div>

          {/* Helper Info */}
          <div className="bg-blue-500/10 rounded-lg p-4 flex items-start space-x-3">
            <Info className="w-5 h-5 text-blue-400 flex-shrink-0 mt-1" />
            <p className="text-sm text-blue-50/70">
              We'll analyze your bill to determine your energy usage patterns and design 
              a solar system that maximizes your savings. Your information is kept private 
              and secure.
            </p>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default BillUploadSection;