import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useBreakpointValue,
  Image,
  Link,
  DrawerBody,
  DrawerHeader,
} from "@chakra-ui/react";
import { FiMenu, FiBell, FiChevronDown } from "react-icons/fi";
import { IconType } from "react-icons";
import { GoMail } from "react-icons/go";
import { BsFiles, BsCardImage } from "react-icons/bs";
import { IoTodayOutline } from "react-icons/io5";
import { BiHomeAlt } from "react-icons/bi";
import { useEffect, useState } from "react";
import logoLight from "../../Assets/logoLight.svg";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Providers/AuthProvider";
import { useOnCreateMessageSubscription } from "../../hooks/userHooks";
import { Bell, MessageSquare, X } from "lucide-react";
import { useAuth as useAuthContext } from "../../Providers/AuthenticationProvider";
import { motion, AnimatePresence } from "framer-motion";

interface LinkItemProps {
  name: string;
  icon: IconType;
  route: string;
}

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: React.ReactNode;
}

interface MobileProps extends FlexProps {
  onOpen: () => void;
  sideBarHover: boolean;
  signOut: () => void;
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
  sideBarHover: boolean;
  setSideBarHover: React.Dispatch<React.SetStateAction<boolean>>;
}

const LinkItems: Array<LinkItemProps> = [
  { name: "Home", icon: BiHomeAlt, route: "" },
  { name: "Final Design", icon: BsCardImage, route: "final-design" },
  { name: "Documents", icon: BsFiles, route: "documents" },
  { name: "Calendar", icon: IoTodayOutline, route: "calendar" },
  { name: "Support Chat", icon: GoMail, route: "support-chat" },
];

const SidebarContent = ({ onClose, sideBarHover, setSideBarHover, ...rest }: SidebarProps) => {
  const breakPoint = useBreakpointValue({ base: "base", sm: "sm", md: "md", lg: "lg", xl: "xl" });
  const navigate = useNavigate();

  return (
    <Box color="white" bgColor="#121212" w={{ base: "full", md: 60 }} pos="fixed" h="full" {...rest} shadow="lg" fontFamily="'eurostile', sans-serif">
      <VStack w="full" h="full" alignItems="flex-start" justifyContent="space-between">
        <VStack w="full" alignItems="flex-start">
          <Flex w="full" h="20" alignItems="center" justifyContent={{ base: "space-between", md: "flex-start" }} pl="24px">
            <HStack onClick={() => navigate("/")} cursor="pointer">
              <Image h="38px" w="38px" src={breakPoint === "base" || breakPoint === "sm" ? logoLight : logoLight} />
              <Link href="/" textAlign={{ base: "center", md: "left" }} fontFamily="'new-astro', sans-serif" fontSize="28px">
                Klaravia
              </Link>
            </HStack>
            <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
          </Flex>
          {LinkItems.map((link) => (
            <NavItem key={link.name} icon={link.icon} onClick={() => navigate(`/user/${link.route}`)}>
              <Box _hover={{ w: "100%" }} h="24px">
                <Text w="120px" overflow="hidden">
                  {link.name}
                </Text>
              </Box>
            </NavItem>
          ))}
        </VStack>
        {/* TODO: Implement help center */}
        {/* <NavItem key={"Help Center"} icon={IoMdHelpCircleOutline} mb="20px" cursor="not-allowed">
          <Box _hover={{ w: "100%" }} h="24px">
            <Text
              w="120px"
              overflow="hidden" // Ensure the text is hidden when the box is narrow
            >
              Help Center
            </Text>
          </Box>
        </NavItem> */}
      </VStack>
    </Box>
  );
};

const NavItem = ({ icon, children, ...rest }: NavItemProps) => {
  return (
    <Box as="a" style={{ textDecoration: "none" }} _focus={{ boxShadow: "none" }}>
      <Flex
        align="center"
        p="4"
        mx="6"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "rgba(255,255,255,.2)",
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Box>
  );
};

const MobileNav = ({ onOpen, sideBarHover, signOut, ...rest }: MobileProps) => {
  const navigate = useNavigate();
  const { onOpen: onNotificationsOpen, onClose: onNotificationsClose, isOpen: isNotificationsOpen } = useDisclosure();
  const [hasViewedNewMessages, setHasViewedNewMessages] = useState<boolean>(false);
  const [messageCount, setMessageCount] = useState<number>(0);
  const { userData } = useAuthContext();

  const { data: subscriptionData, loading: loadingMessages, error: subErr } = useOnCreateMessageSubscription(userData?.id || "");

  useEffect(() => {
    if (subscriptionData && subscriptionData?.onCreateMessage && subscriptionData?.onCreateMessage?.sender) {
      if (subscriptionData?.onCreateMessage?.sender === "support") {
        setMessageCount(messageCount + 1);
      }
    }
  }, [subscriptionData]);

  const handleNotificationClose = () => {
    onNotificationsClose();
    setMessageCount(0);
  };

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height={{ base: "60px", md: "80px" }}
      alignItems="center"
      bg={{ base: "#121212", md: "#1f1f1f" }}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      transition="margin 0.4s ease-in-out"
      {...rest}
    >
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        _hover={{ bgColor: "rgba(0,0,0,.2)" }}
        _focus={{ bgColor: "rgba(0,0,0,.2)" }}
        variant="ghost"
        color="white"
        aria-label="open menu"
        icon={<FiMenu />}
      />
      <HStack w="full" pl="20px">
        <Link
          href="/"
          textAlign={{ base: "center", md: "left" }}
          fontFamily="'new-astro', sans-serif"
          fontSize="24px"
          color={useColorModeValue("white", "white")}
          display={{ base: "block", md: "none" }}
        >
          Klaravia
        </Link>
      </HStack>
      <HStack spacing={{ base: "3", md: "6" }}>
        {messageCount > 0 && (
          <Box
            w="18px"
            h="18px"
            borderRadius="50%"
            bgColor="red.500"
            color="white"
            fontWeight="bold"
            fontSize="10px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="relative"
            left={{ base: "52px", md: "66px" }}
            bottom="12px"
            zIndex={10}
          >
            <Text>{messageCount}</Text>
          </Box>
        )}
        <IconButton
          size="lg"
          variant="ghost"
          _hover={{ bg: "none" }}
          onClick={onNotificationsOpen}
          color={{ base: "white", md: "black" }}
          aria-label="open notifications"
          icon={<FiBell color="white" />}
        />
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: "none" }}>
              <HStack>
                <Avatar
                  size={"sm"}
                  fontFamily="'eurostile', sans-serif"
                  bgColor="gray.400"
                  border="1px solid #D3D3D3"
                  color="white"
                  name={userData && userData.email ? userData.email : "Error"}
                />
                <VStack display={{ base: "none", md: "flex" }} alignItems="flex-start" spacing="1px" ml="2">
                  <Text fontSize="sm" fontFamily="'eurostile', sans-serif" color="#EEEEEE">
                    {userData && userData.email ? userData.email.slice(0, userData.email.indexOf("@")) : "Error"}
                  </Text>
                  <Text fontSize="xs" color="gray.400">
                    Your Account
                  </Text>
                </VStack>
                <Box display={{ base: "none", md: "flex" }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList bg={useColorModeValue("white", "gray.900")} borderColor={useColorModeValue("gray.200", "gray.700")}>
              <MenuItem
                onClick={() => {
                  navigate("/log-in");
                  signOut();
                }}
              >
                Sign out
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
      <Drawer isOpen={isNotificationsOpen} placement="right" onClose={handleNotificationClose}>
        <DrawerContent className="bg-white/10 backdrop-blur-md border-lg border-white/10">
          <DrawerHeader bgColor="#121212">
            <HStack justify="space-between">
              <Text className="text-blue-50 font-medium">Notifications</Text>
              <motion.button whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }} onClick={handleNotificationClose} className="p-2 hover:bg-white/5 rounded-lg transition-colors">
                <X className="w-5 h-5 text-blue-50/70" />
              </motion.button>
            </HStack>
          </DrawerHeader>

          <DrawerBody p={4} bgColor="#121212">
            <VStack spacing={3}>
              <AnimatePresence>
                {messageCount > 0 ? (
                  messageCount > 0 &&
                  Array.from({ length: messageCount }, (_, i) => (
                    <motion.div
                      key={i}
                      initial={{ opacity: 0, x: 20 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: -20 }}
                      transition={{ duration: 0.2 }}
                      className="w-full"
                    >
                      <motion.div
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        onClick={() => {
                          navigate(`/user/support-chat`);
                          onNotificationsClose();
                          setMessageCount(0);
                        }}
                        className="
                    w-full p-4 rounded-lg cursor-pointer
                    bg-gradient-to-r from-amber-500 to-amber-600
                    hover:from-amber-600 hover:to-amber-700
                    border border-white/10 shadow-lg
                    transition-all duration-200
                  "
                      >
                        <HStack spacing={3} align="start">
                          <div className="p-2 bg-white/10 rounded-full">
                            <MessageSquare className="w-4 h-4 text-white" />
                          </div>
                          <VStack align="start" spacing={1}>
                            <Text className="text-white font-medium">New Message</Text>
                            <Text className="text-white/80 text-sm">You have received a new message from Klaravia support.</Text>
                            <Text className="text-white/60 text-xs">Just now</Text>
                          </VStack>
                        </HStack>
                      </motion.div>
                    </motion.div>
                  ))
                ) : (
                  <motion.div initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: 0 }} className="w-full">
                    <VStack
                      className="
                  w-full p-6 rounded-lg
                  bg-white/5 border border-white/10
                "
                      spacing={4}
                    >
                      <Box className="p-4 rounded-full bg-white/5">
                        <Bell className="w-6 h-6 text-blue-50/50" />
                      </Box>
                      <Text className="text-blue-50/70 text-center">No new notifications</Text>
                    </VStack>
                  </motion.div>
                )}
              </AnimatePresence>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

const SidebarWithHeader = ({ children }: { children: React.ReactNode }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOnboardingOpen, onOpen: onOnboardingOpen, onClose: onOnboardingClose } = useDisclosure();
  const [sideBarHover, setSideBarHover] = useState<boolean>(false);

  const { signOut } = useAuth();

  useEffect(() => {
    onOnboardingOpen();
  }, []);

  return (
    <Box minH="100vh">
      <SidebarContent onClose={() => onClose} display={{ base: "none", md: "block" }} sideBarHover={sideBarHover} setSideBarHover={setSideBarHover} />
      <Drawer isOpen={isOpen} placement="left" onClose={onClose} returnFocusOnClose={false} onOverlayClick={onClose} size="xs">
        <DrawerContent>
          <SidebarContent onClose={onClose} sideBarHover={sideBarHover} setSideBarHover={setSideBarHover} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} sideBarHover={sideBarHover} signOut={signOut} />
      <Box ml={{ base: 0, md: 60 }} transition="margin 0.4s ease-in-out">
        {children}
      </Box>
    </Box>
  );
};

export default SidebarWithHeader;
