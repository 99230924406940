import React, { createContext, useState, useEffect, useContext } from "react";
import { Auth } from "aws-amplify";
import { getCurrentUser, isAuthenticated } from "../userSessionHandlers";
import { useGetUser } from "../hooks/userHooks";
import { User } from "../API";

// Define types for user and user data
interface CognitoUser {
  username: string;
  attributes: {
    sub: string;
    email: string;
    "custom:user_id": string;
    "custom:first_name": string;
    "custom:last_name": string;
  };
}

interface AuthContextType {
  user: CognitoUser | null;
  userData: User | null;
  checkAuth: () => Promise<void>;
  loading: boolean;
  signOut: () => Promise<void>;
  isAuthenticated: () => Promise<Boolean>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const {
    data: userData,
    loading: userDataLoading,
    refetch: refetchUserData,
  } = useGetUser({
    variables: {
      id: user?.attributes.sub || "",
    },
    skip: !user, // Skip the query if there's no user
  });

  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = async (): Promise<void> => {
    setLoading(true);
    try {
      const authenticated = await isAuthenticated();
      if (authenticated) {
        const currentUser = await getCurrentUser();
        setUser(currentUser);
        if (currentUser?.attributes["custom:user_id"]) {
          refetchUserData(); // Refetch user data when auth state changes
        }
      } else {
        setUser(null);
      }
    } catch (error) {
      console.error("Error checking authentication:", error);
    } finally {
      setLoading(false);
    }
  };

  const signOut = async (): Promise<void> => {
    try {
      await Auth.signOut();
      setUser(null);
    } catch (error) {
      console.error("Error signing out:", error);
      throw error;
    }
  };

  const contextValue: AuthContextType = {
    user,
    userData: userData?.getUser || null, // Assuming your query returns { getUser: UserData }
    checkAuth,
    loading: loading || userDataLoading,
    signOut,
    isAuthenticated,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export default AuthProvider;
