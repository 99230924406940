import React, { useState } from "react";
import { Box, VStack, HStack, Text, Button, Image, IconButton, Link } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const PurchaseOrQuote = () => {
  const navigate = useNavigate();

  const handlePurchaseOrQuote = (answer: string) => {
    if (answer === "Purchase") {
      navigate("/home-details");
    }
    if (answer === "Quote") {
      navigate("/quote");
    }
  };

  return (
    <Box w="full" minH="90vh" display="flex" alignItems="center" bg="#f4eee9" pt="60px" overflowX="hidden" fontFamily="'eurostile', sans-serif">
      <Box w="full" display="flex" justifyContent="center">
        <Box w="full" display="flex" justifyContent="center">
          <Box w={{ base: "90%", lg: "50%" }} pt="80px" borderRadius="6px">
            <Text w="full" textAlign="center" fontSize={{ base: "20px", lg: "30px" }} fontWeight="bold" pb="0px" color="black">
              Will you be purchasing solar today?
            </Text>
            <VStack w="full" minH={{ base: "30vh", lg: "30vh" }} justifyContent="center">
              <HStack justifyContent={{ base: "center", lg: "center" }} align="stretch" gap={{ base: "10px", lg: "80px" }} flexWrap="wrap">
                <Box
                  w={{ base: "120px", lg: "260px" }}
                  h={{ base: "50px", lg: "100px" }}
                  borderRadius={{ base: "12px", lg: "30px" }}
                  bgColor="#1acd41"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  borderBottom="10px solid #099528"
                  _hover={{
                    bgColor: "#50d481",
                  }}
                  cursor="pointer"
                  onClick={() => handlePurchaseOrQuote("Purchase")}
                >
                  <Text fontSize={{ base: "24px", lg: "36px" }} color="#FFF" fontWeight={700} zIndex="2">
                    Purchase
                  </Text>
                </Box>
                {/* <Box position="relative" cursor="pointer"> */}
                <Box
                  w={{ base: "120px", lg: "260px" }}
                  h={{ base: "50px", lg: "100px" }}
                  cursor="pointer"
                  borderRadius={{ base: "12px", lg: "30px" }}
                  bgColor="#4046e8"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  borderBottom="10px solid #252bc3"
                  _hover={{
                    "> :nth-child(2)": { bottom: "-25px" },
                    "> :nth-child(3)": { bottom: "-50px" },
                    bgColor: "#5157ee",
                  }}
                  onClick={() => handlePurchaseOrQuote("Quote")}
                  zIndex="2"
                >
                  <Text fontSize={{ base: "24px", lg: "36px" }} color="#FFF" fontWeight={700} zIndex="2">
                    Free quote
                  </Text>
                </Box>
              </HStack>
            </VStack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PurchaseOrQuote;
