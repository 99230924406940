import { gql, QueryHookOptions, QueryResult, useQuery, useMutation, useSubscription } from "@apollo/client";
import { GetInstallerQuery, GetUserQuery, ListInstallersQuery, ListMessagesQuery, ListStatesQuery, ListUsersQuery } from "../API";

export const listStates = gql`
query ListStates(
  $filter: ModelStateFilterInput
  $limit: Int
  $nextToken: String
) {
  listStates(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      __typename
    }
    nextToken
    __typename
  }
}
`;

export const getInstaller = gql`
query GetInstaller($id: ID!) {
  getInstaller(id: $id) {
    id
    cognitoPassword
    company_name
    company_address
    company_phone_number
    company_email
    company_tag_line
    company_red_line
    company_about_text
    company_cover_image_url
    available_panels {
      manufacturer
      seriesName
      additional_cost
      requires_watt_multiplier
      watt_multiplier
      pMax
      width
      height
      dimensionsUnit
      efficiency
      panelSpecificationsUrl
      warrantyYears
      image_url
      default_panel
      __typename
    }
    available_inverters {
      manufacturer
      seriesName
      additional_cost
      requires_watt_multiplier
      watt_multiplier
      spec_sheet_url
      __typename
    }
    available_batteries {
      manufacturer
      seriesName
      additional_cost
      spec_sheet_url
      image_url
      power_output
      storage_capacity
      default_battery
      __typename
    }
    adders {
      type
      cost
      requires_watt_multiplier
      watt_multiplier
      __typename
    }
    installerStates {
      nextToken
      __typename
    }
    users {
      items {
    id
    username
    first_name
    last_name
    birth_date
    email
    user_type
    phone_number
    home_number_of_stories
    roof_condition
    roof_material
    address
    stateId
    state {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    utility_provider
    home_owner
    home_has_foundation
    electrical_system_status
    is_hoa_property
    has_home_owners_insurance
    installerId
    payment_method
    electric_bill
    estimatedAnnualUsage
    initialDesign {
      jobId
      productId
      imageUrl
      __typename
    }
    finalDesign {
      designPdf
      __typename
    }
    user_images
    projectStatus
    userStatus
    salesforceId
    installerSalesforceId
    createdAt
    updatedAt
    __typename
  }
    }
    google_place_id
    installer_reviews {
      id
      pfp
      name
      rating
      title
      review
      __typename
    }
    installer_rating
    salesforceId
    createdAt
    updatedAt
    __typename
  }
}
`;

export const createUserMutation = gql`
mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    username
    first_name
    last_name
    birth_date
    email
    user_type
    phone_number
    home_number_of_stories
    roof_condition
    roof_material
    address
    stateId
    state {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    utility_provider
    home_owner
    home_has_foundation
    electrical_system_status
    is_hoa_property
    has_home_owners_insurance
    installerId
    installer {
      id
      cognitoPassword
      company_name
      company_address
      company_phone_number
      company_email
      company_tag_line
      company_red_line
      company_about_text
      company_cover_image_url
      google_place_id
      installer_rating
      salesforceId
      years_in_business
      average_install_time
      createdAt
      updatedAt
      __typename
    }
    payment_method
    electric_bill
    estimatedAnnualUsage
    initialDesign {
      jobId
      productId
      imageUrl
      __typename
    }
    finalDesign {
      designPdf
      __typename
    }
    user_images
    projectStatus
    userStatus
    measurementsOrderReportId
    salesforceId
    installerSalesforceId
    signed_installer_contract
    electric_bill_reviewed
    createdAt
    updatedAt
    __typename
  }
}
`
export function useCreateUser() {
  const [createUser, { data, loading, error }] = useMutation(createUserMutation);

  return {
    createUser,
    data,
    loading,
    error,
  };
}

export const updateUserMutation = gql`
mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    username
    first_name
    last_name
    birth_date
    cognito_id
    email
    user_type
    phone_number
    home_number_of_stories
    roof_condition
    roof_material
    address
    stateId
    state {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    utility_provider
    home_owner
    home_has_foundation
    electrical_system_status
    is_hoa_property
    has_home_owners_insurance
    installerId
    installer {
      id
      cognitoPassword
      company_name
      company_address
      company_phone_number
      company_email
      company_tag_line
      company_red_line
      company_about_text
      company_cover_image_url
      google_place_id
      installer_rating
      salesforceId
      years_in_business
      average_install_time
      user_agreement
      createdAt
      updatedAt
      __typename
    }
    payment_method
    electric_bill
    estimatedAnnualUsage
    initialDesign {
      jobId
      productId
      imageUrl
      __typename
    }
    finalDesign {
      designPdf
      __typename
    }
    user_images
    projectStatus
    userStatus
    measurementsOrderReportId
    salesforceId
    installerSalesforceId
    signed_installer_contract
    electric_bill_reviewed
    google_system_size
    requires_battery
    selected_battery {
      manufacturer
      seriesName
      additional_cost
      spec_sheet_url
      image_url
      power_output
      storage_capacity
      default_battery
      __typename
    }
    total_system_cost
    final_payment_amount
    site_survey_date
    installation_date
    final_payment_invoice
    user_agreed_to_final_design
    updated_by
    createdAt
    updatedAt
    __typename
  }
}
`

export function useUpdateUser() {
  const [updateUser, { data, loading, error }] = useMutation(updateUserMutation);

  return {
    updateUser,
    data,
    loading,
    error,
  };
}

export const listInstallers = gql`
query ListInstallers(
  $filter: ModelInstallerFilterInput
  $limit: Int
  $nextToken: String
) {
  listInstallers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      available_panels{
       seriesName
       manufacturer
        additional_cost
        requires_watt_multiplier
        watt_multiplier
        pMax
        width
        height
        dimensionsUnit
        efficiency
        panelSpecificationsUrl
        warrantyYears
        image_url
        default_panel
      }
      available_batteries {
      manufacturer
      seriesName
      additional_cost
      spec_sheet_url
      image_url
      power_output
      storage_capacity
      default_battery
      __typename
    }
      company_name
      company_address
      company_phone_number
      company_email
      company_tag_line
      company_red_line
      company_about_text
      company_cover_image_url
      google_place_id
      installer_rating
      salesforceId
      years_in_business
      average_install_time
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}`;

export function useListInstallers(
  options?: QueryHookOptions<ListInstallersQuery>,
): QueryResult<ListInstallersQuery> {
  return useQuery(listInstallers, options);
}

export const updateInstallerMutation = gql`
mutation UpdateInstaller(
  $input: UpdateInstallerInput!
  $condition: ModelInstallerConditionInput
) {
  updateInstaller(input: $input, condition: $condition) {
    id
    company_name
    company_address
    company_phone_number
    company_email
    company_tag_line
    company_red_line
    company_about_text
    company_cover_image_url
    available_panels {
      manufacturer
      seriesName
      pMax
      width
      height
      dimensionsUnit
      efficiency
      panelSpecificationsUrl
      warrantyYears
      __typename
    }
    available_inverters {
      manufacturer
      seriesName
      additional_cost
      spec_sheet_url
      __typename
    }
    available_batteries {
      manufacturer
      seriesName
      additional_cost
      spec_sheet_url
      __typename
    }
    adders {
      type
      cost
      requires_watt_multiplier
      watt_multiplier
      __typename
    }
    installerStates {
      nextToken
      __typename
    }
    users {
      nextToken
      __typename
    }
    google_place_id
    installer_reviews {
      id
      pfp
      rating
      title
      review
      __typename
    }
    installer_rating
    createdAt
    updatedAt
    __typename
  }
}
`;

export function useUpdateInstaller() {
  const [updateInstaller, { data, loading, error }] = useMutation(updateInstallerMutation);

  return {
    updateInstaller,
    data,
    loading,
    error,
  };
}

export const createInstallerStateMutation = gql`
mutation CreateInstallerState(
  $input: CreateInstallerStateInput!
  $condition: ModelInstallerStateConditionInput
) {
  createInstallerState(input: $input, condition: $condition) {
    id
    installer {
      id
      company_name
      company_address
      company_phone_number
      company_email
      company_tag_line
      company_red_line
      company_about_text
      company_cover_image_url
      google_place_id
      installer_rating
      createdAt
      updatedAt
      __typename
    }
    state {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    installerId
    stateId
    createdAt
    updatedAt
    __typename
  }
}
`;

export function useCreateInstallerState() {
  const [createInstallerState, { data, loading, error }] = useMutation(createInstallerStateMutation);

  return {
    createInstallerState,
    data,
    loading,
    error,
  };
}

export function useListStates(
  options?: QueryHookOptions<ListStatesQuery>,
): QueryResult<ListStatesQuery> {
  return useQuery(listStates, options);
}

export function useGetInstaller(
  options?: QueryHookOptions<GetInstallerQuery>,
): QueryResult<GetInstallerQuery> {
  return useQuery(getInstaller, options);
}

export const getUser = gql`
query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    username
    first_name
    last_name
    birth_date
    email
    user_type
    # phone_number
    home_number_of_stories
    roof_condition
    roof_material
    address
    stateId
    state {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    utility_provider
    home_owner
    home_has_foundation
    electrical_system_status
    is_hoa_property
    has_home_owners_insurance
    installerId
    installer {
      id
      cognitoPassword
      company_name
      company_address
      company_phone_number
      company_email
      company_tag_line
      company_red_line
      company_about_text
      company_cover_image_url
      google_place_id
      installer_rating
      user_agreement
      salesforceId
      createdAt
      updatedAt
      __typename
    }
    payment_method
    electric_bill
    estimatedAnnualUsage
    initialDesign {
      jobId
      productId
      imageUrl
      __typename
    }
    finalDesign {
      designPdf
      __typename
    }
    user_images
    projectStatus
    userStatus
    measurementsOrderReportId
    salesforceId
    installerSalesforceId
    signed_installer_contract
  google_system_size
    requires_battery
    selected_battery {
      manufacturer
      seriesName
      additional_cost
      spec_sheet_url
      __typename
    }
    total_system_cost
    final_payment_amount
    site_survey_date
    installation_date
    createdAt
    updatedAt
    __typename
  }
}
`;

export function useGetUser(
  options?: QueryHookOptions<GetUserQuery>,
): QueryResult<GetUserQuery> {
  return useQuery(getUser, options);
}

export const listUsers = gql`
query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      username
      first_name
      last_name
      birth_date
      email
      user_type
      phone_number
      home_number_of_stories
      roof_condition
      roof_material
      stateId
      utility_provider
      home_owner
      home_has_foundation
      electrical_system_status
      is_hoa_property
      has_home_owners_insurance
      installerId
      payment_method
      electric_bill
      estimatedAnnualUsage
      user_images
      projectStatus
      userStatus
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}`

export function useListUsers(
  options?: QueryHookOptions<ListUsersQuery>,
): QueryResult<ListUsersQuery> {
  return useQuery(listUsers, options);
}

export const createMessageMutation = gql`
mutation CreateMessage(
  $input: CreateMessageInput!
  $condition: ModelMessageConditionInput
) {
  createMessage(input: $input, condition: $condition) {
    id
    userId
    klaravia_user_id
    content
    sender
    receiver
    createdAt
    updatedAt
    __typename
  }
}
`;

export function useCreateMessage() {
  const [createMessage, { data, loading, error }] = useMutation(createMessageMutation);

  return {
    createMessage,
    data,
    loading,
    error,
  };
}

export const listMessages = gql`
query ListMessages(
  $filter: ModelMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      content
      sender
      receiver
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
`;

export function useListMessages(
  options?: QueryHookOptions<ListMessagesQuery>,
): QueryResult<ListMessagesQuery> {
  return useQuery(listMessages, options);
}

// export const onCreateMessageSubscription = gql`
// subscription OnCreateMessage($filter: ModelSubscriptionMessageFilterInput) {
//   onCreateMessage(filter: $filter) {
//          id
//      userId
//      klaravia_user_id
//      content
//      sender
//      receiver
//      createdAt
//      updatedAt
//      __typename
//   }
// }
// `;


// export function useOnCreateMessage(
//   options?: SubscriptionHookOptions<OnCreateMessageSubscription>,
// ): SubscriptionResult<OnCreateMessageSubscription> {
//   return useSubscription(onCreateMessageSubscription, options);
// }

export const ON_CREATE_MESSAGE_SUBSCRIPTION = gql`
subscription OnCreateMessage($filter: ModelSubscriptionMessageFilterInput) {
  onCreateMessage(filter: $filter) {
    id
    userId
    klaravia_user_id
    content
    sender
    receiver
    createdAt
    updatedAt
    __typename
  }
}
`;

export function useOnCreateMessageSubscription(userID: string) {
  const { data, loading, error } = useSubscription(ON_CREATE_MESSAGE_SUBSCRIPTION, {
    variables: { filter: { userId: { eq: userID } } },
  });

  return {
    data,
    loading,
    error,
  };
}

export const onUpdateUserSubscription = gql`
subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
  onUpdateUser(filter: $filter) {
    id
    username
    first_name
    last_name
    birth_date
    email
    user_type
    phone_number
    home_number_of_stories
    roof_condition
    roof_material
    address
    stateId
    state {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    utility_provider
    home_owner
    home_has_foundation
    electrical_system_status
    is_hoa_property
    has_home_owners_insurance
    installerId
    installer {
      id
      cognitoPassword
      company_name
      company_address
      company_phone_number
      company_email
      company_tag_line
      company_red_line
      company_about_text
      company_cover_image_url
      google_place_id
      installer_rating
      salesforceId
      years_in_business
      average_install_time
      createdAt
      updatedAt
      __typename
    }
    payment_method
    electric_bill
    estimatedAnnualUsage
    initialDesign {
      jobId
      productId
      imageUrl
      __typename
    }
    finalDesign {
      designPdf
      __typename
    }
    user_images
    projectStatus
    userStatus
    measurementsOrderReportId
    salesforceId
    installerSalesforceId
    signed_installer_contract
    electric_bill_reviewed
    createdAt
    updatedAt
    __typename
  }
}
`;

export function useOnUpdateUserSubscription(userID: string) {
  const { data, loading, error } = useSubscription(onUpdateUserSubscription, {
    variables: { filter: { id: { eq: userID } } },
  });

  return {
    data,
    loading,
    error,
  };
}