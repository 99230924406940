import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Mail, Lock, ChevronRight } from 'lucide-react';
import { Auth } from "aws-amplify";
import { ViewOffIcon, ViewIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Providers/AuthProvider";

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const auth = useAuth();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const cognitoUser = await Auth.signIn(email, password);
      auth.setCognitoUser(cognitoUser);
      // User signed in successfully, redirect to dashboard or home page
      // useHistory from react-router-dom could be used here for redirection
      navigate("/LandingPage");
    } catch (error) {
      setError(error.message);
      console.error("Error signing in", error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div className="min-h-screen bg-[#121212] flex items-center justify-center px-4">
      <div className="w-full max-w-md">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-8"
        >
          <h1 className="text-4xl text-blue-50 mb-2 font-['new-astro']">
            Klaravia
          </h1>
          <p className="text-blue-50/70">
            Log in to access your account details
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="bg-white/5 backdrop-blur-sm rounded-lg p-8 shadow-xl"
        >
          <form onSubmit={handleLogin} className="space-y-6">
            <div>
              <label className="block text-blue-50/70 text-sm mb-2">
                Email
              </label>
              <div className="relative">
                <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-50/50 w-5 h-5" />
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full bg-white/5 border border-white/10 rounded-lg pl-10 pr-4 py-3 text-blue-50 placeholder-blue-50/30 focus:outline-none focus:border-amber-500"
                  placeholder="Enter your email"
                />
              </div>
            </div>

            <div>
              <label className="block text-blue-50/70 text-sm mb-2">
                Password
              </label>
              <div className="relative">
                <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-50/50 w-5 h-5" />
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={handleKeyDown}
                  className="w-full bg-white/5 border border-white/10 rounded-lg pl-10 pr-4 py-3 text-blue-50 placeholder-blue-50/30 focus:outline-none focus:border-amber-500"
                  placeholder="Enter your password"
                />
              </div>
            </div>

            {error && (
              <div className="text-red-400 text-sm">
                {error}
              </div>
            )}

            <button
              type="submit"
              className="w-full bg-amber-500 text-[#2b2b34] py-3 rounded-lg font-semibold hover:bg-amber-400 transition-colors flex items-center justify-center group"
            >
              Log In
              <ChevronRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />
            </button>
          </form>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
          className="text-center mt-6"
        >
          <p className="text-blue-50/50 text-sm">
            Don't have an account?{' '}
            <a href="/user-sign-up" className="text-amber-500 hover:text-amber-400">
              Get started now
            </a>
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default LoginPage;