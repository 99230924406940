import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useBreakpointValue,
  Image,
} from "@chakra-ui/react";
import { FiMenu, FiBell, FiChevronDown } from "react-icons/fi";
import { IconType } from "react-icons";
import { GoMail } from "react-icons/go";
import { BsPersonPlus, BsCardList } from "react-icons/bs";
import { AiOutlineWarning } from "react-icons/ai";
import { IoTodayOutline } from "react-icons/io5";
import { BiHomeAlt } from "react-icons/bi";
import { LuSettings } from "react-icons/lu";
import { useEffect, useState } from "react";
import logo from "../../Assets/logoLight.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../Providers/AuthProvider";
import { Installer } from "../../API";
import OnboardingPopUp from "./OnboardingPopUp";
import { useGetInstaller } from "../../hooks/userHooks";

interface LinkItemProps {
  name: string;
  icon: IconType;
  route: string;
}

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: React.ReactNode;
}

interface MobileProps extends FlexProps {
  onOpen: () => void;
  sideBarHover: boolean;
  installer: Installer | null;
  signOut: () => void;
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
  sideBarHover: boolean;
  setSideBarHover: React.Dispatch<React.SetStateAction<boolean>>;
  installer: Installer | null;
}

const LinkItems: Array<LinkItemProps> = [
  { name: "Dashboard", icon: BiHomeAlt, route: "" },
  { name: "Client List", icon: BsCardList, route: "client-list" },
  { name: "Today's Installs", icon: IoTodayOutline, route: "" },
  { name: "Requires Action", icon: AiOutlineWarning, route: "" },
  // { name: "Potential Leads", icon: BsPersonPlus, route: "potential-leads" },
  { name: "Messages", icon: GoMail, route: "messages" },
  { name: "Settings", icon: LuSettings, route: "" },
];

const SidebarContent = ({ onClose, sideBarHover, setSideBarHover, installer, ...rest }: SidebarProps) => {
  const breakPoint = useBreakpointValue({ base: "base", sm: "sm", md: "md", lg: "lg", xl: "xl" });
  const navigate = useNavigate();

  return (
    <Box
      bg={useColorModeValue("black", "gray.900")}
      borderRight="1px"
      color="white"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 20 }}
      pos="fixed"
      h="full"
      _hover={{ w: { base: "full", md: 60 } }}
      onMouseEnter={() => setSideBarHover(true)}
      onMouseLeave={() => setSideBarHover(false)}
      transition="width 0.4s ease-in-out"
      {...rest}
    >
      <Flex w="full" h="20" alignItems="center" justifyContent={{ base: "space-between", md: "flex-start" }} pl="14px" cursor="pointer" onClick={() => navigate("/")}>
        <HStack w="full" pl="10px">
          <Image h="34px" w="34px" src={logo} />
          <Text fontSize="24px" display={sideBarHover ? "flex" : "none"} overflow="hidden" fontFamily="'new-astro', sans-serif">
            Klaravia
          </Text>
        </HStack>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem key={link.name} icon={link.icon} onClick={() => navigate(`/installer/${installer ? installer.id : "null"}/${link.route}`)}>
          <Box _hover={{ w: "100%" }} h="24px" transition="width 0.4s ease-in-out" overflow="hidden">
            <Text
              w="150px"
              overflow="hidden" // Ensure the text is hidden when the box is narrow
            >
              {sideBarHover || breakPoint === "base" ? link.name : ""}
            </Text>
          </Box>
        </NavItem>
      ))}
    </Box>
  );
};

const NavItem = ({ icon, children, ...rest }: NavItemProps) => {
  return (
    <Box as="a" style={{ textDecoration: "none" }} _focus={{ boxShadow: "none" }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "rgba(255,255,255,0.2)",
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Box>
  );
};

const MobileNav = ({ onOpen, sideBarHover, installer, signOut, ...rest }: MobileProps) => {
  const navigate = useNavigate();
  return (
    <Flex
      ml={{ base: 0, md: sideBarHover ? 60 : 20 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("#f4eee9", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.700", "gray.700")}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      transition="margin 0.4s ease-in-out"
      {...rest}
    >
      <IconButton display={{ base: "flex", md: "none" }} onClick={onOpen} variant="outline" aria-label="open menu" icon={<FiMenu />} />

      <Image w="50px" src={logo} display={{ base: "flex", md: "none" }} />

      <HStack spacing={{ base: "0", md: "6" }}>
        <IconButton size="lg" variant="ghost" aria-label="open menu" icon={<FiBell />} />
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: "none" }}>
              <HStack>
                <Avatar size={"sm"} bgColor="gray.400" border="1px solid #D3D3D3" src={`${installer?.company_cover_image_url}`} />
                <VStack display={{ base: "none", md: "flex" }} alignItems="flex-start" spacing="1px" ml="2">
                  <Text fontSize="sm">{installer ? installer.company_name : "error"}</Text>
                  <Text fontSize="xs" color="gray.600">
                    Installer Account
                  </Text>
                </VStack>
                <Box display={{ base: "none", md: "flex" }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList bg={useColorModeValue("white", "gray.900")} borderColor={useColorModeValue("gray.200", "gray.700")}>
              <MenuItem>Profile</MenuItem>
              <MenuItem>Settings</MenuItem>
              <MenuItem>Billing</MenuItem>
              <MenuDivider />
              <MenuItem
                onClick={() => {
                  navigate("/installer/log-in");
                  signOut();
                }}
              >
                Sign out
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

const SidebarWithHeader = ({ children }: { children: React.ReactNode }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOnboardingOpen, onOpen: onOnboardingOpen, onClose: onOnboardingClose } = useDisclosure();
  const [sideBarHover, setSideBarHover] = useState<boolean>(false);
  const [installer, setInstaller] = useState<any>(null);
  const [installerId, setInstallerId] = useState("");
  const { installer_id } = useParams();
  const { signOut } = useAuth();

  const GET_INSTALLER_VARIABLES = {
    variables: {
      id: installerId || installer_id,
    },
  };

  useEffect(() => {
    if (installer_id) {
      setInstallerId(installer_id);
    }
  }, []);

  const { data: installerData, loading: loadingInstallerData, error: installerDataError } = useGetInstaller(GET_INSTALLER_VARIABLES);

  useEffect(() => {
    if (installerData && installerData.getInstaller) {
      setInstaller(installerData.getInstaller);
    }
  }, [installerData]);

  useEffect(() => {
    onOnboardingOpen();
  }, []);

  return (
    <Box minH="100vh">
      <SidebarContent onClose={() => onClose} display={{ base: "none", md: "block" }} sideBarHover={sideBarHover} setSideBarHover={setSideBarHover} installer={installer} />
      <Drawer isOpen={isOpen} placement="left" onClose={onClose} returnFocusOnClose={false} onOverlayClick={onClose} size="xs">
        <DrawerContent>
          <SidebarContent onClose={onClose} sideBarHover={sideBarHover} setSideBarHover={setSideBarHover} installer={installer} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} sideBarHover={sideBarHover} installer={installer} signOut={signOut} />
      <Box ml={{ base: 0, md: sideBarHover ? 60 : 20 }} transition="margin 0.4s ease-in-out">
        {children}
      </Box>
      {/* <OnboardingPopUp isOpen={isOnboardingOpen} onOpen={onOnboardingOpen} onClose={onOnboardingClose} /> */}
    </Box>
  );
};

export default SidebarWithHeader;
