import React, { useEffect } from "react";
import { ClipboardCheck, Clock, Shield, ChevronRight } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const QuestionnaireIntroPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };

  return (
    <section className="min-h-screen bg-[#121212] flex items-center px-4">
      <motion.div initial="hidden" animate="visible" variants={containerVariants} className="max-w-6xl mx-auto w-full grid sm:grid-cols-1 gap-16 py-12">
        {/* Left Column - Main Content */}
        <div className="space-y-8">
          <motion.div variants={itemVariants}>
            <h1 className="mt-8 sm:mt-0 text-2xl sm:text-5xl font-bold text-blue-50 mb-6 leading-tight">
              Let's Design Your <span className="text-amber-500">Solar System</span>
            </h1>
            <p className="text-md sm:text-lg text-blue-50/80">Based on your monthly electric bill, we'll help design a system that maximizes your savings.</p>
          </motion.div>

          {/* Process Steps */}
          <div className="space-y-4">
            {[
              {
                title: "Quick Assessment",
                description: "Answer a few simple questions about your home",
                icon: <ClipboardCheck className="w-5 h-5" />,
              },
              {
                title: "Instant Results",
                description: "Get your custom solar design and savings estimate",
                icon: <Clock className="w-5 h-5" />,
              },
              {
                title: "Accurate Design",
                description: "Receive a system tailored to your needs",
                icon: <Shield className="w-5 h-5" />,
              },
            ].map((step, index) => (
              <motion.div key={index} variants={itemVariants} className="flex items-start space-x-4 p-4 rounded-lg bg-white/5">
                <div className="bg-amber-500/10 rounded-full p-3">
                  <div className="text-amber-500">{step.icon}</div>
                </div>
                <div>
                  <h3 className="text-blue-50 font-semibold mb-1">{step.title}</h3>
                  <p className="text-blue-50/70 text-sm">{step.description}</p>
                </div>
              </motion.div>
            ))}
          </div>

          {/* CTA Button */}
          <motion.div variants={itemVariants} className="pt-4">
            <motion.button
              onClick={() => navigate("/questionnaire")}
              className="bg-amber-500 hover:bg-amber-400 text-[#121212] px-8 py-4 rounded-lg text-lg font-semibold inline-flex items-center transition-all duration-300 hover:translate-x-2"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              Begin Assessment
              <ChevronRight className="ml-2 w-5 h-5" />
            </motion.button>
            <p className="text-blue-50/60 text-sm mt-4">Takes about 2 minutes • 4 simple questions</p>
          </motion.div>
        </div>
      </motion.div>
    </section>
  );
};

export default QuestionnaireIntroPage;
