import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Box, VStack, Text, Fade, Alert, AlertIcon, AlertTitle, AlertDescription, Button, useToast, HStack, Image } from "@chakra-ui/react";
import { Info, CheckCircle, AlertCircle, FileText, Clock, Shield, Calendar } from "lucide-react";
import { useAuth } from "../../Providers/AuthenticationProvider";
import { ProjectStatus, PaymentMethod } from "../../API";
import UserProgressTracker from "../Components/UserProgressTracker";
import { useOnUpdateUserSubscription } from "../../hooks/userHooks";
import DocumentUpload from "../Components/DocumentUpload";
import StripeComponent from "../Components/StripeComponent";

interface UserPostOrderDashProps {} // Empty since we removed props

const UserPostOrderDash: React.FC<UserPostOrderDashProps> = () => {
  const [hoverIndex, setHoverIndex] = useState<number>(0);
  const [userData, setUserData] = useState<any>();
  const [projectStatusIndex, setProjectStatusIndex] = useState<number>(0);
  const [projectStatus, setProjectStatus] = useState<ProjectStatus>();
  const [showStripePayment, setShowStripePayment] = useState<boolean>(false);
  const [filesHaveBeenUploaded, setFilesHaveBeenUploaded] = useState(false);
  const [errorUploadingImage, setErrorUploadingImage] = useState(false);
  const { userData: data } = useAuth();
  const toast = useToast();

  // Subscription for real-time updates
  const { data: subscriptionData } = useOnUpdateUserSubscription(userData && userData.id ? userData.id : "");

  useEffect(() => {
    if (subscriptionData) {
      setProjectStatus(subscriptionData?.onUpdateUser?.projectStatus);
    }
  }, [subscriptionData]);

  useEffect(() => {
    if (projectStatus) {
      setProjectStatusIndex(getProjectStatus(projectStatus));
    }
  }, [projectStatus]);

  useEffect(() => {
    if (data) {
      setUserData(data);
      if (data.projectStatus) {
        setProjectStatus(data.projectStatus);
      }
    }
  }, [data]);

  useEffect(() => {
    setHoverIndex(projectStatusIndex);
  }, [projectStatusIndex]);

  const getProjectStatus = (pStatus: ProjectStatus) => {
    if (pStatus) {
      switch (pStatus) {
        case ProjectStatus.AWAITING_ELECTRIC_BILL_REVIEW:
          return 0;
        case ProjectStatus.ELECTRIC_BILL_ISSUE_AWAITING_CUSTOMER_RE_UPLOAD:
          return 0;
        case ProjectStatus.ELECTRIC_BILL_REVIEWED_AWAITING_TRUE_DESIGN_PAYMENT:
          return 1;
        case ProjectStatus.TRUE_DESIGN_PAYMENT_ISSUE_AWAITING_RESOLUTION:
          return 1;
        case ProjectStatus.TRUE_DESIGN_PAYMENT_SUCCEEDED_AWAITING_TRUE_DESIGN_ORDER:
          return 1;
        case ProjectStatus.TRUE_DESIGN_ORDER_FAILED_AWAITING_RESOLUTION:
          return 1;
        case ProjectStatus.TRUE_DESIGN_ORDER_COMPLETED_AWAITING_FINAL_DESIGN_CREATION:
          return 1;
        case ProjectStatus.FINAL_DESIGN_FAILED_AWAITING_RESOLUTION:
          return 1;
        case ProjectStatus.FINAL_DESIGN_COMPLETE_AWAITING_CUSTOMER_APPROVAL:
          return 1;
        case ProjectStatus.CUSTOMER_REJECTED_FINAL_DESIGN_AWAITING_RESOLUTION:
          return 1;
        case ProjectStatus.CUSTOMER_APPROVED_FINAL_DESIGN_AWAITING_INSTALLER_AGREEMENT_SIGNATURE:
          return 2;
        case ProjectStatus.INSTALLER_AGREEMENT_SIGNATURE_OBTAINED_AWAITING_DEPOSIT:
          return 2;
        case ProjectStatus.DEPOSIT_UNSUCCESSFUL_AWAITING_RESOLUTION:
          return 2;
        case ProjectStatus.DEPOSIT_SUCCESSFUL_AWAITING_SITE_SURVEY_SCHEDULE:
          return 3;
        case ProjectStatus.SITE_SURVEY_SCHEDULED_AWAITING_SITE_VISIT:
          return 3;
        case ProjectStatus.SITE_VISITED_AWAITING_SITE_APPROVAL:
          return 3;
        case ProjectStatus.SITE_NOT_APPROVED_CHANGE_ORDER_REQUIRED:
          return 3;
        case ProjectStatus.CHANGE_ORDER_SUBMITTED_AWAITING_CUSTOMER_SIGNATURE:
          return 3;
        case ProjectStatus.CUSTOMER_SIGNATURE_OBTAINED_AWAITING_ENGINEERING_DOCS:
          return 3;
        case ProjectStatus.SITE_NOT_APPROVED_CANNOT_SERVICE:
          return 3;
        case ProjectStatus.SITE_APPROVED_AWAITING_ENGINEERING_DOCS:
          return 4;
        case ProjectStatus.ENGINEERING_DOCS_FINISHED_AWAITING_PERMITS:
          return 4;
        case ProjectStatus.PERMITS_NOT_OBTAINED_AWAITING_RESOLUTION:
          return 4;
        case ProjectStatus.PERMITS_OBTAINED_AWAITING_SECOND_PAYMENT:
          return 5;
        case ProjectStatus.SECOND_PAYMENT_UNSUCCESSFUL_AWAITING_RESOLUTION:
          return 5;
        case ProjectStatus.SECOND_PAYMENT_SUCCESSFUL_AWAITING_INSTALL_SCHEDULE:
          return 6;
        case ProjectStatus.INSTALL_SCHEDULED_AWAITING_INSTALL:
          return 6;
        case ProjectStatus.INSTALL_NOT_COMPLETED_AWAITING_RESOLUTION:
          return 6;
        case ProjectStatus.INSTALL_COMPLETED_AWAITING_UTILITY_APPROVAL:
          return 7;
        case ProjectStatus.UTILITY_APPROVAL_ISSUE_AWAITING_RESOLUTION:
          return 7;
        case ProjectStatus.UTILITY_APPROVED_AWAITING_SYSTEM_TURN_ON:
          return 7;
        case ProjectStatus.SYSTEM_NOT_TURNED_ON_AWAITING_RESOLUTION:
          return 7;
        case ProjectStatus.SYSTEM_TURNED_ON:
          return 7;
        default:
          return 0;
      }
    } else {
      return 0;
    }
  };

  if (!userData) {
    return (
      <VStack w="full" minH="90vh" className="bg-[#1f1f1f]" justifyContent="center">
        <Text fontSize="32px" fontWeight="bold" className="text-blue-50">
          Loading...
        </Text>
      </VStack>
    );
  }

  return (
    <Box w="full" minH="92vh" p={{ base: "10px", lg: "40px" }} className="bg-[#1f1f1f]">
      <UserProgressTracker setHoverIndex={setHoverIndex} hoverIndex={hoverIndex} projectStatusIndex={projectStatusIndex} />

      {hoverIndex === 0 && (
        <Fade in={hoverIndex === 0} transition={{ enter: { duration: 0.9 } }}>
          <VStack mt="40px" spacing={6}>
            <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
              {/* Awaiting Review State */}
              {(projectStatus === ProjectStatus.AWAITING_ELECTRIC_BILL_REVIEW ||
                (projectStatusIndex === 0 && projectStatus !== ProjectStatus.ELECTRIC_BILL_ISSUE_AWAITING_CUSTOMER_RE_UPLOAD)) && (
                <HStack w="full" flexDirection={{ base: "column", "2xl": "row" }} spacing={{ base: 6, lg: 8 }}>
                  <Box className="relative w-full max-w-xl overflow-hidden rounded-lg" h={{ base: "200px", lg: "300px" }}>
                    <Image borderRadius="12px" className="object-cover w-full h-full" src="https://speedyelectricac.com/wp-content/uploads/2020/02/electric-bill.jpg" />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                  </Box>

                  <VStack flex="1" align="start" spacing={6}>
                    <VStack align="start" spacing={3}>
                      <Text fontSize={{ base: "2xl", lg: "3xl" }} fontWeight="bold" className="text-blue-50">
                        Analyzing Your Energy Usage
                      </Text>
                      <Text className="text-blue-50/70">We're carefully reviewing your electric bill to design the perfect solar system for your home.</Text>
                    </VStack>

                    <VStack w="full" align="start" spacing={4}>
                      {["Calculating average monthly consumption", "Identifying peak usage patterns", "Determining optimal system size", "Estimating potential savings"].map(
                        (item, index) => (
                          <HStack key={index} spacing={3}>
                            <CheckCircle className="w-5 h-5 text-amber-500" />
                            <Text className="text-blue-50/70">{item}</Text>
                          </HStack>
                        )
                      )}
                    </VStack>

                    <Box w="full" className="bg-amber-500/10 border border-amber-500/20 rounded-lg" p={4}>
                      <HStack spacing={3}>
                        <Clock className="w-5 h-5 text-amber-500" />
                        <Text className="text-amber-500">Estimated review completion: 1hr</Text>
                      </HStack>
                    </Box>
                  </VStack>
                </HStack>
              )}

              {/* Error State - Need Re-upload */}
              {projectStatus === ProjectStatus.ELECTRIC_BILL_ISSUE_AWAITING_CUSTOMER_RE_UPLOAD && (
                <VStack w="full" spacing={6}>
                  <VStack spacing={3}>
                    <AlertCircle className="w-12 h-12 text-red-500" />
                    <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold" className="text-blue-50 text-center">
                      We weren't able to review your electric bill
                    </Text>
                    <Text className="text-blue-50/70 text-center">
                      Please upload new photos of your electric bill, and if you have any questions, please reach out to us in your support chat.
                    </Text>
                  </VStack>

                  <Box w="full" className="bg-white/5 border border-white/10 rounded-lg" p={6}>
                    <DocumentUpload setFilesHaveBeenUploaded={setFilesHaveBeenUploaded} setErrorUploadingImage={setErrorUploadingImage} />
                  </Box>
                </VStack>
              )}

              {/* Success State */}
              {(projectStatus === ProjectStatus.ELECTRIC_BILL_REVIEWED_AWAITING_TRUE_DESIGN_PAYMENT || projectStatusIndex > 0) && (
                <HStack w="full" justifyContent="space-between" flexDirection={{ base: "column", lg: "row" }} spacing={4}>
                  <VStack align="start" spacing={3}>
                    <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold" className="text-blue-50">
                      Your electric bill has been reviewed!
                    </Text>
                    <Text className="text-blue-50/70">
                      We've analyzed your usage patterns and confirmed your solar design requirements. You're now ready to proceed with your professional solar design.
                    </Text>
                  </VStack>
                  <CheckCircle className="w-10 h-10 text-green-500 flex-shrink-0" />
                </HStack>
              )}
            </Box>
          </VStack>
        </Fade>
      )}
      {hoverIndex === 1 && (
        <Fade in={hoverIndex === 1} transition={{ enter: { duration: 0.9 } }}>
          <VStack mt="40px" spacing={6}>
            <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
              {/* Awaiting Design State */}
              {projectStatusIndex < 1 && (
                <HStack w="full" flexDirection={{ base: "column", "2xl": "row" }} spacing={{ base: 6, lg: 8 }}>
                  <Box className="relative w-full max-w-xl overflow-hidden rounded-lg" h={{ base: "200px", lg: "300px" }}>
                    <Image
                      borderRadius="12px"
                      className="object-cover w-full h-full"
                      src="https://solarbuildermag.com/wp-content/uploads/2022/04/EagleView-TrueDesign-50_50-Image.jpg"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                  </Box>

                  <VStack flex="1" align="start" spacing={6}>
                    <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold" className="text-blue-50">
                      Professional Solar Design
                    </Text>
                    <Text className="text-blue-50/70">After your electric bill has been reviewed, you'll be able to order your detailed solar design.</Text>
                  </VStack>
                </HStack>
              )}
              {/* Payment Required State */}
              {projectStatus === ProjectStatus.ELECTRIC_BILL_REVIEWED_AWAITING_TRUE_DESIGN_PAYMENT && (
                <HStack w="full" flexDirection={{ base: "column", xl: "row" }} spacing={8}>
                  <Box className="relative w-full max-w-xl overflow-hidden rounded-lg" h={{ base: "200px", lg: "300px" }}>
                    <Image
                      borderRadius="12px"
                      className="object-cover w-full h-full"
                      src="https://solarbuildermag.com/wp-content/uploads/2022/04/EagleView-TrueDesign-50_50-Image.jpg"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                  </Box>

                  {showStripePayment ? (
                    <VStack w="full" className="bg-white/5 rounded-lg" p="40px">
                      <StripeComponent chargeAmount={10000} />
                    </VStack>
                  ) : (
                    <VStack flex="1" align="start" spacing={6}>
                      <Text fontSize={{ base: "2xl", lg: "3xl" }} fontWeight="bold" className="bg-gradient-to-r from-amber-500 to-amber-600 bg-clip-text text-transparent">
                        Order Your Professional Design
                      </Text>
                      <Text className="text-blue-50/70">
                        Ready to move forward with your solar journey? Order your professional design now and we'll create a detailed plan customized for your home.
                      </Text>
                      <VStack align="start" spacing={4} w="full">
                        {["Precise panel placement simulation", "Detailed energy production analysis", "Comprehensive shading study", "Full electrical system design"].map(
                          (item, index) => (
                            <HStack key={index} spacing={3}>
                              <CheckCircle className="w-5 h-5 text-amber-500" />
                              <Text className="text-blue-50/70">{item}</Text>
                            </HStack>
                          )
                        )}
                      </VStack>
                      <Button
                        onClick={() => setShowStripePayment(true)}
                        className="bg-gradient-to-r from-amber-500 to-amber-600 text-white px-8 py-3 rounded-lg 
                            hover:from-amber-600 hover:to-amber-700 transition-all duration-300"
                      >
                        Order Design - $100
                      </Button>
                    </VStack>
                  )}
                </HStack>
              )}

              {/* Payment Issue State */}
              {projectStatus === ProjectStatus.TRUE_DESIGN_PAYMENT_ISSUE_AWAITING_RESOLUTION && (
                <VStack w="full" spacing={6}>
                  <Alert status="error" className="bg-red-500/10 text-red-400 border border-red-500/20 rounded-lg">
                    <AlertIcon />
                    <VStack align="start" spacing={2}>
                      <AlertTitle>Payment Issue</AlertTitle>
                      <AlertDescription>There was an issue processing your payment for the design. Please check your payment method and try again.</AlertDescription>
                    </VStack>
                  </Alert>

                  {!showStripePayment ? (
                    <Button
                      onClick={() => setShowStripePayment(true)}
                      className="bg-gradient-to-r from-amber-500 to-amber-600 text-white px-8 py-3 rounded-lg 
                  hover:from-amber-600 hover:to-amber-700 transition-all duration-300"
                    >
                      Retry Payment
                    </Button>
                  ) : (
                    <VStack w="full" className="bg-white/5 rounded-lg" p="40px">
                      <StripeComponent chargeAmount={10000} />
                    </VStack>
                  )}
                </VStack>
              )}
              {/* Payment Success - Design In Progress State */}
              {projectStatus === ProjectStatus.TRUE_DESIGN_PAYMENT_SUCCEEDED_AWAITING_TRUE_DESIGN_ORDER && (
                <VStack w="full" spacing={6} align="start">
                  <HStack spacing={4}>
                    <div className="bg-green-500/20 p-3 rounded-full">
                      <CheckCircle className="w-8 h-8 text-green-500" />
                    </div>
                    <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold" className="text-blue-50">
                      Design Payment Confirmed!
                    </Text>
                  </HStack>
                  <Text className="text-blue-50/70">Thank you for your payment. Our design team has been notified and will begin working on your custom solar design.</Text>
                  <Box w="full" className="bg-amber-500/10 border border-amber-500/20 rounded-lg" p={4}>
                    <HStack spacing={3}>
                      <Clock className="w-5 h-5 text-amber-500" />
                      <Text className="text-amber-500">Estimated completion: 1 business day</Text>
                    </HStack>
                  </Box>
                </VStack>
              )}
              {/* Design Order Failed State */}
              {projectStatus === ProjectStatus.TRUE_DESIGN_ORDER_FAILED_AWAITING_RESOLUTION && (
                <VStack w="full" spacing={6}>
                  <Alert status="warning" className="bg-amber-500/10 text-amber-500 border border-amber-500/20 rounded-lg">
                    <AlertIcon />
                    <VStack align="start" spacing={2}>
                      <AlertTitle>Design Order Issue</AlertTitle>
                      <AlertDescription>
                        We've encountered an issue while processing your design order. Our team has been notified and will contact you shortly to resolve this.
                      </AlertDescription>
                    </VStack>
                  </Alert>
                </VStack>
              )}
              {/* Design Creation In Progress State */}
              {projectStatus === ProjectStatus.TRUE_DESIGN_ORDER_COMPLETED_AWAITING_FINAL_DESIGN_CREATION && (
                <VStack w="full" spacing={6} align="start">
                  <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold" className="text-blue-50">
                    Creating Your Custom Design
                  </Text>
                  <Text className="text-blue-50/70">
                    Our design team is hard at work creating your custom solar system design. We'll notify you as soon as it's ready for your review.
                  </Text>
                  <VStack w="full" className="bg-white/5 rounded-lg" p={6} spacing={4}>
                    <HStack w="full" justify="space-between" className="border-b border-white/10 pb-4">
                      <Text className="text-blue-50">Design Progress</Text>
                      <Text className="text-amber-500">In Progress</Text>
                    </HStack>
                    <VStack w="full" align="start" spacing={4}>
                      {[
                        { text: "Site Analysis", done: true },
                        { text: "Initial Layout", done: true },
                        { text: "Production Modeling", done: false },
                        { text: "Final Documentation", done: false },
                      ].map((step, index) => (
                        <HStack key={index} spacing={3}>
                          <div className={`p-1 rounded-full ${step.done ? "bg-green-500" : "bg-white/10"}`}>
                            <CheckCircle className={`w-4 h-4 ${step.done ? "text-white" : "text-white/30"}`} />
                          </div>
                          <Text className={step.done ? "text-blue-50" : "text-blue-50/50"}>{step.text}</Text>
                        </HStack>
                      ))}
                    </VStack>
                  </VStack>
                </VStack>
              )}
              {/* Design Creation Failed State */}
              {projectStatus === ProjectStatus.FINAL_DESIGN_FAILED_AWAITING_RESOLUTION && (
                <VStack w="full" spacing={6}>
                  <Alert status="error" className="bg-red-500/10 text-red-400 border border-red-500/20 rounded-lg">
                    <AlertIcon />
                    <VStack align="start" spacing={2}>
                      <AlertTitle>Design Creation Issue</AlertTitle>
                      <AlertDescription>
                        We've encountered an issue while creating your final design. Our team is working to resolve this and will update you soon.
                      </AlertDescription>
                    </VStack>
                  </Alert>
                </VStack>
              )}
              {/* Design Ready for Review State */}
              {projectStatus === ProjectStatus.FINAL_DESIGN_COMPLETE_AWAITING_CUSTOMER_APPROVAL && (
                <VStack w="full" spacing={6} align="start">
                  <HStack spacing={4}>
                    <div className="bg-green-500/20 p-3 rounded-full">
                      <CheckCircle className="w-8 h-8 text-green-500" />
                    </div>
                    <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold" className="text-blue-50">
                      Your Design is Ready!
                    </Text>
                  </HStack>
                  <Text className="text-blue-50/70">
                    Your custom solar design is complete and ready for your review. Please check your email for the design documents and approval instructions.
                  </Text>
                  <Button
                    className="bg-gradient-to-r from-amber-500 to-amber-600 text-white px-8 py-3 rounded-lg 
                hover:from-amber-600 hover:to-amber-700 transition-all duration-300"
                    onClick={() => {
                      /* Handle review action */
                    }}
                  >
                    Review Design
                  </Button>
                </VStack>
              )}
              {/* Design Rejected State */}
              {projectStatus === ProjectStatus.CUSTOMER_REJECTED_FINAL_DESIGN_AWAITING_RESOLUTION && (
                <VStack w="full" spacing={6}>
                  <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold" className="text-blue-50">
                    Design Revision in Progress
                  </Text>
                  <Text className="text-blue-50/70">
                    We've received your feedback on the design. Our team is working on incorporating your requested changes and will present an updated design soon.
                  </Text>
                  <Box w="full" className="bg-amber-500/10 border border-amber-500/20 rounded-lg" p={4}>
                    <HStack spacing={3}>
                      <Clock className="w-5 h-5 text-amber-500" />
                      <Text className="text-amber-500">Estimated revision completion: 1-2 business days</Text>
                    </HStack>
                  </Box>
                </VStack>
              )}
              {/* Success State - Moving to Next Phase */}
              {(projectStatus === ProjectStatus.CUSTOMER_APPROVED_FINAL_DESIGN_AWAITING_INSTALLER_AGREEMENT_SIGNATURE || projectStatusIndex > 1) && (
                <HStack w="full" justifyContent="space-between" flexDirection={{ base: "column", lg: "row" }} spacing={4}>
                  <VStack align="start" spacing={3}>
                    <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold" className="text-blue-50">
                      Design Approved
                    </Text>
                    <Text className="text-blue-50/70">Your solar system design has been finalized and approved. We're now ready to move forward with the next steps.</Text>
                  </VStack>
                  <CheckCircle className="w-10 h-10 text-green-500 flex-shrink-0" />
                </HStack>
              )}
            </Box>
          </VStack>
        </Fade>
      )}
      {hoverIndex === 2 && (
        <Fade in={hoverIndex === 2} transition={{ enter: { duration: 0.9 } }}>
          <VStack mt="40px" spacing={6}>
            {/* Initial/Waiting State */}
            {projectStatusIndex < 2 && (
              <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
                <HStack w="full" flexDirection={{ base: "column", "2xl": "row" }} spacing={{ base: 6, lg: 8 }}>
                  <Box className="relative w-full max-w-xl overflow-hidden rounded-lg" h={{ base: "200px", lg: "300px" }}>
                    <Image borderRadius="12px" className="object-cover w-full h-full" src="https://images.unsplash.com/photo-1450101499163-c8848c66ca85?q=80&w=2070" />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                  </Box>
                  <VStack flex="1" align="start" spacing={6}>
                    <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold" className="text-blue-50">
                      Documents & Deposit
                    </Text>
                    <Text className="text-blue-50/70">Once your design is approved, you'll receive your contract for review and can submit your initial deposit.</Text>
                  </VStack>
                </HStack>
              </Box>
            )}

            {/* Contract Signing State */}
            {projectStatus === ProjectStatus.CUSTOMER_APPROVED_FINAL_DESIGN_AWAITING_INSTALLER_AGREEMENT_SIGNATURE && (
              <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
                <VStack spacing={8} align="start" w="full">
                  {/* Contract Section */}
                  <VStack spacing={4} align="start" w="full">
                    <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold" className="text-blue-50">
                      Sign Your Contract
                    </Text>
                    <Text className="text-blue-50/70">
                      A contract from {userData.installer ? userData.installer.company_name : "your installer"} has been sent to {userData.email || "your email"}. Please review and
                      sign the agreement.
                    </Text>
                    <HStack className="bg-amber-500/10 rounded-lg p-4" spacing={3}>
                      <Info className="w-5 h-5 text-amber-500" />
                      <Text className="text-amber-500">Check your email for the contract signing link</Text>
                    </HStack>
                  </VStack>

                  {/* Checklist Section */}
                  <VStack w="full" className="bg-white/5 rounded-lg" p={6} spacing={4}>
                    <Text w="full" fontWeight="semibold" className="text-blue-50">
                      Contract Checklist
                    </Text>
                    <VStack w="full" align="start" spacing={4}>
                      {[
                        "Review system specifications",
                        "Check installation timeline",
                        "Verify pricing and payment terms",
                        "Understand warranty coverage",
                        "Sign agreement electronically",
                      ].map((item, index) => (
                        <HStack key={index} spacing={3}>
                          <div className="p-1 rounded-full bg-white/10">
                            <CheckCircle className="w-4 h-4 text-white/30" />
                          </div>
                          <Text className="text-blue-50/70">{item}</Text>
                        </HStack>
                      ))}
                    </VStack>
                  </VStack>
                </VStack>
              </Box>
            )}

            {/* Deposit State */}
            {projectStatus === ProjectStatus.INSTALLER_AGREEMENT_SIGNATURE_OBTAINED_AWAITING_DEPOSIT && (
              <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
                <VStack spacing={8} align="start" w="full">
                  <VStack align="start" spacing={4}>
                    <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold" className="text-blue-50">
                      Submit Your Deposit
                    </Text>
                    <Text className="text-blue-50/70">Your signed contract has been received. The next step is to submit your deposit to begin the installation process.</Text>
                  </VStack>

                  {!showStripePayment ? (
                    <VStack w="full" spacing={6}>
                      <VStack w="full" className="bg-white/5 rounded-lg" p={6} spacing={4}>
                        <HStack w="full" justify="space-between" className="border-b border-white/10 pb-4">
                          <Text className="text-blue-50">Deposit Amount</Text>
                          <Text className="text-blue-50 font-bold">$3,000</Text>
                        </HStack>
                        <Text className="text-blue-50/70 text-sm">
                          This deposit secures your installation slot and covers initial project costs including permits and equipment ordering.
                        </Text>
                      </VStack>
                      <Button
                        onClick={() => setShowStripePayment(true)}
                        className="bg-gradient-to-r from-amber-500 to-amber-600 text-white px-8 py-3 rounded-lg 
                            hover:from-amber-600 hover:to-amber-700 transition-all duration-300"
                      >
                        Submit Deposit
                      </Button>
                    </VStack>
                  ) : (
                    <VStack w="full" className="bg-white/5 rounded-lg" p="40px">
                      <StripeComponent chargeAmount={300000} />
                    </VStack>
                  )}
                </VStack>
              </Box>
            )}

            {/* Deposit Failed State */}
            {projectStatus === ProjectStatus.DEPOSIT_UNSUCCESSFUL_AWAITING_RESOLUTION && (
              <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
                <VStack spacing={6}>
                  <Alert status="error" className="bg-red-500/10 text-red-400 border border-red-500/20 rounded-lg">
                    <AlertIcon />
                    <VStack align="start" spacing={2}>
                      <AlertTitle>Deposit Payment Failed</AlertTitle>
                      <AlertDescription>There was an issue processing your deposit. Please check your payment method and try again.</AlertDescription>
                    </VStack>
                  </Alert>

                  {!showStripePayment ? (
                    <Button
                      onClick={() => setShowStripePayment(true)}
                      className="bg-gradient-to-r from-amber-500 to-amber-600 text-white px-8 py-3 rounded-lg 
                          hover:from-amber-600 hover:to-amber-700 transition-all duration-300"
                    >
                      Retry Deposit
                    </Button>
                  ) : (
                    <VStack w="full" className="bg-white/5 rounded-lg" p="40px">
                      <StripeComponent chargeAmount={300000} />
                    </VStack>
                  )}
                </VStack>
              </Box>
            )}

            {/* Success State */}
            {(projectStatus === ProjectStatus.DEPOSIT_SUCCESSFUL_AWAITING_SITE_SURVEY_SCHEDULE || projectStatusIndex > 2) && (
              <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
                <HStack w="full" justifyContent="space-between" flexDirection={{ base: "column", lg: "row" }} spacing={4}>
                  <VStack align="start" spacing={3}>
                    <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold" className="text-blue-50">
                      Contract Signed & Deposit Received
                    </Text>
                    <Text className="text-blue-50/70">
                      Great job! Your contract has been signed and deposit has been processed. We're ready to move forward with your installation.
                    </Text>
                  </VStack>
                  <CheckCircle className="w-10 h-10 text-green-500 flex-shrink-0" />
                </HStack>
              </Box>
            )}
          </VStack>
        </Fade>
      )}

      {hoverIndex === 3 && (
        <Fade in={hoverIndex === 3} transition={{ enter: { duration: 0.9 } }}>
          <VStack mt="40px" spacing={6}>
            {/* Initial/Waiting State */}
            {projectStatusIndex < 3 && (
              <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
                <HStack w="full" flexDirection={{ base: "column", "2xl": "row" }} spacing={{ base: 6, lg: 8 }}>
                  <Box className="relative w-full max-w-xl overflow-hidden rounded-lg" h={{ base: "200px", lg: "300px" }}>
                    <Image borderRadius="12px" className="object-cover w-full h-full" src="https://images.unsplash.com/photo-1503387762-592deb58ef4e?q=80&w=2071" />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                  </Box>
                  <VStack flex="1" align="start" spacing={6}>
                    <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold" className="text-blue-50">
                      Site Survey
                    </Text>
                    <Text className="text-blue-50/70">After contract signing and deposit, a professional surveyor will visit your property to finalize installation details.</Text>
                  </VStack>
                </HStack>
              </Box>
            )}

            {/* Awaiting Schedule State */}
            {projectStatus === ProjectStatus.DEPOSIT_SUCCESSFUL_AWAITING_SITE_SURVEY_SCHEDULE && (
              <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
                <VStack spacing={6} align="start">
                  <VStack align="start" spacing={3}>
                    <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold" className="text-blue-50">
                      Scheduling Your Site Survey
                    </Text>
                    <Text className="text-blue-50/70">
                      We're coordinating with {userData.installer ? userData.installer.company_name : "your installer"} to schedule your site survey. You'll be contacted shortly to
                      arrange a convenient time.
                    </Text>
                  </VStack>

                  <Box w="full" className="bg-white/5 rounded-lg" p={6}>
                    <VStack align="start" spacing={4}>
                      <Text className="text-blue-50" fontWeight="semibold">
                        What to Expect:
                      </Text>
                      {["Detailed roof inspection", "Electrical system assessment", "Shading analysis", "Installation path planning", "Equipment placement confirmation"].map(
                        (item, index) => (
                          <HStack key={index} spacing={3}>
                            <CheckCircle className="w-5 h-5 text-amber-500" />
                            <Text className="text-blue-50/70">{item}</Text>
                          </HStack>
                        )
                      )}
                    </VStack>
                  </Box>
                </VStack>
              </Box>
            )}

            {/* Survey Scheduled State */}
            {projectStatus === ProjectStatus.SITE_SURVEY_SCHEDULED_AWAITING_SITE_VISIT && (
              <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
                <VStack spacing={6} align="start">
                  <HStack spacing={4}>
                    <div className="bg-green-500/20 p-3 rounded-full">
                      <Calendar className="w-8 h-8 text-green-500" />
                    </div>
                    <VStack align="start" spacing={1}>
                      <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold" className="text-blue-50">
                        Site Survey Scheduled
                      </Text>
                      <Text className="text-blue-50/70">
                        {userData && userData.installation_date
                          ? `Scheduled for ${new Date(userData.installation_date).toLocaleDateString()} at ${new Date(userData.installation_date).toLocaleTimeString()}`
                          : "Date and time to be confirmed"}
                      </Text>
                    </VStack>
                  </HStack>

                  <Box w="full" className="bg-amber-500/10 border border-amber-500/20 rounded-lg" p={4}>
                    <VStack align="start" spacing={3}>
                      <Text className="text-amber-500" fontWeight="semibold">
                        Preparation Checklist:
                      </Text>
                      {["Ensure access to your electrical panel", "Clear access to your roof/attic", "Secure any pets", "Allow 2-3 hours for the survey"].map((item, index) => (
                        <HStack key={index} spacing={3}>
                          <CheckCircle className="w-4 h-4 text-amber-500" />
                          <Text className="text-amber-500">{item}</Text>
                        </HStack>
                      ))}
                    </VStack>
                  </Box>
                </VStack>
              </Box>
            )}

            {/* Survey In Progress/Review State */}
            {projectStatus === ProjectStatus.SITE_VISITED_AWAITING_SITE_APPROVAL && (
              <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
                <VStack spacing={6} align="start">
                  <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold" className="text-blue-50">
                    Site Survey Completed
                  </Text>
                  <Text className="text-blue-50/70">
                    The site survey has been completed. {userData.installer ? userData.installer.company_name : "Your installer"} is reviewing the results to ensure everything is
                    in order for the installation.
                  </Text>
                  <Box w="full" className="bg-white/5 rounded-lg" p={6}>
                    <VStack align="start" spacing={4}>
                      <Text className="text-blue-50" fontWeight="semibold">
                        Survey Results Under Review:
                      </Text>
                      <div className="w-full h-2 bg-white/10 rounded-full overflow-hidden">
                        <div className="w-3/4 h-full bg-gradient-to-r from-amber-500 to-amber-600"></div>
                      </div>
                      <Text className="text-blue-50/70">Estimated completion: 1-2 business days</Text>
                    </VStack>
                  </Box>
                </VStack>
              </Box>
            )}

            {/* Changes Required State */}
            {projectStatus === ProjectStatus.SITE_NOT_APPROVED_CHANGE_ORDER_REQUIRED && (
              <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
                <VStack spacing={6}>
                  <Alert status="warning" className="bg-amber-500/10 text-amber-500 border border-amber-500/20 rounded-lg">
                    <AlertIcon />
                    <VStack align="start" spacing={2}>
                      <AlertTitle>Site Survey Results: Changes Required</AlertTitle>
                      <AlertDescription>
                        Based on the site survey, some adjustments to the original plan are necessary. We'll be in touch with details and next steps.
                      </AlertDescription>
                    </VStack>
                  </Alert>
                </VStack>
              </Box>
            )}

            {/* Change Order State */}
            {projectStatus === ProjectStatus.CHANGE_ORDER_SUBMITTED_AWAITING_CUSTOMER_SIGNATURE && (
              <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
                <VStack spacing={6} align="start">
                  <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold" className="text-blue-50">
                    Change Order Ready for Review
                  </Text>
                  <Text className="text-blue-50/70">A change order based on the site survey results has been prepared. Please review and sign the updated agreement.</Text>
                  <Button
                    className="bg-gradient-to-r from-amber-500 to-amber-600 text-white px-8 py-3 rounded-lg 
                        hover:from-amber-600 hover:to-amber-700 transition-all duration-300"
                  >
                    Review Changes
                  </Button>
                </VStack>
              </Box>
            )}

            {/* Success State */}
            {(projectStatus === ProjectStatus.SITE_APPROVED_AWAITING_ENGINEERING_DOCS || projectStatusIndex > 3) && (
              <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
                <HStack w="full" justifyContent="space-between" flexDirection={{ base: "column", lg: "row" }} spacing={4}>
                  <VStack align="start" spacing={3}>
                    <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold" className="text-blue-50">
                      Site Survey Approved
                    </Text>
                    <Text className="text-blue-50/70">Great news! Your property has passed the site survey, and we're ready to move forward with the installation process.</Text>
                  </VStack>
                  <CheckCircle className="w-10 h-10 text-green-500 flex-shrink-0" />
                </HStack>
              </Box>
            )}

            {projectStatus === ProjectStatus.CUSTOMER_SIGNATURE_OBTAINED_AWAITING_ENGINEERING_DOCS && (
              <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
                <VStack spacing={6} align="start">
                  <HStack spacing={4}>
                    <div className="bg-green-500/20 p-3 rounded-full">
                      <CheckCircle className="w-8 h-8 text-green-500" />
                    </div>
                    <VStack align="start" spacing={1}>
                      <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold" className="text-blue-50">
                        Change Order Signed
                      </Text>
                      <Text className="text-blue-50/70">
                        Thank you for signing the change order. {userData?.installer?.company_name || "Your installer"}'s engineering team is now preparing the final documentation
                        for your installation.
                      </Text>
                    </VStack>
                  </HStack>

                  <Box w="full" className="bg-white/5 rounded-lg" p={6}>
                    <VStack align="start" spacing={4}>
                      <Text className="text-blue-50" fontWeight="semibold">
                        Next Steps:
                      </Text>
                      {["Engineering team review", "Technical documentation preparation", "System specifications update", "Final design modifications"].map((item, index) => (
                        <HStack key={index} spacing={3}>
                          <Clock className="w-5 h-5 text-amber-500" />
                          <Text className="text-blue-50/70">{item}</Text>
                        </HStack>
                      ))}
                    </VStack>
                  </Box>

                  <Box w="full" className="bg-amber-500/10 border border-amber-500/20 rounded-lg" p={4}>
                    <HStack spacing={3}>
                      <Info className="w-5 h-5 text-amber-500" />
                      <Text className="text-amber-500">Estimated completion: 3-5 business days</Text>
                    </HStack>
                  </Box>
                </VStack>
              </Box>
            )}

            {/* Site Cannot Be Serviced State */}
            {projectStatus === ProjectStatus.SITE_NOT_APPROVED_CANNOT_SERVICE && (
              <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
                <VStack spacing={6}>
                  <Alert status="error" className="bg-red-500/10 text-red-400 border border-red-500/20 rounded-lg">
                    <AlertIcon />
                    <VStack align="start" spacing={2}>
                      <AlertTitle>Unable to Proceed with Installation</AlertTitle>
                      <AlertDescription>
                        Unfortunately, based on the site survey results, we are unable to proceed with the solar installation at this time due to technical limitations.
                      </AlertDescription>
                    </VStack>
                  </Alert>

                  <VStack spacing={4} className="w-full bg-white/5 rounded-lg" p={6}>
                    <Text className="w-full text-blue-50" fontWeight="semibold">
                      Next Steps:
                    </Text>
                    <VStack align="start" spacing={4} w="full">
                      <Text className="text-blue-50/70">A representative will contact you within 24-48 hours to:</Text>
                      {[
                        "Explain the specific limitations identified",
                        "Discuss potential alternative solutions",
                        "Review your options moving forward",
                        "Process any applicable refunds",
                      ].map((item, index) => (
                        <HStack key={index} spacing={3}>
                          <AlertCircle className="w-5 h-5 text-red-400" />
                          <Text className="text-blue-50/70">{item}</Text>
                        </HStack>
                      ))}
                    </VStack>
                  </VStack>

                  <Button
                    className="bg-gradient-to-r from-amber-500 to-amber-600 text-white px-8 py-3 rounded-lg 
                  hover:from-amber-600 hover:to-amber-700 transition-all duration-300"
                    onClick={() => {
                      /* Handle contact support action */
                    }}
                  >
                    Contact Support
                  </Button>
                </VStack>
              </Box>
            )}
          </VStack>
        </Fade>
      )}

      {hoverIndex === 4 && (
        <Fade in={hoverIndex === 4} transition={{ enter: { duration: 0.9 } }}>
          <VStack mt="40px" spacing={6}>
            {/* Initial/Waiting State */}
            {projectStatusIndex < 4 && (
              <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
                <HStack w="full" flexDirection={{ base: "column", "2xl": "row" }} spacing={{ base: 6, lg: 8 }}>
                  <Box className="relative w-full max-w-xl overflow-hidden rounded-lg" h={{ base: "200px", lg: "300px" }}>
                    <Image borderRadius="12px" className="object-cover w-full h-full" src="https://images.unsplash.com/photo-1554224155-8d04cb21cd6c?q=80&w=2071" />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                  </Box>
                  <VStack flex="1" align="start" spacing={6}>
                    <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold" className="text-blue-50">
                      Permits & Approvals
                    </Text>
                    <Text className="text-blue-50/70">After your site survey is complete, we'll handle all necessary permits and approvals with local authorities.</Text>
                  </VStack>
                </HStack>
              </Box>
            )}

            {/* Engineering Docs State */}
            {projectStatus === ProjectStatus.SITE_APPROVED_AWAITING_ENGINEERING_DOCS && (
              <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
                <VStack spacing={6} align="start">
                  <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold" className="text-blue-50">
                    Preparing Engineering Documents
                  </Text>
                  <Text className="text-blue-50/70">Our engineering team is creating detailed technical documentation required for permit applications.</Text>

                  <Box w="full" className="bg-white/5 rounded-lg" p={6}>
                    <VStack align="start" spacing={4} w="full">
                      <Text className="text-blue-50" fontWeight="semibold">
                        Document Preparation Progress:
                      </Text>
                      {[
                        { text: "Structural Analysis", done: true },
                        { text: "Electrical Diagrams", progress: 75 },
                        { text: "Equipment Specifications", progress: 50 },
                        { text: "Safety Plans", progress: 25 },
                      ].map((item, index) => (
                        <VStack key={index} w="full" spacing={2}>
                          <HStack w="full" justify="space-between">
                            <Text className="text-blue-50/70">{item.text}</Text>
                            <Text className="text-amber-500">{item.done ? "100%" : `${item.progress}%`}</Text>
                          </HStack>
                          <div className="w-full h-2 bg-white/10 rounded-full overflow-hidden">
                            <div
                              className="h-full bg-gradient-to-r from-amber-500 to-amber-600 transition-all duration-500"
                              style={{ width: item.done ? "100%" : `${item.progress}%` }}
                            ></div>
                          </div>
                        </VStack>
                      ))}
                    </VStack>
                  </Box>
                </VStack>
              </Box>
            )}

            {/* Permit Processing State */}
            {projectStatus === ProjectStatus.ENGINEERING_DOCS_FINISHED_AWAITING_PERMITS && (
              <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
                <VStack spacing={6} align="start">
                  <HStack spacing={4}>
                    <div className="bg-amber-500/20 p-3 rounded-full">
                      <FileText className="w-8 h-8 text-amber-500" />
                    </div>
                    <VStack align="start" spacing={1}>
                      <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold" className="text-blue-50">
                        Permit Applications Submitted
                      </Text>
                      <Text className="text-blue-50/70">All required documentation has been submitted to local authorities for review.</Text>
                    </VStack>
                  </HStack>

                  <Box w="full" className="bg-white/5 rounded-lg" p={6}>
                    <VStack align="start" spacing={4}>
                      <Text className="text-blue-50" fontWeight="semibold">
                        Submitted Applications:
                      </Text>
                      {["Building Permit", "Electrical Permit", "HOA Approval (if applicable)", "Utility Interconnection"].map((item, index) => (
                        <HStack key={index} spacing={3}>
                          <CheckCircle className="w-5 h-5 text-amber-500" />
                          <Text className="text-blue-50/70">{item}</Text>
                        </HStack>
                      ))}
                    </VStack>
                  </Box>

                  <Box w="full" className="bg-amber-500/10 border border-amber-500/20 rounded-lg" p={4}>
                    <HStack spacing={3}>
                      <Clock className="w-5 h-5 text-amber-500" />
                      <Text className="text-amber-500">Estimated approval time: 2-4 weeks</Text>
                    </HStack>
                  </Box>
                </VStack>
              </Box>
            )}

            {/* Permit Issues State */}
            {projectStatus === ProjectStatus.PERMITS_NOT_OBTAINED_AWAITING_RESOLUTION && (
              <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
                <VStack spacing={6}>
                  <Alert status="warning" className="bg-amber-500/10 text-amber-500 border border-amber-500/20 rounded-lg">
                    <AlertIcon width="40px" height="40px" />
                    <VStack align="start" spacing={2}>
                      <AlertTitle>Permit Processing Delay</AlertTitle>
                      <AlertDescription>
                        We've encountered some questions from the permitting authority. Our team is working to address their concerns and provide additional documentation as
                        needed.
                      </AlertDescription>
                    </VStack>
                  </Alert>
                  <Text className="text-blue-50/70">
                    This is a common part of the process and typically resolves within 5-10 business days. We'll keep you updated on any developments.
                  </Text>
                </VStack>
              </Box>
            )}

            {/* Success State */}
            {(projectStatus === ProjectStatus.PERMITS_OBTAINED_AWAITING_SECOND_PAYMENT || projectStatusIndex > 4) && (
              <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
                <HStack w="full" justifyContent="space-between" flexDirection={{ base: "column", lg: "row" }} spacing={4}>
                  <VStack align="start" spacing={3}>
                    <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold" className="text-blue-50">
                      All Permits Approved!
                    </Text>
                    <Text className="text-blue-50/70">Great news! All necessary permits and approvals have been granted. We're clear to proceed with your installation.</Text>
                  </VStack>
                  <CheckCircle className="w-10 h-10 text-green-500 flex-shrink-0" />
                </HStack>
              </Box>
            )}
          </VStack>
        </Fade>
      )}

      {hoverIndex === 5 && (
        <Fade in={hoverIndex === 5} transition={{ enter: { duration: 0.9 } }}>
          <VStack mt="40px" spacing={6}>
            {/* Initial/Waiting State */}
            {projectStatusIndex < 5 && (
              <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
                <HStack w="full" flexDirection={{ base: "column", "2xl": "row" }} spacing={{ base: 6, lg: 8 }}>
                  <Box className="relative w-full max-w-xl overflow-hidden rounded-lg" h={{ base: "200px", lg: "300px" }}>
                    <Image borderRadius="12px" className="object-cover w-full h-full" src="https://images.unsplash.com/photo-1604999565976-8913ad2ddb0c?q=80&w=2071" />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                  </Box>
                  <VStack flex="1" align="start" spacing={6}>
                    <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold" className="text-blue-50">
                      Second Payment
                    </Text>
                    <Text className="text-blue-50/70">After the permits have been obtained, you'll need to submit the final payment to complete the installation process.</Text>
                  </VStack>
                </HStack>
              </Box>
            )}

            {/* Payment Required State */}
            {projectStatus === ProjectStatus.PERMITS_OBTAINED_AWAITING_SECOND_PAYMENT && (
              <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
                <VStack spacing={6} align="start">
                  <VStack align="start" spacing={3}>
                    <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold" className="text-blue-50">
                      Submit Final Payment
                    </Text>
                    <Text className="text-blue-50/70">With the permits in place, the final step is to submit your remaining payment to secure your installation slot.</Text>
                  </VStack>

                  {!showStripePayment ? (
                    <VStack w="full" spacing={6}>
                      <VStack w="full" className="bg-white/5 rounded-lg" p={6} spacing={4}>
                        <HStack w="full" justify="space-between" className="border-b border-white/10 pb-4">
                          <Text className="text-blue-50">Final Payment Amount</Text>
                          <Text className="text-blue-50 font-bold">$12,000</Text>
                        </HStack>
                        <Text className="text-blue-50/70 text-sm">
                          This final payment covers the remaining installation costs, including equipment, labor, and interconnection fees.
                        </Text>
                      </VStack>
                      <Button
                        onClick={() => setShowStripePayment(true)}
                        className="bg-gradient-to-r from-amber-500 to-amber-600 text-white px-8 py-3 rounded-lg 
                        hover:from-amber-600 hover:to-amber-700 transition-all duration-300"
                      >
                        Submit Final Payment
                      </Button>
                    </VStack>
                  ) : (
                    <VStack w="full" className="bg-white/5 rounded-lg" p="40px">
                      <StripeComponent chargeAmount={12000} />
                    </VStack>
                  )}
                </VStack>
              </Box>
            )}

            {/* Payment Failed State */}
            {projectStatus === ProjectStatus.SECOND_PAYMENT_UNSUCCESSFUL_AWAITING_RESOLUTION && (
              <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
                <VStack spacing={6}>
                  <Alert status="error" className="bg-red-500/10 text-red-400 border border-red-500/20 rounded-lg">
                    <AlertIcon />
                    <VStack align="start" spacing={2}>
                      <AlertTitle>Final Payment Failed</AlertTitle>
                      <AlertDescription>There was an issue processing your final payment. Please check your payment method and try again.</AlertDescription>
                    </VStack>
                  </Alert>

                  {!showStripePayment ? (
                    <Button
                      onClick={() => setShowStripePayment(true)}
                      className="bg-gradient-to-r from-amber-500 to-amber-600 text-white px-8 py-3 rounded-lg 
                      hover:from-amber-600 hover:to-amber-700 transition-all duration-300"
                    >
                      Retry Final Payment
                    </Button>
                  ) : (
                    <VStack w="full" className="bg-white/5 rounded-lg" p="40px">
                      <StripeComponent chargeAmount={12000} />
                    </VStack>
                  )}
                </VStack>
              </Box>
            )}

            {/* Success State */}
            {(projectStatus === ProjectStatus.SECOND_PAYMENT_SUCCESSFUL_AWAITING_INSTALL_SCHEDULE || projectStatusIndex > 5) && (
              <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
                <HStack w="full" justifyContent="space-between" flexDirection={{ base: "column", lg: "row" }} spacing={4}>
                  <VStack align="start" spacing={3}>
                    <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold" className="text-blue-50">
                      Final Payment Received
                    </Text>
                    <Text className="text-blue-50/70">Thank you for your final payment! We're now ready to schedule your installation appointment.</Text>
                  </VStack>
                  <CheckCircle className="w-10 h-10 text-green-500 flex-shrink-0" />
                </HStack>
              </Box>
            )}
          </VStack>
        </Fade>
      )}

      {hoverIndex === 6 && (
        <Fade in={hoverIndex === 6} transition={{ enter: { duration: 0.9 } }}>
          <VStack mt="40px" spacing={6}>
            {/* Initial/Waiting State */}
            {projectStatusIndex < 6 && (
              <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
                <HStack w="full" flexDirection={{ base: "column", "2xl": "row" }} spacing={{ base: 6, lg: 8 }}>
                  <Box className="relative w-full max-w-xl overflow-hidden rounded-lg" h={{ base: "200px", lg: "300px" }}>
                    <Image borderRadius="12px" className="object-cover w-full h-full" src="https://images.unsplash.com/photo-1593441669257-c862810b9d81?q=80&w=2071" />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                  </Box>
                  <VStack flex="1" align="start" spacing={6}>
                    <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold" className="text-blue-50">
                      Installation Scheduling
                    </Text>
                    <Text className="text-blue-50/70">With all the necessary steps completed, we'll now schedule your installation appointment with the crew.</Text>
                  </VStack>
                </HStack>
              </Box>
            )}

            {/* Awaiting Schedule State */}
            {projectStatus === ProjectStatus.SECOND_PAYMENT_SUCCESSFUL_AWAITING_INSTALL_SCHEDULE && (
              <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
                <VStack spacing={6} align="start">
                  <VStack align="start" spacing={3}>
                    <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold" className="text-blue-50">
                      Scheduling Your Installation
                    </Text>
                    <Text className="text-blue-50/70">
                      We're coordinating with {userData.installer ? userData.installer.company_name : "your installer"} to find the best time for your solar installation. You'll be
                      contacted shortly to confirm the appointment.
                    </Text>
                  </VStack>

                  <Box w="full" className="bg-white/5 rounded-lg" p={6}>
                    <VStack align="start" spacing={4}>
                      <Text className="text-blue-50" fontWeight="semibold">
                        What to Expect:
                      </Text>
                      {["Full system installation", "Electrical connections and testing", "Final inspections", "Utility interconnection", "System activation"].map(
                        (item, index) => (
                          <HStack key={index} spacing={3}>
                            <CheckCircle className="w-5 h-5 text-amber-500" />
                            <Text className="text-blue-50/70">{item}</Text>
                          </HStack>
                        )
                      )}
                    </VStack>
                  </Box>
                </VStack>
              </Box>
            )}

            {/* Installation Scheduled State */}
            {projectStatus === ProjectStatus.INSTALL_SCHEDULED_AWAITING_INSTALL && (
              <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
                <VStack spacing={6} align="start">
                  <HStack spacing={4}>
                    <div className="bg-green-500/20 p-3 rounded-full">
                      <Calendar className="w-8 h-8 text-green-500" />
                    </div>
                    <VStack align="start" spacing={1}>
                      <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold" className="text-blue-50">
                        Installation Scheduled
                      </Text>
                      <Text className="text-blue-50/70">
                        {userData && userData.installation_date
                          ? `Scheduled for ${new Date(userData.installation_date).toLocaleDateString()} at ${new Date(userData.installation_date).toLocaleTimeString()}`
                          : "Date and time to be confirmed"}
                      </Text>
                    </VStack>
                  </HStack>

                  <Box w="full" className="bg-amber-500/10 border border-amber-500/20 rounded-lg" p={4}>
                    <VStack align="start" spacing={3}>
                      <Text className="text-amber-500" fontWeight="semibold">
                        Preparation Checklist:
                      </Text>
                      {["Ensure clear access to your roof and yard", "Secure any pets during the installation", "Be present for the full duration (typically 6-8 hours)"].map(
                        (item, index) => (
                          <HStack key={index} spacing={3}>
                            <CheckCircle className="w-4 h-4 text-amber-500" />
                            <Text className="text-amber-500">{item}</Text>
                          </HStack>
                        )
                      )}
                    </VStack>
                  </Box>
                </VStack>
              </Box>
            )}

            {/* Installation in Progress State */}
            {projectStatus === ProjectStatus.INSTALL_SCHEDULED_AWAITING_INSTALL && (
              <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
                <VStack spacing={6} align="start">
                  <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold" className="text-blue-50">
                    Installation in Progress
                  </Text>
                  <Text className="text-blue-50/70">
                    Our installation crew is on-site working to complete your solar system. We'll keep you updated on the progress throughout the day.
                  </Text>
                  <Box w="full" className="bg-white/5 rounded-lg" p={6}>
                    <VStack align="start" spacing={4}>
                      <Text className="text-blue-50" fontWeight="semibold">
                        Installation Timeline:
                      </Text>
                      {[
                        { text: "Roof preparation", progress: 80 },
                        { text: "Panel installation", progress: 50 },
                        { text: "Electrical connections", progress: 30 },
                        { text: "Final inspections", progress: 10 },
                      ].map((item, index) => (
                        <VStack key={index} w="full" spacing={2}>
                          <HStack w="full" justify="space-between">
                            <Text className="text-blue-50/70">{item.text}</Text>
                            <Text className="text-amber-500">{item.progress}%</Text>
                          </HStack>
                          <div className="w-full h-2 bg-white/10 rounded-full overflow-hidden">
                            <div className="h-full bg-gradient-to-r from-amber-500 to-amber-600 transition-all duration-500" style={{ width: `${item.progress}%` }}></div>
                          </div>
                        </VStack>
                      ))}
                    </VStack>
                  </Box>
                </VStack>
              </Box>
            )}

            {/* Installation Issue State */}
            {projectStatus === ProjectStatus.INSTALL_NOT_COMPLETED_AWAITING_RESOLUTION && (
              <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
                <VStack spacing={6}>
                  <Alert status="warning" className="bg-amber-500/10 text-amber-500 border border-amber-500/20 rounded-lg">
                    <AlertIcon width="40px" height="40px" />
                    <VStack align="start" spacing={2}>
                      <AlertTitle>Installation Delay</AlertTitle>
                      <AlertDescription>
                        We've encountered an issue during the installation process. Our team is working to resolve the problem and get your system back on track.
                      </AlertDescription>
                    </VStack>
                  </Alert>
                  <Text className="text-blue-50/70">
                    This is an uncommon occurrence, but we'll do our best to get your system installed as quickly as possible. We'll keep you updated on the status.
                  </Text>
                </VStack>
              </Box>
            )}

            {/* Success State */}
            {(projectStatus === ProjectStatus.INSTALL_COMPLETED_AWAITING_UTILITY_APPROVAL || projectStatusIndex > 6) && (
              <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
                <HStack w="full" justifyContent="space-between" flexDirection={{ base: "column", lg: "row" }} spacing={4}>
                  <VStack align="start" spacing={3}>
                    <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold" className="text-blue-50">
                      Installation Complete
                    </Text>
                    <Text className="text-blue-50/70">
                      Congratulations! Your solar system installation is now complete. We'll be working with the utility company to finalize the interconnection process.
                    </Text>
                  </VStack>
                  <CheckCircle className="w-10 h-10 text-green-500 flex-shrink-0" />
                </HStack>
              </Box>
            )}
          </VStack>
        </Fade>
      )}

      {hoverIndex === 7 && (
        <Fade in={hoverIndex === 7} transition={{ enter: { duration: 0.9 } }}>
          <VStack mt="40px" spacing={6}>
            {/* Initial/Waiting State */}
            {projectStatusIndex < 7 && (
              <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
                <HStack w="full" flexDirection={{ base: "column", "2xl": "row" }} spacing={{ base: 6, lg: 8 }}>
                  <Box className="relative w-full max-w-xl overflow-hidden rounded-lg" h={{ base: "200px", lg: "300px" }}>
                    <Image borderRadius="12px" className="object-cover w-full h-full" src="https://images.unsplash.com/photo-1554224155-8d04cb21cd6c?q=80&w=2071" />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                  </Box>
                  <VStack flex="1" align="start" spacing={6}>
                    <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold" className="text-blue-50">
                      Utility Approval
                    </Text>
                    <Text className="text-blue-50/70">
                      After your solar installation is complete, we'll work with the utility company to get your system approved and connected to the grid.
                    </Text>
                  </VStack>
                </HStack>
              </Box>
            )}

            {/* Awaiting Utility Approval State */}
            {projectStatus === ProjectStatus.INSTALL_COMPLETED_AWAITING_UTILITY_APPROVAL && (
              <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
                <VStack spacing={6} align="start">
                  <HStack spacing={4}>
                    <div className="bg-amber-500/20 p-3 rounded-full">
                      <Shield className="w-8 h-8 text-amber-500" />
                    </div>
                    <VStack align="start" spacing={1}>
                      <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold" className="text-blue-50">
                        Awaiting Utility Approval
                      </Text>
                      <Text className="text-blue-50/70">
                        We've submitted your solar system for utility interconnection approval. This is the final step before your system can be activated.
                      </Text>
                    </VStack>
                  </HStack>

                  <Box w="full" className="bg-white/5 rounded-lg" p={6}>
                    <VStack align="start" spacing={4}>
                      <Text className="text-blue-50" fontWeight="semibold">
                        Utility Approval Process:
                      </Text>
                      {["Utility site inspection", "Interconnection agreement review", "Meter installation", "Final system testing"].map((item, index) => (
                        <HStack key={index} spacing={3}>
                          <CheckCircle className="w-5 h-5 text-amber-500" />
                          <Text className="text-blue-50/70">{item}</Text>
                        </HStack>
                      ))}
                    </VStack>
                  </Box>

                  <Box w="full" className="bg-amber-500/10 border border-amber-500/20 rounded-lg" p={4}>
                    <HStack spacing={3}>
                      <Clock className="w-5 h-5 text-amber-500" />
                      <Text className="text-amber-500">Estimated approval time: 1-2 weeks</Text>
                    </HStack>
                  </Box>
                </VStack>
              </Box>
            )}

            {/* Utility Issue State */}
            {projectStatus === ProjectStatus.UTILITY_APPROVAL_ISSUE_AWAITING_RESOLUTION && (
              <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
                <VStack spacing={6}>
                  <Alert status="warning" className="bg-amber-500/10 text-amber-500 border border-amber-500/20 rounded-lg">
                    <AlertIcon width="40px" height="40px" />
                    <VStack align="start" spacing={2}>
                      <AlertTitle>Utility Approval Delay</AlertTitle>
                      <AlertDescription>
                        We're experiencing a delay in obtaining final utility approval for your solar system. Our team is working closely with the utility company to resolve the
                        issue.
                      </AlertDescription>
                    </VStack>
                  </Alert>
                  <Text className="text-blue-50/70">
                    This is an uncommon occurrence, but we'll do our best to get your system approved and activated as quickly as possible. We'll keep you updated on the status.
                  </Text>
                </VStack>
              </Box>
            )}

            {/* Utility Approved State */}
            {projectStatus === ProjectStatus.UTILITY_APPROVED_AWAITING_SYSTEM_TURN_ON && (
              <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
                <VStack spacing={6} align="start">
                  <HStack spacing={4}>
                    <div className="bg-green-500/20 p-3 rounded-full">
                      <CheckCircle className="w-8 h-8 text-green-500" />
                    </div>
                    <VStack align="start" spacing={1}>
                      <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold" className="text-blue-50">
                        Utility Approval Obtained
                      </Text>
                      <Text className="text-blue-50/70">
                        Great news! Your solar system has been approved by the utility company. We're now ready to schedule the final system activation.
                      </Text>
                    </VStack>
                  </HStack>

                  <Box w="full" className="bg-white/5 rounded-lg" p={6}>
                    <VStack align="start" spacing={4}>
                      <Text className="text-blue-50" fontWeight="semibold">
                        Next Steps:
                      </Text>
                      {["Final utility inspection", "Net meter installation", "System activation and testing"].map((item, index) => (
                        <HStack key={index} spacing={3}>
                          <Clock className="w-5 h-5 text-amber-500" />
                          <Text className="text-blue-50/70">{item}</Text>
                        </HStack>
                      ))}
                    </VStack>
                  </Box>

                  <Box w="full" className="bg-amber-500/10 border border-amber-500/20 rounded-lg" p={4}>
                    <HStack spacing={3}>
                      <Info className="w-5 h-5 text-amber-500" />
                      <Text className="text-amber-500">Estimated completion: 3-5 business days</Text>
                    </HStack>
                  </Box>
                </VStack>
              </Box>
            )}

            {/* System Activation Failed State */}
            {projectStatus === ProjectStatus.SYSTEM_NOT_TURNED_ON_AWAITING_RESOLUTION && (
              <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
                <VStack spacing={6}>
                  <Alert status="warning" className="bg-amber-500/10 text-amber-500 border border-amber-500/20 rounded-lg">
                    <AlertIcon width="40px" height="40px" />
                    <VStack align="start" spacing={2}>
                      <AlertTitle>System Activation Issue</AlertTitle>
                      <AlertDescription>
                        We've encountered a problem during the final system activation. Our team is working to resolve the issue and get your solar system up and running.
                      </AlertDescription>
                    </VStack>
                  </Alert>
                  <Text className="text-blue-50/70">
                    This is an uncommon occurrence, but we'll do our best to get your system activated as quickly as possible. We'll keep you updated on the status.
                  </Text>
                </VStack>
              </Box>
            )}

            {/* Success State */}
            {projectStatus === ProjectStatus.SYSTEM_TURNED_ON && (
              <Box w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p={{ base: "6", lg: "8" }}>
                <HStack w="full" justifyContent="space-between" flexDirection={{ base: "column", lg: "row" }} spacing={4}>
                  <VStack align="start" spacing={3}>
                    <Text fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold" className="text-blue-50">
                      System Activated!
                    </Text>
                    <Text className="text-blue-50/70">
                      Congratulations! Your solar system is now fully installed, approved, and activated. You can start enjoying the benefits of clean, renewable energy.
                    </Text>
                  </VStack>
                  <CheckCircle className="w-10 h-10 text-green-500 flex-shrink-0" />
                </HStack>
              </Box>
            )}
          </VStack>
        </Fade>
      )}
    </Box>
  );
};

export default UserPostOrderDash;
