import React from "react";
import { Box, VStack, HStack, Text } from "@chakra-ui/react";
import AdminNavBar from "../Components/AdminNavBar";

const AdminDash = () => {
  return (
    <Box w="full" minH="100vh">
      <AdminNavBar />
      <VStack w="full" p="40px">
        <HStack w="full" align="stretch">
          <Box borderRadius="6px" border="2px solid black" p="20px">
            <Text fontWeight="bold">Installers on the platform</Text>
            <Text fontWeight="bold" fontSize="32px">
              12
            </Text>
          </Box>
        </HStack>
      </VStack>
    </Box>
  );
};
export default AdminDash;
