import React, { useState, useEffect, useRef } from 'react';
import { Sun, Battery, CloudSun, Zap, DollarSign, Users, Shield, Activity, ChevronDown, Check, TreePine, Car, Factory, Smartphone, LockIcon, Home, BatteryCharging } from 'lucide-react';
import { HStack } from '@chakra-ui/react';
import "./LandingPage.css";

const LandingPage = () => {
  const [activeSection, setActiveSection] = useState('hero');
  const [savings, setSavings] = useState(0);
  const [showCalculator, setShowCalculator] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [hoveredBenefit, setHoveredBenefit] = useState(null);
  
  // Parallax effect for hero section
  const [offset, setOffset] = useState(0);
  const heroRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (heroRef.current) {
        const { top } = heroRef.current.getBoundingClientRect();
        setOffset(window.scrollY * 0.5);
        
        // Update active section based on scroll
        const sections = document.querySelectorAll('section');
        sections.forEach(section => {
          const rect = section.getBoundingClientRect();
          if (rect.top <= window.innerHeight * 0.5 && rect.bottom >= window.innerHeight * 0.5) {
            setActiveSection(section.id);
          }
        });
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Animated counter for savings
  useEffect(() => {
    const interval = setInterval(() => {
      setSavings(prev => {
        const next = prev + 1;
        return next > 45 ? 45 : next;
      });
    }, 50);
    return () => clearInterval(interval);
  }, []);

  const benefits = [
    {
      icon: <DollarSign className="w-16 h-16" />,
      title: "Zero Commission",
      description: "Skip the salespeople and save thousands on commissions",
      color: "bg-gradient-to-br from-green-400 to-emerald-600"
    },
    {
      icon: <Users className="w-16 h-16" />,
      title: "Vetted Installers",
      description: "Compare top-rated installers competing for your business",
      color: "bg-gradient-to-br from-blue-400 to-indigo-600"
    },
    {
      icon: <Shield className="w-16 h-16" />,
      title: "Price Match",
      description: "Get the best price guaranteed with our installer network",
      color: "bg-gradient-to-br from-purple-400 to-violet-600"
    }
  ];

  const QuickCalculator = () => (
    <div className="bg-white rounded-xl p-6 shadow-xl">
      <h3 className="text-2xl font-bold mb-4">Estimate Your Savings</h3>
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium mb-2">Monthly Electric Bill</label>
          <input 
            type="range" 
            min="100" 
            max="500" 
            step="50"
            className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
            onChange={(e) => setSavings(Math.round(e.target.value * 0.15))}
          />
        </div>
        <div className="text-center">
          <div className="text-4xl font-bold text-green-500">${savings}</div>
          <div className="text-gray-600">Estimated Monthly Savings</div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-full overflow-hidden">
      {/* Floating Navigation */}
      <nav className="fixed top-4 left-1/2 transform -translate-x-1/2 z-50 bg-white/90 rounded-3xl shadow-lg backdrop-blur-md px-6 py-3">
        <ul className="flex space-x-8">
          {['Benefits', 'Calculator', 'Process', 'Reviews'].map((item) => (
            <li key={item}>
              <button 
                className={`text-sm font-medium transition-colors ${
                  activeSection === item.toLowerCase() ? 'text-blue-600' : 'text-gray-600 hover:text-blue-600'
                }`}
                onClick={() => document.getElementById(item.toLowerCase())?.scrollIntoView({ behavior: 'smooth' })}
              >
                {item}
              </button>
            </li>
          ))}
        </ul>
      </nav>

      {/* Hero Section */}
      <section 
        id="hero" 
        ref={heroRef}
        className="min-h-screen relative flex items-center justify-center overflow-hidden bg-gradient-to-b from-blue-900 via-blue-600 to-blue-400"
      >
        {/* Animated background elements */}
        {/* <div className="absolute inset-0 overflow-hidden">
          {[...Array(20)].map((_, i) => (
            <div
              key={i}
              className="absolute animate-float"
              style={{
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                animationDelay: `${Math.random() * 5}s`,
                transform: `translateY(${offset * (Math.random() + 0.5)}px)`
              }}
            >
              <Sun className="w-8 h-8 text-yellow-300 opacity-30" />
            </div>
          ))}
        </div> */}
        
        <div className="relative z-10 max-w-6xl mx-auto text-center px-4">
          <div className="mb-8">
            <Sun className="w-32 h-32 text-yellow-400 mx-auto animate-pulse" />
          </div>
          {/* <h1 className="text-6xl font-bold mb-6 text-white">
            Solar Power, <span className="text-yellow-300">Simplified</span>
          </h1> */}
          <h1 className="text-6xl font-bold mb-6 text-white">
            <span className='text-6xl' style={{fontFamily: "'new-astro', sans-serif", fontWeight: 300}}>Klaravia</span>
          </h1>
          <p className="text-2xl text-blue-100 mb-12 max-w-2xl mx-auto">
            Design, compare, and purchase your perfect solar system online - 
            <span className="font-semibold"> save up to 45% on installation</span>
          </p>
          <HStack className="flex flex-col sm:flex-row items-center justify-center gap-4">
            <button className="px-8 py-4 bg-gradient-to-r from-green-400 to-emerald-600 text-white rounded-3xl text-lg font-semibold hover:scale-105 transition-transform">
              Start Saving Now
            </button>
            <button className="px-8 py-4 bg-white/10 backdrop-blur-md text-white rounded-3xl text-lg font-semibold hover:bg-white/20 transition-colors">
              Watch Demo
            </button>
          </HStack>
          <ChevronDown className="w-12 h-12 text-white/50 mx-auto mt-12 animate-bounce" />
        </div>
      </section>

      {/* Benefits Section */}
      <section id="benefits" className="py-24 px-4 bg-gray-50">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-4xl font-bold text-center mb-16">
            Why Choose Our Platform?
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {benefits.map((benefit, index) => (
              <div
                key={index}
                className="relative group"
                onMouseEnter={() => setHoveredBenefit(index)}
                onMouseLeave={() => setHoveredBenefit(null)}
              >
                <div className={`rounded-2xl p-8 text-white transition-transform ${benefit.color} ${
                  hoveredBenefit === index ? 'scale-105' : ''
                }`}>
                  <div className="mb-6">{benefit.icon}</div>
                  <h3 className="text-2xl font-bold mb-4">{benefit.title}</h3>
                  <p className="text-white/90">{benefit.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

            {/* Process Section */}
            <section id="process" className="py-24 px-4 bg-gradient-to-br from-indigo-900 via-blue-900 to-blue-800 text-white">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-4xl font-bold text-center mb-16">
            Your Journey to Solar Power
          </h2>
          <div className="relative">
            <div className="absolute top-1/2 left-0 w-full h-1 bg-blue-600 transform -translate-y-1/2 hidden md:block" />
            <div className="grid md:grid-cols-4 gap-8">
              {[
                { icon: <CloudSun />, title: "Design System" },
                { icon: <Users />, title: "Compare Installers" },
                { icon: <DollarSign />, title: "Best Price" },
                { icon: <Activity />, title: "Track Install" }
              ].map((step, index) => (
                <div 
                  key={index}
                  className={`relative ${currentStep === index + 1 ? 'scale-110' : ''} transition-transform`}
                  onMouseEnter={() => setCurrentStep(index + 1)}
                >
                  <div className="bg-blue-800 rounded-2xl p-8 text-center hover:bg-blue-700 transition-colors">
                    <div className="w-16 h-16 mx-auto mb-4 flex items-center justify-center bg-blue-600 rounded-full">
                      {step.icon}
                    </div>
                    <h3 className="text-xl font-semibold">{step.title}</h3>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Interactive Calculator Section */}
      <section id="calculator" className="py-24 px-4 bg-white">
        <div className="max-w-6xl mx-auto">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <h2 className="text-4xl font-bold mb-6">
                Design Your Perfect Solar System
              </h2>
              <p className="text-xl text-gray-600 mb-8">
                Use our interactive calculator to customize your solar system and see real-time savings estimates.
              </p>
              <div className="space-y-4">
                {[
                  "Instant cost estimates",
                  "Compare multiple designs",
                  "See potential savings",
                  "No commitment required"
                ].map((item, index) => (
                  <div key={index} className="flex items-center space-x-3">
                    <Check className="w-6 h-6 text-green-500" />
                    <span>{item}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className="relative">
              <QuickCalculator />
              <button 
                className="mt-6 w-full py-4 bg-blue-600 text-white rounded-xl font-semibold hover:bg-blue-700 transition-colors"
                onClick={() => setShowCalculator(true)}
              >
                Get Detailed Estimate
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Reviews Section */}
      {/* <section id="reviews" className="py-24 px-4 bg-gray-50">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-4xl font-bold text-center mb-16">
            What Our Customers Say
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {[...Array(3)].map((_, index) => (
              <div key={index} className="bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-shadow">
                <div className="flex items-center mb-4">
                  {[...Array(5)].map((_, i) => (
                    <Star key={i} className="w-5 h-5 text-yellow-400 fill-current" />
                  ))}
                </div>
                <p className="text-gray-600 mb-4">
                  "The process was incredibly smooth. No pushy salespeople, just straightforward information and great savings."
                </p>
                <div className="flex items-center">
                  <div className="w-12 h-12 bg-blue-100 rounded-full mr-4" />
                  <div>
                    <div className="font-semibold">John D.</div>
                    <div className="text-sm text-gray-500">Saved $12,450</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section> */}

      <section id="environmental-impact" className="py-24 px-4 bg-green-50">
  <div className="max-w-6xl mx-auto">
    <h2 className="text-4xl font-bold text-center mb-16 text-green-800">
      Your Impact on the Planet
    </h2>
    <div className="grid md:grid-cols-3 gap-8">
      {[
        { icon: <TreePine className="w-16 h-16 text-green-600" />, title: "Trees Planted", value: "250", subtitle: "equivalent CO2 offset per year" },
        { icon: <Car className="w-16 h-16 text-green-600" />, title: "Cars Off the Road", value: "2.5", subtitle: "equivalent emissions reduced per year" },
        { icon: <Factory className="w-16 h-16 text-green-600" />, title: "Tons of CO2 Avoided", value: "12", subtitle: "annual reduction in carbon emissions" }
      ].map((item, index) => (
        <div key={index} className="bg-white rounded-xl p-8 text-center shadow-lg hover:shadow-xl transition-shadow">
          <div className="mb-4 flex justify-center">{item.icon}</div>
          <h3 className="text-2xl font-bold mb-2 text-green-700">{item.title}</h3>
          <p className="text-4xl font-bold text-green-600 mb-2">{item.value}</p>
          <p className="text-gray-600">{item.subtitle}</p>
        </div>
      ))}
    </div>
  </div>
</section>

<section id="solar-technology" className="py-24 px-4 bg-gray-100">
  <div className="max-w-6xl mx-auto">
    <h2 className="text-4xl font-bold text-center mb-16">
      Cutting-Edge Solar Technology
    </h2>
    <div className="grid md:grid-cols-2 gap-12 items-center">
      <div className="space-y-6">
        <div className="flex items-start space-x-4">
          <Zap className="w-8 h-8 text-yellow-500 flex-shrink-0" />
          <div>
            <h3 className="text-xl font-semibold mb-2">High-Efficiency Panels</h3>
            <p className="text-gray-600">Our panels convert more sunlight into electricity, maximizing your energy production and savings.</p>
          </div>
        </div>
        <div className="flex items-start space-x-4">
          <Battery className="w-8 h-8 text-blue-500 flex-shrink-0" />
          <div>
            <h3 className="text-xl font-semibold mb-2">Smart Energy Storage</h3>
            <p className="text-gray-600">Store excess energy for use during peak hours or outages, ensuring you're always powered.</p>
          </div>
        </div>
        <div className="flex items-start space-x-4">
          <Smartphone className="w-8 h-8 text-green-500 flex-shrink-0" />
          <div>
            <h3 className="text-xl font-semibold mb-2">Intelligent Monitoring</h3>
            <p className="text-gray-600">Track your system's performance in real-time and optimize your energy usage with our smart app.</p>
          </div>
        </div>
      </div>
      <div className="relative">
        <img src="https://tandem-solar-systems.com/wp-content/uploads/2021/01/qcell360_BLK_600x600.jpeg" alt="Solar panel technology" className="rounded-lg shadow-xl" />
        <div className="absolute -bottom-6 -right-6 bg-white rounded-lg p-4 shadow-lg">
          <p className="text-2xl font-bold text-blue-600">25+ Years</p>
          <p className="text-gray-600">Warranty</p>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="tax-credit" className="py-24 px-4 bg-green-50">
      <div className="max-w-6xl mx-auto">
        <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
          <div className="grid md:grid-cols-2">
            <div className="p-12 bg-gradient-to-br from-green-400 to-green-600 text-white">
              <h2 className="text-4xl font-bold mb-6">
                Claim Your 30% Federal Tax Credit
              </h2>
              <p className="text-xl mb-8">
                The government is paying you to go solar! Don't miss out on this incredible opportunity to save thousands.
              </p>
              <ul className="space-y-4">
                {[
                  "No income limit",
                  "Credit carries over",
                  "Applies to entire system cost"
                ].map((item, index) => (
                  <li key={index} className="flex items-center space-x-3">
                    <Check className="w-6 h-6 text-yellow-300" />
                    <span className="text-lg">{item}</span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="p-12 flex flex-col justify-center">
              <h3 className="text-3xl font-bold mb-4 text-gray-800">How much can you save?</h3>
              <p className="text-gray-600 mb-6">
                On a typical $20,000 solar installation:
              </p>
              <div className="bg-green-100 rounded-xl p-6 text-center">
                <p className="text-5xl font-bold text-green-600 mb-2">$6,000</p>
                <p className="text-xl text-gray-700">In Tax Credits</p>
              </div>
              <button className="mt-8 py-4 px-8 bg-green-500 text-white rounded-xl text-lg font-semibold hover:bg-green-600 transition-colors">
                Calculate Your Tax Credit
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="energy-prices" className="py-24 px-4 bg-gray-100">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-4xl font-bold text-center mb-16">
          Beat Rising Energy Prices
        </h2>
        <div className="grid md:grid-cols-2 gap-12 items-center">
          <div>
            <h3 className="text-2xl font-bold mb-6 text-red-600">
              Electricity Costs Are Skyrocketing
            </h3>
            <p className="text-xl text-gray-700 mb-6">
              Over the past decade, electricity prices have risen by an average of 3% per year. Don't let your bills keep climbing!
            </p>
            <ul className="space-y-4">
              {[
                "Lock in your energy costs",
                "Protect against future rate hikes",
                "Achieve energy independence"
              ].map((item, index) => (
                <li key={index} className="flex items-center space-x-3">
                  <LockIcon className="w-6 h-6 text-green-500" />
                  <span className="text-lg">{item}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="relative">
            <img src="https://assets.solar.com/wp-content/uploads/2023/07/longterm-grid-electricity-price-forecast.png" alt="Graph showing rising energy prices" className="rounded shadow-xl" />
            <div className="absolute -bottom-6 -right-6 bg-white rounded-lg p-4 shadow-lg">
              <p className="text-2xl font-bold text-green-600">Save up to 90%</p>
              <p className="text-gray-600">on your electric bill</p>
            </div>
          </div>
        </div>
        <div className="mt-12 text-center">
          <button className="py-4 px-8 bg-blue-600 text-white rounded-xl text-lg font-semibold hover:bg-blue-700 transition-colors">
            Get Your Free Savings Estimate
          </button>
        </div>
      </div>
    </section>

    <section id="home-value" className="py-24 px-4 bg-gray-100">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-4xl font-bold text-center mb-16">
          Boost Your Home's Value
        </h2>
        <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
          <div className="grid md:grid-cols-2">
            <div className="p-12">
              <h3 className="text-3xl font-bold mb-6 text-gray-800">
                Solar Homes Sell for More
              </h3>
              <p className="text-xl text-gray-600 mb-8">
                Studies show that homes with solar panels sell for 4.1% more on average than comparable homes without solar.
              </p>
              <div className="bg-green-100 rounded-xl p-6 text-center mb-8">
                <p className="text-4xl font-bold text-green-600 mb-2">$15,000</p>
                <p className="text-xl text-gray-700">Average increase in home value</p>
                <p className="text-sm text-gray-500">Based on median U.S. home price</p>
              </div>
              <ul className="space-y-4">
                {[
                  "Attract eco-conscious buyers",
                  "Faster home sales",
                  "Future-proof your property"
                ].map((item, index) => (
                  <li key={index} className="flex items-center space-x-3">
                    <Check className="w-6 h-6 text-green-500" />
                    <span className="text-lg text-gray-700">{item}</span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="bg-gradient-to-br from-blue-400 to-blue-600 p-12 flex flex-col justify-center text-white">
              <h3 className="text-3xl font-bold mb-6">
                Invest in Your Home's Future
              </h3>
              <p className="text-xl mb-8">
                Solar panels are not just an energy solution – they're a smart financial investment that pays dividends for years to come.
              </p>
              <button className="py-4 px-8 bg-white text-blue-600 rounded-xl text-lg font-semibold hover:bg-gray-100 transition-colors">
                Get a Free Home Value Assessment
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="energy-independence" className="py-24 px-4 bg-blue-50">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-4xl font-bold text-center mb-16">
          Achieve True Energy Independence
        </h2>
        <div className="grid md:grid-cols-3 gap-8">
          {[
            { icon: <Home className="w-16 h-16" />, title: "Power Your Home", description: "Generate your own clean electricity and reduce reliance on the grid." },
            { icon: <BatteryCharging className="w-16 h-16" />, title: "Store Your Energy", description: "With battery storage, use your own power even when the sun isn't shining." },
            { icon: <Zap className="w-16 h-16" />, title: "Sell Excess Power", description: "Feed excess energy back to the grid and get credited on your bill." }
          ].map((item, index) => (
            <div key={index} className="bg-white rounded-xl p-8 text-center shadow-lg hover:shadow-xl transition-shadow">
              <div className="mb-6 flex justify-center text-blue-500">{item.icon}</div>
              <h3 className="text-2xl font-bold mb-4">{item.title}</h3>
              <p className="text-gray-600">{item.description}</p>
            </div>
          ))}
        </div>
        <div className="mt-16 text-center">
          <p className="text-xl text-gray-700 mb-8">
            Take control of your energy future. Say goodbye to unpredictable bills and hello to sustainable, self-generated power.
          </p>
          <button className="py-4 px-8 bg-blue-600 text-white rounded-xl text-lg font-semibold hover:bg-blue-700 transition-colors">
            Start Your Journey to Energy Independence
          </button>
        </div>
      </div>
    </section>

      {/* CTA Section */}
      <section className="py-24 px-4 bg-gradient-to-r from-green-500 to-emerald-600 text-white">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-4xl font-bold mb-6">
            Ready to Start Saving?
          </h2>
          <p className="text-xl mb-8">
            Join thousands of homeowners who have already made the switch to clean, affordable solar energy.
          </p>
          <button className="px-12 py-6 bg-white text-emerald-600 rounded-3xl text-xl font-bold hover:scale-105 transition-transform">
            Get Your Free Quote
          </button>
          <p className="mt-6 text-sm text-white/80">
            No obligation • Free consultation • Best price guaranteed
          </p>
        </div>
      </section>

      {/* Footer */}
      {/* <footer className="bg-gray-900 text-white py-16 px-4">
        <div className="max-w-6xl mx-auto grid md:grid-cols-4 gap-12">
          <div>
            <h3 className="text-xl font-bold mb-4">About Us</h3>
            <p className="text-gray-400">
              Making solar simple and accessible for everyone through transparent pricing and innovative technology.
            </p>
          </div>
          {['Resources', 'Support', 'Legal'].map((section) => (
            <div key={section}>
              <h3 className="text-xl font-bold mb-4">{section}</h3>
              <ul className="space-y-2">
                {[...Array(4)].map((_, index) => (
                  <li key={index} className="text-gray-400 hover:text-white cursor-pointer">
                    {section} Link {index + 1}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </footer> */}
    </div>
  );
};

 export default LandingPage;

// import React, { useState, useEffect } from 'react';
// import { Sun, ArrowRight, CheckCircle, Star, ChevronDown, DollarSign, Shield, Users, Clock, Activity, Menu } from 'lucide-react';

// const LandingPage = () => {
//   const [isScrolled, setIsScrolled] = useState(false);
//   const [currentBenefit, setCurrentBenefit] = useState(0);
//   const [billAmount, setBillAmount] = useState(200);
//   const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
//   const [activeSection, setActiveSection] = useState('hero');

//   // Handle scroll events
//   useEffect(() => {
//     const handleScroll = () => {
//       setIsScrolled(window.scrollY > 50);
      
//       // Update active section based on scroll position
//       const sections = document.querySelectorAll('section[id]');
//       const scrollPosition = window.scrollY + 100;

//       sections.forEach(section => {
//         if (scrollPosition >= section.offsetTop && scrollPosition < section.offsetTop + section.offsetHeight) {
//           setActiveSection(section.id);
//         }
//       });
//     };

//     window.addEventListener('scroll', handleScroll);
//     return () => window.removeEventListener('scroll', handleScroll);
//   }, []);

//   // Auto-rotate benefits
//   useEffect(() => {
//     const timer = setInterval(() => {
//       setCurrentBenefit((prev) => (prev + 1) % benefits.length);
//     }, 5000);
//     return () => clearInterval(timer);
//   }, []);

//   const benefits = [
//     {
//       icon: <DollarSign className="w-6 h-6" />,
//       title: "Commission-Free Savings",
//       description: "Skip the salespeople and save up to 30% on your installation costs"
//     },
//     {
//       icon: <Users className="w-6 h-6" />,
//       title: "Vetted Local Installers",
//       description: "Compare top-rated installers competing to offer you the best price"
//     },
//     {
//       icon: <Shield className="w-6 h-6" />,
//       title: "Transparent Process",
//       description: "Track your installation progress with real-time updates and support"
//     }
//   ];

//   const calculateSavings = (bill) => {
//     return Math.round(bill * 0.85 * 12);
//   };

//   return (
//     <div className="min-h-screen bg-white">
//       {/* Navigation */}
//       <nav className={`fixed w-full z-50 transition-all duration-300 ${
//         isScrolled ? 'bg-white shadow-md py-2' : 'bg-transparent py-4'
//       }`}>
//         <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//           <div className="flex justify-between items-center">
//             {/* Logo */}
//             <div className="flex items-center">
//               <Sun className={`w-8 h-8 ${isScrolled ? 'text-blue-600' : 'text-white'}`} />
//               <span className={`ml-2 font-bold text-xl ${isScrolled ? 'text-gray-900' : 'text-white'}`}>
//                 SolarSimple
//               </span>
//             </div>

//             {/* Desktop Navigation */}
//             <div className="hidden md:flex items-center space-x-8">
//               {['Benefits', 'Process', 'Calculator', 'Reviews'].map((item) => (
//                 <button
//                   key={item}
//                   className={`font-medium transition-colors ${
//                     isScrolled ? 'text-gray-600 hover:text-blue-600' : 'text-white/90 hover:text-white'
//                   } ${activeSection === item.toLowerCase() ? 'text-blue-600' : ''}`}
//                   onClick={() => document.getElementById(item.toLowerCase())?.scrollIntoView({ behavior: 'smooth' })}
//                 >
//                   {item}
//                 </button>
//               ))}
//               <button className="px-6 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-colors">
//                 Get Started
//               </button>
//             </div>

//             {/* Mobile menu button */}
//             <button 
//               className="md:hidden"
//               onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
//             >
//               <Menu className={`w-6 h-6 ${isScrolled ? 'text-gray-900' : 'text-white'}`} />
//             </button>
//           </div>
//         </div>

//         {/* Mobile menu */}
//         {mobileMenuOpen && (
//           <div className="md:hidden absolute top-full left-0 w-full bg-white shadow-lg">
//             <div className="px-4 py-2 space-y-2">
//               {['Benefits', 'Process', 'Calculator', 'Reviews'].map((item) => (
//                 <button
//                   key={item}
//                   className="block w-full text-left px-4 py-2 text-gray-600 hover:bg-gray-50"
//                   onClick={() => {
//                     document.getElementById(item.toLowerCase())?.scrollIntoView({ behavior: 'smooth' });
//                     setMobileMenuOpen(false);
//                   }}
//                 >
//                   {item}
//                 </button>
//               ))}
//             </div>
//           </div>
//         )}
//       </nav>

//       {/* Hero Section */}
//       <section id="hero" className="relative min-h-screen flex items-center pt-20 overflow-hidden bg-gradient-to-br from-blue-900 via-blue-800 to-blue-900">
//         {/* Animated background elements */}
//         <div className="absolute inset-0">
//           {[...Array(20)].map((_, i) => (
//             <div
//               key={i}
//               className="absolute animate-pulse"
//               style={{
//                 left: `${Math.random() * 100}%`,
//                 top: `${Math.random() * 100}%`,
//                 animationDelay: `${Math.random() * 5}s`,
//                 opacity: 0.1
//               }}
//             >
//               <Sun className="w-8 h-8 text-blue-400" />
//             </div>
//           ))}
//         </div>

//         <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
//           <div className="grid lg:grid-cols-2 gap-12 items-center">
//             <div>
//               <h1 className="text-5xl lg:text-6xl font-bold text-white mb-6">
//                 Your Solar Journey,
//                 <span className="block text-blue-400">Simplified</span>
//               </h1>
//               <p className="text-xl text-blue-100 mb-8 max-w-lg">
//                 Design and purchase your perfect solar system online. No salespeople, 
//                 no commissions – just honest pricing and verified installers.
//               </p>
//               <div className="flex flex-col sm:flex-row gap-4">
//                 <button className="px-8 py-4 bg-green-500 hover:bg-green-600 text-white rounded-full font-semibold transition-all transform hover:scale-105">
//                   Start Your Design
//                 </button>
//                 <button className="px-8 py-4 bg-white/10 hover:bg-white/20 text-white rounded-full font-semibold backdrop-blur-sm transition-all">
//                   Compare Prices
//                 </button>
//               </div>
              
//               {/* Trust indicators */}
//               <div className="mt-12 flex items-center space-x-8 text-white/80">
//                 <div className="flex items-center">
//                   <CheckCircle className="w-5 h-5 mr-2" />
//                   <span>No Sales Commission</span>
//                 </div>
//                 <div className="flex items-center">
//                   <CheckCircle className="w-5 h-5 mr-2" />
//                   <span>Best Price Guarantee</span>
//                 </div>
//               </div>
//             </div>

//             {/* Quick Calculator Card */}
//             <div className="relative">
//               <div className="bg-white rounded-2xl shadow-2xl p-8">
//                 <h3 className="text-2xl font-bold mb-6">Calculate Your Savings</h3>
//                 <div className="space-y-6">
//                   <div>
//                     <label className="block text-sm font-medium text-gray-700 mb-2">
//                       Monthly Electric Bill
//                     </label>
//                     <input
//                       type="range"
//                       min="50"
//                       max="500"
//                       value={billAmount}
//                       onChange={(e) => setBillAmount(e.target.value)}
//                       className="w-full h-2 bg-gray-200 rounded-full appearance-none cursor-pointer"
//                     />
//                     <div className="mt-2 text-center font-medium">
//                       ${billAmount}
//                     </div>
//                   </div>

//                   <div className="bg-gray-50 rounded-xl p-6 text-center">
//                     <div className="text-sm text-gray-600 mb-2">
//                       Estimated Annual Savings
//                     </div>
//                     <div className="text-4xl font-bold text-green-600">
//                       ${calculateSavings(billAmount)}
//                     </div>
//                   </div>

//                   <button className="w-full py-4 bg-blue-600 hover:bg-blue-700 text-white rounded-xl font-semibold transition-colors">
//                     Get Detailed Quote
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* Benefits Section */}
//       <section id="benefits" className="py-24 bg-gray-50">
//         <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//           <div className="text-center mb-16">
//             <h2 className="text-4xl font-bold mb-4">
//               Why Choose Our Platform?
//             </h2>
//             <p className="text-xl text-gray-600 max-w-2xl mx-auto">
//               We've revolutionized the solar buying process to make it transparent, 
//               efficient, and stress-free.
//             </p>
//           </div>

//           <div className="grid lg:grid-cols-3 gap-8">
//             {benefits.map((benefit, index) => (
//               <div
//                 key={index}
//                 className={`bg-white rounded-2xl p-8 shadow-lg transform transition-all duration-500 ${
//                   currentBenefit === index ? 'scale-105' : 'hover:scale-105'
//                 }`}
//                 onMouseEnter={() => setCurrentBenefit(index)}
//               >
//                 <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mb-6">
//                   {benefit.icon}
//                 </div>
//                 <h3 className="text-xl font-bold mb-4">{benefit.title}</h3>
//                 <p className="text-gray-600">{benefit.description}</p>
//               </div>
//             ))}
//           </div>
//         </div>
//       </section>

//       {/* Process Section */}
//       <section id="process" className="py-24 bg-white">
//         <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//           <div className="text-center mb-16">
//             <h2 className="text-4xl font-bold mb-4">
//               How It Works
//             </h2>
//             <p className="text-xl text-gray-600 max-w-2xl mx-auto">
//               A streamlined process designed to get you the perfect solar system at 
//               the best price.
//             </p>
//           </div>

//           <div className="relative">
//             <div className="absolute top-1/2 left-0 w-full h-0.5 bg-blue-100 transform -translate-y-1/2 hidden lg:block" />
//             <div className="grid lg:grid-cols-4 gap-8">
//               {[
//                 {
//                   icon: <Activity className="w-6 h-6" />,
//                   title: "Design Your System",
//                   description: "Use our calculator to design your perfect system"
//                 },
//                 {
//                   icon: <Users className="w-6 h-6" />,
//                   title: "Compare Installers",
//                   description: "Get competing quotes from verified installers"
//                 },
//                 {
//                   icon: <DollarSign className="w-6 h-6" />,
//                   title: "Choose & Save",
//                   description: "Select the best offer and save on commission"
//                 },
//                 {
//                   icon: <Clock className="w-6 h-6" />,
//                   title: "Track Installation",
//                   description: "Monitor progress with real-time updates"
//                 }
//               ].map((step, index) => (
//                 <div key={index} className="relative">
//                   <div className="bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-shadow">
//                     <div className="w-12 h-12 bg-blue-600 rounded-full flex items-center justify-center text-white mb-6">
//                       {step.icon}
//                     </div>
//                     <h3 className="text-xl font-bold mb-4">{step.title}</h3>
//                     <p className="text-gray-600">{step.description}</p>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* Reviews Section */}
//       <section id="reviews" className="py-24 bg-gray-50">
//         <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//           <div className="text-center mb-16">
//             <h2 className="text-4xl font-bold mb-4">
//               What Our Customers Say
//             </h2>
//             <p className="text-xl text-gray-600 max-w-2xl mx-auto">
//               Join thousands of satisfied homeowners who've made the switch to solar.
//             </p>
//           </div>
//               </div>
//       </section>
// </div>
// )}

// export default LandingPage;