import React from 'react';
import './CoverageMap.css';
import USAMap from "react-usa-map";
import { Box, VStack, HStack, Text } from '@chakra-ui/react'; 

function CoverageMap() {
  const mapHandler = (event) => {
    // alert(event.target.dataset.name);
  };

  const statesFilling = () => {
    return {
      "AZ": {
        fill: "#58ad6c",
        // clickHandler: () => alert("Custom callback")
      },
      "MO": {
        fill: "#e2b945"
      },
      "NM": {
        fill: "#e2b945"
      },
      "IL": {
        fill: "#e2b945"
      },
      "TX": {
        fill: "#e2b945"
      },
    };
  };

  return (
    <Box w='full' fontFamily="'eurostile', sans-serif">
      <VStack w='full'  justifyContent='center'>
        <Text fontSize={{base: '42px', md: "60px", lg: "80px", xl: '92px'}} fontWeight='bold' color='gray.300'>We're growing</Text>
      <VStack w={{base: '90vw', lg: '900px'}} pt='60px'>
        <USAMap defaultFill='gray' customize={statesFilling()} onClick={mapHandler} />
        <Box w='full'>
        <HStack w='full' justifyContent='center'  borderRadius='6px' p='10px' pb='0px' mt='40px'>
          <HStack w='full' justifyContent='center'>
            <Box w='14px' h='14px' borderRadius='50%' bgColor='#58ad6c' />
            <Text fontSize={{base: '14px', sm: '18px'}} color='gray.300'>Servicing</Text>
          </HStack>
          <HStack w='full' justifyContent='center'>
            <Box w='14px' h='14px' borderRadius='50%' bgColor='#e2b945' />
            <Text fontSize={{base: '14px', sm: '18px'}} color='gray.300'>Coming Soon</Text>
          </HStack>
          <HStack w='full' justifyContent='center'>
            <Box w='14px' h='14px' borderRadius='50%' bgColor='gray' />
            <Text fontSize={{base: '14px', sm: '18px'}} color='gray.300'>Not Available</Text>
          </HStack>
        </HStack>
        </Box>
        </VStack>
      </VStack>
    </Box>
  );
}

export default CoverageMap;