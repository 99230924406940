import React from "react";
import { Box, Button, HStack, Input, VStack, Text } from "@chakra-ui/react";
import { BiSend } from "react-icons/bi";

const ReferralClaim = () => {
  return (
    <Box w="full" minH="100vh" bgColor="#f4eee9" fontFamily="'eurostile', sans-serif">
      <VStack w="full" minH="90vh" justifyContent="center">
        <VStack>
          <VStack minH="300px" bgColor="white" borderRadius="12px" p="20px" justifyContent="space-between">
            <Text fontSize="24px" fontWeight="bold" fontFamily="'eurostile', sans-serif">
              Claim your discount
            </Text>
            <Text>
              Use a referral bonus to recieve <b>$500</b> off the cost of your system.
            </Text>
            <HStack gap="0px">
              <Input w="md" h="60px" fontSize="22px" placeholder="__ __ __ ---- __ __ __ __" borderColor="gray.800" borderRightRadius="0px" borderRight="0px" bgColor="gray.100" />
              <Button colorScheme="green" h="60px" border="1px solid" borderLeftRadius="0px" borderColor="gray.800" rightIcon={<BiSend />}>
                Apply
              </Button>
            </HStack>
          </VStack>
          {/* TODO: implement referal codes */}
          {/* <HStack w="full" mt="10px" justifyContent="space-between" px="20px">
            <Text fontSize="14px">Want to share a referral code of your own?</Text>
            <Button h="24px" fontSize="14px" variant="link" colorScheme="orange">
              Generate a Referral Code
            </Button>
          </HStack> */}
        </VStack>
      </VStack>
    </Box>
  );
};
export default ReferralClaim;
