import React from "react";
import { Box, VStack, HStack, Text } from "@chakra-ui/react";
import { FileText, PenTool, FileSignature, Home, ClipboardCheck, CreditCard, HardHat, Power, LucideIcon } from "lucide-react";

interface Step {
  title: string;
  icon: LucideIcon;
}

interface UserProgressTrackerProps {
  setHoverIndex: (index: number) => void;
  hoverIndex: number;
  projectStatusIndex: number;
}

const UserProgressTracker: React.FC<UserProgressTrackerProps> = ({ setHoverIndex, hoverIndex, projectStatusIndex }) => {
  const steps: Step[] = [
    {
      title: "Bill Review",
      icon: FileText,
    },
    {
      title: "Design",
      icon: PenTool,
    },
    {
      title: "Documents",
      icon: FileSignature,
    },
    {
      title: "Site Survey",
      icon: Home,
    },
    {
      title: "Permits",
      icon: ClipboardCheck,
    },
    {
      title: "Payment",
      icon: CreditCard,
    },
    {
      title: "Installation",
      icon: HardHat,
    },
    {
      title: "Activation",
      icon: Power,
    },
  ];

  return (
    <VStack w="full" spacing={4}>
      {/* <Box w="full" className="rounded-lg p-4 mb-2 flex items-start space-x-3">
        <Info className="w-5 h-5 text-blue-400 flex-shrink-0" />
        <Text className="text-sm text-blue-50/70">Track your solar installation progress below. Click any stage to view more details.</Text>
      </Box> */}

      <Box w="full" overflowX="auto" className="hide-scrollbar" py={4}>
        <HStack spacing={0} minW={{ base: "800px", lg: "full" }} px={4} position="relative">
          {/* Progress Line */}
          <Box
            position="absolute"
            left="6%" // Adjust to align with first icon
            right="6%" // Adjust to align with last icon
            top="22px"
            height="6px"
            className="bg-white/10"
          />
          <Box
            position="absolute"
            left="6%" // Adjust to align with first icon
            right={`${95 - (projectStatusIndex + 1) * (90 / steps.length)}%`} // Adjusted calculation
            top="22px"
            height="6px"
            className="bg-gradient-to-r from-amber-500 to-amber-600 rounded-lg"
          />

          {steps.map((step, index) => {
            const StepIcon = step.icon;
            const isActive = index <= projectStatusIndex;
            const isHovered = index === hoverIndex;

            return (
              <VStack
                key={index}
                flex={1}
                position="relative"
                cursor="pointer"
                onClick={() => setHoverIndex(index)}
                spacing={2}
                className="transition-all duration-300 group"
                zIndex={1}
              >
                <Box
                  className={`
                    w-11 h-11 rounded-lg flex items-center justify-center
                    transition-all duration-300
                    ${isActive ? "bg-gradient-to-r from-amber-500 to-amber-600 shadow-lg shadow-amber-500/20" : "bg-[#121212] border-2 border-white/10"}
                    ${isHovered && !isActive ? "border-white/20" : ""}
                    group-hover:scale-110
                  `}
                >
                  <StepIcon className={`w-5 h-5 ${isActive ? "text-white" : "text-white/50"}`} />
                </Box>

                <Text
                  fontSize="sm"
                  fontWeight="medium"
                  className={`
                    transition-colors duration-300
                    ${isActive ? "text-blue-50" : "text-blue-50/50"}
                    ${isHovered ? "text-blue-50" : ""}
                  `}
                  textAlign="center"
                  px={2}
                >
                  {step.title}
                </Text>
              </VStack>
            );
          })}
        </HStack>
      </Box>
    </VStack>
  );
};

export default UserProgressTracker;
