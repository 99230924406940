import React, { useEffect, useState } from "react";
import { Search, DollarSign, ArrowRight, Sun, Check, ChevronRight, Clock, Shield, Zap } from "lucide-react";
import axios from "axios";
import { UserUpdateOptions } from "../../API";
import { NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import AddressSearchBar from "./AddressSearchBar";
import { useUserDataContext } from "../../Providers/LeadDataProvider";
import { motion } from "framer-motion";

const EntrySection = ({ onStart }: { onStart: () => void }) => {
  const [address, setAddress] = useState("");
  const [electricBill, setElectricBill] = useState<number | undefined>(undefined);
  const [monthlykWhValue, setMonthlykWhValue] = useState<number | undefined>(0);
  const [annualKwHUsage, setAnnualKwHUsage] = useState(0);
  const [localKwHPrice, setlocalKwHPrice] = useState(0);
  const [addressObject, setAddressObject] = useState({ long_name: "", state: "", county: "", city: "", postal_code: "", street: "", lat: 0, lng: 0 });
  const toast = useToast();
  const navigate = useNavigate();
  const { userData, setUserData } = useUserDataContext();

  useEffect(() => {
    if (electricBill && localKwHPrice && electricBill !== 0 && localKwHPrice !== 0) {
      const annUsage = electricBill / localKwHPrice;
      setAnnualKwHUsage(annUsage * 12);
    }
  }, [electricBill, localKwHPrice]);

  async function fetchApiData(lat: string, lng: string) {
    const url = `https://4zpask5mw0.execute-api.us-east-2.amazonaws.com/dev/address-processing-layer?lat=${lat}&lng=${lng}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`API call failed with status: ${response.status}`);
      }

      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error calling the API:", error);
      throw error;
    }
  }

  const callGoogleSolarApi = async (lat: string, lng: string) => {
    try {
      const googleSolarRes = axios.get(`https://4zpask5mw0.execute-api.us-east-2.amazonaws.com/dev/getGoogleSolarApi?lat=${lat}&lng=${lng}`);

      return googleSolarRes;
    } catch (error) {
      console.error("error getting response from google solar api:", error);
    }
  };

  const handleAddressSubmit = async (location: {
    long_name: string;
    state: string;
    county: string;
    city: string;
    street: string;
    postal_code: string;
    lat: number;
    lng: number;
  }) => {
    // fetchApiData(`${location.lat}`, `${location.lng}`)
    //   .then((data) => {
    //     setlocalKwHPrice(data + 0.08);
    //   })
    //   .catch((error) => {
    //     // Handle any errors

    //     console.error("Failed to fetch data from API:", error);
    //   });
    setlocalKwHPrice(0.18);
    setAddressObject(location);

    callGoogleSolarApi(`${location.lat}`, `${location.lng}`).then((res) => {
      try {
        if (res) {
          // @ts-ignore
          setUserData((prevLeadData: User) => {
            if (
              JSON.stringify(prevLeadData) !==
              JSON.stringify({
                ...prevLeadData,
                google_solar_object: res.data,
              })
            ) {
              return {
                ...prevLeadData,
                google_solar_object: res.data,
              };
            }
            return prevLeadData;
          });
        }
      } catch (error: any) {
        console.error("Error processing response from google solar api");
      }
    });
  };

  const handleFormSubmit = () => {
    if (addressObject.long_name && electricBill) {
      // @ts-ignore
      setUserData((prevLeadData: User) => {
        if (
          JSON.stringify(prevLeadData) !==
          JSON.stringify({
            ...prevLeadData,
            address: addressObject.long_name,
            electric_bill: `${electricBill}`,
            estimatedAnnualUsage: `${Math.round(annualKwHUsage)}`,
            updated_by: UserUpdateOptions.DYNAMO,
          })
        ) {
          return {
            ...prevLeadData,
            address: addressObject.long_name,
            electric_bill: `${electricBill}`,
            estimatedAnnualUsage: `${Math.round(annualKwHUsage)}`,
            updated_by: UserUpdateOptions.DYNAMO,
          };
        }
        return prevLeadData;
      });

      navigate("/questionnaire-intro");
    } else {
      toast({
        title: "Missing Information",
        description: "Please enter your address and monthly kWh usage.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    onStart();
  };

  return (
    <div className="max-w-6xl mx-auto px-4 py-12 md:py-24">
      {/* Hero Section */}
      <div className="text-center mb-16">
        <motion.h1 initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} className="text-4xl md:text-6xl font-bold text-white mb-6">
          Your Path To Solar <span className="text-amber-500/90">Savings</span>
        </motion.h1>
        <motion.p initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.1 }} className="text-lg md:text-xl text-gray-400 max-w-2xl mx-auto">
          Design, compare, and purchase your perfect solar system online. Save up to 45% on installation with no salespeople involved.
        </motion.p>
      </div>

      {/* Input Form */}
      <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} className="max-w-4xl mx-auto mb-16 px-4">
        <style>
          {`
            @media (max-width: 640px) {
              .form-container {
                flex-direction: column;
              }
              .input-container {
                width: 100%;
                margin-bottom: 1rem;
              }
              .button-container {
                width: 100%;
              }
            }
            @media (min-width: 641px) {
              .form-container {
                flex-direction: row;
              }
              .input-container {
                margin-right: 1rem;
                margin-bottom: 0;
              }
              .bill-input {
                width: 12rem;
              }
            }
          `}
        </style>

        <form onSubmit={handleSubmit} className="form-container flex border-dynamic backdrop-blur-sm rounded-2xl p-3 border border-white/10 items-end">
          <div className="input-container relative flex-grow">
            <p className="w-full text-sm text-gray-400 mb-2 pl-2">Address</p>
            <Search className="absolute left-4 top-14 transform -translate-y-1/2 text-gray-400" />
            <AddressSearchBar onSubmit={handleAddressSubmit} />
          </div>
          <div className="input-container bill-input relative">
            <p className="w-full text-sm text-gray-400 mb-2 pl-2">Utility Bill</p>
            <DollarSign className="absolute left-4 top-14 transform -translate-y-1/2 text-gray-400" />
            <input
              type="number"
              placeholder="Monthly bill"
              onChange={(valueString) => setElectricBill(Number(valueString.target.value) || undefined)}
              onFocus={() => setElectricBill(undefined)}
              value={electricBill}
              className="w-full bg-white/5 rounded-xl pl-12 pr-4 py-4 text-white placeholder-gray-500 focus:outline-none border-0"
            />
          </div>
          <button
            type="submit"
            className="h-14 button-container bg-amber-500 text-[#121212] px-8 py-4 rounded-xl font-semibold hover:bg-amber-400 transition-colors flex items-center justify-center whitespace-nowrap"
            onClick={handleFormSubmit}
          >
            Get Price
            <ChevronRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />
          </button>
        </form>
        {/* <div className="w-full text-center mt-1">
          <p className=" text-sm text-gray-600 text-center">Free, no-obligation estimate. See your savings in minutes.</p>
        </div> */}
      </motion.div>

      {/* Features Grid */}
      <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.3 }} className="grid md:grid-cols-3 gap-8 mt-20">
        <div className="bg-white/5 rounded-lg p-6 border border-white/10">
          <div className="bg-amber-500/10 rounded-full p-3 w-fit mb-4">
            <Zap className="w-6 h-6 text-amber-500" />
          </div>
          <h3 className="text-xl font-semibold text-white mb-2">Instant Design</h3>
          <p className="text-gray-400">Get your custom solar design instantly using our advanced AI technology.</p>
        </div>

        <div className="bg-white/5 rounded-lg p-6 border border-white/10">
          <div className="bg-amber-500/10 rounded-full p-3 w-fit mb-4">
            <Shield className="w-6 h-6 text-amber-500" />
          </div>
          <h3 className="text-xl font-semibold text-white mb-2">Best Price Guarantee</h3>
          <p className="text-gray-400">Compare vetted installers competing for your business at wholesale prices.</p>
        </div>

        <div className="bg-white/5 rounded-lg p-6 border border-white/10">
          <div className="bg-amber-500/10 rounded-full p-3 w-fit mb-4">
            <Clock className="w-6 h-6 text-amber-500" />
          </div>
          <h3 className="text-xl font-semibold text-white mb-2">Track Progress</h3>
          <p className="text-gray-400">Monitor your installation journey with real-time updates at every step.</p>
        </div>
      </motion.div>

      {/* Trust Indicators */}
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.4 }} className="mt-16 text-center">
        <p className="text-gray-500 text-sm">Trusted by homeowners across America • BBB Accredited • 5-Star Rated</p>
      </motion.div>
    </div>
  );
};

export default EntrySection;
