import React, { useEffect, useRef, useState } from "react";
import { FormControl, FormErrorMessage, InputGroup, useToast } from "@chakra-ui/react";
import "../Pages/LandingPage.css";

interface AddressSearchBarProps {
  onSubmit: (location: { long_name: string; state: string; county: string; city: string; street: string; postal_code: string; lat: number; lng: number }) => Promise<void>;
}

const serviceableStates = ["AZ"];

const AddressSearchBar: React.FC<AddressSearchBarProps> = ({ onSubmit }) => {
  const [address, setAddress] = useState<string>("");
  const [error, setError] = useState<string>("");
  const autocompleteRef = useRef<HTMLInputElement>(null);
  const autocompleteService = useRef<google.maps.places.Autocomplete>();
  const toast = useToast();

  useEffect(() => {
    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.Autocomplete(autocompleteRef.current as HTMLInputElement, {
        types: ["geocode"],
        componentRestrictions: { country: "us" },
      });
      autocompleteService.current.addListener("place_changed", onPlaceChanged);
    }
  }, []);

  const processPlace = (place: google.maps.places.PlaceResult) => {
    if (place?.address_components) {
      const stateComponent = place.address_components.find((component) => component.types.includes("administrative_area_level_1"));
      const state = stateComponent?.short_name;

      if (state && !serviceableStates.includes(state)) {
        toast({
          title: "State not serviceable",
          description: "We currently service Arizona exclusively",
          status: "warning",
          duration: 9000,
          isClosable: true,
        });
        return false;
      }

      setAddress(place.formatted_address || "");

      if (place.geometry?.location) {
        onSubmit({
          long_name: place.formatted_address || "",
          street: place.address_components.find((component) => component.types.includes("route"))?.long_name || "",
          city: place.address_components.find((component) => component.types.includes("locality"))?.long_name || "",
          postal_code: place.address_components.find((component) => component.types.includes("postal_code"))?.long_name || "",
          county: place.address_components.find((component) => component.types.includes("administrative_area_level_2"))?.long_name || "",
          state: place.address_components.find((component) => component.types.includes("administrative_area_level_1"))?.long_name || "",
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        }).catch(() => {
          toast({
            title: "Whoops..",
            description: "We encountered an issue while looking up this address.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        });
        return true;
      }
    }
    return false;
  };

  const onPlaceChanged = () => {
    if (autocompleteService.current) {
      const place = autocompleteService.current.getPlace();
      if (!place?.address_components) {
        // console.error("err", place);
        // toast({
        //   title: "Whoops..",
        //   description: "We encountered an issue while looking up this address.",
        //   status: "error",
        //   duration: 9000,
        //   isClosable: true,
        // });
      } else {
        processPlace(place);
      }
    }
  };

  const handleKeyPress = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();

      // Get the current input value
      const input = autocompleteRef.current;
      if (!input || !input.value.trim()) return;

      // Use Places Service to get the first prediction
      const placesService = new google.maps.places.PlacesService(document.createElement("div"));
      const geocoder = new google.maps.Geocoder();

      try {
        // First, get place predictions
        const predictions = await new Promise<google.maps.places.AutocompletePrediction[]>((resolve, reject) => {
          const autocompleteService = new google.maps.places.AutocompleteService();
          autocompleteService.getPlacePredictions(
            {
              input: input.value,
              componentRestrictions: { country: "us" },
              types: ["geocode"],
            },
            (results, status) => {
              if (status === google.maps.places.PlacesServiceStatus.OK && results) {
                resolve(results);
              } else {
                reject(status);
              }
            }
          );
        });

        if (predictions && predictions.length > 0) {
          // Get details for the first prediction
          const placeDetails = await new Promise<google.maps.places.PlaceResult>((resolve, reject) => {
            placesService.getDetails(
              {
                placeId: predictions[0].place_id,
                fields: ["address_components", "formatted_address", "geometry"],
              },
              (place, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK && place) {
                  resolve(place);
                } else {
                  reject(status);
                }
              }
            );
          });

          processPlace(placeDetails);
        }
      } catch (error) {
        toast({
          title: "Error",
          description: "Unable to find address. Please try again or select from the dropdown.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(e.target.value);
  };

  const hasError = Boolean(error);

  return (
    <FormControl isInvalid={hasError} isRequired>
      <InputGroup>
        <input
          id="address"
          type="text"
          value={address}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          placeholder="Enter your address"
          ref={autocompleteRef}
          className="w-full bg-white/5 rounded-xl pl-12 pr-4 py-4 text-white placeholder-gray-500 focus:outline-none border-0"
        />
      </InputGroup>
      {hasError && <FormErrorMessage fontFamily="'eurostile', sans-serif">{error}</FormErrorMessage>}
    </FormControl>
  );
};

export default AddressSearchBar;
