import { motion } from "framer-motion";
import { ChevronRight } from "lucide-react";
import { useNavigate } from "react-router-dom";

const LoanApplicationProcessingMessage = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-[#121212] pt-20">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="h-[80vh] max-w-[90vw] mx-auto flex flex-col items-center justify-center text-center"
      >
        <h1 className="text-2xl lg:text-4xl font-bold text-blue-50 mb-4">We'll get to work reviewing your electric bill</h1>

        <p className="text-sm lg:text-base text-blue-50/70 max-w-md">In the meantime, you can view the status of your project in your customer portal</p>

        <button
          onClick={() => navigate(`/user`)}
          className="mt-8 bg-gradient-to-r from-amber-500 to-amber-600 hover:from-amber-600 hover:to-amber-700 
                text-white px-8 py-3 rounded-lg font-semibold transition-all duration-200
                flex items-center"
        >
          Continue to Customer Portal
          <ChevronRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />
        </button>
      </motion.div>
    </div>
  );
};

export default LoanApplicationProcessingMessage;
