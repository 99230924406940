import React from "react";
import { Box, Heading, HStack, VStack, Text, Divider, SimpleGrid, Grid, GridItem } from "@chakra-ui/react";
import { useAuth } from "../../Providers/AuthProvider";

const ProfilePage = () => {
  const { user } = useAuth();

  return (
    <Box w="full" bgColor="#f4eee9" minH="100vh" p="40px" pt="120px">
      {user ? (
        <VStack w="full">
          <Box w="full" borderRadius="12px" bgColor="white" p="20px" shadow="lg">
            <VStack w="full">
              <Grid w="full" h="200px" templateRows="repeat(2, 1fr)" templateColumns="repeat(5, 1fr)" gap={4}>
                <GridItem rowSpan={2} colSpan={1}>
                  <Heading w="full" mb="20px">
                    {user.first_name} {user.last_name}
                  </Heading>
                  <VStack gap={0}>
                    <Text w="full">{user.email}</Text>
                    <Text w="full" fontSize="14px">
                      {formatPhoneNumber(user.phone_number)}
                    </Text>
                  </VStack>
                </GridItem>
                <GridItem colSpan={2}></GridItem>
                <GridItem colSpan={2} />
                <GridItem colSpan={4} />
              </Grid>
              <Grid w="full" h="200px" templateRows="repeat(2, 1fr)" templateColumns="repeat(5, 1fr)" gap={4}>
                <GridItem rowSpan={2} colSpan={1}>
                  <Text w="full" fontWeight="bold">
                    Billing Address
                  </Text>
                  <VStack gap={0}>
                    {/* <Text w="full">{user.address.items.long_name}</Text> */}
                    <Text w="full" fontSize="14px">
                      {formatPhoneNumber(user.phone_number)}
                    </Text>
                  </VStack>
                </GridItem>
                <GridItem colSpan={2}></GridItem>
                <GridItem colSpan={2} />
                <GridItem colSpan={4} />
              </Grid>
            </VStack>
          </Box>
          <VStack w="full" mt="20px" alignItems="flex-start">
            <Text fontSize="20px">Your Order</Text>
            <Divider w="full" style={{ borderColor: "black" }} />
            <Text color="gray.600">You have not ordered any products yet.</Text>
          </VStack>
        </VStack>
      ) : (
        <Box />
      )}
    </Box>
  );
};
export default ProfilePage;

function formatPhoneNumber(phoneNumber: string) {
  // Remove any non-numeric characters (such as '+')
  let cleaned = phoneNumber.replace(/\D/g, "");

  // Extract the necessary parts
  let countryCode = cleaned.slice(0, 1);
  let areaCode = cleaned.slice(1, 4);
  let centralOfficeCode = cleaned.slice(4, 7);
  let lineNumber = cleaned.slice(7, 11);

  // Format the phone number
  let formattedNumber = `(${areaCode}) ${centralOfficeCode}-${lineNumber}`;

  return formattedNumber;
}
