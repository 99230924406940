import React, { createContext, useContext, useState, useEffect, useRef } from "react";

export type EnergyTable = {
  Facet: string;
  "Panel Id": number;
  "Panels Monthly Production": number[];
  "Production Multiplier": string;
  "SAV(Each panel)": string;
  "System Production Multiplier": string;
  "TSRF(Each panel)": string;
  coordinates: number[][];
  "kW size": string;
  "kW size (Cumulative)": string;
  "kWh/yr": string;
  "kWh/yr(Cumulative)": string;
};

export type Facet = {
  "Average SAV": string;
  "Average TSRF": string;
  Azimuth: string;
  "Facet Id": string;
  "No of Obstructions": string;
  "Pitch (degree)": string;
  "Sq Ft": string;
};

export type SolarReadyProposeDataObject = {
  dataOutput: {
    energyTable: EnergyTable[];
    facet: Facet[];
    roof: {
      "Existing panels on structure": string;
      "Home Classification": string;
      "Imagery date": string;
      "Job Id": string;
      "Lat Long": string;
      "Max Number of Panels": string;
      "Max kW system": string;
      "Max kWh/yr system": string;
      "Module length": string;
      "Module watts": string;
      "Module width": string;
      "Number of Facets": string;
      "Other Losses": string;
      "Predominant Pitch (degree)": string;
      Setbacks: string;
      "Total Area of Max System": string;
      "Total Sq Ft of Roof": string;
      "Tree Overhang %": string;
    };
  };
  jobId: string;
  productId: number;
};
export type SolarReadyData = {
  proposeData: SolarReadyProposeDataObject;
  panelLayoutImage: string;
};

const defaultSolarReadyData: SolarReadyData = {
  proposeData: {
    dataOutput: {
      energyTable: [
        {
          Facet: "",
          "Panel Id": 0,
          "Panels Monthly Production": [],
          "Production Multiplier": "",
          "SAV(Each panel)": "",
          "System Production Multiplier": "",
          "TSRF(Each panel)": "",
          coordinates: [],
          "kW size": "",
          "kW size (Cumulative)": "",
          "kWh/yr": "",
          "kWh/yr(Cumulative)": "",
        },
      ],
      facet: [
        {
          "Average SAV": "",
          "Average TSRF": "",
          Azimuth: "",
          "Facet Id": "",
          "No of Obstructions": "",
          "Pitch (degree)": "",
          "Sq Ft": "",
        },
      ],
      roof: {
        "Existing panels on structure": "",
        "Home Classification": "",
        "Imagery date": "",
        "Job Id": "",
        "Lat Long": "",
        "Max Number of Panels": "",
        "Max kW system": "",
        "Max kWh/yr system": "",
        "Module length": "",
        "Module watts": "",
        "Module width": "",
        "Number of Facets": "",
        "Other Losses": "",
        "Predominant Pitch (degree)": "",
        Setbacks: "",
        "Total Area of Max System": "",
        "Total Sq Ft of Roof": "",
        "Tree Overhang %": "",
      },
    },
    jobId: "",
    productId: 95,
  },
  panelLayoutImage: "",
};

const getSavedSolarReadyData = () => {
  const savedSolarReadyData = localStorage.getItem("solarReadyData");
  return savedSolarReadyData ? JSON.parse(savedSolarReadyData) : defaultSolarReadyData;
};

const solarReadyContext = createContext({
  solarReadyData: defaultSolarReadyData,
  setSolarReadyData: (solarReadySolarReadyData: SolarReadyData) => {},
  solarReadyDesignTrackingId: "",
  setSolarReadyDesignTrackingId: (val: string) => {},
  solarReadyStatus: { status: "", jobStatus: "", errorMessage: "" },
  setSolarReadyStatus: (statusObj: { status: string; jobStatus: string; errorMessage: string }) => {},
  statusIsReady: false,
  setStatusIsReady: (val: boolean) => {},
  solarReadyDataIsLoaded: false,
  setSolarReadyDataIsLoaded: (val: boolean) => {},
});

export const useSolarReadyContext = () => {
  return useContext(solarReadyContext);
};

const SolarReadyDataProvider = ({ children }: { children: React.ReactNode }) => {
  const [solarReadyDesignTrackingId, setSolarReadyDesignTrackingId] = useState("");
  const [solarReadyStatus, setSolarReadyStatus] = useState({ status: "", jobStatus: "", errorMessage: "" });
  const [solarReadyData, setSolarReadyData] = useState<SolarReadyData>(getSavedSolarReadyData);
  const [statusIsReady, setStatusIsReady] = useState(false);
  const [solarReadyDataIsLoaded, setSolarReadyDataIsLoaded] = useState(false);

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    localStorage.setItem("solarReadyData", JSON.stringify(solarReadyData));
  }, [solarReadyData]);

  return (
    <solarReadyContext.Provider
      value={{
        solarReadyData,
        setSolarReadyData,
        solarReadyDesignTrackingId,
        setSolarReadyDesignTrackingId,
        solarReadyStatus,
        setSolarReadyStatus,
        statusIsReady,
        setStatusIsReady,
        solarReadyDataIsLoaded,
        setSolarReadyDataIsLoaded,
      }}
    >
      {children}
    </solarReadyContext.Provider>
  );
};

export default SolarReadyDataProvider;
