import React, { useState } from "react";
import {
  Box,
  VStack,
  HStack,
  Text,
  Button,
  Image,
  IconButton,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import homeSvg from "../Assets/home.svg";
import twoStoryHomeSvg from "../Assets/twoStoryHome.svg";
import threeStoryHomeSvg from "../Assets/threeStoryHome.svg";
import { AnimatePresence, motion } from "framer-motion";
import { IoArrowBack } from "react-icons/io5";
import { UserWithoutTypeName, useUserDataContext } from "../../Providers/LeadDataProvider";
import { PaymentMethod, UserUpdateOptions } from "../../API";

const MotionBox = motion(Box);

const variants = {
  hidden: {
    x: "50%",
    opacity: 0,
    transition: {
      duration: 0.4, // Adjust duration as needed
      ease: "easeInOut", // This easing function provides a smooth start and end
    },
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.4, // Keep consistent for a uniform animation speed
      ease: "easeInOut", // Smooth transition for entering animation
    },
  },
  exit: {
    x: "-50%",
    opacity: 0,
    transition: {
      duration: 0.4,
      ease: "easeInOut", // Ensures a smooth exit
    },
  },
};

const HomeDetails = () => {
  const [step, setStep] = useState(0);
  const { setUserData } = useUserDataContext();
  const navigate = useNavigate();
  const [isNavigatingForward, setIsNavigatingForward] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isHomeOwner, setIsHomeOwner] = useState(true);
  const [hasFoundation, setHasFoundation] = useState(true);
  const [roofConditionIsPoor, setRoofConditionIsPoor] = useState(false);

  const roofTypes = [
    {
      name: "Composition Shingles",
      imgUrl: "https://klaravia-public-assets.s3.us-east-2.amazonaws.com/vecteezy-photos/vecteezy_soft-roof-roof-tiles-flexible-shingles-roof-tiling_47150815.jpg",
    },
    { name: "S-Type Concrete", imgUrl: "https://klaravia-public-assets.s3.us-east-2.amazonaws.com/vecteezy-photos/vecteezy_clay-tiles-on-the-roof_1313180.jpg" },
    { name: "Flat Concrete Tile", imgUrl: "https://eagleroofing.com/wp-content/themes/eagl/assets/img/filters/profile-bel-air.jpg" },
    {
      name: "Metal",
      imgUrl: "https://klaravia-public-assets.s3.us-east-2.amazonaws.com/vecteezy-photos/vecteezy_black-gray-wavy-metal-roof-texture-surface-detail-roller_47550595.jpg",
    },
    { name: "Flat", imgUrl: "https://klaravia-public-assets.s3.us-east-2.amazonaws.com/vecteezy-photos/vecteezy_activities-of-construction-workers-heavy-work-work_46251475.jpg" },
    { name: "Clay", imgUrl: "https://klaravia-public-assets.s3.us-east-2.amazonaws.com/vecteezy-photos/vecteezy_red-roof-shingles_19914586.jpg" },
    {
      name: "Rock",
      imgUrl: "https://klaravia-public-assets.s3.us-east-2.amazonaws.com/vecteezy-photos/vecteezy_a-pile-of-textured-stones-in-natural-light_24161556.jpeg",
    },
    {
      name: "Slate or Stone",
      imgUrl: "https://klaravia-public-assets.s3.us-east-2.amazonaws.com/vecteezy-photos/vecteezy_slate-tile-texture-with-fine-details-and-varying-shades-of_49507105.jpg",
    },
  ];

  const handleHomeOwnerSelected = (answer: string) => {
    // @ts-ignore
    setUserData((prevUserData: UserWithoutTypeName) => {
      if (
        JSON.stringify(prevUserData) !==
        JSON.stringify({
          ...prevUserData,
          home_owner: answer === "Yes" ? true : false,
          updated_by: UserUpdateOptions.DYNAMO,
        })
      ) {
        return {
          ...prevUserData,
          home_owner: answer === "Yes" ? true : false,
          updated_by: UserUpdateOptions.DYNAMO,
        };
      }
      return prevUserData;
    });

    if (answer === "No") {
      setIsHomeOwner(false);
    }

    if (answer === "Yes") {
      setIsHomeOwner(true);
      setIsNavigatingForward(true);
      setStep(step + 1);
    }
  };

  const handleHasFoundationSelected = (answer: string) => {
    // @ts-ignore
    setUserData((prevUserData: UserWithoutTypeName) => {
      if (
        JSON.stringify(prevUserData) !==
        JSON.stringify({
          ...prevUserData,
          home_has_foundation: answer === "Yes" ? true : false,
          updated_by: UserUpdateOptions.DYNAMO,
        })
      ) {
        return {
          ...prevUserData,
          home_has_foundation: answer === "Yes" ? true : false,
          updated_by: UserUpdateOptions.DYNAMO,
        };
      }
      return prevUserData;
    });

    if (answer === "No") {
      setHasFoundation(false);
    }

    if (answer === "Yes") {
      setHasFoundation(true);
      setIsNavigatingForward(true);
      setStep(step + 1);
    }
  };

  const handleRoofConditionSelected = (condition: string) => {
    const getCondition = () => {
      switch (condition) {
        case "Excellent":
          return "EXCELLENT";
        case "Good":
          onOpen();
          return "GOOD";
        case "Poor":
          navigate("/poor-roof-condition");
          return "POOR";
        default:
          return "UNKNOWN";
      }
    };

    const roofCondition = getCondition();

    // @ts-ignore
    setUserData((prevUserData: UserWithoutTypeName) => {
      if (
        JSON.stringify(prevUserData) !==
        JSON.stringify({
          ...prevUserData,
          roof_condition: roofCondition,
          updated_by: UserUpdateOptions.DYNAMO,
        })
      ) {
        return {
          ...prevUserData,
          roof_condition: roofCondition,
          updated_by: UserUpdateOptions.DYNAMO,
        };
      }
      return prevUserData;
    });

    if (roofCondition !== "GOOD") {
      setIsNavigatingForward(true);
      setStep(step + 1);
    }
  };

  const handleRoofTypeSelected = (roofType: string) => {
    const getRoofMaterialType = () => {
      switch (roofType) {
        case "Composition Shingles":
          return "COMPOSITION_SHINGLES";
        case "S-Type Concrete":
          return "S_TYPE_CONCRETE";
        case "Flat Concrete Tile":
          return "FLAT_CONCRETE_TILE";
        case "Metal":
          return "METAL";
        case "Flat":
          return "FLAT";
        case "Clay":
          return "CLAY";
        case "Rock":
          return "ROCK";
        case "Slate or Stone":
          return "SLATE_OR_STONE";
        default:
          return "UNKNOWN";
      }
    };

    // @ts-ignore
    setUserData((prevUserData: UserWithoutTypeName) => {
      if (
        JSON.stringify(prevUserData) !==
        JSON.stringify({
          ...prevUserData,
          roof_material: getRoofMaterialType(),
          updated_by: UserUpdateOptions.DYNAMO,
        })
      ) {
        return {
          ...prevUserData,
          roof_material: getRoofMaterialType(),
          updated_by: UserUpdateOptions.DYNAMO,
        };
      }
      return prevUserData;
    });

    setIsNavigatingForward(true);
    setStep(step + 1);
  };

  const handleStoriesSelected = (numStories: number) => {
    // @ts-ignore
    setUserData((prevUserData: UserWithoutTypeName) => {
      if (
        JSON.stringify(prevUserData) !==
        JSON.stringify({
          ...prevUserData,
          home_number_of_stories: numStories,
          updated_by: UserUpdateOptions.DYNAMO,
        })
      ) {
        return {
          ...prevUserData,
          home_number_of_stories: numStories,
          updated_by: UserUpdateOptions.DYNAMO,
        };
      }
      return prevUserData;
    });

    setStep(step + 1);
  };

  // const handlePotentialIncreaseSelected = (answer: string) => {
  //   navigate("/klaravia-studio");
  // };

  const handlePaymentMethodSelect = (method: PaymentMethod) => {
    // @ts-ignore
    setUserData((prevUserData: UserWithoutTypeName) => {
      if (
        JSON.stringify(prevUserData) !==
        JSON.stringify({
          ...prevUserData,
          payment_method: method,
          updated_by: UserUpdateOptions.DYNAMO,
        })
      ) {
        return {
          ...prevUserData,
          payment_method: method,
          updated_by: UserUpdateOptions.DYNAMO,
        };
      }
      return prevUserData;
    });
    navigate("/klaravia-studiog");
  };

  const handleBack = () => {
    if (step >= 0) {
      setIsNavigatingForward(false);
      setStep(step - 1);
    }
  };

  return (
    <Box w="full" minH="100vh" className="bg-blue-50" pt="60px" overflowX="hidden" fontFamily="'eurostile', sans-serif">
      <Box w="full" minH="120px" p="40px" mb={{ base: "20px", lg: "40px" }}>
        {step > 0 && (
          <IconButton as={IoArrowBack} aria-label="backButton" onClick={handleBack} variant="ghost" _hover={{ backgroundColor: "transparent", color: "gray.600" }} cursor="pointer">
            Back
          </IconButton>
        )}
      </Box>
      <Box w="full" display="flex" justifyContent="center" mt={{ base: "3vh", lg: "6vh" }}>
        <Box w="50vw" h="16px" border="2px solid #121212" borderRadius="12px">
          <Box w={`${50 * ((step + 1) / 7)}vw`} h="12px" className="bg-amber-500" borderRadius="12px" transition="width 1s ease-out" />
        </Box>
      </Box>
      <AnimatePresence mode="wait">
        {step === 0 && (
          <MotionBox key="step0" variants={variants} initial="hidden" animate="visible" exit="exit" w="full" display="flex" justifyContent="center" position="relative">
            <Box w="full" display="flex" justifyContent="center">
              <Box w={{ base: "90%", lg: "50%" }} pt="80px" borderRadius="6px">
                <Text w="full" textAlign="center" fontSize={{ base: "22px", lg: "30px" }} fontWeight="bold" pb="0px" color="#121212">
                  Let's see if solar is a good fit for you.
                </Text>
                <Text w="full" textAlign="center" mt={2} fontSize={{ base: "18px", lg: "20px" }} color="#121212">
                  We'll start by asking some questions.
                </Text>
                <VStack w="full" minH={{ base: "20vh", lg: "30vh" }} justifyContent="center">
                  <HStack justifyContent="center" alignItems="center" align="stretch" gap={{ base: "20px", lg: "60px" }} flexWrap="wrap">
                    <Box
                      w={{ base: "280px", lg: "240px" }}
                      h={{ base: "60px", lg: "80px" }}
                      borderRadius={{ base: "12px", lg: "10px" }}
                      border="2px"
                      borderColor="#121212"
                      className="bg-amber-400"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      textAlign="center"
                      shadow="lg"
                      _hover={{
                        bgColor: "rgba(0,0,0,.7)",
                      }}
                      cursor="pointer"
                      onClick={() => setStep(step + 1)}
                    >
                      <Text
                        fontSize={{ base: "20px", lg: "30px" }}
                        color="white"
                        // textShadow="-1px -1px 0 #121212, 1px -1px 0 #121212, -1px 1px 0 #121212, 1px 1px 0 #121212"
                        fontWeight={700}
                        zIndex="2"
                      >
                        Okay
                      </Text>
                    </Box>
                  </HStack>
                  {!isHomeOwner && (
                    <Text mt="10px" fontSize="20px" color="red.500">
                      We do not currently support solar installations for non-homeowners.
                    </Text>
                  )}
                </VStack>
              </Box>
            </Box>
          </MotionBox>
        )}
        {step === 1 && (
          <MotionBox key="step1" variants={variants} initial="hidden" animate="visible" exit="exit" w="full" display="flex" justifyContent="center" position="relative">
            <Box w="full" display="flex" justifyContent="center">
              <Box w={{ base: "90%", lg: "50%" }} pt="80px" borderRadius="6px">
                <Text w="full" textAlign="center" fontSize={{ base: "24px", lg: "30px" }} fontWeight="bold" pb="0px" color="#121212">
                  Are you the owner of this home?
                </Text>
                <VStack w="full" minH={{ base: "20vh", lg: "30vh" }} justifyContent="center" mt={{ base: "40px", lg: "0px" }}>
                  <HStack justifyContent="center" alignItems="center" align="stretch" gap={{ base: "20px", lg: "60px" }} flexWrap="wrap">
                    <Box
                      w={{ base: "280px", md: "240px" }}
                      h={{ base: "60px", md: "80px" }}
                      borderRadius={{ base: "12px", md: "10px" }}
                      bgColor="#121212"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      textAlign="center"
                      shadow="lg"
                      _hover={{
                        bgColor: "rgba(0,0,0,.7)",
                      }}
                      cursor="pointer"
                      onClick={() => handleHomeOwnerSelected("Yes")}
                    >
                      <Text fontSize={{ base: "20px", md: "30px" }} color="#FFF" fontWeight={700} zIndex="2">
                        Yes
                      </Text>
                    </Box>
                    <Box
                      w={{ base: "280px", md: "240px" }}
                      h={{ base: "60px", md: "80px" }}
                      cursor="pointer"
                      borderRadius={{ base: "12px", md: "10px" }}
                      border="2px solid black"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      textAlign="center"
                      shadow="lg"
                      color="#121212"
                      _hover={{
                        color: "rgba(0,0,0,.7)",
                      }}
                      onClick={() => handleHomeOwnerSelected("No")}
                      zIndex="2"
                    >
                      <Text fontSize={{ base: "20px", md: "30px" }} fontWeight={700} zIndex="2">
                        No
                      </Text>
                    </Box>
                  </HStack>
                  {!isHomeOwner && (
                    <Text mt="10px" fontSize="20px" color="red.500">
                      We do not currently support solar installations for non-homeowners.
                    </Text>
                  )}
                </VStack>
              </Box>
            </Box>
          </MotionBox>
        )}
        {step === 2 && (
          <MotionBox key="step2" variants={variants} initial="hidden" animate="visible" exit="exit" w="full" display="flex" justifyContent="center" position="relative">
            <Box w="full" display="flex" justifyContent="center">
              <Box w={{ base: "90%", lg: "50%" }} pt="80px" borderRadius="6px">
                <Text w="full" textAlign="center" fontSize={{ base: "24px", lg: "30px" }} fontWeight="bold" pb="0px" color="#121212">
                  Does your house have a foundation?
                </Text>
                <VStack w="full" minH={{ base: "20vh", lg: "30vh" }} justifyContent="center" mt={{ base: "40px", lg: "0px" }}>
                  <HStack justifyContent="center" alignItems="center" align="stretch" gap={{ base: "20px", lg: "60px" }} flexWrap="wrap">
                    <Box
                      w={{ base: "280px", md: "240px" }}
                      h={{ base: "60px", md: "80px" }}
                      borderRadius={{ base: "12px", md: "10px" }}
                      bgColor="#121212"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      textAlign="center"
                      shadow="lg"
                      _hover={{
                        bgColor: "rgba(0,0,0,.7)",
                      }}
                      cursor="pointer"
                      onClick={() => handleHasFoundationSelected("Yes")}
                    >
                      <Text fontSize={{ base: "20px", lg: "30px" }} color="#FFF" fontWeight={700} zIndex="2">
                        Yes
                      </Text>
                    </Box>
                    <Box
                      w={{ base: "280px", md: "240px" }}
                      h={{ base: "60px", md: "80px" }}
                      borderRadius={{ base: "12px", md: "10px" }}
                      cursor="pointer"
                      border="2px solid black"
                      color="#121212"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      textAlign="center"
                      shadow="lg"
                      _hover={{
                        borderColor: "rgba(0,0,0,.7)",
                      }}
                      onClick={() => handleHasFoundationSelected("No")}
                      zIndex="2"
                    >
                      <Text fontSize={{ base: "20px", lg: "30px" }} fontWeight={700} zIndex="2">
                        No
                      </Text>
                    </Box>
                  </HStack>
                  {!hasFoundation && (
                    <Text mt="10px" fontSize="20px" color="red.500">
                      We do not currently support solar installations for homes without a foundation.
                    </Text>
                  )}
                </VStack>
              </Box>
            </Box>
          </MotionBox>
        )}
        {step === 3 && (
          <MotionBox key="step3" variants={variants} initial="hidden" animate="visible" exit="exit" w="full" display="flex" justifyContent="center" position="relative">
            <Box pt="80px" borderRadius="6px">
              <VStack>
                <Text w={{ base: "80%", lg: "full" }} textAlign="center" fontSize={{ base: "24px", lg: "30px" }} fontWeight="bold" pb="0px" color="#121212">
                  What is the condition of your roof?
                </Text>
                <VStack w="full" minH={{ base: "45vh", lg: "30vh" }} mt={{ base: "20px", lg: "0px" }} pb="40px" justifyContent="center">
                  <HStack justifyContent={{ base: "center", lg: "space-between" }} align="stretch" gap={{ base: "15px", lg: "30px" }} flexWrap="wrap">
                    <Box
                      w={{ base: "80%", lg: "300px" }}
                      display="flex"
                      flexDir={{ base: "row", lg: "column" }}
                      alignItems="center"
                      justifyContent="space-between"
                      borderRadius="10px"
                      shadow="md"
                      bgColor="#121212"
                      p={{ base: "20px", lg: "40px" }}
                      textAlign="center"
                      cursor="pointer"
                      _hover={{ shadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px" }}
                      onClick={() => handleRoofConditionSelected("Excellent")}
                    >
                      <Text fontSize={{ base: "22px", lg: "28px" }} color="green.300" fontWeight="bold">
                        Excellent
                      </Text>
                      <Text fontSize={{ base: "14px", lg: "16px" }} color="gray.200">
                        20+ years left
                      </Text>
                    </Box>
                    <Box
                      w={{ base: "80%", lg: "300px" }}
                      display="flex"
                      flexDir={{ base: "row", lg: "column" }}
                      alignItems="center"
                      justifyContent="space-between"
                      borderRadius="10px"
                      shadow="md"
                      bgColor="#121212"
                      p={{ base: "20px", lg: "40px" }}
                      textAlign="center"
                      cursor="pointer"
                      _hover={{ shadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px" }}
                      onClick={() => handleRoofConditionSelected("Good")}
                    >
                      <Text fontSize={{ base: "22px", lg: "28px" }} color="yellow.300" fontWeight="bold">
                        Good
                      </Text>
                      <Text fontSize={{ base: "14px", lg: "16px" }} color="gray.200">
                        10+ years left
                      </Text>
                    </Box>
                    <Box
                      w={{ base: "80%", lg: "300px" }}
                      display="flex"
                      flexDir={{ base: "row", lg: "column" }}
                      alignItems="center"
                      justifyContent="space-between"
                      borderRadius="10px"
                      shadow="md"
                      bgColor="#121212"
                      p={{ base: "20px", lg: "40px" }}
                      textAlign="center"
                      cursor="pointer"
                      _hover={{ shadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px" }}
                      onClick={() => setRoofConditionIsPoor(true)}
                    >
                      <Text fontSize={{ base: "22px", lg: "28px" }} color="red.300" fontWeight="bold">
                        Poor
                      </Text>
                      <Text fontSize={{ base: "14px", lg: "16px" }} color="gray.200">
                        {"< 10 years left"}
                      </Text>
                    </Box>
                  </HStack>
                  {roofConditionIsPoor && (
                    <Text mt="10px" fontSize="20px" color="red.500">
                      We do not currently support solar installations for roofs in poor condition.
                    </Text>
                  )}
                </VStack>
              </VStack>
            </Box>
          </MotionBox>
        )}
        <ModerateRoofConditionModal isOpen={isOpen} onClose={onClose} setIsNavigatingForward={setIsNavigatingForward} setStep={setStep} step={step} />
        {step === 4 && (
          <MotionBox key="step4" variants={variants} initial="hidden" animate="visible" exit="exit" w="full" display="flex" justifyContent="center" position="relative">
            <VStack pt="80px">
              <Text w="full" textAlign="center" fontSize={{ base: "24px", lg: "30px" }} fontWeight="bold" pb="0px" color="#121212">
                Which roof type do you have?
              </Text>
              <VStack w="80vw" minH="50vh" justifyContent="center" p="40px">
                <HStack justifyContent={{ base: "center", lg: "center" }} align="stretch" gap="20px" flexWrap="wrap">
                  {roofTypes.map((roofType, i) => {
                    return (
                      <Box
                        key={i}
                        w="300px"
                        borderRadius="10px"
                        border="2px solid #121212"
                        bgColor="#121212"
                        shadow="lg"
                        textAlign="center"
                        cursor="pointer"
                        _hover={{ shadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px" }}
                        onClick={() => handleRoofTypeSelected(roofType.name)}
                      >
                        <Box w="full" h={{ base: "100px", lg: "120px" }} borderTopRadius="10px" bgImage={roofType.imgUrl} bgPosition="center" bgSize="cover" bgRepeat="no-repeat" />
                        <Box w="full" p="10px">
                          <Text fontSize="18px" color="white" fontWeight="bold">
                            {roofType.name}
                          </Text>
                        </Box>
                      </Box>
                    );
                  })}
                </HStack>
              </VStack>
            </VStack>
          </MotionBox>
        )}
        {step === 5 && (
          <MotionBox key="step5" variants={variants} initial="hidden" animate="visible" exit="exit" w="full" display="flex" justifyContent="center" position="relative">
            <VStack pt="80px">
              <Text w="full" textAlign="center" fontSize={{ base: "24px", lg: "30px" }} fontWeight="bold" pb="0px" color="#121212">
                How many stories is your house?
              </Text>
              <VStack minH="44vh" justifyContent="center" p="40px">
                <HStack justifyContent={{ base: "center", lg: "space-between" }} align="stretch" gap={{ base: "20px", lg: "40px" }} flexDirection={{ base: "column", lg: "row" }}>
                  <Box
                    w={{ base: "full", lg: "250px" }}
                    minW={{ base: "350px", lg: "unset" }}
                    minH={{ base: "170px", lg: "unset" }}
                    borderRadius="10px"
                    bgColor="#121212"
                    shadow="rgba(149, 157, 165, 0.2) 0px 8px 24px"
                    p="40px"
                    textAlign="center"
                    cursor="pointer"
                    _hover={{ shadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px" }}
                    onClick={() => handleStoriesSelected(1)}
                    display="flex"
                    flexDirection={{ base: "row-reverse", lg: "column" }}
                    justifyContent={{ base: "space-between", lg: "flex-end" }}
                    alignItems={{ base: "center", lg: "unset" }}
                  >
                    <Image w={{ base: "100px", lg: "full" }} src={homeSvg} />
                    <Text fontSize="24px" color="white" fontWeight="bold" pt={{ base: "0px", lg: "20px" }}>
                      1 Story
                    </Text>
                  </Box>
                  <Box
                    w={{ base: "full", lg: "250px" }}
                    minW={{ base: "350px", lg: "unset" }}
                    minH={{ base: "170px", lg: "unset" }}
                    borderRadius="10px"
                    bgColor="#121212"
                    shadow="rgba(149, 157, 165, 0.2) 0px 8px 24px"
                    p="40px"
                    textAlign="center"
                    cursor="pointer"
                    _hover={{ shadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px" }}
                    onClick={() => handleStoriesSelected(2)}
                    display="flex"
                    flexDirection={{ base: "row-reverse", lg: "column" }}
                    justifyContent={{ base: "space-between", lg: "flex-end" }}
                    alignItems={{ base: "center", lg: "unset" }}
                  >
                    <Image w={{ base: "100px", lg: "full" }} src={twoStoryHomeSvg} />
                    <Text fontSize="24px" color="white" fontWeight="bold" pt={{ base: "0px", lg: "20px" }}>
                      2 Stories
                    </Text>
                  </Box>
                  <Box
                    w={{ base: "full", lg: "250px" }}
                    minW={{ base: "350px", lg: "unset" }}
                    minH={{ base: "170px", lg: "unset" }}
                    borderRadius="10px"
                    bgColor="#121212"
                    shadow="rgba(149, 157, 165, 0.2) 0px 8px 24px"
                    p="40px"
                    textAlign="center"
                    cursor="pointer"
                    _hover={{ shadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px" }}
                    onClick={() => handleStoriesSelected(3)}
                    display="flex"
                    flexDirection={{ base: "row-reverse", lg: "column" }}
                    justifyContent={{ base: "space-between", lg: "flex-end" }}
                    alignItems={{ base: "center", lg: "unset" }}
                  >
                    <Image w={{ base: "120px", lg: "full" }} src={threeStoryHomeSvg} />
                    <Text fontSize="24px" color="white" fontWeight="bold" pt={{ base: "0px", lg: "20px" }}>
                      3 Stories
                    </Text>
                  </Box>
                </HStack>
              </VStack>
            </VStack>
          </MotionBox>
        )}
        {step === 6 && (
          <MotionBox key="step6" variants={variants} initial="hidden" animate="visible" exit="exit" w="full" display="flex" justifyContent="center" position="relative">
            <VStack pt="80px">
              <Text w="full" textAlign="center" fontSize={{ base: "24px", lg: "30px" }} fontWeight="bold" pb="0px" color="#121212">
                How will you purchase your solar?
              </Text>
              <VStack w="full" minH="24vh" justifyContent="center">
                <HStack justifyContent="center" alignItems="center" align="stretch" gap={{ base: "20px", lg: "60px" }} flexWrap="wrap">
                  <Box
                    w={{ base: "280px", lg: "240px" }}
                    h={{ base: "60px", lg: "80px" }}
                    borderRadius={{ base: "12px", lg: "10px" }}
                    bgColor="#121212"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    shadow="lg"
                    _hover={{
                      bgColor: "rgba(0,0,0,.7)",
                    }}
                    cursor="pointer"
                    onClick={() => handlePaymentMethodSelect(PaymentMethod.CASH)}
                  >
                    <Text fontSize={{ base: "20px", lg: "30px" }} color="#FFF" fontWeight={700} zIndex="2">
                      Cash
                    </Text>
                  </Box>
                  <Box
                    w={{ base: "280px", lg: "240px" }}
                    h={{ base: "60px", lg: "80px" }}
                    borderRadius={{ base: "12px", lg: "10px" }}
                    border="2px solid #121212"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    shadow="lg"
                    color="#121212"
                    _hover={{
                      borderColor: "rgba(0,0,0,.7)",
                      color: "rgba(0,0,0,.7)",
                    }}
                    cursor="pointer"
                    onClick={() => handlePaymentMethodSelect(PaymentMethod.LOAN)}
                  >
                    <Text fontSize={{ base: "20px", lg: "30px" }} fontWeight={700} zIndex="2">
                      Loan
                    </Text>
                  </Box>
                </HStack>
              </VStack>
            </VStack>
          </MotionBox>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default HomeDetails;

const ModerateRoofConditionModal = ({
  isOpen,
  onClose,
  setIsNavigatingForward,
  setStep,
  step,
}: {
  isOpen: boolean;
  onClose: () => void;
  setIsNavigatingForward: (bool: boolean) => void;
  setStep: (num: number) => void;
  step: number;
}) => {
  const handleGotIt = () => {
    onClose();
    setIsNavigatingForward(true);
    setStep(step + 1);
  };

  return (
    <>
      <Modal size={{ base: "xs", lg: "lg" }} isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bgColor="#f4eee9">
          <ModalHeader>Just so you know</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Solar Panels are made to last 25+ years. If you expect to replace your roof before then, you may want to consider the additional cost incurred by having to remove and
              reinstall the solar panels.
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button bgColor="#121212" color="white" _hover={{ bgColor: "rgba(0,0,0,.7)" }} onClick={handleGotIt}>
              Got It
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
