import { extendTheme } from "@chakra-ui/react";

// Helper function for color manipulation
const alpha = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const colors = {
  // Brand Colors
  primary: {
    50: '#fff7e6',
    100: '#ffe5b3',
    200: '#ffd480',
    300: '#ffc24d',
    400: '#ffaf1a',
    500: '#f59e0b', // Main accent
    600: '#ea8f00', // Hover
    700: '#d97706', // Active
    800: '#b45309',
    900: '#92400e'
  },

  // Theme Base Colors
  background: {
    light: '#f4eee9',
    dark: '#1a1a1a'
  },

  // Surface Colors (Light)
  surface: {
    light: {
      ground: '#f4eee9',
      low: '#ffffff',
      mid: '#faf7f5',
      high: '#f7f3f0',
      highest: '#f1ebe5'
    },
    dark: {
      ground: '#1a1a1a',
      low: '#262626',
      mid: '#333333',
      high: '#404040',
      highest: '#4d4d4d'
    }
  },

  // Text Colors
  text: {
    light: {
      primary: '#1a1a1a',
      secondary: '#4d4d4d',
      tertiary: '#666666',
      disabled: '#999999'
    },
    dark: {
      primary: '#ffffff',
      secondary: '#cccccc',
      tertiary: '#999999',
      disabled: '#666666'
    }
  },

  // Border Colors
  border: {
    light: {
      subtle: '#e6e6e6',
      light: '#d9d9d9',
      medium: '#cccccc',
      strong: '#b3b3b3'
    },
    dark: {
      subtle: '#262626',
      light: '#333333',
      medium: '#404040',
      strong: '#4d4d4d'
    }
  },

  // Semantic Colors
  semantic: {
    success: {
      light: '#22c55e',
      dark: '#16a34a'
    },
    error: {
      light: '#ef4444',
      dark: '#dc2626'
    },
    warning: {
      light: '#f59e0b',
      dark: '#d97706'
    },
    info: {
      light: '#3b82f6',
      dark: '#2563eb'
    }
  }
};

const theme = extendTheme({
  config: {
    useSystemColorMode: false,
    initialColorMode: 'dark',
  },
  colors,
  styles: {
    global: (props) => ({
      ':root': {
        '--background': props.colorMode === 'dark' ? colors.background.dark : colors.background.light,
        '--surface-ground': props.colorMode === 'dark' ? colors.surface.dark.ground : colors.surface.light.ground,
        '--surface-low': props.colorMode === 'dark' ? colors.surface.dark.low : colors.surface.light.low,
        '--surface-mid': props.colorMode === 'dark' ? colors.surface.dark.mid : colors.surface.light.mid,
        '--surface-high': props.colorMode === 'dark' ? colors.surface.dark.high : colors.surface.light.high,
        '--surface-highest': props.colorMode === 'dark' ? colors.surface.dark.highest : colors.surface.light.highest,
        '--text-primary': props.colorMode === 'dark' ? colors.text.dark.primary : colors.text.light.primary,
        '--text-secondary': props.colorMode === 'dark' ? colors.text.dark.secondary : colors.text.light.secondary,
        '--text-tertiary': props.colorMode === 'dark' ? colors.text.dark.tertiary : colors.text.light.tertiary,
        '--text-disabled': props.colorMode === 'dark' ? colors.text.dark.disabled : colors.text.light.disabled,
      },
      body: {
        bg: 'var(--background)',
        color: 'var(--text-primary)',
      },
    }),
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 'bold',
      },
      variants: {
        solid: (props) => ({
          bg: 'primary.500',
          color: 'white',
          _hover: {
            bg: 'primary.600',
          },
          _active: {
            bg: 'primary.700',
          },
        }),
        outline: (props) => ({
          border: '2px solid',
          borderColor: 'primary.500',
          color: props.colorMode === 'dark' ? 'primary.400' : 'primary.500',
          _hover: {
            bg: props.colorMode === 'dark' ? 'primary.900' : 'primary.50',
          },
        }),
        ghost: (props) => ({
          color: props.colorMode === 'dark' ? 'text.dark.primary' : 'text.light.primary',
          _hover: {
            bg: props.colorMode === 'dark' ? 'surface.dark.low' : 'surface.light.low',
          },
        }),
        link: (props) => ({
          color: 'primary.500',
          _hover: {
            color: 'primary.600',
            textDecoration: 'none',
          },
        }),
      },
    },
    Card: {
      baseStyle: (props) => ({
        container: {
          bg: props.colorMode === 'dark' ? 'surface.dark.low' : 'surface.light.low',
          borderRadius: 'lg',
          borderWidth: '1px',
          borderColor: props.colorMode === 'dark' ? 'border.dark.subtle' : 'border.light.subtle',
        },
      }),
    },
    Input: {
      variants: {
        outline: (props) => ({
          field: {
            bg: props.colorMode === 'dark' ? 'surface.dark.low' : 'surface.light.low',
            borderColor: props.colorMode === 'dark' ? 'border.dark.light' : 'border.light.light',
            _hover: {
              borderColor: props.colorMode === 'dark' ? 'border.dark.medium' : 'border.light.medium',
            },
            _focus: {
              borderColor: 'primary.500',
              boxShadow: `0 0 0 1px ${colors.primary[500]}`,
            },
          },
        }),
      },
    },
  },
});

export default theme;