import { Auth } from 'aws-amplify';

   export const signUp = async (username, password, attributes) => {
     try {
       const { user } = await Auth.signUp({
         username,
         password,
         attributes,
       });
       return user;
     } catch (error) {
       console.error('Error signing up:', error);
       throw error;
     }
   };

   export const signIn = async (username, password) => {
     try {
       const user = await Auth.signIn(username, password);
       return user;
     } catch (error) {
       console.error('Error signing in:', error);
       throw error;
     }
   };

   export const signOut = async () => {
     try {
       await Auth.signOut();
     } catch (error) {
       console.error('Error signing out:', error);
       throw error;
     }
   };

   export const getCurrentUser = async () => {
     try {
       const user = await Auth.currentAuthenticatedUser();
       return user;
     } catch (error) {
       console.error('Error getting current user:', error);
       return null;
     }
   };

   export const isAuthenticated = async () => {
     try {
       await Auth.currentSession();
       return true;
     } catch {
       return false;
     }
   };