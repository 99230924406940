import React, { useEffect, useState } from 'react';
import SolarResultsPage from './SolarResultsPage';
import { useUserDataContext } from '../../Providers/LeadDataProvider';

const SolarCalculator = () => {
  const [loading, setLoading] = useState(true);
  const [systemEstimates, setSystemEstimates] = useState(null);
  const [panelAdjustment, setPanelAdjustment] = useState(0);
  const { userData } = useUserDataContext();

  const CONSTANTS = {
    avgElectricityRate: .18, //((Number(userData.electric_bill) / (Number(userData.estimatedAnnualUsage) / 12))),
    avgSunHoursPerDay: 5.5,
    systemEfficiency: 0.85,
    panelWattage: 400, // watts
    costPerWatt: 2.4,
    annualDegradation: 0.005,
    CO2PerKWh: 0.0007,
    treesPerTonCO2: 40,
    averageHomeEmissions: 7.72
  };

  const adjustPanels = (adjustment) => {
    setPanelAdjustment(prev => {
      const newAdjustment = prev + adjustment;
      const baseSystemSize = calculateBaseSystemSize();
      const basePanels = Math.ceil((baseSystemSize * 1000) / CONSTANTS.panelWattage);
      
      if ((basePanels + newAdjustment) < Math.ceil(basePanels * 0.25)) {
        return prev;
      }
      return newAdjustment;
    });
  };

  const calculateBaseSystemSize = () => {
    const monthlyBill = Number(userData.electric_bill);
    const annualConsumption = Number(userData.estimatedAnnualUsage);
    return (annualConsumption / 
      (CONSTANTS.avgSunHoursPerDay * 365 * CONSTANTS.systemEfficiency)) * .9;
  };

  const calculateSystemEstimates = () => {
    setLoading(true);
    const monthlyBill = Number(userData.electric_bill);
    const ANNUAL_RATE_INCREASE = 0.03; // 3% annual increase
  
    try {
      const annualConsumption = Number(userData.estimatedAnnualUsage);
      const baseSystemSize = calculateBaseSystemSize();
      const basePanels = Math.ceil((baseSystemSize * 1000) / CONSTANTS.panelWattage);
      
      const adjustedPanels = basePanels + panelAdjustment;
      const systemSize = (adjustedPanels * CONSTANTS.panelWattage) / 1000;
  
      const dailyProduction = systemSize * CONSTANTS.avgSunHoursPerDay * CONSTANTS.systemEfficiency;
      const annualProduction = Math.min(dailyProduction * 365, annualConsumption); // Cap production at consumption
      const offset = (annualProduction / annualConsumption) * 100;
  
      const systemCost = systemSize * 1000 * CONSTANTS.costPerWatt;
      const federalTaxCredit = systemCost * 0.30;
      const netCost = systemCost - federalTaxCredit;
  
      const monthlyProduction = annualProduction / 12;
      const estimatedLoanPayment = calculateSolarLoanPayment(netCost).monthlyPayment;
  
      const calculateYearlySavings = (year) => {
        const yearRate = CONSTANTS.avgElectricityRate * Math.pow(1 + ANNUAL_RATE_INCREASE, year);
        const yearProduction = annualProduction * Math.pow(1 - CONSTANTS.annualDegradation, year);
        const solarSavings = Math.min(yearProduction, annualConsumption) * yearRate;
        const remainingUtilityCost = Math.max(0, (annualConsumption - yearProduction)) * yearRate;
        return solarSavings - remainingUtilityCost - (estimatedLoanPayment * 12);
      };
  
      // Calculate first year savings
      const firstYearSavings = calculateYearlySavings(0);
      const monthlySavings = firstYearSavings / 12;
  
      // Calculate lifetime savings
      let totalLifetimeSavings = 0;
      for (let year = 0; year < 25; year++) {
        totalLifetimeSavings += calculateYearlySavings(year);
      }

      const paybackPeriod = calculatePaybackPeriod(netCost, annualProduction, annualConsumption, CONSTANTS.avgElectricityRate, estimatedLoanPayment);
  
      // Environmental calculations
      const annualCO2Reduction = annualProduction * CONSTANTS.CO2PerKWh;
      const treesPlanted = Math.round(annualCO2Reduction * CONSTANTS.treesPerTonCO2);
      const homesOffset = annualCO2Reduction / CONSTANTS.averageHomeEmissions;
  
      setSystemEstimates({
        systemSize: parseFloat(systemSize.toFixed(1)),
        annualConsumption: Math.round(annualConsumption),
        annualProduction: Math.round(annualProduction),
        numberOfPanels: adjustedPanels,
        basePanels: basePanels,
        offset: Math.round(offset),
        estimatedSavings: Math.round(totalLifetimeSavings),
        monthlyBill: Math.round(monthlyBill),
        estimatedMonthlyBill: Math.round(monthlyBill - monthlySavings),
        estimatedLoanPayment: Math.round(estimatedLoanPayment),
        monthlySavings: Math.round(monthlySavings),
        paybackPeriod: parseFloat(paybackPeriod.toFixed(1)),
        environmentalImpact: {
          treesPlanted,
          carsRemoved: parseFloat((annualCO2Reduction / 4.6).toFixed(1)),
          co2Reduced: parseFloat(annualCO2Reduction.toFixed(1)),
          homesOffset: parseFloat(homesOffset.toFixed(1)),
          wasteDiverted: parseFloat((annualCO2Reduction * 2.3).toFixed(1))
        },
        monthlyProduction: calculateMonthlyProduction(systemSize),
        onAdjustPanels: adjustPanels,
        panelAdjustment
      });
  
      setLoading(false);
    } catch (error) {
      console.error('Error calculating solar estimates:', error);
      setLoading(false);
    }
  };
  
  const calculatePaybackPeriod = (netCost, annualProduction, annualConsumption, currentRate, monthlyLoanPayment) => {
    const ANNUAL_RATE_INCREASE = 0.03; // 3% annual increase
    const ANNUAL_DEGRADATION = 0.005; // 0.5% annual panel degradation
    
    let remainingCost = netCost;
    let year = 0;
    
    while (remainingCost > 0 && year < 25) {
      const yearRate = currentRate * Math.pow(1 + ANNUAL_RATE_INCREASE, year);
      const yearProduction = Math.min(annualProduction * Math.pow(1 - ANNUAL_DEGRADATION, year), annualConsumption);
      const solarSavings = yearProduction * yearRate;
      const remainingUtilityCost = Math.max(0, (annualConsumption - yearProduction)) * yearRate;
      const yearSavings = solarSavings - remainingUtilityCost - (monthlyLoanPayment * 12);
      
      remainingCost -= yearSavings;
      
      if (remainingCost <= 0) {
        // Interpolate partial year
        return year + (remainingCost + yearSavings) / yearSavings;
      }
      
      year++;
    }
    
    return 99; // Return 99 if system never pays back
  };

  const calculateSolarLoanPayment = (netSystemCost) => {
    const LOAN_CONSTANTS = {
      INTEREST_RATE: 0.07, // 7% APR
      LOAN_TERM_YEARS: 25,
      PAYMENTS_PER_YEAR: 12
    };
  
    const monthlyRate = LOAN_CONSTANTS.INTEREST_RATE / LOAN_CONSTANTS.PAYMENTS_PER_YEAR;
    const totalPayments = LOAN_CONSTANTS.LOAN_TERM_YEARS * LOAN_CONSTANTS.PAYMENTS_PER_YEAR;
  
    // Monthly payment formula: P = L[c(1 + c)^n]/[(1 + c)^n - 1]
    // where: P = Payment, L = Loan amount, c = monthly interest rate, n = total number of payments
    const monthlyPayment = netSystemCost * 
      (monthlyRate * Math.pow(1 + monthlyRate, totalPayments)) / 
      (Math.pow(1 + monthlyRate, totalPayments) - 1);
  
    return {
      monthlyPayment: Math.round(monthlyPayment),
      totalLoanCost: Math.round(monthlyPayment * totalPayments),
      interestPaid: Math.round((monthlyPayment * totalPayments) - netSystemCost),
      loanTerm: LOAN_CONSTANTS.LOAN_TERM_YEARS,
      apr: LOAN_CONSTANTS.INTEREST_RATE
    };
  };

  const calculateLifetimeSavings = (annualSavings, years, degradation, rateIncrease) => {
    let totalSavings = 0;
    let currentAnnualSavings = annualSavings;

    for (let year = 0; year < years; year++) {
      totalSavings += currentAnnualSavings;
      currentAnnualSavings *= (1 - degradation);
      currentAnnualSavings *= (1 + rateIncrease);
    }

    return totalSavings;
  };

  const calculateMonthlyProduction = (systemSize) => {
    const monthlyFactors = {
      Jan: 0.65, Feb: 0.75, Mar: 0.9, Apr: 1.0,
      May: 1.1, Jun: 1.15, Jul: 1.15, Aug: 1.1,
      Sep: 0.95, Oct: 0.8, Nov: 0.7, Dec: 0.6
    };

    return Object.entries(monthlyFactors).map(([month, factor]) => ({
      month,
      value: Math.round(systemSize * factor * CONSTANTS.avgSunHoursPerDay * 30)
    }));
  };

  useEffect(() => {
    calculateSystemEstimates();
  }, [userData, panelAdjustment]);

  if (loading) {
    return (
      <div className="min-h-screen bg-[#121212] flex items-center justify-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-amber-500 mx-auto mb-4"></div>
          <p className="text-blue-50 text-lg">Calculating your solar potential...</p>
        </div>
      </div>
    );
  }

  if (!systemEstimates) {
    return (
      <div className="min-h-screen bg-[#121212] flex items-center justify-center">
        <div className="text-center text-blue-50">
          <p>Error calculating estimates. Please try again.</p>
        </div>
      </div>
    );
  }

  return <SolarResultsPage {...systemEstimates} />;
};

export default SolarCalculator;