import React, { useState, useEffect } from "react";
import { Sun, Home, Calculator, LineChart, CloudSun, Check } from "lucide-react";
import { useNavigate } from "react-router-dom";

const LoadingSequence = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const steps = [
    {
      icon: Home,
      text: "Analyzing roof dimensions and orientation...",
      duration: 1600,
    },
    {
      icon: Sun,
      text: "Calculating solar exposure and peak sun hours...",
      duration: 1600,
    },
    {
      icon: Calculator,
      text: "Determining optimal panel configuration...",
      duration: 1600,
    },
    // {
    //   icon: CloudSun,
    //   text: "Factoring in local weather patterns...",
    //   duration: 1600,
    // },
    {
      icon: LineChart,
      text: "Estimating potential energy production...",
      duration: 1600,
    },
    {
      icon: Check,
      text: "Preparing your personalized solar proposal...",
      duration: 1600,
    },
  ];

  useEffect(() => {
    // Progress bar animation
    const progressInterval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(progressInterval);
          navigate("/klaravia-studiog");
          return 100;
        }
        return prev + 0.5;
      });
    }, 36); // 6000ms / 100 steps = 60ms, but we increment by 0.5 so 30ms

    // Step sequence animation
    let stepTimeout: NodeJS.Timeout;
    const nextStep = () => {
      setCurrentStep((prev) => {
        if (prev < steps.length - 1) {
          stepTimeout = setTimeout(nextStep, steps[prev + 1].duration);
          return prev + 1;
        }
        return prev;
      });
    };
    stepTimeout = setTimeout(nextStep, steps[0].duration);

    return () => {
      clearInterval(progressInterval);
      clearTimeout(stepTimeout);
    };
  }, []);

  return (
    <div className="min-h-screen bg-[#121212] flex flex-col items-center justify-center p-4">
      <div className="max-w-xl w-full space-y-8">
        {/* Sun animation */}
        <div className="flex justify-center mb-8">
          <div className="relative">
            <Sun
              className="w-24 h-24 text-yellow-500 animate-pulse"
              style={{
                filter: "drop-shadow(0 0 20px rgba(234, 179, 8, 0.5))",
              }}
            />
          </div>
        </div>

        {/* Progress bar */}
        <div className="relative pt-1">
          <div className="overflow-hidden h-3 mb-4 text-sm flex rounded bg-white/10">
            <div className="transition-all duration-300 ease-out bg-gradient-to-r from-yellow-500 to-orange-500 rounded-3xl" style={{ width: `${progress}%` }} />
          </div>
          <div className="text-right">
            <span className="text-lg font-semibold inline-block text-yellow-500">{Math.round(progress)}%</span>
          </div>
        </div>

        {/* Steps display */}
        <div className="w-full space-y-4">
          {steps.map((step, index) => (
            <div
              key={index}
              className={`flex items-center space-x-4 transition-all duration-300 ${
                index === currentStep ? "opacity-100 transform scale-100" : index < currentStep ? "opacity-50 transform scale-95" : "opacity-30 transform scale-95"
              }`}
            >
              <step.icon className={`w-6 h-6 ${index === currentStep ? "text-yellow-500" : "text-gray-400"}`} />
              <span className={`text-xl ${index === currentStep ? "text-white" : "text-gray-400"}`}>{step.text}</span>
              {index < currentStep && <Check className="w-8 h-8 text-green-500 ml-auto" />}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LoadingSequence;
