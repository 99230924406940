import { Box, VStack, Heading, Text } from "@chakra-ui/react";

interface SavingsBreakdownProps {
  estimatedUsage: number;
  estimatedSystemSize: number;
  requiresBattery: boolean;
}

const SavingsBreakdown = ({ estimatedUsage, estimatedSystemSize, requiresBattery }: SavingsBreakdownProps) => {
  // Constants
  const ELECTRICITY_PRICE = 0.14; // per kWh
  const SOLAR_COST_PER_WATT = 2.5;
  const SYSTEM_LIFETIME = 25; // years
  const FEDERAL_TAX_CREDIT = 0.6; // 30% federal tax credit
  const ANNUAL_ELECTRICITY_PRICE_INCREASE = 0.045; // 2% annual increase

  const calculateSolarMetrics = () => {
    const installationCost = estimatedSystemSize * 1000 * SOLAR_COST_PER_WATT;
    const costAfterIncentives = requiresBattery ? (installationCost + 10000) * FEDERAL_TAX_CREDIT : installationCost * FEDERAL_TAX_CREDIT;

    // Calculate lifetime energy cost with annual price increases
    let lifetimeEnergyCost = 0;
    let yearlyBill = estimatedUsage * ELECTRICITY_PRICE;
    for (let year = 0; year < SYSTEM_LIFETIME; year++) {
      lifetimeEnergyCost += yearlyBill;
      yearlyBill *= 1 + ANNUAL_ELECTRICITY_PRICE_INCREASE;
    }

    const lifetimeSavings = requiresBattery ? lifetimeEnergyCost - costAfterIncentives - 10000 : lifetimeEnergyCost - costAfterIncentives;

    return {
      installationCost: Math.round(costAfterIncentives),
      lifetimeSavings: Math.round(lifetimeSavings),
    };
  };

  const { installationCost, lifetimeSavings } = calculateSolarMetrics();

  return (
    <Box w="full" maxWidth="full" backgroundColor="white" margin="auto" p={8} borderRadius="lg" boxShadow="lg" fontFamily="'eurostile', sans-serif">
      <VStack spacing={6} align="stretch">
        <Text fontWeight="bold" fontSize="26px" fontFamily="'eurostile', sans-serif">
          3. Estimated Savings
        </Text>

        <Box bg="blue.50" p={4} borderRadius="md" mt={4}>
          <Text fontWeight="bold">
            Estimated Solar Installation Cost{" "}
            <Text fontSize="10px" color="gray.600">
              (after incentives):
            </Text>
          </Text>
          <Box w="full" display="flex" justifyContent="flex-end">
            <Text as="span" fontWeight="bold" fontSize="24px" color="blue.600">
              ${installationCost.toLocaleString()}
            </Text>
          </Box>
        </Box>

        <Box bg="green.50" p={4} borderRadius="md">
          <Text fontWeight="bold">Estimated Lifetime Energy Savings:</Text>
          <Box w="full" display="flex" justifyContent="flex-end">
            <Text as="span" fontSize="32px" fontWeight="bold" color="green.600">
              ${lifetimeSavings.toLocaleString()}
            </Text>
          </Box>
        </Box>

        <Text fontSize="sm">
          *Calculations assume a {2.5}% annual increase in electricity prices and a 2% annual inflation increase over {SYSTEM_LIFETIME} years.
        </Text>
      </VStack>
    </Box>
  );
};

export default SavingsBreakdown;
