import { ReactElement, useEffect, useRef, useState } from "react";
import { Box, VStack, HStack, Heading, Text, Container, Flex, SimpleGrid, Stack, StackDivider, useColorModeValue, Icon } from "@chakra-ui/react";
import EngagementInfo from "../Components/EngagementInfo";
import FrequentlyAsked from "../Components/FrequentlyAsked";
import { motion, useTransform, MotionValue, useScroll, useInView, useAnimation } from "framer-motion";
import CoverageMap from "./CoverageMap";
import {
  Activity,
  CloudSun,
  DollarSign,
  Shield,
  Users,
  Home,
  BatteryCharging,
  Zap,
  TreePine,
  Car,
  Factory,
  Check,
  LockIcon,
  Search,
  BarChart,
  Thermometer,
  Lock,
  ChevronRight,
  CheckCircle,
  Award,
  MessageSquare,
  Star,
  Mail,
  HelpCircle,
} from "lucide-react";
import "./LandingPage.css";
import LifetimeSavingsComponent from "../Components/LifetimeSavingsComponent";
import { IoBuild, IoLogoUsd, IoSearchSharp } from "react-icons/io5";
import ScrollToTopButton from "../Components/ScrollToTopButton";
import ScrollDownChevron from "../Components/ScrollDownChevron";
import EntrySection from "../Components/EntrySection";

function LandingPage() {
  const [showBanner, setShowBanner] = useState(true);
  const MotionVStack = motion(VStack);
  const [hoveredBenefit, setHoveredBenefit] = useState<number | null>(null);
  const [savings, setSavings] = useState(0);
  const [offset, setOffset] = useState(0);
  const [activeSection, setActiveSection] = useState("hero");
  const [currentStep, setCurrentStep] = useState(1);

  const containerRef = useRef(null);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const isScrollingRef = useRef(false);

  useEffect(() => {
    const container = containerRef.current;
    // @ts-ignore
    const sections = container.querySelectorAll("section");
    const totalSections = sections.length;

    const handleWheel = (e: { preventDefault: () => void; deltaY: number }) => {
      e.preventDefault();

      if (isScrollingRef.current) return;

      const direction = e.deltaY > 0 ? 1 : -1;
      const nextIndex = Math.max(0, Math.min(currentSectionIndex + direction, totalSections - 1));

      if (nextIndex !== currentSectionIndex) {
        isScrollingRef.current = true;
        setCurrentSectionIndex(nextIndex);

        sections[nextIndex].scrollIntoView({ behavior: "smooth" });

        // Reset the scrolling flag after animation completes
        setTimeout(() => {
          isScrollingRef.current = false;
        }, 1000); // Adjust this timing to match your scroll animation duration
      }
    };

    window.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      window.removeEventListener("wheel", handleWheel);
    };
  }, [currentSectionIndex]);

  const handleScrollToTop = () => {
    setCurrentSectionIndex(0);
  };

  const handleCallNow = () => {
    window.location.href = "tel:+15632995135";
  };

  const benefits = [
    {
      icon: <DollarSign className="w-16 h-16" />,
      title: "Zero Commission",
      description: "Skip the salespeople and save thousands on commissions",
      color: "bg-gradient-to-br from-green-400 to-emerald-600",
    },
    {
      icon: <Users className="w-16 h-16" />,
      title: "Vetted Installers",
      description: "Compare top-rated installers competing for your business",
      color: "bg-gradient-to-br from-blue-400 to-indigo-600",
    },
    {
      icon: <Shield className="w-16 h-16" />,
      title: "Price Match",
      description: "Get the best price guaranteed with our installer network",
      color: "bg-gradient-to-br from-purple-400 to-violet-600",
    },
  ];

  const problems = [
    {
      icon: <DollarSign className="w-12 h-12 text-red-600/80" />,
      title: "Rising Energy Bills",
      description: "Utility costs are rising at an alarming rate, putting a strain on household budgets.",
      stat: "6.3%",
      statDescription: "Average annual increase in electricity prices over the last decade",
    },
    {
      icon: <Zap className="w-12 h-12 text-yellow-500" />,
      title: "Unreliable Power Grid",
      description: "Aging infrastructure leads to frequent outages, leaving homes vulnerable.",
      stat: "60%",
      statDescription: "Increase in power outages since 2015",
    },
    {
      icon: <Thermometer className="w-12 h-12 text-orange-500" />,
      title: "Climate Concerns",
      description: "Traditional energy sources contribute to climate change, affecting our future.",
      stat: "75%",
      statDescription: "Of Americans worried about climate change impact",
    },
    {
      icon: <Lock className="w-12 h-12 text-blue-500" />,
      title: "Energy Dependency",
      description: "Reliance on the grid leaves homeowners at the mercy of utility companies.",
      stat: "100%",
      statDescription: "Of grid-tied homes affected by utility company decisions",
    },
  ];

  function useParallax(value: MotionValue<number>, distance: number) {
    return useTransform(value, [0, 1], [-distance, distance - 200]);
  }

  const heroRef = useRef(null);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (heroRef.current) {
  //       //@ts-ignore
  //       const { top } = heroRef.current.getBoundingClientRect();
  //       setOffset(window.scrollY * 0.5);

  //       // Update active section based on scroll
  //       const sections = document.querySelectorAll("section");
  //       sections.forEach((section) => {
  //         const rect = section.getBoundingClientRect();
  //         if (rect.top <= window.innerHeight * 0.5 && rect.bottom >= window.innerHeight * 0.5) {
  //           setActiveSection(section.id);
  //         }
  //       });
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  const ref = useRef(null);
  const { scrollYProgress } = useScroll({ target: ref });
  const y = useParallax(scrollYProgress, -400);

  const popUpRef = useRef(null);
  const isInView = useInView(popUpRef, { once: true });

  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  const handleHideBanner = () => {
    setShowBanner(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setSavings((prev) => {
        const next = prev + 1;
        return next > 45 ? 45 : next;
      });
    }, 50);
    return () => clearInterval(interval);
  }, []);

  const scrollToNextSection = () => {
    // @ts-ignore
    const sections = containerRef.current.querySelectorAll("section");
    const nextIndex = Math.min(currentSectionIndex + 1, sections.length - 1);
    if (nextIndex !== currentSectionIndex) {
      setCurrentSectionIndex(nextIndex);
      sections[nextIndex].scrollIntoView({ behavior: "smooth" });
    }
  };

  const MotionHStack = motion(HStack);

  interface FeatureProps {
    text: string;
    iconBg: string;
    icon?: ReactElement;
  }

  const Feature = ({ text, icon, iconBg }: FeatureProps) => {
    return (
      <Stack direction={"row"} align={"center"}>
        <Flex w={8} h={8} align={"center"} justify={"center"} rounded={"full"} bg={iconBg}>
          {icon}
        </Flex>
        <Text fontWeight={600}>{text}</Text>
      </Stack>
    );
  };

  const handleStart = () => {
    setCurrentSectionIndex(1); // Move to the next section
    //@ts-ignore
    containerRef.current.querySelectorAll("section")[1].scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box w="full" className="lp-container font-['eurostile']" display="relative" ref={ref} pt={{ base: "56px", md: "0px" }}>
      <div ref={containerRef} className="scroll-container">
        <section id="ctaction" className="bg-dynamic font-['eurostile'] pt-8 sm:pt-24 flex items-center" style={{ minHeight: "100vh", backgroundColor: "#121212" }}>
          <EntrySection onStart={handleStart} />
          <ScrollDownChevron onClick={scrollToNextSection} />
        </section>

        <section id="klaravia-mission" className="dark:bg-background-dark py-24 px-4 text-white content-center relative" style={{ minHeight: "100vh", backgroundColor: "#121212" }}>
          <div className="max-w-6xl mx-auto">
            <div className="grid md:grid-cols-2 gap-16 items-center">
              <div>
                <h3 className="text-3xl font-bold mb-6">Our Mission</h3>
                <p className="text-xl mb-8 leading-relaxed">
                  At Klaravia, we're on a mission to make solar power accessible, transparent, and hassle-free for every homeowner. We're reimagining the solar buying experience
                  from the ground up.
                </p>
                <div className="bg-white/10 backdrop-blur-md rounded-xl p-6 mb-8">
                  <p className="text-3xl font-bold text-green-400 mb-2">45%</p>
                  <p className="text-xl">Average savings on solar installation</p>
                </div>
                <button
                  className="py-4 px-8 bg-amber-500 text-white rounded-xl text-lg font-semibold hover:bg-amber-600 transition-colors"
                  onClick={() => {
                    const container = document.querySelector(".scroll-container");
                    if (container) {
                      //@ts-ignore
                      container.querySelector("section").scrollIntoView({ behavior: "smooth" });
                      handleScrollToTop(); // Call the prop function to update the index
                    }
                  }}
                >
                  Start Your Solar Journey
                </button>
              </div>
              <div className="grid md:grid-cols-2 gap-6">
                {[
                  { icon: <DollarSign className="w-8 h-8 text-green-400" />, title: "No Commissions", description: "Skip salespeople and save on hefty commissions." },
                  { icon: <Search className="w-8 h-8 text-yellow-400" />, title: "Solar Education", description: "Make informed decisions about your energy future." },
                  { icon: <Users className="w-8 h-8 text-blue-400" />, title: "Vetted Installers", description: "Top-rated installers compete for your business." },
                  { icon: <BarChart className="w-8 h-8 text-purple-400" />, title: "Real-time Tracking", description: "Monitor your installation progress at every step." },
                ].map((item, index) => (
                  <div key={index} className="bg-white/5 backdrop-blur-sm rounded-xl p-6 hover:bg-white/10 transition-colors">
                    <div className="flex items-center mb-4">
                      <div className="bg-white/10 rounded-full p-2 mr-4">{item.icon}</div>
                      <h4 className="text-xl font-semibold">{item.title}</h4>
                    </div>
                    <p className="text-gray-300">{item.description}</p>
                  </div>
                ))}
              </div>
            </div>
            <ScrollDownChevron onClick={scrollToNextSection} />
          </div>
        </section>

        <section
          id="energy-independence"
          className="py-24 dark:bg-background-dark text-white px-4 content-center relative"
          style={{ minHeight: "100vh", backgroundColor: "#121212" }}
        >
          <div className="max-w-6xl mx-auto">
            <h2 className="text-4xl font-bold text-white/90 text-center mb-16">Achieve True Energy Independence</h2>
            <div className="grid md:grid-cols-3 gap-8">
              {[
                { icon: <Home className="w-16 h-16" />, title: "Power Your Home", description: "Generate your own clean electricity and reduce reliance on the grid." },
                {
                  icon: <BatteryCharging className="w-16 h-16" />,
                  title: "Store Your Energy",
                  description: "With battery storage, use your own power even when the sun isn't shining.",
                },
                { icon: <Zap className="w-16 h-16" />, title: "Sell Excess Power", description: "Feed excess energy back to the grid and get credited on your bill." },
              ].map((item, index) => (
                <div key={index} className="bg-white/10 rounded-xl p-8 text-center shadow-lg hover:shadow-xl transition-shadow">
                  <div className="mb-6 flex justify-center text-amber-500">{item.icon}</div>
                  <h3 className="text-2xl font-bold text-white/90 mb-4">{item.title}</h3>
                  <p className="text-gray-400">{item.description}</p>
                </div>
              ))}
            </div>
            <div className="mt-16 text-center">
              <p className="text-xl text-gray-300 mb-8">Take control of your energy future. Say goodbye to unpredictable bills and hello to sustainable, self-generated power.</p>
              <button
                className="py-4 px-8 bg-amber-500 text-white rounded-xl text-lg font-semibold hover:bg-amber-600 transition-colors"
                onClick={() => {
                  const container = document.querySelector(".scroll-container");
                  if (container) {
                    //@ts-ignore
                    container.querySelector("section").scrollIntoView({ behavior: "smooth" });
                    handleScrollToTop(); // Call the prop function to update the index
                  }
                }}
              >
                Start Your Journey to Energy Independence
              </button>
            </div>
          </div>
          <ScrollDownChevron onClick={scrollToNextSection} />
        </section>

        <section id="process" className="relative min-h-screen bg-[#121212] py-12 md:py-24 px-4">
          <div className="max-w-6xl mx-auto">
            {/* Header */}
            <motion.div initial={{ opacity: 0, y: 20 }} whileInView={{ opacity: 1, y: 0 }} viewport={{ once: true }} className="text-center mb-8 md:mb-16">
              <h2 className="text-3xl md:text-5xl font-bold text-blue-50 mb-4 md:mb-6">Your Journey to Solar</h2>
              <p className="text-base md:text-lg text-blue-50/70 max-w-2xl mx-auto px-4">
                A streamlined process designed to make your transition to solar energy simple, transparent, and hassle-free.
              </p>
            </motion.div>

            {/* Process Steps */}
            <div className="relative">
              {/* Connection Line - Hidden on mobile, visible on larger screens */}
              <div className="absolute top-1/3 left-0 w-full hidden md:block">
                {/* Vertical line for mobile */}
                <div className="absolute left-4 top-0 w-1 h-full bg-gradient-to-b from-amber-500 to-amber-600 md:hidden" />
                {/* Horizontal line for desktop */}
                <div className="hidden md:block h-1 bg-gradient-to-r from-amber-500 to-amber-600 transform -translate-y-1/2" />
              </div>

              {/* Steps Grid */}
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 md:gap-8 relative z-10">
                {[
                  {
                    icon: <CloudSun className="w-6 md:w-8 h-6 md:h-8" />,
                    title: "Design System",
                    description: "Get an instant custom design using our advanced AI technology",
                    features: ["3D roof analysis", "Optimal panel placement", "Production estimates"],
                  },
                  {
                    icon: <Users className="w-8 h-8" />,
                    title: "Compare Installers",
                    description: "Browse verified local installers competing for your project",
                    features: ["Vetted professionals", "Direct comparisons", "Transparent pricing"],
                  },
                  {
                    icon: <DollarSign className="w-8 h-8" />,
                    title: "Best Price",
                    description: "Lock in the lowest price with our wholesale network rates",
                    features: ["No hidden fees", "Price matching", "Financing options"],
                  },
                  {
                    icon: <Activity className="w-8 h-8" />,
                    title: "Track Install",
                    description: "Monitor every step of your installation in real-time",
                    features: ["Live updates", "Project timeline", "Direct communication"],
                  },
                ].map((step, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: index * 0.1 }}
                    className="flex md:block" // Makes it horizontal on mobile
                  >
                    {/* Mobile Step Number */}
                    <div
                      className="
              flex-shrink-0 mr-4 md:hidden
              w-8 h-8 rounded-full
              bg-gradient-to-r from-amber-500 to-amber-600
              flex items-center justify-center
              text-sm font-bold text-white
              self-start mt-4
            "
                    >
                      {index + 1}
                    </div>

                    <div
                      className={`
                flex-grow relative group
                ${currentStep === index + 1 ? "md:scale-105" : ""}
                transition-all duration-300
              `}
                      onMouseEnter={() => setCurrentStep(index + 1)}
                    >
                      {/* Card */}
                      <div
                        className="
                bg-gradient-to-br from-amber-500 to-amber-600
                rounded-xl md:rounded-2xl 
                p-4 md:p-8
                text-left md:text-center
                group-hover:translate-y-[-4px]
                transition-all duration-300
                shadow-lg shadow-amber-500/10
              "
                      >
                        {/* Icon */}
                        <div
                          className="
                  w-12 h-12 md:w-16 md:h-16
                  mb-4 md:mb-6
                  bg-black/20 rounded-full
                  flex items-center justify-center
                  text-white/90
                  group-hover:scale-110
                  transition-transform duration-300
                  md:mx-auto
                "
                        >
                          {step.icon}
                        </div>

                        {/* Content */}
                        <h3 className="text-lg md:text-xl font-bold text-white mb-2 md:mb-4">{step.title}</h3>
                        <p className="text-sm md:text-base text-white/80 mb-4 md:mb-6">{step.description}</p>

                        {/* Features */}
                        <ul
                          className="
                  space-y-2 
                  text-xs md:text-sm 
                  text-white/70 
                  text-left 
                  hidden md:block
                "
                        >
                          {step.features.map((feature, i) => (
                            <li key={i} className="flex items-center">
                              <CheckCircle className="w-4 h-4 mr-2 text-white/90" />
                              {feature}
                            </li>
                          ))}
                        </ul>
                      </div>

                      {/* Desktop Step Number */}
                      <div
                        className="
                absolute -top-4 -right-4
                w-8 h-8 rounded-full
                bg-[#1a1a1a]
                border-2 border-amber-500
                flex items-center justify-center
                text-sm font-bold text-white
                hidden md:flex
              "
                      >
                        {index + 1}
                      </div>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>

            {/* Bottom CTA */}
            <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} viewport={{ once: true }} transition={{ delay: 0.4 }} className="mt-8 md:mt-16 text-center px-4">
              <p className="text-base md:text-lg text-blue-50/70 mb-6 md:mb-8">We're here to walk you through every step of the way</p>
              <button
                onClick={scrollToNextSection}
                className="
          w-full md:w-auto
          bg-gradient-to-r from-amber-500 to-amber-600
          hover:from-amber-600 hover:to-amber-700
          text-white px-6 md:px-8 py-3 rounded-lg
          font-semibold transition-all duration-200
          flex items-center justify-center md:mx-auto
        "
              >
                Get Started
                <ChevronRight className="ml-2 w-5 h-5" />
              </button>
            </motion.div>
          </div>

          {/* Background Elements - Adjusted for mobile */}
          <div className="absolute top-0 left-0 w-full h-full pointer-events-none overflow-hidden">
            <div className="absolute top-1/4 left-0 md:left-1/4 w-48 md:w-96 h-48 md:h-96 bg-amber-500/5 rounded-full blur-3xl" />
            <div className="absolute bottom-1/4 right-0 md:right-1/4 w-48 md:w-96 h-48 md:h-96 bg-amber-500/5 rounded-full blur-3xl" />
          </div>

          <ScrollDownChevron onClick={scrollToNextSection} />
        </section>

        {/* <section id="problems" className="relative min-h-screen bg-[#121212] py-12 md:py-24 px-4">
          <div className="max-w-6xl mx-auto">

            <motion.div initial={{ opacity: 0, y: 20 }} whileInView={{ opacity: 1, y: 0 }} viewport={{ once: true }} className="text-center mb-12 md:mb-16">
              <h2 className="text-3xl md:text-5xl font-bold text-blue-50 mb-4 md:mb-6">The Energy Crisis at Home</h2>
              <p className="text-base md:text-xl text-blue-50/70 max-w-3xl mx-auto px-4">
                Homeowners across the country are facing unprecedented challenges with their energy supply. It's time to address these issues head-on.
              </p>
            </motion.div>

            <div className="grid md:grid-cols-2 gap-6 md:gap-8">
              {problems.map((problem, index) => (
                <motion.div key={index} initial={{ opacity: 0, y: 20 }} whileInView={{ opacity: 1, y: 0 }} viewport={{ once: true }} transition={{ delay: index * 0.1 }}>
                  <div
                    className="
            h-full
            bg-white/5 
            border border-white/10
            backdrop-blur-sm 
            rounded-lg 
            overflow-hidden 
            transition-all duration-300 
            hover:translate-y-[-4px]
            hover:bg-white/[0.07]
            group
            items-stretch
          "
                  >
                    <div className="h-full flex flex-col items-stretch p-6 md:p-8">

                      <div className="flex items-start md:items-center mb-6">
                        <div
                          className="
                  p-3 mr-4
                  rounded-full
                  bg-amber-500/10
                  text-amber-500
                  group-hover:scale-110
                  transition-transform duration-300
                  items-end
                "
                        >
                          {problem.icon}
                        </div>
                        <h3 className="text-xl md:text-2xl font-bold text-blue-50">{problem.title}</h3>
                      </div>


                      <p className="text-blue-50/70 mb-6 text-sm md:text-base">{problem.description}</p>


                      <div
                        className="
                bg-white/5 
                border border-white/10
                rounded-lg 
                p-4 
                flex 
                flex-col md:flex-row
                items-start md:items-center 
                justify-between
                gap-4
              "
                      >
                        <div
                          className="
                  text-2xl md:text-3xl 
                  font-bold 
                  bg-gradient-to-r from-red-500/90 to-red-600/90 
                  bg-clip-text 
                  text-transparent
                  max-w-[200px]
                "
                        >
                          {problem.stat}
                        </div>
                        <div
                          className="
                  text-xs md:text-sm 
                  text-blue-50/50 
                  text-left md:text-right
                  
                "
                        >
                          {problem.statDescription}
                        </div>
                      </div>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>


            <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} viewport={{ once: true }} transition={{ delay: 0.4 }} className="mt-12 md:mt-16 text-center">
              <p className="text-xl md:text-2xl text-blue-50/90 mb-8 max-w-3xl mx-auto">
                These problems aren't going away. But what if there was a solution that addressed all of them?
              </p>
              <button
                onClick={scrollToNextSection}
                className="
          w-full md:w-auto
          bg-gradient-to-r from-amber-500 to-amber-600
          hover:from-amber-600 hover:to-amber-700
          text-white 
          px-8 py-4 
          rounded-lg
          font-semibold 
          transition-all duration-200
          flex items-center justify-center 
          mx-auto
          group
        "
              >
                Discover the Solar Advantage
                <ChevronRight
                  className="
          ml-2 w-5 h-5 
          group-hover:translate-x-1 
          transition-transform
        "
                />
              </button>
            </motion.div>
          </div>

          <ScrollDownChevron onClick={scrollToNextSection} />
        </section> */}

        {/* <section id="energy-prices" className="py-24 px-4 dark:bg-background-dark text-white content-center relative" style={{ minHeight: "100vh", backgroundColor: "#121212" }}>
          <div className="max-w-6xl mx-auto">
            <h2 className="text-4xl font-bold text-center mb-16">Beat Rising Energy Prices</h2>
            <div className="grid md:grid-cols-2 gap-12 items-center">
              <div>
                <h3 className="text-2xl font-bold mb-6 text-red-500">Electricity Costs Are Skyrocketing</h3>
                <p className="text-xl text-gray-400 mb-6">Over the past decade, electricity prices have risen by an average of 3% per year. Don't let your bills keep climbing!</p>
                <ul className="space-y-4">
                  {["Lock in your energy costs", "Protect against future rate hikes", "Achieve energy independence"].map((item, index) => (
                    <li key={index} className="flex items-center space-x-3">
                      <LockIcon className="w-6 h-6 text-green-500" />
                      <span className="text-lg">{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="relative">
                <img
                  src="https://assets.solar.com/wp-content/uploads/2023/07/longterm-grid-electricity-price-forecast.png"
                  alt="Graph showing rising energy prices"
                  className="rounded shadow-xl"
                />
                <div className="absolute -bottom-6 -right-6 bg-[#383434] rounded-lg p-4 shadow-lg border-blue">
                  <p className="text-2xl font-bold text-green-500">Save up to 90%</p>
                  <p className="text-gray-300">on your electric bill</p>
                </div>
              </div>
            </div>
          </div>
          <ScrollDownChevron onClick={scrollToNextSection} />
        </section> */}

        {/* <section id="tax-credit" className="py-24 px-4 dark:bg-background-dark content-center relative" style={{ minHeight: "100vh", backgroundColor: "#121212" }}>
          <div className="max-w-6xl mx-auto">
            <h2 className="text-4xl font-bold text-white text-center mb-16">If you're considering solar, now's the time to act.</h2>
            <div className="rounded-2xl shadow-xl overflow-hidden">
              <div className="grid md:grid-cols-2">
                <div className="p-12 bg-gradient-to-br from-green-400/[.5] to-green-600/[.5] text-white">
                  <h2 className="text-4xl font-bold mb-6">Claim Your 30% Federal Tax Credit</h2>
                  <p className="text-xl mb-8">The government is paying you to go solar! Don't miss out on this incredible opportunity to save thousands.</p>
                  <ul className="space-y-4">
                    {["No income limit", "Credit carries over", "Applies to entire system cost"].map((item, index) => (
                      <li key={index} className="flex items-center space-x-3">
                        <Check className="w-6 h-6 text-yellow-300" />
                        <span className="text-lg">{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="p-12 flex flex-col justify-center bg-white/70">
                  <h3 className="text-3xl font-bold mb-2" style={{ color: "#121212" }}>
                    How much can you save?
                  </h3>
                  <p className="text-gray-800 mb-8">On a typical $20,000 solar installation:</p>
                  <div className="bg-green-100 rounded-xl p-6 text-center">
                    <p className="text-5xl font-bold text-green-600 mb-2">$6,000</p>
                    <p className="text-xl text-gray-700">In Tax Credits</p>
                  </div>
                  <div className="w-full mt-8 flex flex-col rounded-xl justify-center dark:bg-background-dark">
                    <button
                      className="bg-green-600/[.8] py-4 px-8 text-white rounded-xl text-lg font-semibold hover:bg-green-600 transition-colors"
                      onClick={() => {
                        const container = document.querySelector(".scroll-container");
                        if (container) {
                          //@ts-ignore
                          container.querySelector("section").scrollIntoView({ behavior: "smooth" });
                          handleScrollToTop(); // Call the prop function to update the index
                        }
                      }}
                    >
                      Calculate Your Tax Credit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ScrollDownChevron onClick={scrollToNextSection} />
        </section> */}

        {/* <section className="py-24 px-4 justify-center relative pt-0" style={{ minHeight: "100vh", backgroundColor: "#121212" }}>
          <Container w="full" maxW={{ base: "full", "2xl": "1440px" }} justifyContent="center" py={24}>
            <CoverageMap />
            <ScrollDownChevron onClick={scrollToNextSection} />
          </Container>
        </section> */}

        <section id="have-questions" className="dark:bg-background-dark py-24 px-4 text-white content-center relative" style={{ minHeight: "100vh", backgroundColor: "#121212" }}>
          <div className="max-w-6xl mx-auto">
            <div className="text-center">
              <h2 className="text-4xl font-bold text-white/90 mb-8">Have Any Questions or Need Help?</h2>
              <p className="text-xl text-gray-300 mb-12">
                We're here to assist you! If you have any questions, encounter any issues, or need further assistance, please don't hesitate to reach out to our support team.
              </p>
            </div>
            <div className=" flex justify-center gap-8">
              <div className="w-full md:w-1/2 bg-white/10 rounded-xl p-8 text-center shadow-lg hover:shadow-xl transition-shadow">
                <div className="mb-6 flex justify-center text-amber-500">
                  <Mail className="w-16 h-16" />
                </div>
                <h3 className="text-2xl font-bold text-white/90 mb-4">Contact Support</h3>
                <p className="text-gray-400 mb-6">Get in touch with our dedicated support team for personalized assistance.</p>
                <div
                  onClick={handleCallNow}
                  className="inline-block py-3 px-6 bg-amber-500 text-white rounded-xl text-lg font-semibold hover:bg-amber-600 transition-colors cursor-pointer"
                >
                  Contact Us
                </div>
              </div>
              {/* <div className="bg-white/10 rounded-xl p-8 text-center shadow-lg hover:shadow-xl transition-shadow">
                <div className="mb-6 flex justify-center text-amber-500">
                  <HelpCircle className="w-16 h-16" />
                </div>
                <h3 className="text-2xl font-bold text-white/90 mb-4">Visit Help Center</h3>
                <p className="text-gray-400 mb-6">Explore our knowledge base for answers to common questions and guides.</p>
                <a href="#" className="inline-block py-3 px-6 bg-amber-500 text-white rounded-xl text-lg font-semibold hover:bg-amber-600 transition-colors">
                  Go to Help Center
                </a>
              </div> */}
            </div>
          </div>
          <ScrollDownChevron onClick={scrollToNextSection} />
        </section>

        <section className="relative bg-gradient-to-r from-green-500 to-emerald-600 content-center overflow-hidden" style={{ minHeight: "100vh" }}>
          {/* Decorative Background Elements */}
          <div className="absolute inset-0 pointer-events-none">
            <div className="absolute -top-1/2 -left-1/2 w-full h-full bg-white/10 rounded-full blur-3xl" />
            <div className="absolute -bottom-1/2 -right-1/2 w-full h-full bg-black/10 rounded-full blur-3xl" />
          </div>

          <div className="relative py-12 md:py-24 px-4">
            <motion.div className="max-w-4xl mx-auto text-center" initial={{ opacity: 0, y: 20 }} whileInView={{ opacity: 1, y: 0 }} viewport={{ once: true }}>
              {/* Main Content */}
              <motion.div initial={{ opacity: 0, scale: 0.95 }} whileInView={{ opacity: 1, scale: 1 }} viewport={{ once: true }} className="mb-12 md:mb-16">
                <h2
                  className="
          text-3xl md:text-5xl 
          font-bold 
          mb-6 
          text-white
          [text-shadow:_0_1px_0_rgb(0_0_0_/_20%)]
        "
                >
                  Ready to Start Saving?
                </h2>
                <p
                  className="
          text-lg md:text-xl 
          text-white/90 
          max-w-2xl 
          mx-auto 
          mb-8
        "
                >
                  Join thousands of homeowners who have already made the switch to clean, affordable solar energy.
                </p>
              </motion.div>

              {/* CTA Button */}
              <motion.div initial={{ opacity: 0, y: 20 }} whileInView={{ opacity: 1, y: 0 }} viewport={{ once: true }} transition={{ delay: 0.2 }} className="mb-8">
                <motion.button
                  onClick={() => {
                    const container = document.querySelector(".scroll-container");
                    if (container) {
                      //@ts-ignore
                      container.querySelector("section").scrollIntoView({ behavior: "smooth" });
                      handleScrollToTop();
                    }
                  }}
                  className="
            group
            px-8 md:px-12 
            py-4 md:py-6 
            bg-white 
            text-emerald-600
            rounded-2xl 
            text-lg md:text-xl 
            font-bold 
            shadow-lg
            hover:shadow-xl
            transition-all 
            duration-300
            hover:translate-y-[-4px]
            relative
            overflow-hidden
            flex items-center justify-center
            mx-auto
          "
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <span className="relative z-10">Get Your Free Quote</span>
                  <ChevronRight
                    className="
            ml-2 w-5 h-5 
            group-hover:translate-x-1 
            transition-transform
          "
                  />
                  <div
                    className="
            absolute 
            inset-0 
            bg-gradient-to-r 
            from-white/0 
            via-emerald-50/50 
            to-white/0
            translate-x-[-100%]
            group-hover:translate-x-[100%]
            transition-transform
            duration-1000
          "
                  />
                </motion.button>
              </motion.div>

              {/* Trust Indicators */}
              <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} viewport={{ once: true }} transition={{ delay: 0.3 }} className="space-y-6">
                {/* Features */}
                <div className="flex flex-wrap justify-center gap-4 md:gap-8 mb-8">
                  {[
                    { icon: Shield, text: "No obligation" },
                    { icon: MessageSquare, text: "Free consultation" },
                    { icon: CheckCircle, text: "Best price guaranteed" },
                  ].map((feature, index) => (
                    <div key={index} className="flex items-center text-white/90">
                      <feature.icon className="w-4 h-4 mr-2 text-white/80" />
                      <span className="text-sm">{feature.text}</span>
                    </div>
                  ))}
                </div>

                {/* Trust Badges */}
                <div className="flex justify-center items-center gap-8">
                  <motion.div whileHover={{ scale: 1.05 }} className="p-3 rounded-full bg-white/10 backdrop-blur-sm">
                    <Award className="w-6 h-6 text-white" />
                  </motion.div>
                  <motion.div whileHover={{ scale: 1.05 }} className="p-3 rounded-full bg-white/10 backdrop-blur-sm">
                    <Star className="w-6 h-6 text-white" />
                  </motion.div>
                  <motion.div whileHover={{ scale: 1.05 }} className="p-3 rounded-full bg-white/10 backdrop-blur-sm">
                    <Shield className="w-6 h-6 text-white" />
                  </motion.div>
                </div>
              </motion.div>
            </motion.div>
          </div>
        </section>

        {/* <section className=" py-24 px-4 content-center justify-center relative" style={{ minHeight: "100vh", backgroundColor: "#121212" }}>
          <Container w="full" maxW={{ base: "full", "2xl": "1440px" }} justifyContent="center" px={{ base: "0px", lg: "20px" }} py={24}>
            <FrequentlyAsked />
          </Container>
        </section> */}
        {/* {[1, 2, 3].map((sectionNumber, index) => (
          <section key={sectionNumber} className="relative">
            Section {sectionNumber}
            {index < 2 && <ScrollDownChevron onClick={scrollToNextSection} />}
          </section>
        ))} */}
      </div>
      {currentSectionIndex > 0 && <ScrollToTopButton onScrollToTop={handleScrollToTop} />}
    </Box>
  );
}

export default LandingPage;
