import React from "react";
import { Box, VStack, HStack, Button, Heading, Text, IconButton } from "@chakra-ui/react";
import ClientProjectStatusBar from "../Components/ClientProjectStatusBar";
import { useNavigate, useParams } from "react-router-dom";
import { useGetUser } from "../../hooks/userHooks";
import { ArrowBackIcon } from "@chakra-ui/icons";

const ClientProjectDetails = () => {
  const { lead_id } = useParams();
  const navigate = useNavigate();

  const getLeadVariables = {
    variables: {
      id: lead_id,
    },
  };

  const { data, loading, error } = useGetUser(getLeadVariables);

  return (
    <Box w="full" minH="90vh" p="40px">
      {data?.getUser && (
        <VStack w="full" alignContent="center">
          {/* <Box bgColor="gray.100" borderRadius="50px" padding="20px" paddingLeft="40px" paddingRight="40px">
          <ClientProjectStatusBar stepIndex={3} clientId={"123"} />
        </Box> */}
          <Box w="full">
            <IconButton as={ArrowBackIcon} size="sm" variant="ghost" aria-label="back button" onClick={() => navigate(`/installer/${data.getUser?.installerId}/potential-leads`)} />
          </Box>
          <VStack>
            <Box w="full" display="flex" justifyContent="flex-start" mt="40px">
              <VStack>
                <Heading>Lead Details</Heading>
                {/* @ts-ignore */}
                {/* <Text fontWeight="bold">{data.getLead.user.address.long_name}</Text> */}
              </VStack>
            </Box>
          </VStack>
          {/* <VStack>
            <VStack w="100%" alignItems="flex-start" mt="30px">
              <Heading w="full" fontSize="2xl" mb="10px">
                Client:
              </Heading>
              <HStack w="40vw" justifyContent="space-between">
                <HStack>
                  <Text fontWeight="bold">Name:</Text>
                  <Text textDecoration="underline">
                    {data.getLead.user.first_name} {data.getLead.user.last_name}
                  </Text>
                </HStack>
                <HStack>
                  <Text fontWeight="bold">Email:</Text>
                  <Text textDecoration="underline">{data.getLead.user.email}</Text>
                </HStack>
              </HStack>
              <HStack w="40vw" justifyContent="space-between">
                <HStack>
                  <Text fontWeight="bold">Phone:</Text>
                  <Text textDecoration="underline">{data.getLead.user.phone_number}</Text>
                </HStack>
                <HStack>
                  <Text fontWeight="bold">Home Owner:</Text>
                  <Text textDecoration="underline">{data.getLead.user.home_owner ? "Yes" : "No"}</Text>
                </HStack>
              </HStack>
            </VStack>
            <VStack w="100%" alignItems="flex-start" mt="40px">
              <Heading w="full" fontSize="2xl" mb="10px">
                Property:
              </Heading>
              <HStack w="40vw" justifyContent="space-between">
                <HStack>
                  <Text fontWeight="bold">Roof Type:</Text>
                  <Text textDecoration="underline">{data.getLead.user.roof_material}</Text>
                </HStack>
                <HStack>
                  <Text fontWeight="bold">Stories:</Text>
                  <Text textDecoration="underline">{data.getLead.user.home_number_of_stories}</Text>
                </HStack>
              </HStack>
              <HStack w="40vw" justifyContent="space-between">
                <HStack>
                  <Text fontWeight="bold">Roof Condition:</Text>
                  <Text textDecoration="underline">{data.getLead.user.roof_condition}</Text>
                </HStack>
              </HStack>
              <HStack w="40vw" justifyContent="space-between">
                <HStack>
                  <Text fontWeight="bold">Has Foundation:</Text>
                  <Text textDecoration="underline">{data.getLead.user.home_has_foundation}</Text>
                </HStack>
              </HStack>
            </VStack>
            <VStack w="100%" alignItems="flex-start" mt="40px">
              <Heading w="full" fontSize="2xl" mb="10px">
                System Specifications:
              </Heading>
              <HStack w="40vw" justifyContent="space-between">
                <HStack>
                  <Text fontWeight="bold">Panel Type:</Text>
                  <Text textDecoration="underline">-</Text>
                </HStack>
                <HStack>
                  <Text fontWeight="bold">KW/h:</Text>
                  <Text textDecoration="underline">-</Text>
                </HStack>
              </HStack>
              <HStack w="40vw" justifyContent="space-between">
                <HStack>
                  <Text fontWeight="bold"># of Panels:</Text>
                  <Text textDecoration="underline">-</Text>
                </HStack>
                <HStack>
                  <Text fontWeight="bold">Inverter Type:</Text>
                  <Text textDecoration="underline">-</Text>
                </HStack>
              </HStack>
            </VStack>
            <VStack w="100%" alignItems="flex-start" mt="40px">
              <Heading w="full" fontSize="2xl" mb="10px">
                Financing:
              </Heading>
              <HStack w="40vw" justifyContent="space-between">
                <HStack>
                  <Text fontWeight="bold">Loan Amount:</Text>
                  <Text textDecoration="underline">-</Text>
                </HStack>
                <HStack>
                  <Text fontWeight="bold">APR:</Text>
                  <Text textDecoration="underline">-</Text>
                </HStack>
              </HStack>
              <HStack w="40vw" justifyContent="space-between">
                <HStack>
                  <Text fontWeight="bold">Loan Term:</Text>
                  <Text textDecoration="underline">-</Text>
                </HStack>
                <HStack>
                  <Text fontWeight="bold">Monthly Payment:</Text>
                  <Text textDecoration="underline">-</Text>
                </HStack>
              </HStack>
              <HStack w="40vw" justifyContent="space-between">
                <HStack>
                  <Text fontWeight="bold">ROI:</Text>
                  <Text textDecoration="underline">-</Text>
                </HStack>
              </HStack>
            </VStack>
            <VStack mb="20px" mt="40px">
              <Text w="full" fontSize="18px" fontWeight="bold">
                Attached Documents
              </Text>
              <HStack w="40vw" bgColor="gray.100" borderRadius="6px" p="20px">
                <Text bgColor="white" pl="10px" pr="10px" border="1px">
                  Lease Contract 1 X
                </Text>
                <Text bgColor="white" pl="10px" pr="10px" border="1px">
                  Installation Contract 1 X
                </Text>
              </HStack>
            </VStack>
          </VStack> */}
          <HStack>
            <Button>Send To Utility</Button>
            <Button>Get Permits</Button>
          </HStack>
        </VStack>
      )}
    </Box>
  );
};

export default ClientProjectDetails;
