import React, { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { Mail, Lock, ChevronRight, User, Phone } from 'lucide-react';
import { Auth } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { useAuth as useAuthentication } from '../../Providers/AuthenticationProvider';
import { useAuth } from '../../Providers/AuthProvider';
import { useCreateUser } from "../../hooks/userHooks";
import { useUserDataContext } from '../../Providers/LeadDataProvider';
import { UserUpdateOptions } from '../../API';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';

const SignUpPage = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [error, setError] = useState('');
  const [isVerificationOpen, setIsVerificationOpen] = useState(false);
  const auth = useAuth();
  const { checkAuth } = useAuthentication();
  const navigate = useNavigate();
  const toast = useToast();

  const { userData } = useUserDataContext();

  const { createUser, data: createUserData, loading: loadingUser, error: createUserError } = useCreateUser();

  const formatPhoneNumber = (value) => {
    const cleaned = value.replace(/\D/g, "");
    const formatted = cleaned.startsWith("1") ? `+${cleaned}` : `+1${cleaned}`;
    return formatted;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Add validation here
    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    try {
      await Auth.signUp({
        username: formData.email,
        password: formData.password,
        attributes: {
          email: formData.email,
          phone_number: formatPhoneNumber(formData.phone),
          "custom:user_id": uuidv4(),
          "custom:first_name": formData.firstName,
          "custom:last_name": formData.lastName,
        },
      });


    setIsVerificationOpen(true);
  
    } catch (error) {
      setError(error.message);
      console.error("Error during sign-up:", error);
    }
  };

  const handleVerification = async (code) => {
    // Verify code
    console.log('Verifying code:', code);
    // setIsLoading(true); 
    try {
      await Auth.confirmSignUp(formData.email, code);

      const cognitoUser = await Auth.signIn(formData.email, formData.password);
      auth.setCognitoUser(cognitoUser);

      userData.id = cognitoUser.attributes.sub;
      userData.cognito_id = cognitoUser.attributes.sub;
      userData.first_name = cognitoUser.attributes["custom:first_name"];
      userData.last_name = cognitoUser.attributes["custom:last_name"];
      userData.email = cognitoUser.attributes.email;
      userData.phone_number = cognitoUser.attributes.phone_number;
      userData.updated_by = UserUpdateOptions.DYNAMO;

      if(userData && userData.google_solar_object){
        delete userData.google_solar_object
      }

      createUser({
        variables: {
          input: userData,
        },
      });

      checkAuth();

      navigate('/LandingPage'); // Navigate to the landing page or dashboard
    } catch (error) {
      console.error('Error confirming sign up', error);
      toast({
        title: 'Error verifying email',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } finally {
      // setIsLoading(false); 
    }
    setIsVerificationOpen(false);
  };

  const resendCode = async () => {
    try {
      await Auth.resendSignUp(formData.email);
      console.log('Verification code resent successfully');
      toast({
        title: 'Verification code resent',
        description: "Check your email for the new code",
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
    } catch (err) {
      toast({
        title: "Couldn't resend verification",
        description: "Try refreshing the page",
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
      console.error('Error resending code: ', err);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div className="min-h-screen bg-[#121212] flex items-center justify-center px-4 py-12">
      <div className="w-full max-w-md">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-8"
        >
          <h1 className="text-4xl text-blue-50 mb-2 font-bold">
            Create Account
          </h1>
          <p className="text-blue-50/70">
            Start your solar journey with Klaravia
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="bg-white/5 backdrop-blur-sm rounded-lg p-8 shadow-xl"
        >
               <form onSubmit={handleSubmit} className="space-y-4">
           <div className="grid grid-cols-2 gap-4">
             <div>
               {/* <label className="block text-blue-50/70 text-sm mb-2">
                 First Name
               </label> */}
               <div className="relative">
                 <User className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-50/50 w-5 h-5" />
                 <input
                   type="text"
                   name="firstName"
                   value={formData.firstName}
                   onChange={handleChange}
                   className="w-full bg-white/5 border border-white/10 rounded-lg pl-10 pr-4 py-3 text-blue-50 placeholder-blue-50/30 focus:outline-none focus:border-amber-500"
                   placeholder="First name"
                 />
               </div>
             </div>

             <div>
               {/* <label className="block text-blue-50/70 text-sm mb-2">
                 Last Name
               </label> */}
               <div className="relative">
                 <User className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-50/50 w-5 h-5" />
                 <input
                   type="text"
                   name="lastName"
                   value={formData.lastName}
                   onChange={handleChange}
                   className="w-full bg-white/5 border border-white/10 rounded-lg pl-10 pr-4 py-3 text-blue-50 placeholder-blue-50/30 focus:outline-none focus:border-amber-500"
                   placeholder="Last name"
                 />
               </div>
             </div>
           </div>

           <div>
             {/* <label className="block text-blue-50/70 text-sm mb-2">
               Phone Number
             </label> */}
             <div className="relative">
               <Phone className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-50/50 w-5 h-5" />
               <input
                 type="tel"
                 name="phone"
                 value={formData.phone}
                 onChange={handleChange}
                 className="w-full bg-white/5 border border-white/10 rounded-lg pl-10 pr-4 py-3 text-blue-50 placeholder-blue-50/30 focus:outline-none focus:border-amber-500"
                 placeholder="(123) 456-7890"
               />
             </div>
           </div>

           <div>
             {/* <label className="block text-blue-50/70 text-sm mb-2">
               Email
             </label> */}
             <div className="relative">
               <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-50/50 w-5 h-5" />
               <input
                 type="email"
                 name="email"
                 value={formData.email}
                 onChange={handleChange}
                 className="w-full bg-white/5 border border-white/10 rounded-lg pl-10 pr-4 py-3 text-blue-50 placeholder-blue-50/30 focus:outline-none focus:border-amber-500"
                 placeholder="Enter your email"
               />
             </div>
           </div>

           <div>
             {/* <label className="block text-blue-50/70 text-sm mb-2">
               Password
             </label> */}
             <div className="relative">
               <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-50/50 w-5 h-5" />
               <input
                 type="password"
                 name="password"
                 value={formData.password}
                 onChange={handleChange}
                 className="w-full bg-white/5 border border-white/10 rounded-lg pl-10 pr-4 py-3 text-blue-50 placeholder-blue-50/30 focus:outline-none focus:border-amber-500"
                 placeholder="Create password"
               />
             </div>
           </div>

           <div>
             {/* <label className="block text-blue-50/70 text-sm mb-2">
               Confirm Password
             </label> */}
             <div className="relative">
               <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-50/50 w-5 h-5" />
               <input
                 type="password"
                 name="confirmPassword"
                 value={formData.confirmPassword}
                 onChange={handleChange}
                 className="w-full bg-white/5 border border-white/10 rounded-lg pl-10 pr-4 py-3 text-blue-50 placeholder-blue-50/30 focus:outline-none focus:border-amber-500"
                 placeholder="Confirm password"
               />
             </div>
           </div>

           {error && (
             <div className="text-red-400 text-sm">
               {error}
             </div>
           )}

           <button
             type="submit"
             className="w-full bg-amber-500 text-[#2b2b34] py-3 rounded-lg font-semibold hover:bg-amber-400 transition-colors flex items-center justify-center group"
           >
             Create Account
             <ChevronRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />
           </button>
         </form>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
          className="text-center mt-6"
        >
          <p className="text-blue-50/50 text-sm">
            Already have an account?{' '}
            <a href="/log-in" className="text-amber-500 hover:text-amber-400">
              Sign in
            </a>
          </p>
        </motion.div>
      </div>

      <VerificationModal 
        isOpen={isVerificationOpen}
        onVerify={handleVerification}
        onClose={() => setIsVerificationOpen(false)}
        resendCode={resendCode}
      />
    </div>
  );
};

const VerificationModal = ({ isOpen, onVerify, onClose, resendCode }) => {
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const [error, setError] = useState('');
  const inputs = useRef([]);

  const handleChange = (value, index) => {
    if (isNaN(value)) return;
    
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    if (value !== '' && index < 5) {
      inputs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !code[index] && index > 0) {
      inputs.current[index - 1].focus();
    }
  };

  const handleSubmit = () => {
    const verificationCode = code.join('');
    if (verificationCode.length !== 6) {
      setError('Please enter all 6 digits');
      return;
    }
    onVerify(verificationCode);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        className="bg-[#2b2b34] rounded-lg p-8 max-w-md w-full mx-4"
      >
        <h2 className="text-2xl font-bold text-blue-50 mb-2 text-center">
          Enter Verification Code
        </h2>
        <p className="text-blue-50/70 text-center mb-8">
          Enter the 6-digit code we sent to your email
        </p>

        <div className="flex justify-center gap-2 mb-8">
          {code.map((digit, index) => (
            <input
              key={index}
              ref={el => inputs.current[index] = el}
              type="text"
              maxLength={1}
              value={digit}
              onChange={e => handleChange(e.target.value, index)}
              onKeyDown={e => handleKeyDown(e, index)}
              className="w-12 h-12 text-center bg-white/5 border border-white/10 rounded-lg text-blue-50 text-xl font-bold focus:outline-none focus:border-amber-500"
            />
          ))}
        </div>

        {error && (
          <div className="text-red-400 text-sm text-center mb-4">
            {error}
          </div>
        )}

        <button
          onClick={handleSubmit}
          className="w-full bg-amber-500 text-[#2b2b34] py-3 rounded-lg font-semibold hover:bg-amber-400 transition-colors"
        >
          Verify Code
        </button>

        <button
          onClick={onClose}
          className="w-full mt-4 text-blue-50/70 hover:text-blue-50 transition-colors"
        >
          Cancel
        </button>

        <div className="mt-6 text-center">
          <button className="text-amber-500 hover:text-amber-400 text-sm" onClick={resendCode}>
            Resend Code
          </button>
        </div>
      </motion.div>
    </div>
  );
};

export default SignUpPage;