import React from "react";
import ReactDOM from "react-dom/client";
import { Amplify } from "aws-amplify";
import Router from "./Router";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import ClientAddressProvider from "./Providers/GuestAddressProvider";
import { AuthProvider } from "./Providers/AuthProvider";
import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink, ApolloLink } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import SolarReadyDataProvider from "./Providers/SolarReadyProvider";
import LeadDataProvider from "./Providers/LeadDataProvider";
import { createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import AuthenticationProvider from "./Providers/AuthenticationProvider";
import theme from "./klaraivaTheme";
import "./index.css";

const url = process.env.REACT_APP_GRAPHQL_ENDPOINT || "";
const region = "us-east-2";

// Explicitly type the auth object
const auth: { type: "API_KEY"; apiKey: string } = {
  type: "API_KEY", // or your auth type (API_KEY, AWS_IAM, AMAZON_COGNITO_USER_POOLS, etc.)
  apiKey: process.env.REACT_APP_APPSYNC_KEY || "",
};

const httpLink = createHttpLink({ uri: url });

const link = ApolloLink.from([createAuthLink({ url, region, auth }), createSubscriptionHandshakeLink({ url, region, auth }, httpLink)]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

// uncomment before deploy
const config = {
  aws_project_region: process.env.REACT_APP_AMPLIFY_REGION,
  aws_cognito_region: process.env.REACT_APP_AMPLIFY_REGION,
  aws_user_pools_id: process.env.REACT_APP_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_USER_POOLS_WEB_CLIENT_ID,
  oauth: {},
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: [],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: ["REQUIRES_LOWERCASE", "REQUIRES_UPPERCASE", "REQUIRES_NUMBERS", "REQUIRES_SYMBOLS"],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.REACT_APP_AMPLIFY_REGION,
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: process.env.REACT_APP_APPSYNC_KEY,
  Storage: {
    AWSS3: {
      bucket: "installer-cover-images",
      region: process.env.REACT_APP_AMPLIFY_REGION,
      isObjectLockEnabled: true,
    },
  },
};

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ChakraProvider theme={theme}>
        <AuthenticationProvider>
          <LeadDataProvider>
            <AuthProvider>
              <SolarReadyDataProvider>
                <Router />
              </SolarReadyDataProvider>
            </AuthProvider>
          </LeadDataProvider>
        </AuthenticationProvider>
      </ChakraProvider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
Amplify.configure(config);
reportWebVitals();
