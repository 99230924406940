import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { 
  Sun, Battery, Leaf, DollarSign, Zap, 
  Calendar, TrendingUp, Home, CloudSun,
  Shield, ChevronRight, Moon, Check, InfoIcon,
  TriangleAlert,
  CheckCircle, Minus, Plus
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { HStack, Text, Box } from '@chakra-ui/react';
import { useUserDataContext } from '../../Providers/LeadDataProvider';

const fadeInUpVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 }
};

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.1
    }
  }
};

const PanelAdjustmentControls = ({ 
  numberOfPanels, 
  basePanels,
  panelAdjustment,
  onAdjustPanels 
}) => {
  return (
    <motion.div
      variants={fadeInUpVariants}
      className="bg-white/5 rounded-lg p-6 border border-white/10 mb-8"
    >
      <div className="flex justify-between items-center mb-4">
        <div>
          <h3 className="text-xl font-semibold text-blue-50">System Size</h3>
          <p className="text-blue-50/70">Adjust the number of panels to meet your goals</p>
        </div>
        <div className="flex items-center space-x-4">
          <button
            onClick={() => onAdjustPanels(-1)}
            className="p-2 rounded-lg bg-white/10 hover:bg-white/20 text-blue-50 transition-colors"
            disabled={numberOfPanels <= Math.ceil(basePanels * 0.25)}
          >
            <Minus className="w-5 h-5" />
          </button>
          <div className="text-2xl font-bold text-blue-50">{numberOfPanels}</div>
          <button
            onClick={() => onAdjustPanels(1)}
            className="p-2 rounded-lg bg-white/10 hover:bg-white/20 text-blue-50 transition-colors"
          >
            <Plus className="w-5 h-5" />
          </button>
        </div>
      </div>
      <div className="flex items-center justify-between text-sm text-blue-50/70">
        <span>Recommended: {basePanels} panels</span>
        <span>{panelAdjustment > 0 ? '+' : ''}{panelAdjustment} panels from recommendation</span>
      </div>
    </motion.div>
  );
};

const SystemResultsPage = ({ 
  systemSize,
  annualConsumption,
  annualProduction,
  numberOfPanels,
  offset,
  estimatedSavings,
  monthlyBill,
  monthlySavings,
  paybackPeriod,
  environmentalImpact,
  monthlyProduction,
  basePanels,
  panelAdjustment,
  onAdjustPanels
}) => {
  const [activeTab, setActiveTab] = useState('overview');
  const [includeBattery, setIncludeBattery] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setUserData } = useUserDataContext();

  const handleProceedToInstallerSelect = () => {
    setUserData((prevUserData) => {
      if (
        JSON.stringify(prevUserData) !==
        JSON.stringify({
          ...prevUserData,
          google_system_size: systemSize,
          requires_battery: includeBattery,
        })
      ) {
        return {
          ...prevUserData,
          google_system_size: systemSize,
          requires_battery: includeBattery,
        };
      }
      return prevUserData;
    });
    navigate("/installer-select");
  };

  const maxValue = Math.max(...monthlyProduction.map(m => m.value));


  // Calculate savings metrics
  const yearlySavings = (monthlyBill - monthlySavings) * 12;
  const monthsSinceStart = 0;
  const totalSavedSoFar = monthsSinceStart * (monthlyBill - monthlySavings);

  return (
    <section className="min-h-screen bg-[#121212] px-4 py-28">
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="max-w-6xl mx-auto"
      >
        {/* Header Section */}
        <motion.div variants={fadeInUpVariants} className="text-center mb-8">
          <h1 className="text-4xl md:text-4xl font-bold text-blue-50 mb-4">
            Your Preliminary System Breakdown
          </h1>
          <p className="text-lg text-blue-50/70 max-w-3xl mx-auto">
            We've analyzed your energy needs and designed a custom solar solution 
            that will transform your home into a clean energy powerhouse.
          </p>
        </motion.div>

        {/* Navigation Tabs */}
        <motion.div variants={fadeInUpVariants} className="flex justify-start sm:justify-center mb-12 overflow-x-auto ">
          <div className="bg-white/5 rounded-lg p-1 inline-flex">
            {['Overview', 'Savings', 'Battery', 'Environmental'].map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab.toLowerCase())}
                className={`px-6 py-2 rounded-lg transition-all duration-300 ${
                  activeTab === tab.toLowerCase()
                    ? 'bg-amber-500 text-[#121212]'
                    : 'text-blue-50/70 hover:text-blue-50'
                }`}
              >
                {tab}
              </button>
            ))}
          </div>
        </motion.div>

        <motion.div
  variants={fadeInUpVariants}
  className="bg-blue-500/10 backdrop-blur-sm rounded-lg p-4 mb-4 border border-blue-500/20"
>
  <div className="flex items-start space-x-4">
    <InfoIcon className="w-6 h-6 text-blue-400 flex-shrink-0 mt-1" />
    <div>
      <h4 className="text-blue-50 font-medium mb-1">Custom Analysis for Your Home</h4>
      <p className="text-blue-50/70 text-sm">
        These estimates are based on your address, electricity usage, and regional solar data. 
        Final system specifications and savings will be confirmed by your chosen installation partner.
      </p>
    </div>
  </div>
</motion.div>

{monthlySavings < 0 && (
  <motion.div
  variants={fadeInUpVariants}
  className="bg-red-500/10 backdrop-blur-sm rounded-lg p-4 mb-8 border border-red-500/20"
>
  <div className="flex items-start space-x-4">
    <TriangleAlert className="w-6 h-6 text-red-400 flex-shrink-0 mt-1" />
    <div>
      <h4 className="text-red-50 font-medium mb-1">This system may not lower your monthly payment</h4>
      <p className="text-red-50/70 text-sm">
        Based off our initial estimates, it appears this system will not lower your monthly payment. You may need to adjust the system to meet your needs. Please contact us if you have any questions.
      </p>
    </div>
  </div>
</motion.div>
)}

        {/* Overview Section */}
        {activeTab === 'overview' && (
  <div className="space-y-8">
    <PanelAdjustmentControls 
      numberOfPanels={numberOfPanels}
      basePanels={basePanels}
      panelAdjustment={panelAdjustment}
      onAdjustPanels={onAdjustPanels}
    />

    {/* Primary Impact Metrics */}
    <div className="grid md:grid-cols-3 gap-6">
      {[
        {
          icon: <DollarSign className="w-8 h-8" />,
          label: "Monthly Bill",
          value: monthlyBill - monthlySavings,
          unit: "$/mo",
          description: `Down from $${monthlyBill}/mo`,
          color: "text-green-400"
        },
        {
          icon: <Sun className="w-8 h-8" />,
          label: "System Size",
          value: systemSize,
          unit: "kW",
          description: `${numberOfPanels} Solar Panels`,
          color: "text-amber-500"
        },
        {
          icon: <Leaf className="w-8 h-8" />,
          label: "Carbon Offset",
          value: environmentalImpact.co2Reduced,
          unit: "tons/yr",
          description: "Carbon reduction",
          color: "text-blue-400"
        }
      ]
      .map((metric, index) => {
        if(metric.label === "Monthly Bill" && (monthlySavings < 0)){
          return (
            <motion.div
            variants={fadeInUpVariants}
            className="bg-red-500/10 rounded-lg p-6 border border-red-500/50"
          >
            <div className="flex items-center space-x-3 mb-4">
              <div className="text-red-400"><DollarSign className="w-8 h-8" /></div>
              <h3 className="text-red-50/90 font-medium">Additional Monthly Cost</h3>
            </div>
            <div className="text-3xl font-bold text-red-400 mb-2">
              +{(monthlySavings * -1).toLocaleString()}
              <span className="text-lg text-red-300/70 ml-1">$/mo</span>
              <span className="text-xs text-red-300/50 ml-2">Estimated</span>
            </div>
            <p className="text-sm text-red-300/50">${monthlyBill - monthlySavings}/mo up from ${monthlyBill}/mo</p>
          </motion.div>
          )
        } else {
        return (
        <motion.div
          key={index}
          variants={fadeInUpVariants}
          className="bg-white/5 rounded-lg p-6 border border-white/10 relative"
        >
        <EstimateTooltip text="Preliminary estimate based on your location and usage. Final values may vary based on detailed site assessment." />
          <div className="flex items-center space-x-3 mb-4">
            <div className={`${metric.color}`}>{metric.icon}</div>
            <h3 className="text-blue-50/90 font-medium">{metric.label}</h3>
          </div>
          <div className="text-3xl font-bold text-blue-50 mb-2">
            {metric.value.toLocaleString()}
            <span className="text-lg text-blue-50/70 ml-1">{metric.unit}</span>
            <span className="text-xs text-blue-50/50 ml-2">Estimated</span>
          </div>
          <p className="text-sm text-blue-50/50">{metric.description}</p>
        </motion.div>
      )}})}
    </div>

    {/* Energy Independence Section */}
    <div className="grid md:grid-cols-2 gap-8">
      <motion.div
        variants={fadeInUpVariants}
        className="bg-white/5 rounded-lg p-6 border border-white/10"
      >
        <h3 className="text-xl font-semibold text-blue-50 mb-6">Energy Independence</h3>
        <div className="space-y-2 mb-4">
          <div className="relative h-4 bg-white/10 rounded-3xl overflow-hidden">
            <motion.div
              initial={{ width: 0 }}
              animate={{ width: `${(Math.min(offset, 150) / 150) * 100}%` }}
              transition={{ duration: 1.5 }}
              className="h-full bg-gradient-to-r from-amber-500 to-amber-400 rounded-3xl"
            >
              <span className="absolute inset-0 flex items-center justify-center text-xs font-bold">
                {offset}%
              </span>
            </motion.div>
          </div>
          <div className="flex justify-between text-sm text-blue-50/50 px-1">
            <span>0%</span>
            <span>50%</span>
            <span>100%</span>
            <span>150%</span>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 text-center">
          <div>
            <p className="text-3xl font-bold text-blue-50 mb-1">
              {annualProduction.toLocaleString()}
            </p>
            <p className="text-sm text-blue-50/50">kWh/Year Produced</p>
          </div>
          <div>
            <p className="text-3xl font-bold text-blue-50 mb-1">
              {annualConsumption.toLocaleString()}
            </p>
            <p className="text-sm text-blue-50/50">kWh/Year Needed</p>
          </div>
        </div>
      </motion.div>

      <motion.div
        variants={fadeInUpVariants}
        className="bg-white/5 rounded-lg p-6 border border-white/10"
      >
        <h3 className="text-xl font-semibold text-blue-50 mb-6">Financial Impact</h3>
        <div className="flex items-end space-x-4 mb-6">
        <div>
            <p className="text-sm text-blue-50/50 mb-1">Payback Period</p>
            <p className="text-3xl font-bold text-blue-500/80">{paybackPeriod === 99 ? `${paybackPeriod}+` : paybackPeriod} Years</p>
          </div>
          <div className="text-blue-50/50 mb-2">with</div>
          <div>
            <p className="text-sm text-blue-50/50 mb-1">25-Year Savings</p>
            <p className="text-3xl font-bold text-green-500/80">
              ${estimatedSavings.toLocaleString()}
            </p>
          </div>
        </div>
        <div className="h-2 bg-green-500/60 rounded-3xl overflow-hidden mb-2">
          <motion.div
            initial={{ width: 0 }}
            animate={{ width: `${(paybackPeriod / 25) * 100}%` }}
            transition={{ duration: 1.5 }}
            className="h-full bg-gradient-to-r from-blue-500 to-blue-400 rounded-3xl"
          />
        </div>
        <p className="text-sm text-blue-50/50">
          System pays for itself in {paybackPeriod.toFixed(1)} years, providing free energy for {(25 - paybackPeriod).toFixed(1)} more years
        </p>
      </motion.div>
    </div>

    {/* Monthly Production Chart */}
    <motion.div
      variants={fadeInUpVariants}
      className="bg-white/5 rounded-lg p-6 border-2 border-white/10"
    >
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-xl font-semibold text-blue-50">Annual Production <span className="text-xs text-blue-50/50 ml-2">Estimated</span></h3>
        <div className="flex items-center space-x-2">
          <span className="text-sm text-blue-50/50">Peak Month:</span>
          <span className="text-sm font-semibold text-amber-500">
            {monthlyProduction.reduce((max, month) => 
              month.value > max.value ? month : max
            ).month}
          </span>
        </div>
      </div>
      <div className="h-64">
        <div className="h-full flex items-end justify-between gap-1">
          {monthlyProduction.map((month, index) => (
            <div key={index} className="flex flex-col items-center flex-1">
              <motion.div
                initial={{ height: 0 }}
                animate={{ height: `${(month.value / Math.max(...monthlyProduction.map(m => m.value))) * 200}px` }}
                transition={{ duration: 1, delay: index * 0.1 }}
                className="w-full bg-gradient-to-t from-amber-500 to-amber-400 rounded-t relative group border-2"
              >
                <div className="w-full text-center absolute -top-8 left-1/2 transform -translate-x-1/2 bg-white/10 backdrop-blur-md px-2 py-1 rounded text-xs text-blue-50 opacity-0 group-hover:opacity-100 transition-opacity">
                  {month.value.toLocaleString()} kWh
                </div>
              </motion.div>
              <div className="text-xs text-blue-50/50 mt-2">{month.month}</div>
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  </div>
)}

{activeTab === 'savings' && (
  <div className="space-y-8">
    {/* Key Savings Metrics */}
    <div className="grid md:grid-cols-3 gap-6">
      {[
        {
          icon: <DollarSign className="w-6 h-6" />,
          label: "Monthly Savings",
          value: monthlySavings,
          unit: "$/mo",
          description: "Average reduction in monthly bills",
          color: "text-green-400"
        },
        {
          icon: <Calendar className="w-6 h-6" />,
          label: "Annual Savings",
          value: (monthlyBill - monthlySavings) * 12,
          unit: "$/yr",
          description: "Total yearly savings",
          color: "text-blue-400"
        },
        {
          icon: <TrendingUp className="w-6 h-6" />,
          label: "25-Year Savings",
          value: estimatedSavings,
          unit: "",
          description: "Lifetime system savings",
          color: "text-purple-400"
        }
      ].map((metric, index) => {
        if(metric.label === "Monthly Savings" && (monthlySavings < 0)){
          return (
            <motion.div
            variants={fadeInUpVariants}
            className="bg-red-500/10 rounded-lg p-6 border border-red-500/50"
          >
            <div className="flex items-center space-x-3 mb-4">
              <div className="text-red-400"><DollarSign className="w-8 h-8" /></div>
              <h3 className="text-red-50/90 font-medium">Additional Monthly Cost</h3>
            </div>
            <div className="text-3xl font-bold text-red-400 mb-2">
              +{(monthlySavings * -1).toLocaleString()}
              <span className="text-lg text-red-300/70 ml-1">$/mo</span>
              <span className="text-xs text-red-300/50 ml-2">Estimated</span>
            </div>
            <p className="text-sm text-red-300/50">Up from ${monthlyBill}/mo</p>
          </motion.div>
          )
        } else {
        return (
        <motion.div
          key={index}
          variants={fadeInUpVariants}
          className="bg-white/5 rounded-lg p-6 border border-white/10 relative"
        >
        <EstimateTooltip text="Preliminary estimate based on your location and usage. Final values may vary based on detailed site assessment." />
          <div className="flex items-center space-x-3 mb-4">
            <div className={`${metric.color}`}>{metric.icon}</div>
            <h3 className="text-blue-50/90 font-medium">{metric.label}</h3>
          </div>
          <div className="text-3xl font-bold text-blue-50 mb-2">
            ${metric.value.toLocaleString()}
            <span className="text-lg text-blue-50/70 ml-1">{metric.unit}</span>
            <span className="text-xs text-blue-50/50 ml-2">Estimated</span>
          </div>
          <p className="text-sm text-blue-50/50">{metric.description}</p>
        </motion.div>
      )}})}
    </div>

    {/* Bill Comparison */}
    <motion.div
      variants={fadeInUpVariants}
      className="bg-white/5 rounded-lg p-6 border border-white/10"
    >
      <h3 className="text-blue-50 font-medium mb-6">Monthly Bill Comparison</h3>
      <div className="grid md:grid-cols-2 gap-8">
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <span className="text-blue-50/70 text-xs">Current Monthly Bill</span>
            <div className=" h-8 flex items-center">
              <motion.div
                initial={{ width: 0 }}
                animate={{ width: '150px' }}
                transition={{ duration: 1 }}
                className="bg-blue-500/50 h-full rounded-lg px-3 flex items-center"
              >
                <span className="text-blue-50 font-semibold">${monthlyBill}</span>
              </motion.div>
            </div>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-blue-50/70 text-xs">Solar Monthly Bill <span className="text-xs text-blue-50/50 ml-2">Estimated</span></span>
            {monthlySavings < 0 && (
              <div className=" h-8 flex items-center">
              <motion.div
                initial={{ width: 0 }}
                animate={{ width: `${((-monthlySavings + monthlyBill) / monthlyBill) * 150}px` }}
                transition={{ duration: 1 }}
                className="bg-amber-500/50 h-full rounded-lg px-3 flex items-center"
              >
                <span className="text-blue-50 font-semibold">${-monthlySavings + monthlyBill}</span>
              </motion.div>
            </div>
            )}
            {monthlySavings > 0 && (
            <Box display='flex' className="w-1/2 h-8 items-center" justifyContent='flex-end'>
              <motion.div
                initial={{ width: 0 }}
                animate={{ width: `${(monthlySavings / (monthlyBill - monthlySavings)) * 150}px` }}
                transition={{ duration: 1 }}
                className="bg-amber-500/50 h-full rounded-lg px-3 flex items-center"
              >
                <span className="text-blue-50 font-semibold">${monthlyBill - monthlySavings}</span>
              </motion.div>
            </Box>
            )}
          </div>
        </div>
        {monthlySavings < 0 && (
        <div className="bg-white/5 rounded-lg p-6">
        <h4 className="text-blue-50 font-medium mb-2">Monthly Cost</h4>
        <div className="text-4xl font-bold text-red-400">
          ${(monthlyBill - monthlySavings).toLocaleString()}
          <span className="text-xs text-blue-50/50 ml-2">Estimated</span>
        </div>
        <p className="text-sm text-blue-50/50 mt-2">
          That's a {Math.round(((monthlyBill - monthlySavings) / monthlyBill) * 100) - 100}% addition to your monthly energy costs
        </p>
      </div>
        )}
        {monthlySavings > 0 && (
                  <div className="bg-white/5 rounded-lg p-6">
                  <h4 className="text-blue-50 font-medium mb-2">Monthly Savings</h4>
                  <div className="text-4xl font-bold text-amber-500">
                    ${(monthlySavings).toLocaleString()}
                    <span className="text-xs text-blue-50/50 ml-2">Estimated</span>
                  </div>
                  <p className="text-sm text-blue-50/50 mt-2">
                    That's a {Math.round(100 - ((monthlyBill - monthlySavings) * 100) / monthlyBill)}% reduction in your monthly energy costs
                  </p>
                </div>
        )}
      </div>
    </motion.div>

    {/* Investment Returns */}
    <div className="grid md:grid-cols-2 gap-8">
    <motion.div variants={fadeInUpVariants} className="bg-white/5 rounded-lg p-6 border border-white/10">
      <h3 className="text-blue-50 font-medium mb-4">Payback Period <span className="text-xs text-blue-50/50 ml-2">Estimated</span></h3>
      <div className="space-y-2">
        <div className="relative h-4 bg-green-500/30 rounded-3xl overflow-hidden">
          <motion.div
            initial={{ width: 0 }}
            animate={{ width: `${(paybackPeriod / 25) * 100}%` }}
            transition={{ duration: 1 }}
            className="absolute h-full bg-gradient-to-r from-amber-500 to-amber-400 rounded-xl"
          >
            <div 
              className="absolute text-xs font-medium"
              style={{ 
                left: '50%', 
                transform: 'translateX(-50%)',
                whiteSpace: 'nowrap',
                top: '0px', // Adjust to position below the bar
                fontWeight: 'bold'
              }}
            >
              {paybackPeriod} Years
            </div>
          </motion.div>
        </div>
        <div className="flex justify-between text-sm text-blue-50/50 px-1">
          <span>Today</span>
          <span>12.5 Years</span>
          <span>25 Years</span>
        </div>
      </div>
      <p className="text-sm text-blue-50/70 mt-4">
        Your system pays for itself in {paybackPeriod.toFixed(1)} years, providing free energy for the rest of its life
      </p>
    </motion.div>

      <motion.div
        variants={fadeInUpVariants}
        className="bg-white/5 rounded-lg p-6 border border-white/10"
      >
        <h3 className="text-blue-50 font-medium mb-4">Return on Investment <span className="text-xs text-blue-50/50 ml-2">Estimated</span></h3>
        <div className="space-y-4">
          <div className="flex justify-between">
            <span className="text-blue-50/70">Total Investment</span>
            <span className="text-blue-50 font-semibold">$20,000</span>
          </div>
          <div className="flex justify-between">
            <span className="text-blue-50/70">Tax Credit (30%)</span>
            <span className="text-green-400 font-semibold">-$6,000</span>
          </div>
          <div className="flex justify-between">
            <span className="text-blue-50/70">Net Cost</span>
            <span className="text-blue-50 font-semibold">$14,000</span>
          </div>
          <div className="border-t border-white/10 pt-4">
            <div className="flex justify-between">
              <span className="text-blue-50/70">25-Year Return</span>
              <span className="text-amber-500 font-semibold">${estimatedSavings.toLocaleString()}</span>
            </div>
          </div>
        </div>
      </motion.div>
    </div>

    {/* Additional Savings Info */}
    <motion.div
      variants={fadeInUpVariants}
      className="bg-white/5 rounded-lg p-6 border border-white/10"
    >
      <h3 className="text-blue-50 font-medium mb-6">Additional Financial Benefits</h3>
      <div className="grid md:grid-cols-3 gap-6">
        {[
          {
            icon: <Home className="w-6 h-6" />,
            title: "Property Value",
            description: "Average home value increase of 4.1%"
          },
          {
            icon: <Shield className="w-6 h-6" />,
            title: "Energy Security",
            description: "Protection against rising utility rates"
          },
          {
            icon: <Leaf className="w-6 h-6" />,
            title: "Green Incentives",
            description: "Additional state and local tax benefits"
          }
        ].map((benefit, index) => (
          <div key={index} className="flex items-start space-x-4">
            <div className="text-amber-500">{benefit.icon}</div>
            <div>
              <h4 className="text-blue-50 font-medium mb-1">{benefit.title}</h4>
              <p className="text-sm text-blue-50/70">{benefit.description}</p>
            </div>
          </div>
        ))}
      </div>
    </motion.div>
  </div>
)}

{activeTab === 'battery' && (
  <div className="space-y-8">
    {/* Battery System Toggle */}
    <motion.div
      variants={fadeInUpVariants}
      className="bg-white/5 rounded-lg p-6 border border-white/10"
    >
      <div className="flex justify-between items-center">
        <div>
          <h3 className="text-2xl font-bold text-blue-50">Add Battery Storage</h3>
          <p className="text-blue-50/70">Power your home day and night with stored solar energy</p>
        </div>
        <label className="relative inline-flex items-center cursor-pointer">
          <input 
            type="checkbox" 
            className="sr-only peer"
            checked={includeBattery}
            onChange={() => setIncludeBattery(!includeBattery)}
          />
          <div className="w-14 h-7 bg-white/10 peer-focus:outline-none rounded-3xl peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-3xl after:h-6 after:w-6 after:transition-all peer-checked:bg-amber-500"></div>
        </label>
      </div>
      {includeBattery && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white/5 rounded-lg p-4 mt-4"
        >
          <div className="grid md:grid-cols-3 gap-4 text-center">
            <div>
              <p className="text-blue-50/70 text-sm">Battery Capacity</p>
              <p className="text-2xl font-bold text-blue-50">13.5 kWh</p>
            </div>
            <div>
              <p className="text-blue-50/70 text-sm">Backup Duration</p>
              <p className="text-2xl font-bold text-blue-50">24+ Hours</p>
            </div>
            <div>
              <p className="text-blue-50/70 text-sm">Additional Cost</p>
              <p className="text-2xl font-bold text-amber-500">~ $8,000</p>
            </div>
          </div>
        </motion.div>
      )}
    </motion.div>

    {/* Key Benefits Grid */}
    <div className="grid md:grid-cols-3 gap-6">
      {[
        {
          icon: <Shield className="w-8 h-8" />,
          title: "Backup Power",
          description: "Keep your lights on and essential appliances running during grid outages",
          highlight: "Never lose power again"
        },
        {
          icon: <DollarSign className="w-8 h-8" />,
          title: "Peak Shifting",
          description: "Use stored energy during peak rate hours to maximize savings",
          highlight: "Save up to 40% more"
        },
        {
          icon: <Zap className="w-8 h-8" />,
          title: "Energy Independence",
          description: "Reduce reliance on the grid and increase self-consumption",
          highlight: "90% energy independence"
        }
      ].map((benefit, index) => (
        <motion.div
          key={index}
          variants={fadeInUpVariants}
          className="bg-white/5 rounded-lg p-6 border border-white/10 relative overflow-hidden"
        >
          <div className="text-amber-500 mb-4">{benefit.icon}</div>
          <h3 className="text-xl font-semibold text-blue-50 mb-2">{benefit.title}</h3>
          <p className="text-blue-50/70 text-sm mb-4">{benefit.description}</p>
          <div className="text-amber-500 text-sm font-semibold">{benefit.highlight}</div>
        </motion.div>
      ))}
    </div>

    {/* Power Flow Visualization */}
    <motion.div
      variants={fadeInUpVariants}
      className="bg-white/5 rounded-lg p-6 border border-white/10"
    >
      <h3 className="text-xl font-semibold text-blue-50 mb-6">How Battery Storage Works</h3>
      <div className="grid md:grid-cols-3 gap-8 items-center">
        <div className="space-y-6 text-center">
          <Sun className="w-12 h-12 text-amber-500 mx-auto" />
          <div>
            <h4 className="text-lg font-medium text-blue-50 mb-2">Day Time</h4>
            <p className="text-sm text-blue-50/70">Solar panels power your home and charge the battery during the day</p>
          </div>
        </div>
        <div className="space-y-6 text-center">
          <Battery className="w-12 h-12 text-green-500 mx-auto" />
          <div>
            <h4 className="text-lg font-medium text-blue-50 mb-2">Peak Hours</h4>
            <p className="text-sm text-blue-50/70">Your battery provides power during expensive peak rates and in times of need</p>
          </div>
        </div>
        <div className="space-y-6 text-center">
          <Moon className="w-12 h-12 text-blue-400 mx-auto" />
          <div>
            <h4 className="text-lg font-medium text-blue-50 mb-2">Night Time</h4>
            <p className="text-sm text-blue-50/70">Stored energy powers your home overnight when your panels aren't producing energy</p>
          </div>
        </div>
      </div>
    </motion.div>

    {/* Backup Protection */}
    <div className="grid md:grid-cols-2 gap-8">
      <motion.div
        variants={fadeInUpVariants}
        className="bg-white/5 rounded-lg p-6 border border-white/10"
      >
        <h3 className="text-xl font-semibold text-blue-50 mb-4">Protected During Outages</h3>
        <div className="space-y-3">
          {[
            "Refrigerator & Freezer",
            "Lights & Internet",
            "Air Conditioning",
            "Home Security System",
            "Phone & Computer Charging",
            "Essential Medical Equipment"
          ].map((item, index) => (
            <div key={index} className="flex items-center space-x-3">
              <Check className="w-5 h-5 text-green-500" />
              <span className="text-blue-50/90">{item}</span>
            </div>
          ))}
        </div>
      </motion.div>

      <motion.div
        variants={fadeInUpVariants}
        className="bg-white/5 rounded-lg p-6 border border-white/10"
      >
        <h3 className="text-xl font-semibold text-blue-50 mb-4">Return on Investment</h3>
        <div className="space-y-4">
          <div className="flex justify-between">
            <span className="text-blue-50/70">Federal Tax Credit</span>
            <span className="text-green-500 font-semibold">30% Included</span>
          </div>
          <div className="flex justify-between">
            <span className="text-blue-50/70">Additional Savings/Year</span>
            <span className="text-blue-50">$800 <span className="text-xs text-blue-50/50 ml-2">Estimated</span></span>
          </div>
          <div className="flex justify-between">
            <span className="text-blue-50/70">Payback Period</span>
            <span className="text-blue-50">7-9 Years</span>
          </div>
          <div className="pt-4 border-t border-white/10">
            <div className="flex justify-between">
              <span className="text-blue-50/70">25-Year Savings</span>
              <span className="text-amber-500 font-semibold">$12,000+</span>
            </div>
          </div>
        </div>
      </motion.div>
    </div>

    {/* CTA Section */}
    {includeBattery && (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-center bg-gradient-to-r from-green-500/20 to-green-600/20 rounded-lg p-8"
      >
        <h3 className="text-2xl font-bold text-blue-50 mb-4 flex flex-row justify-center items-center gap-2">
          Battery Added to Your System
          <CheckCircle />
        </h3>
        <p className="text-blue-50/70 mb-6">
          You've made a great choice! Your new system will include battery storage for maximum efficiency and protection.
        </p>
        <button className="bg-green-500 hover:bg-green-400 text-[#121212] px-8 py-3 rounded-lg font-semibold transition-colors" onClick={handleProceedToInstallerSelect}>
          View Updated Quote
        </button>
      </motion.div>
    )}
  </div>
)}

        {/* Environmental Impact Section */}
        {activeTab === 'environmental' && (
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                icon: <Leaf className='text-green-500' />,
                label: "Trees Planted",
                value: environmentalImpact.treesPlanted,
                description: "Equivalent annual impact",
                unit: "/year",
              },
              {
                icon: <CloudSun className='text-blue-500' />,
                label: "CO₂ Reduction",
                value: environmentalImpact.co2Reduced,
                unit: "tons/year",
                description: "Annual carbon offset"
              },
              {
                icon: <Home />,
                label: "Homes Powered",
                value: environmentalImpact.homesOffset,
                description: "Equivalent energy production",
                unit: "/year",
              }
            ].map((impact, index) => (
              <motion.div
                key={index}
                variants={fadeInUpVariants}
                className="bg-white/5 rounded-lg p-6 border border-white/10"
              >
                <div className="flex items-center space-x-3 mb-4">
                  <div className="text-amber-500">{impact.icon}</div>
                  <h3 className="text-blue-50/90 font-medium">{impact.label}</h3>
                </div>
                <div className="text-3xl font-bold text-blue-50 mb-2">
                  {impact.value.toLocaleString()}
                  {impact.unit && <span className="text-lg text-blue-50/70 ml-1">{impact.unit}</span>}
                </div>
                <p className="text-sm text-blue-50/50">{impact.description}</p>
              </motion.div>
            ))}
          </div>
        )}



<motion.div
  variants={fadeInUpVariants}
  className="bg-white/5 rounded-lg p-6 border border-white/10 my-12"
>
  <h3 className="text-xl font-semibold text-blue-50 mb-4">Next Steps</h3>
  <div className="grid md:grid-cols-3 gap-6">
    <div className="flex items-start space-x-3">
      <div className="w-8 h-8 rounded-full bg-white/10 flex items-center justify-center flex-shrink-0">
        <span className="text-blue-50 font-medium">1</span>
      </div>
      <div>
        <h4 className="text-blue-50 font-medium mb-1">Compare Installers</h4>
        <p className="text-sm text-blue-50/70">Review quotes from certified local installers</p>
      </div>
    </div>
    <div className="flex items-start space-x-3">
      <div className="w-8 h-8 rounded-full bg-white/10 flex items-center justify-center flex-shrink-0">
        <span className="text-blue-50 font-medium">2</span>
      </div>
      <div>
        <h4 className="text-blue-50 font-medium mb-1">Site Assessment</h4>
        <p className="text-sm text-blue-50/70">Get detailed analysis and final system design</p>
      </div>
    </div>
    <div className="flex items-start space-x-3">
      <div className="w-8 h-8 rounded-full bg-white/10 flex items-center justify-center flex-shrink-0">
        <span className="text-blue-50 font-medium">3</span>
      </div>
      <div>
        <h4 className="text-blue-50 font-medium mb-1">Final Quote</h4>
        <p className="text-sm text-blue-50/70">Receive exact pricing and production estimates</p>
      </div>
    </div>
  </div>
</motion.div>

    {/* Call to Action */}
    <motion.div
  variants={fadeInUpVariants}
  className="bg-gradient-to-r from-amber-500/20 to-amber-600/20 rounded-lg p-8 text-center"
>
  <h3 className="text-2xl font-bold text-blue-50 mb-4">
    Ready to Get Exact Numbers?
  </h3>
  <p className="text-blue-50/70 mb-6 max-w-2xl mx-auto">
    Compare quotes from vetted local installers who will provide detailed assessments 
    and final system designs specific to your home.
  </p>
  <div className="flex flex-col items-center space-y-6">
  {!includeBattery && (
      <button 
        onClick={() => {
          setActiveTab('battery');
          setIncludeBattery(true);
        }}
        className="w-full sm:1/3 bg-white/10 hover:bg-white/20 text-blue-50 px-8 py-3 rounded-lg font-semibold transition-colors"
      >
        Add Battery Storage
      </button>
    )}
    <button className="w-full sm:w-1/3 bg-amber-500 hover:bg-amber-400 text-[#121212] px-8 py-3 rounded-lg font-semibold transition-colors" onClick={handleProceedToInstallerSelect}>
      <HStack w='full' justifyContent='center'>
      <Text>Compare Installation Partners</Text>
      <ChevronRight />
      </HStack>
    </button>
    <p className="text-sm text-blue-50/50">
      No commitment required • Free consultation • Best price guaranteed
    </p>
  </div>
</motion.div>
      </motion.div>
    </section>
  );
};

export default SystemResultsPage;

const EstimateTooltip = ({ text }) => (
  <div className='absolute right-4 top-2'>
  <div className="group relative inline-block">
    <InfoIcon className="w-4 h-4 text-blue-50/30 inline ml-1" />
    <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 hidden group-hover:block w-48 p-2 bg-white/10 backdrop-blur-md rounded text-xs text-blue-50">
      {text}
    </div>
  </div>
  </div>
);