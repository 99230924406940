import { Box, VStack, Text, Heading, List, ListItem, ListIcon, Link } from "@chakra-ui/react";
import { InfoIcon, CheckCircleIcon } from "@chakra-ui/icons";

const PoorRoofCondition = () => {
  return (
    <Box w="full" bg="#f4eee9" minH="90vh" p={{ base: 4, md: 8 }}>
      <VStack w="full" spacing={{ base: 5, md: 8 }} align="start" pt="80px">
        <Heading as="h1" size={{ base: "md", md: "lg" }} textAlign={{ base: "center", md: "left" }} w="full">
          Roof Condition Notice
        </Heading>
        <Text fontSize={{ base: "md", md: "lg" }} textAlign={{ base: "center", md: "left" }} w="full">
          Unfortunately, if your roof is in poor condition, we won't be able to install solar panels.
        </Text>
        <Heading as="h2" size={{ base: "sm", md: "md" }} mt={5}>
          Why We Can't Install Solar on a Poor Roof
        </Heading>
        <Text>Installing solar panels on a roof that is in poor condition can lead to several issues:</Text>
        <List spacing={3} mt={3}>
          <ListItem>
            <ListIcon as={InfoIcon} color="red.500" />
            <Text as="span" fontWeight="bold">
              Structural Integrity:
            </Text>{" "}
            A weak or damaged roof may not be able to support the weight of the solar panels, leading to potential collapse or further damage.
          </ListItem>
          <ListItem>
            <ListIcon as={InfoIcon} color="red.500" />
            <Text as="span" fontWeight="bold">
              Safety Concerns:
            </Text>{" "}
            Working on a roof that is in poor condition can be dangerous for the installation crew.
          </ListItem>
          <ListItem>
            <ListIcon as={InfoIcon} color="red.500" />
            <Text as="span" fontWeight="bold">
              Longevity:
            </Text>{" "}
            Solar panels are designed to last 25 years or more. If your roof needs repairs or replacement during that time, it could necessitate removing and reinstalling the
            panels, leading to additional costs.
          </ListItem>
        </List>
        <Heading as="h2" size={{ base: "sm", md: "md" }} mt={5}>
          Next Steps to Get Solar
        </Heading>
        <Text>Here are the steps you can take to prepare your roof for a future solar installation:</Text>
        <List spacing={3} mt={3}>
          <ListItem>
            <ListIcon as={CheckCircleIcon} color="green.500" />
            <Text as="span" fontWeight="bold">
              Inspect and Repair:
            </Text>{" "}
            Have a professional roofer inspect your roof and perform any necessary repairs.
          </ListItem>
          <ListItem>
            <ListIcon as={CheckCircleIcon} color="green.500" />
            <Text as="span" fontWeight="bold">
              Replace if Needed:
            </Text>{" "}
            If your roof is near the end of its life, consider a full roof replacement to ensure it's in top condition.
          </ListItem>
          <ListItem>
            <ListIcon as={CheckCircleIcon} color="green.500" />
            <Text as="span" fontWeight="bold">
              Regular Maintenance:
            </Text>{" "}
            Keep your roof well-maintained with regular inspections and cleanings to prevent future issues.
          </ListItem>
        </List>
        <Text mt={5}>For more information on roof repair and maintenance, you can visit the following resources:</Text>
        <List spacing={3} mt={3}>
          <ListItem>
            <Link href="https://www.example.com/roof-repair-tips" color="teal.500" isExternal>
              Roof Repair Tips
            </Link>
          </ListItem>
          <ListItem>
            <Link href="https://www.example.com/find-roofers" color="teal.500" isExternal>
              Find Professional Roofers
            </Link>
          </ListItem>
          <ListItem>
            <Link href="https://www.example.com/solar-ready-roof" color="teal.500" isExternal>
              Preparing Your Roof for Solar
            </Link>
          </ListItem>
        </List>
      </VStack>
    </Box>
  );
};

export default PoorRoofCondition;
