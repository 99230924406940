import React from "react";
import { Box, VStack, Text, HStack } from "@chakra-ui/react";

const FinancialCalculator = () => {
  const annualKWHUsage = 10000;
  const utilityRate = 0.13;
  const buyBackRate = 0.8;
  const systemSize = 6.5;
  const loanRate = 0.06;
  const installerRedline = 2.1;
  const klaraviaMargin = 0.4;
  const systemCost = installerRedline * systemSize * 1000 + klaraviaMargin * systemSize * 1000;
  const avgElectricBill = (utilityRate * annualKWHUsage) / 12;

  return (
    <Box w="full" minH="90vh" bg="#f4eee9">
      <VStack w="full" pt="100px">
        <Text fontSize="32px" fontFamily="'eurostile', sans-serif">
          Financial Calculator
        </Text>
        <VStack w={{ base: "full", lg: "40vw" }} maxW="800px" bgColor="white" borderRadius="6px" p={{ base: "10px", lg: "30px" }}>
          <HStack w="full">
            <Text>System Cost:</Text>
            <Text>${new Intl.NumberFormat().format(systemCost)}</Text>
          </HStack>
          <HStack w="full">
            <Text>Current Bill:</Text>
            <Text>${new Intl.NumberFormat().format(Math.round(avgElectricBill))}</Text>
          </HStack>
        </VStack>
      </VStack>
    </Box>
  );
};
export default FinancialCalculator;
