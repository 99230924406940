import { Box, VStack, Text, Avatar } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import doorSalesman from "../Blogs/BlogImages/doorSalesman.webp";
import solarTown from "../Blogs/BlogImages/solarTown.webp";
import questionInABottle from "../Blogs/BlogImages/questionInABottle.webp";
import solarCalc from "../Blogs/BlogImages/solarCalc.webp";
import josiahLongenecker from "../Blogs/BlogImages/josiahLongenecker.jpg";

const BlogShiningALightOnSolar = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBlogLinkClick = (link: string) => {
    navigate(link);
  };

  return (
    <Box w="full" minH="90vh" pb="60px" pt="80px" bg="#1a1a1a" color="gray.400">
      <Box w="full" h={{ base: "200px", lg: "500px" }} bgImage={doorSalesman} bgSize="cover" bgRepeat="no-repeat" bgPosition="center" />
      <VStack w="full" gap="40px" align="stretch" p="20px">
        <VStack w="full">
          <br />
          <VStack w="full" mt="20px" lineHeight={1} maxW="800px">
            <Text w="full" fontWeight="bold" fontSize={{ base: "28px", lg: "32px" }}>
              Shining a Light on Solar
            </Text>
            <Text w="full" color="gray.300">
              How Klaravia is Fighting for Homeowners
            </Text>
          </VStack>
          <br />
          <Text fontSize="20px" maxW="800px">
            Hey there, solar seekers! Have you ever had a run-in with a door-knocking, fast-talking salesman pushing solar panels like they're the last piece of pie at Thanksgiving
            dinner? If you have, you're not alone. Let me tell you a little story about my friend Luke, who got caught up in the whirlwind of the solar sales game.
          </Text>
          <br />
          <Text fontSize="20px" maxW="800px">
            So there's Luke, minding his own business, sipping his morning coffee, when knock knock — there appears a seemingly jolly ole’ chap at the door claiming he will let
            Luke in on the secret to eliminating his electric bill without having to pay a dime! Now Luke has been thinking about getting a solar roof since his neighbor got one
            last year, so this could be the perfect opportunity to conveniently learn a little bit more about solar, right? Well, maybe, but something feels just a bit off about
            the whole situation. Nevertheless, Luke lets the guy in. Before he knows it, Luke is being bombarded with promises of "free electricity", a roof that is “paid for by
            the government”, and a “painless process" if he just signs on the dotted line. What a dream! &#128580;
          </Text>
          <br />
          <VStack w="full" maxW="800px">
            <Text fontSize="20px">
              Fortunately, Luke's not one to make hasty decisions, so he does what any savvy homeowner would do: he does his research. And what does he find? A boatload of horror
              stories from folks just like him who got duped by slick salesmen pushing subpar solar deals with hidden fees higher than Snoop Dogg himself! Stories of solar being
              paid for but never installed, loan terms with carefully concealed dealer fees up to 45%, harassment tactics that make your local extended warranty salesman look like
              a saint… a seemingly endless list of negative experiences that makes Luke question solar altogether.
            </Text>
            <br />
            <Text fontSize="20px">
              Sadly, this experience has become all too common for homeowners over the last few years. But here's where the story takes a turn for the better. Enter Klaravia, the
              hero of our tale. With Klaravia's online marketplace, Luke can kiss those pushy salesmen goodbye and say hello to transparency and freedom of choice.
            </Text>
          </VStack>
          <br />
          <VStack w="full" maxW="800px">
            <Text fontSize="20px">
              You see, Klaravia believes in fighting for the homeowner. We're all about making solar more accessible, cheaper, and easier for folks like Luke. How do we do it? By
              laying out all the available options side by side, so homeowners can see exactly what they're getting into before making a decision. Klaravia equips homeowners with
              optimal roof designs, detailed cost analyses, transparent reviews, and industry-leading education. No more lies. No more tactics. No more surprises. This is solar
              operating how it does best – in the light.
            </Text>
            <br />
            <Text fontSize="20px">
              And here's the kicker: by fostering competition among installers on our online solar marketplace, we are able to drive down costs and pass those savings on to you,
              the homeowner. We are quite literally getting yelled at by installers on the phone for forcing prices down. Throw in the savings you get by eliminating sales
              commissions and the value we add by working with the installer to make sure your project is done right and you’ve got yourself one steal of a deal! It feels nice to
              have someone fighting on your side for a change, huh?
            </Text>
          </VStack>
          <br />
          <VStack w="full" maxW="800px">
            <Text w="full" fontSize="20px">
              So whether you're fed up with door-knocking salesmen or just want to take control of your solar journey, Klaravia's got your back. We're not just another solar
              company—we're a beacon of transparency and choice in a sea of shady sales tactics. We are a brighter way to go solar.
            </Text>
            <br />
            <Text w="full" fontSize="20px">
              Here's to taking the power back, one solar panel at a time. &#129346;
            </Text>
          </VStack>
          <br />
          <br />
          <VStack w="full" maxW="800px">
            <Avatar size="xl" src={josiahLongenecker} />
            <Text>
              Written by: <b>Josiah Longenecker</b>
            </Text>
          </VStack>
        </VStack>
        <br />
        <VStack w="full">
          <VStack w={{ base: "full", lg: "35vw" }} alignItems="center" gap="20px" maxW="800px">
            <VStack w="full" cursor="pointer" onClick={() => handleBlogLinkClick("/blogs/to-solar-or-not-to-solar")} alignItems="center">
              <Box w="full" minH="250px" h="auto" bgImage={solarTown} bgSize="cover" bgRepeat="no-repeat" bgPosition="top" />
              <VStack w="full" mt="10px" lineHeight={1} pb="10px">
                <Text w="full" color="gray.500">
                  Does solar work for you?
                </Text>
                <Text w="full" fontWeight="bold" fontSize="24px">
                  To Solar or not to Solar: When Solar makes Sense
                </Text>
              </VStack>
            </VStack>

            <VStack w="full" cursor="pointer" onClick={() => handleBlogLinkClick("/blogs/top-10-solar-questions-answered")}>
              <Box w="full" minH="250px" h="auto" bgImage={questionInABottle} bgSize="cover" bgRepeat="no-repeat" bgPosition="top" />
              <VStack w="full" mt="10px" lineHeight={1} pb="10px">
                <Text w="full" color="gray.500">
                  Better understanding solar
                </Text>
                <Text w="full" fontWeight="bold" fontSize="24px">
                  Top 10 Solar Questions Answered
                </Text>
              </VStack>
            </VStack>

            <VStack w="full" cursor="pointer" onClick={() => handleBlogLinkClick("/blogs/understanding-solar-roof-tax-incentives")}>
              <Box w="full" minH="250px" h="auto" bgImage={solarCalc} bgSize="cover" bgRepeat="no-repeat" bgPosition="top" />
              <VStack w="full" mt="10px" lineHeight={1} pb="10px">
                <Text w="full" color="gray.500">
                  Solar tax incentives
                </Text>
                <Text w="full" fontWeight="bold" fontSize="24px">
                  Understanding Solar Roof Tax Incentives
                </Text>
              </VStack>
            </VStack>
          </VStack>
        </VStack>
      </VStack>
    </Box>
  );
};
export default BlogShiningALightOnSolar;
