import React, { useState, useEffect, useCallback } from 'react';
import { Box, VStack, Button, Text, Flex, Spinner, Alert, AlertIcon, AlertTitle, AlertDescription, useBreakpoint } from '@chakra-ui/react';

const PDFViewer = ({ pdfUrl }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [pdfDocument, setPdfDocument] = useState(null);
  const [error, setError] = useState(null);
  const breakpoint = useBreakpoint();

  const addLog = useCallback((message) => {
    console.log(message);
  }, []);

  const loadPdfJs = useCallback(() => {
    return new Promise((resolve, reject) => {
      if (window.pdfjsLib) {
        addLog("PDF.js already loaded");
        resolve(window.pdfjsLib);
        return;
      }

      addLog("Loading PDF.js");
      const script = document.createElement('script');
      script.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.9.359/pdf.min.js';
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        addLog("PDF.js loaded successfully");
        window.pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.9.359/pdf.worker.min.js';
        resolve(window.pdfjsLib);
      };
      script.onerror = reject;
    });
  }, [addLog]);

  const loadPdf = useCallback(async (pdfjsLib) => {
    if (!pdfUrl) {
      addLog("No PDF URL provided");
      setIsLoading(false);
      return;
    }

    addLog(`Attempting to load PDF from URL: ${pdfUrl}`);
    try {
      const loadingTask = pdfjsLib.getDocument(pdfUrl);
      addLog("PDF loading task created");
      const pdf = await loadingTask.promise;
      addLog("PDF loaded successfully");
      setPdfDocument(pdf);
      setNumPages(pdf.numPages);
      addLog(`PDF has ${pdf.numPages} pages`);
      setIsLoading(false);
    } catch (error) {
      addLog(`Error loading PDF: ${error.message}`);
      setError(`Failed to load PDF: ${error.message}`);
      setIsLoading(false);
    }
  }, [pdfUrl, addLog]);

  useEffect(() => {
    let mounted = true;
    let retryCount = 0;
    const maxRetries = 3;

    const loadPdfWithRetry = async () => {
      try {
        const pdfjsLib = await loadPdfJs();
        if (mounted) {
          await loadPdf(pdfjsLib);
        }
      } catch (error) {
        addLog(`Error in loadPdfWithRetry: ${error.message}`);
        if (retryCount < maxRetries) {
          retryCount++;
          addLog(`Retrying (${retryCount}/${maxRetries})...`);
          setTimeout(loadPdfWithRetry, 1000); // Retry after 1 second
        } else {
          setError(`Failed to load PDF after ${maxRetries} attempts`);
          setIsLoading(false);
        }
      }
    };

    loadPdfWithRetry();

    return () => {
      mounted = false;
    };
  }, [loadPdfJs, loadPdf, addLog]);

  const renderPage = useCallback(async () => {
    if (!pdfDocument) {
      addLog("No PDF document to render");
      return null;
    }

    addLog(`Rendering page ${pageNumber}`);
    try {
      const page = await pdfDocument.getPage(pageNumber);
      addLog("Page retrieved successfully");
      const scale = breakpoint === '2xl' || breakpoint === 'xl' || breakpoint === 'lg' ? 1 : .5;
      const viewport = page.getViewport({ scale });

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport
      };

      addLog("Starting page render");
      await page.render(renderContext);
      addLog("Page rendered successfully");
      return canvas;
    } catch (error) {
      addLog(`Error rendering page: ${error.message}`);
      setError(`Failed to render page: ${error.message}`);
      return null;
    }
  }, [pdfDocument, pageNumber, addLog]);

  useEffect(() => {
    const updateCanvas = async () => {
      const container = document.getElementById('pdf-container');
      if (!container) {
        addLog("PDF container not found");
        return;
      }

      container.innerHTML = '';
      const canvas = await renderPage();
      if (canvas) {
        container.appendChild(canvas);
        addLog("Canvas appended to container");
      } else {
        addLog("No canvas to append");
      }
    };

    if (!isLoading && pdfDocument) {
      updateCanvas();
    }
  }, [pageNumber, pdfDocument, isLoading, renderPage, addLog]);

  const changePage = (offset) => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  };

  const previousPage = () => changePage(-1);
  const nextPage = (e) => {
    e.stopPropagation();
    changePage(1)
  };

  return (
      <VStack spacing={4} align="center" w="full" className="bg-dynamic" pt='20px'>
        {error && (
          <Alert status="error">
            <AlertIcon />
            <Box>
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Box>
          </Alert>
        )}
        {pdfUrl ? (
          <>
            <Box w="full" display='flex' justifyContent='center' overflow="auto" className='bg-dynamic'>
              {isLoading ? (
                <Flex justify="center" align="center" h="full">
                  <Spinner size="xl" />
                </Flex>
              ) : (
                <div id="pdf-container" />
              )}
            </Box>
            {numPages && (
              <Box pb="20px" w='full' textAlign='center'>
                <Text color='text-dynamic'>
                  Page {pageNumber} of {numPages}
                </Text>
                <Flex w='full' justifyContent='space-evenly'>
                  <Button w='120px' onClick={previousPage} isDisabled={pageNumber <= 1} mr={2}>
                    Previous
                  </Button>
                  <Button w='120px' onClick={nextPage} isDisabled={pageNumber >= numPages}>
                    Next
                  </Button>
                </Flex>
              </Box>
            )}
          </>
        ) : (
          <Text>No PDF URL provided</Text>
        )}
      </VStack>
  );
};

export default PDFViewer;