import { ReactNode } from "react";
import { Box, Container, SimpleGrid, Stack, Text, HStack, Image, useColorModeValue } from "@chakra-ui/react";
import logo from "../../Assets/logoLight.svg";

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

export default function Footer() {
  return (
    <Box className="bg-[#1f1f1f]" color={useColorModeValue("gray.200", "gray.200")} fontFamily="'eurostile', sans-serif">
      <Container as={Stack} maxW={"6xl"} py={10}>
        <SimpleGrid templateColumns={{ sm: "1fr 1fr", md: "2fr 1fr 1fr 1fr 1fr" }} spacing={8}>
          <Stack spacing={6}>
            <Box>
              <HStack>
                <Image h="40px" w="40px" src={logo} />
                <Text fontSize="36px" fontFamily="'new-astro', sans-serif">
                  Klaravia
                </Text>
              </HStack>
            </Box>
            <Text fontSize={"sm"}>© 2024 Klaravia. All rights reserved</Text>
          </Stack>
          <Stack align={"flex-start"}>
            <ListHeader>
              <b>Support</b>
            </ListHeader>
            {/* <Box as="a" href={"#"}>
              Help Center
            </Box> */}
            <Box as="a" href={"/terms-of-service"}>
              Terms of Service
            </Box>
            {/* <Box as="a" href={"#"}>
              Legal
            </Box>
            <Box as="a" href={"#"}>
              Privacy Policy
            </Box> */}
          </Stack>
          {/* <Stack align={"flex-start"}>
            <ListHeader>
              <b>Product</b>
            </ListHeader>
            <Box as="a" href={"#"}>
              Overview
            </Box>
            <Box as="a" href={"#"}>
              Features
            </Box>
            <Box as="a" href={"#"}>
              Tutorials
            </Box>
            <Box as="a" href={"#"}>
              Pricing
            </Box>
            <Box as="a" href={"#"}>
              Releases
            </Box>
          </Stack> */}
          <Stack align={"flex-start"}>
            <ListHeader>
              <b>Company</b>
            </ListHeader>
            <Box as="a" href={"/LandingPage"}>
              About
            </Box>
            {/* <Box as="a" href={"#"}>
              Press
            </Box>
            <Box as="a" href={"#"}>
              Careers
            </Box>
            <Box as="a" href={"#"}>
              Contact
            </Box>
            <Box as="a" href={"#"}>
              Partners
            </Box> */}
          </Stack>
          {/* <Stack align={"flex-start"}>
            <ListHeader>
              <b>Follow Us</b>
            </ListHeader>
            <Box as="a" href={"#"}>
              Facebook
            </Box>
            <Box as="a" href={"#"}>
              Twitter
            </Box>
            <Box as="a" href={"#"}>
              Tiktok
            </Box>
            <Box as="a" href={"#"}>
              Instagram
            </Box>
            <Box as="a" href={"#"}>
              LinkedIn
            </Box>
          </Stack> */}
        </SimpleGrid>
      </Container>
    </Box>
  );
}
