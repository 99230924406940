import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useNumberInput,
  VStack,
  Icon,
  FormControl,
  FormLabel,
  Switch,
} from "@chakra-ui/react";
import axios from "axios";
import Chart from "react-apexcharts";
import "./KlaraviaStudio.css";
import { useNavigate } from "react-router-dom";
import carbonFactory from "../Assets/carbonFactory.svg";
import tree from "../Assets/tree.svg";
import { useSolarReadyContext } from "../../Providers/SolarReadyProvider";
import { UserWithoutTypeName, useUserDataContext } from "../../Providers/LeadDataProvider";
import logoSpinner from "../../Assets/logo.svg";
import EstimatedSavingsChart from "../Components/EstimatedSavingsChart";
import { ArrowRightIcon, ChevronRightIcon, ChevronUpIcon } from "@chakra-ui/icons";
import LifetimeSavingsComponent from "../Components/LifetimeSavingsComponent";
import SavingsBreakdown from "../Components/SavingsBreakdown";
import { motion } from "framer-motion";
import { UserUpdateOptions } from "../../API";

const MotionIcon = motion(Icon);

function getTotalAnnualProductionByMonth(data: any) {
  // Initialize an array of 12 elements to store the sum of production for each month.
  const totalMonthlyProduction = new Array(12).fill(0);

  // Iterate over each panel in the energyTable.
  if (data) {
    data.energyTable.forEach((panel: any) => {
      //@ts-ignore
      panel["Panels Monthly Production"].forEach((monthlyProduction, index) => {
        // Add the monthly production of the current panel to the total.
        totalMonthlyProduction[index] += monthlyProduction;
      });
    });
  }

  return totalMonthlyProduction;
}

const KlaraviaStudio = () => {
  const navigate = useNavigate();
  const { userData, setUserData } = useUserDataContext();
  const { solarReadyDesignTrackingId, setSolarReadyStatus, solarReadyStatus, setSolarReadyData, solarReadyData } = useSolarReadyContext();
  const [statusIsReady, setStatusIsReady] = useState(false);
  const [solarReadyDataIsLoaded, setSolarReadyDataIsLoaded] = useState(false);
  const [optimizedOutput, setOptimizedOutput] = useState<any>(null);
  const [selectedNumPanels, setSelectedNumPanels] = useState<number>(-1);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loadingNewPanelImage, setLoadingNewPanelImage] = useState(false);
  const [dataIsOptimized, setDataIsOptimized] = useState<boolean>(false);
  const [displaySystemSpecs, setDisplaySystemSpecs] = useState<boolean>(true);

  // useEffect(() => {
  //   onOpen();
  // }, []);

  const handleDisplaySystemSpecs = () => {
    setDisplaySystemSpecs(!displaySystemSpecs);
  };

  const pollingCount = useRef(0);

  const getSolarReadyStatus = async () => {
    try {
      const response = await axios.get(`https://4zpask5mw0.execute-api.us-east-2.amazonaws.com/dev/getSolarReadyStatus?trackingId=${solarReadyDesignTrackingId}`);

      // Check if the request was successful
      if (response.status !== 200) {
        throw new Error(`API call failed with status: ${response.status}`);
      }

      return response.data;
    } catch (error) {
      // Handle any errors that occur during the fetch
      console.error("Error calling the API:", error);
      setErrorMessage("We're having trouble checking the status of your initial design.");
      throw error; // Rethrow the error if you want to handle it further up the call stack
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      console.log("polling...", pollingCount, solarReadyStatus);
      getSolarReadyStatus()
        .then((res: { status: string; jobStatus: string; errorMessage: string }) => {
          console.log("res", res);
          if (res.status === "Completed" && res.jobStatus === "Success") {
            console.warn("clearing");
            setSolarReadyStatus(res);
            setStatusIsReady(true);
            clearInterval(intervalId);
            // navigate("/installer-select");
          }
          if (res.status === "Completed" && res.jobStatus === "Failed") {
            console.log("status", res);
            setErrorMessage("Sorry, we are having trouble processing your address. Please try again later.");
            console.warn("failed, clearing");
            clearInterval(intervalId);
          }
          if (pollingCount.current > 30) {
            setErrorMessage("This session has timed out");
            clearInterval(intervalId);
            return;
          }
          pollingCount.current = pollingCount.current + 1;
        })
        .catch((error) => {
          setErrorMessage("We're having trouble checking the status of your initial design.");
          console.error("Failed to get solar ready status:", error);
          // Optional: Decide how to handle errors, e.g., retry, give up, show error message, etc.
        });
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (solarReadyStatus.status === "Completed" && solarReadyStatus.jobStatus === "Success") {
      const getSolarReadyData = async () => {
        try {
          const response = await axios.get(`https://4zpask5mw0.execute-api.us-east-2.amazonaws.com/dev/getSolarReadyData?trackingId=${solarReadyDesignTrackingId}`);

          // Check if the request was successful
          if (response.status !== 200) {
            throw new Error(`API call failed with status: ${response.status}`);
          }

          return response.data;
        } catch (error) {
          // Handle any errors that occur during the fetch
          console.error("Error calling the API:", error);
          throw error; // Rethrow the error if you want to handle it further up the call stack
        }
      };

      getSolarReadyData().then((res) => {
        console.log("res", res);
        if (res.proposeData) {
          if (res.proposeData.dataOutput) {
            if (res.proposeData.dataOutput.roof["Home Classification"] === "Red") {
              setErrorMessage("It looks like your roof cannot support solar panels.");
              return;
            }
            if (res && res.proposeData.dataOutput.energyTable.length > 1) {
              filterPanels(res, Number(userData.estimatedAnnualUsage), selectedNumPanels);
              setSolarReadyData(res);
              setSolarReadyDataIsLoaded(true);
            }
          }
        }
      });
    }
  }, [solarReadyStatus]);

  useEffect(() => {
    setLoadingNewPanelImage(true);
    filterPanels(solarReadyData, Number(userData.estimatedAnnualUsage), selectedNumPanels);
  }, [selectedNumPanels]);

  function renameFields(array: any, mapping: any) {
    return array.map((obj: any) => {
      const newObj: any = {};
      Object.keys(obj).forEach((oldKey) => {
        const newKey = mapping[oldKey] || oldKey; // Use the new key if it exists in the mapping, otherwise use the old key
        newObj[newKey] = obj[oldKey];
      });
      return newObj;
    });
  }

  function filterPanels(data: any, kWhThreshold: number, numPanels: number) {
    console.log(data, kWhThreshold, numPanels);
    // Group panels by facets
    let facetsMap = data.proposeData.dataOutput.energyTable.reduce((map: any, panel: any) => {
      if (!map[panel.Facet]) map[panel.Facet] = [];
      map[panel.Facet].push(panel);
      return map;
    }, {});

    // Calculate total kWh potential for each facet
    let facets = Object.keys(facetsMap).map((facetId) => {
      let panels = facetsMap[facetId];
      let totalkWh = panels.reduce((sum: number, panel: any) => sum + panel["kWh/yr"], 0);
      return {
        facetId,
        totalkWh,
        panels,
      };
    });

    // Sort facets by total kWh potential in descending order
    facets.sort((a, b) => b.totalkWh - a.totalkWh);

    let selectedPanels: any[] = [];
    let selectedPanelIds: number[] = [];
    let selectedFacets = new Set();
    let totalkWh = 0;

    if (numPanels === -1) {
      for (let facet of facets) {
        if (totalkWh >= kWhThreshold) break;

        // Sort panels within the facet by kWh/yr in descending order
        facet.panels.sort((a: any, b: any) => b["kWh/yr"] - a["kWh/yr"]);

        for (let panel of facet.panels) {
          if (totalkWh >= kWhThreshold) break;

          selectedPanels.push(panel);
          selectedPanelIds.push(panel["Panel Id"]);
          selectedFacets.add(panel.Facet);
          totalkWh += panel["kWh/yr"];
        }
      }
    } else {
      //   // Collect all panels from all facets
      //   let allPanels: any[] = [];
      //   for (let facet of facets) {
      //     // Sort panels within the facet by kWh/yr in descending order
      //     facet.panels.sort((a: any, b: any) => b["kWh/yr"] - a["kWh/yr"]);
      //     allPanels = allPanels.concat(facet.panels);
      //   }

      //   // Sort all panels by kWh/yr in descending order
      //   allPanels.sort((a: any, b: any) => b["kWh/yr"] - a["kWh/yr"]);

      //   // Select only the specified number of panels
      //   selectedPanels = allPanels.slice(0, numPanels);

      //   // Update selectedPanelIds, selectedFacets, and totalkWh
      //   selectedPanels.forEach((panel) => {
      //     selectedPanelIds.push(panel["Panel Id"]);
      //     selectedFacets.add(panel.Facet);
      //     totalkWh += panel["kWh/yr"];
      //   });
      // }

      for (let facet of facets) {
        // Sort panels within the facet by kWh/yr in descending order
        facet.panels.sort((a: any, b: any) => b["kWh/yr"] - a["kWh/yr"]);

        for (let panel of facet.panels) {
          selectedPanels.push(panel);
          selectedPanelIds.push(panel["Panel Id"]);
          selectedFacets.add(panel.Facet);
          totalkWh += panel["kWh/yr"];
        }
      }

      selectedPanelIds = selectedPanelIds.slice(0, numPanels);
      selectedPanels = selectedPanels.slice(0, numPanels);
    }

    // Filter facets based on selected panels
    let filteredFacets = data.proposeData.dataOutput.facet.filter((facet: any) => selectedFacets.has(facet["Facet Id"]));

    const getSolarReadyImageByPanelIds = async () => {
      try {
        console.warn("fired the get image call");
        const response = await axios.get(
          `https://4zpask5mw0.execute-api.us-east-2.amazonaws.com/dev/getSolarReadyImageByPanelIds?trackingId=${solarReadyDesignTrackingId}&panelIds=${selectedPanelIds.join(",")}`
        );

        // Check if the request was successful
        if (response.status !== 200) {
          setLoadingNewPanelImage(false);
          throw new Error(`API call failed with status: ${response.status}`);
        }
        if (response) {
          setLoadingNewPanelImage(false);
        }
        return response.data;
      } catch (error) {
        setLoadingNewPanelImage(false);
        // Handle any errors that occur during the fetch
        console.error("Error calling the API:", error);
        throw error; // Rethrow the error if you want to handle it further up the call stack
      }
    };

    getSolarReadyImageByPanelIds().then((res) => {
      setOptimizedOutput({
        energyTable: selectedPanels,
        facet: filteredFacets,
        roof: {
          "Existing panels on structure": solarReadyData.proposeData.dataOutput.roof["Existing panels on structure"],
          "Home Classification": solarReadyData.proposeData.dataOutput.roof["Home Classification"],
          "Imagery date": solarReadyData.proposeData.dataOutput.roof["Imagery date"],
          "Job Id": solarReadyData.proposeData.dataOutput.roof["Job Id"],
          "Lat Long": solarReadyData.proposeData.dataOutput.roof["Lat Long"],
          "Max Number of Panels": selectedPanelIds.length,
          "Max kW system": selectedPanels.reduce((acc, curr) => {
            return acc + Number(curr["kW size"]);
          }, 0),
          "Max kWh/yr system": selectedPanels.reduce((acc, curr) => {
            return acc + Number(curr["kWh/yr"]);
          }, 0),
          "Number of Facets": 1,
          "Predominant Pitch (degree)": solarReadyData.proposeData.dataOutput.roof["Predominant Pitch (degree)"],
          "Total Area of Max System": solarReadyData.proposeData.dataOutput.roof["Total Area of Max System"],
          "Total Sq Ft of Roof": solarReadyData.proposeData.dataOutput.roof["Total Sq Ft of Roof"],
          "Tree Overhang %": solarReadyData.proposeData.dataOutput.roof["Tree Overhang %"],
        },
        imageUrl: res,
      });

      const energyTableMapping = {
        Facet: "facet",
        "Panel Id": "panelId",
        "Panels Monthly Production": "panelsMonthlyProduction",
        "SAV(Each panel)": "savEachPanel",
        "Production Multiplier": "productionMultiplier",
        "System Production Multiplier": "systemProductionMultiplier",
        "TSRF(Each panel)": "tsrfEachPanel",
        coordinates: "coordinates",
        "kW size": "kWSize",
        "kW size (Cumulative)": "kWSizeCumulative",
        "kWh/yr": "kWhYr",
        "kWh/yr(Cumulative)": "kWhYrCumulative",
      };

      const facetMapping = {
        "Average SAV": "averageSAV",
        "Average TSRF": "averageTSRF",
        Azimuth: "azimuth",
        "Facet Id": "facetId",
        "No of Obstructions": "noOfObstructions",
        "Pitch (degree)": "pitchDegree",
        "Sq Ft": "sqFt",
      };

      const mappedEnergyTable = renameFields(selectedPanels, energyTableMapping);
      const mappedFacets = renameFields(filteredFacets, facetMapping);

      // @ts-ignore
      setUserData((prevUserData: UserWithoutTypeName) => {
        if (
          JSON.stringify(prevUserData) !==
          JSON.stringify({
            ...prevUserData,
            id: localStorage.getItem("userId"),
            updated_by: UserUpdateOptions.DYNAMO,
            initialDesign: {
              dataOutput: {
                energyTable: mappedEnergyTable,
                facet: mappedFacets,
                roof: {
                  existingPanelsOnStructure: solarReadyData.proposeData.dataOutput.roof["Existing panels on structure"],
                  homeClassification: solarReadyData.proposeData.dataOutput.roof["Home Classification"],
                  imageryDate: solarReadyData.proposeData.dataOutput.roof["Imagery date"],
                  jobId: solarReadyData.proposeData.dataOutput.roof["Job Id"],
                  latLong: solarReadyData.proposeData.dataOutput.roof["Lat Long"],
                  maxNumberOfPanels: selectedPanelIds.length,
                  maxKWSystem: selectedPanels.reduce((acc, curr) => {
                    return acc + Number(curr["kW size"]);
                  }, 0),
                  maxKWhYrSystem: selectedPanels.reduce((acc, curr) => {
                    return acc + Number(curr["kWh/yr"]);
                  }, 0),
                  numberOfFacets: 1,
                  predominantPitchDegree: solarReadyData.proposeData.dataOutput.roof["Predominant Pitch (degree)"],
                  totalAreaOfMaxSystem: solarReadyData.proposeData.dataOutput.roof["Total Area of Max System"],
                  totalSqFtOfRoof: solarReadyData.proposeData.dataOutput.roof["Total Sq Ft of Roof"],
                  treeOverhangPrecent: solarReadyData.proposeData.dataOutput.roof["Tree Overhang %"],
                },
              },
              imageUrl: res,
            },
          })
        ) {
          return {
            ...prevUserData,
            id: localStorage.getItem("userId"),
            updated_by: UserUpdateOptions.DYNAMO,
            initialDesign: {
              dataOutput: {
                energyTable: mappedEnergyTable,
                facet: mappedFacets,
                roof: {
                  existingPanelsOnStructure: solarReadyData.proposeData.dataOutput.roof["Existing panels on structure"],
                  homeClassification: solarReadyData.proposeData.dataOutput.roof["Home Classification"],
                  imageryDate: solarReadyData.proposeData.dataOutput.roof["Imagery date"],
                  jobId: solarReadyData.proposeData.dataOutput.roof["Job Id"],
                  latLong: solarReadyData.proposeData.dataOutput.roof["Lat Long"],
                  maxNumberOfPanels: selectedPanelIds.length,
                  maxKWSystem: selectedPanels.reduce((acc, curr) => {
                    return acc + Number(curr["kW size"]);
                  }, 0),
                  maxKWhYrSystem: selectedPanels.reduce((acc, curr) => {
                    return acc + Number(curr["kWh/yr"]);
                  }, 0),
                  numberOfFacets: 1,
                  predominantPitchDegree: solarReadyData.proposeData.dataOutput.roof["Predominant Pitch (degree)"],
                  totalAreaOfMaxSystem: solarReadyData.proposeData.dataOutput.roof["Total Area of Max System"],
                  totalSqFtOfRoof: solarReadyData.proposeData.dataOutput.roof["Total Sq Ft of Roof"],
                  treeOverhangPrecent: solarReadyData.proposeData.dataOutput.roof["Tree Overhang %"],
                },
              },
              imageUrl: res,
            },
          };
        }
        return prevUserData;
      });
      return;
    });

    console.log({
      proposeData: {
        dataOutput: {
          energyTable: selectedPanels,
          facet: filteredFacets,
          roof: data.proposeData.dataOutput.roof,
        },
        jobId: data.proposeData.jobId,
        productId: data.proposeData.productId,
      },
    });
    setDataIsOptimized(true);
  }

  const totalProductionByMonth = getTotalAnnualProductionByMonth(optimizedOutput);

  const chartOptions: any = {
    chart: {
      type: "line",
      height: "100%",
      width: "100%",
      redrawOnWindowResize: true,
      toolbar: {
        show: false, // Hides the toolbar
      },
    },
    stroke: {
      curve: "smooth", // This makes the line chart's lines smooth
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    },
    yaxis: {
      labels: {
        formatter: function (value: number) {
          return Math.round(value); // Round values to the nearest whole number
        },
      },
    },
    title: {
      text: "Annual system production by month (kWh)",
      align: "left",
    },
    dataLabels: {
      enabled: false, // Disable data labels for the entire chart
    },
    responsive: [
      {
        breakpoint: 2000,
        options: {
          chart: {
            width: "700px",
          },
        },
      },
      {
        breakpoint: 1700,
        options: {
          chart: {
            width: "600px",
          },
        },
      },
      {
        breakpoint: 1400,
        options: {
          chart: {
            width: "500px",
          },
        },
      },
      {
        breakpoint: 1100,
        options: {
          chart: {
            width: "400px",
          },
        },
      },
      {
        breakpoint: 600,
        options: {
          chart: {
            width: "400px",
          },
        },
      },
      {
        breakpoint: 400,
        options: {
          chart: {
            width: "300px",
          },
          xaxis: {
            labels: {
              show: false,
            },
          },
        },
      },
    ],
    // Add other customization options as needed
  };

  const series = [
    {
      name: "kWh",
      type: "area",
      data: totalProductionByMonth,
      dataLabels: {
        enabled: true, // Enable labels for the first series
      },
    },
    {
      name: "current kWh",
      type: "area",
      data: [
        Math.round(Number(userData?.estimatedAnnualUsage) / 12),
        Math.round(Number(userData?.estimatedAnnualUsage) / 12),
        Math.round(Number(userData?.estimatedAnnualUsage) / 12),
        Math.round(Number(userData?.estimatedAnnualUsage) / 12),
        Math.round(Number(userData?.estimatedAnnualUsage) / 12),
        Math.round(Number(userData?.estimatedAnnualUsage) / 12),
        Math.round(Number(userData?.estimatedAnnualUsage) / 12),
        Math.round(Number(userData?.estimatedAnnualUsage) / 12),
        Math.round(Number(userData?.estimatedAnnualUsage) / 12),
        Math.round(Number(userData?.estimatedAnnualUsage) / 12),
        Math.round(Number(userData?.estimatedAnnualUsage) / 12),
        Math.round(Number(userData?.estimatedAnnualUsage) / 12),
      ],
      dataLabels: {
        enabled: false, // Disable labels for the second series
      },
    },
  ];

  return (
    <>
      {errorMessage || !userData || userData === undefined ? (
        <Box w="full" bgColor="#f4eee9">
          <VStack w="full" h="90vh" justifyContent="center">
            <Text fontSize="40px">Whoops ...</Text>
            <Text mb="20px">{errorMessage || "Cannot load your data"}</Text>
            <Button
              w="120px"
              h="40px"
              bgColor="black"
              color="white"
              _hover={{ bgColor: "rgba(0,0,0,.7)" }}
              onClick={() => {
                navigate("/LandingPage");
              }}
            >
              Back
            </Button>
          </VStack>
        </Box>
      ) : (
        <>
          {solarReadyDataIsLoaded && statusIsReady && optimizedOutput && dataIsOptimized ? (
            <Box w="full" minH="100vh" p={{ base: "20px", lg: "40px" }} pt="80px" bg="#f4eee9" fontFamily="'eurostile', sans-serif">
              <HStack w="full" justifyContent="center" gap="40px" pt="80px" flexDirection={{ base: "column", lg: "row" }} alignItems={{ base: "center", lg: "flex-start" }}>
                <VStack maxW={{ base: "full", lg: "50vw" }}>
                  {/* TODO: add default image here */}
                  <Box position="relative" w="full" maxH="84vh">
                    {/* <HStack w="full" justifyContent="space-between" position="absolute" p="10px" top="0px" right="0px" bgColor="rgba(0,0,0,.75)">
                      <Text fontSize="18px" fontWeight="bold" color="white">
                        Number of panels:
                      </Text>
                      <HStack>
                        <PanelCounter initialPanelCount={Number(optimizedOutput.energyTable.length)} setSelectedNumPanels={setSelectedNumPanels} />
                      </HStack>
                    </HStack> */}
                    <Image maxH="84vh" shadow="lg" src={optimizedOutput.imageUrl} alt="Dynamic" borderRadius="10px" />
                    <VStack w="full" bgColor="black" borderRadius="10px" p={{ base: "10px", lg: "20px" }} shadow="lg" position="absolute" bottom="0" left="0">
                      <HStack w="full" justifyContent="space-between" cursor="pointer" onClick={handleDisplaySystemSpecs}>
                        <Text fontSize={{ base: "18px", lg: "20px" }} fontWeight="bold" color="gray.50">
                          System Specs
                        </Text>
                        <MotionIcon
                          as={ChevronUpIcon}
                          color="gray.50"
                          boxSize={{ base: 6, lg: 8 }}
                          animate={{ rotate: displaySystemSpecs ? 180 : 0 }}
                          transition={{ duration: 0.3 }}
                        />
                      </HStack>
                      {displaySystemSpecs && (
                        <VStack w="full" mt="10px" alignItems="space-between" spacing="6px">
                          <VStack w="full" gap="0px" spacing="0px" lineHeight={1.1}>
                            <HStack w="full" justifyContent="space-between">
                              <Text fontSize={{ base: "14px", lg: "18x" }} fontWeight="bold" color="gray.50">
                                Annual Solar production:
                              </Text>
                              <HStack>
                                <Text fontWeight="bold" fontSize={{ base: "20px", lg: "24px" }} color="gray.50">
                                  {new Intl.NumberFormat().format(Number(optimizedOutput.roof["Max kWh/yr system"]))}
                                </Text>
                                <Text color="gray.50">kWh</Text>
                              </HStack>
                            </HStack>
                            <HStack w="full" justifyContent="space-between">
                              <Text fontSize={{ base: "12px", lg: "16px" }} color="gray.200">
                                Estimated Current Usage:
                              </Text>
                              <HStack>
                                <Text fontSize={{ base: "12px", lg: "16px" }} color="gray.200">
                                  {new Intl.NumberFormat().format(Math.round(Number(userData.estimatedAnnualUsage)))}
                                </Text>
                                <Text color="gray.200" fontSize={{ base: "12px", lg: "16px" }}>
                                  kWh
                                </Text>
                              </HStack>
                            </HStack>
                          </VStack>
                          <HStack w="full" justifyContent="space-between">
                            <Text fontSize={{ base: "14px", lg: "18x" }} fontWeight="bold" color="gray.50">
                              Number of Panels:
                            </Text>
                            <HStack>
                              <Text fontWeight="bold" fontSize={{ base: "20px", lg: "24px" }} color="gray.50">
                                {optimizedOutput.roof["Max Number of Panels"]}
                              </Text>
                              <Text color="gray.50">Panels</Text>
                            </HStack>
                          </HStack>
                          <HStack w="full" justifyContent="space-between">
                            <Text fontSize={{ base: "14px", lg: "18x" }} fontWeight="bold" color="gray.50">
                              System size:
                            </Text>
                            <HStack>
                              <Text fontWeight="bold" fontSize={{ base: "20px", lg: "24px" }} color="gray.50">
                                {optimizedOutput.roof["Max kW system"].toFixed(2)}
                              </Text>
                              <Text color="gray.50">kW</Text>
                            </HStack>
                          </HStack>
                        </VStack>
                      )}
                    </VStack>
                  </Box>
                  {loadingNewPanelImage && (
                    <VStack w="48vw" h="80vh" alignItems="center" justifyContent="center" bg="none" position="relative" bottom="80vh">
                      <Image src={logoSpinner} w="60px" alt="cannot load loading component" className="spinner" />
                    </VStack>
                  )}
                </VStack>
                <VStack
                  w={{ base: "full", lg: "44vw" }}
                  pr="0px"
                  paddingTop="0px"
                  spacing="20px"
                  overflowY={{ base: "unset", lg: "scroll" }}
                  maxH="90vh"
                  className="hide-scrollbar"
                >
                  <VStack w="full" bgColor="blackAlpha.700" borderRadius="10px" p="20px" shadow="lg">
                    <Text w="full" fontWeight="bold" fontSize="24px" color="gray.100">
                      Doesn't look right?
                    </Text>
                    <Text w="full" fontSize="14px" color="gray.100">
                      That's alright. This is a visualization powered by AI to help you understand the impact of installing solar on your home. Once you have uploaded a picture of
                      your electric bill, a professional final design will be built and sent to you for review.
                    </Text>
                  </VStack>
                  <VStack w="full" bgColor="white" borderRadius="10px" p="20px" shadow="lg">
                    <Text w="full" fontWeight="bold" fontSize="26px" display="inline-flex" alignItems="flex-end" lineHeight={1}>
                      <Text fontSize="32px" mr="10px">
                        1.
                      </Text>{" "}
                      Energy Needs
                    </Text>
                    {/* <Text>What percentage of your current energy needs will be met by your system?</Text> */}
                    <VStack w={{ base: "full", lg: "80%" }}>
                      <Box w="full">
                        <HStack
                          w={`${(100 * Number(optimizedOutput.roof["Max kWh/yr system"])) / (Number(userData.estimatedAnnualUsage) * 1.5)}%`}
                          maxW={{ base: `${window.innerWidth - 80}px`, lg: "full" }}
                          minW="80px"
                          h="40px"
                          pl="20px"
                          pr="20px"
                          transition="width 0.5s ease"
                          bgColor={(100 * Number(optimizedOutput.roof["Max kWh/yr system"])) / Number(userData.estimatedAnnualUsage) < 50 ? "red.400" : "#48BB78"}
                          border="2px solid"
                          borderColor={(100 * Number(optimizedOutput.roof["Max kWh/yr system"])) / Number(userData.estimatedAnnualUsage) < 50 ? "red.400" : "#48BB78"}
                          borderRadius="20px"
                          justifyContent="space-between"
                          position="relative"
                          top="30px"
                          zIndex={1}
                        >
                          <Text w="full" minW="20px" maxW={{ base: `${window.innerWidth - 80}px`, lg: "full" }} textAlign="right" color="white" fontWeight="bold" fontSize="20px">
                            {`${Math.floor((100 * Number(optimizedOutput.roof["Max kWh/yr system"])) / Number(userData.estimatedAnnualUsage))}%`}
                          </Text>
                        </HStack>
                        <HStack w="full" h="40px" bgColor="gray.300" borderRadius="20px" justifyContent="space-evenly" position="relative" bottom="10px">
                          <Text fontWeight="bold">25%</Text>
                          <Text fontWeight="bold">50%</Text>
                          <Text fontWeight="bold">75%</Text>
                          <Text fontWeight="bold">100%</Text>
                          <Text fontWeight="bold">125%</Text>
                          <Text fontWeight="bold">150%</Text>
                        </HStack>
                      </Box>
                    </VStack>
                    {(100 * Number(optimizedOutput.roof["Max kWh/yr system"])) / Number(userData.estimatedAnnualUsage) > 100 ? (
                      <Text mt="20px">
                        {`This system will meet ${Math.floor(
                          (100 * Number(optimizedOutput.roof["Max kWh/yr system"])) / Number(userData.estimatedAnnualUsage)
                        )}% of your expected energy needs. This means that you will produce a little bit more energy than you require on average.`}
                      </Text>
                    ) : (
                      <Text mt="20px">
                        {`This system will meet ${Math.floor(
                          (100 * Number(optimizedOutput.roof["Max kWh/yr system"])) / Number(userData.estimatedAnnualUsage)
                        )}% of your expected energy needs. This means that you will produce a little bit less energy than you require on average. The remaining energy will still be purchased by your utility provider.`}
                      </Text>
                    )}
                  </VStack>
                  {optimizedOutput.energyTable.length > 1 && (
                    <VStack w="full" bgColor="white" borderRadius="10px" p="20px" shadow="lg">
                      <Text w="full" fontWeight="bold" fontSize="26px" display="inline-flex" alignItems="flex-end" lineHeight={1}>
                        <Text fontSize="32px" mr="10px">
                          2.
                        </Text>{" "}
                        Customize your build
                      </Text>
                      <VStack w="80%" mt="20px" mb="20px" alignItems="space-between">
                        <HStack w="full" justifyContent="space-between" mb="20px">
                          <Text fontSize="18px" fontWeight="bold">
                            Number of panels:
                          </Text>
                          <HStack>
                            <PanelCounter initialPanelCount={Number(optimizedOutput.energyTable.length)} setSelectedNumPanels={setSelectedNumPanels} />
                          </HStack>
                        </HStack>
                        <FormControl display="flex" alignItems="center" justifyContent="space-between">
                          <FormLabel fontSize="18px" htmlFor="battery-selection" mb="0" fontWeight="bold">
                            Add a Battery
                          </FormLabel>
                          <Switch id="battery-selection" size="lg" colorScheme="green" />
                        </FormControl>
                      </VStack>
                      <Chart options={chartOptions} series={series} type="area" width="600" />
                    </VStack>
                  )}
                  <SavingsBreakdown
                    estimatedUsage={Number(userData.estimatedAnnualUsage)}
                    estimatedSystemSize={Number(optimizedOutput.roof["Max kW system"])}
                    requiresBattery={false}
                  />
                  <VStack shadow="lg" bgColor="white" p="20px" borderRadius="12px" w="full" alignItems="center" gap={0} spacing={0}>
                    <Text w="full" fontWeight="bold" fontSize="26px">
                      4. Your environmental impact
                    </Text>
                    {/* <Text fontWeight="bold" fontSize="20px">
                      Your environmental impact
                    </Text> */}
                    <Text fontSize="12px" color="gray.500" mb="20px">
                      over 25 years
                    </Text>
                    <HStack flexDirection={{ base: "column", md: "row" }} justifyContent="space-between">
                      <VStack bgColor="white" borderRadius="12px" p="40px">
                        <Image w="200px" h="180px" src={carbonFactory} />
                        <Text lineHeight={1} fontWeight="bold" fontSize="24px" color="black" mt="20px">
                          {new Intl.NumberFormat().format(Math.round(Number(optimizedOutput.roof["Max kWh/yr system"]) * 0.846 * 25))}
                        </Text>
                        <Text lineHeight={1} color="gray.600">
                          Pounds of carbon
                        </Text>
                      </VStack>
                      <Text fontSize="30px" fontWeight="bold" ml="20px" mr="20px">
                        =
                      </Text>
                      <VStack bgColor="white" borderRadius="12px" p="40px">
                        <Image w="200px" h="180px" src={tree} />
                        <Text lineHeight={1} fontWeight="bold" fontSize="24px" color="black" mt="20px">
                          {new Intl.NumberFormat().format(Math.round((Number(optimizedOutput.roof["Max kWh/yr system"]) * 0.846 * 25) / 48))}
                        </Text>
                        <Text lineHeight={1} color="gray.600">
                          Trees
                        </Text>
                      </VStack>
                    </HStack>
                  </VStack>
                  <VStack w="full" position="sticky" bottom="0px" gap="0px" zIndex={10}>
                    <Box
                      w="full"
                      h="180px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      pt="20px"
                      bg="linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(244,238,233,1) 30%)"
                    >
                      <Button
                        w="full"
                        h="60px"
                        fontSize="20px"
                        onClick={() => {
                          navigate("/installer-select");
                        }}
                        bgColor="black"
                        color="white"
                        _hover={{ bgColor: "blackAlpha.800" }}
                        rightIcon={<ArrowRightIcon w="16px" h="16px" />}
                      >
                        Continue
                      </Button>
                    </Box>
                  </VStack>
                </VStack>
              </HStack>
              <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent minW="40vw" fontFamily="'eurostile', sans-serif">
                  <ModalHeader>Important notice!</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody w="full" pt="40px">
                    <Text fontSize="20px">This is an initial design powered by AI to quickly determine if solar is a good fit for your home.</Text>
                    <Text fontSize="20px" mt="10px">
                      After you choose the company that will install your solar, we will create a professional design to sign off on.
                    </Text>
                  </ModalBody>

                  <ModalFooter>
                    <Button variant="ghost">Learn More</Button>
                    <Button ml="10px" variant="solid" bgColor="black" color="white" _hover={{ bgColor: "rgba(0,0,0,.8)" }} onClick={onClose}>
                      Got it!
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </Box>
          ) : (
            <Box w="full" minH="100vh" bg="#f4eee9">
              <VStack w="full" h="90vh" justifyContent="center">
                <Text w="full" textAlign="center" fontSize={{ base: "20px", lg: "40px" }}>
                  Creating your initial design
                </Text>
                <Text mb="20px">This may take a few minutes...</Text>
                <Image src={logoSpinner} w="60px" alt="cannot load loading component" className="spinner" />
                {/* <CircularProgress size="80px" isIndeterminate /> */}
              </VStack>
            </Box>
          )}
        </>
      )}
    </>
  );
};
export default KlaraviaStudio;

function PanelCounter({ initialPanelCount, setSelectedNumPanels }: { initialPanelCount: number; setSelectedNumPanels: (num: number) => void }) {
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps, valueAsNumber } = useNumberInput({
    step: 1,
    defaultValue: initialPanelCount,
    min: 1,
    precision: 0,
  });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  console.warn("ping!", initialPanelCount, valueAsNumber);

  const handleValueChange = () => {
    if (valueAsNumber > 1) {
      setSelectedNumPanels(valueAsNumber);
    }
  };

  return (
    <HStack maxW="320px" border="1px solid black" borderRadius="8px" gap="0px">
      <Button
        variant="ghost"
        _hover={{ borderLeftRadius: "8px", borderRightRadius: "0px", bgColor: "gray.100" }}
        borderRadius="12px"
        {...dec}
        pt="17px"
        display="flex"
        alignItems="flex-start"
        onClick={handleValueChange}
      >
        <Text fontSize="34px" lineHeight={0}>
          -
        </Text>
      </Button>
      <Input
        maxW="40px"
        textAlign="center"
        border="none"
        borderRadius="0px"
        p="0px"
        fontWeight="bold"
        pointerEvents="none"
        opacity={1}
        cursor="not-allowed"
        borderRight="1px solid gray"
        borderLeft="1px solid gray"
        {...input}
      />
      <Button
        maxW="20px"
        borderRadius="12px"
        p="0px"
        variant="ghost"
        _hover={{ borderLeftRadius: "0px", borderRightRadius: "8px", bgColor: "gray.100" }}
        {...inc}
        fontSize="24px"
        onClick={handleValueChange}
      >
        <Text fontSize="24px" lineHeight={0}>
          +{" "}
        </Text>
      </Button>
    </HStack>
  );
}
