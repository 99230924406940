import React, { useEffect, useState } from "react";
import { Box, HStack, VStack, Text } from "@chakra-ui/react";
import GradientLineChart from "../Components/GradientLineChart";
import { useGetInstaller } from "../../hooks/userHooks";
import { useAuth } from "../../Providers/AuthProvider";
import { useParams } from "react-router-dom";
import { Installer } from "../../API";

const Home = () => {
  const [dashData, setDashData] = useState(null);
  const [customerCount, setCustomerCount] = useState<string>("0");
  const { installer_id } = useParams();

  const { data: installerData, loading: loadingInstallerData, error: installerDataError } = useGetInstaller({ variables: { id: installer_id } });

  useEffect(() => {
    console.warn("inst", installerData);
    if (installerData && installerData.getInstaller && installerData.getInstaller.users) {
      //@ts-ignore
      const signedCustomers = installerData.getInstaller.users.items.filter((user) => user.signed_installer_contract === true);
      //@ts-ignore
      setCustomerCount(signedCustomers.length.toLocaleString());
    }
  }, [installerData]);

  return (
    <Box w="full" minH="90vh" p={{ base: "10px", md: "40px" }} bg="#f4eee9" fontFamily="'eurostile', sans-serif">
      {!dashData ? (
        <VStack w="full">
          <HStack w="full" justifyContent="space-between" gap="6" align="stretch" flexDirection={{ base: "column", md: "row" }}>
            <VStack w="full" p="20px" bgColor="white" shadow="lg" borderRadius="12px">
              <Text w="100%" fontSize="18px" fontWeight={500}>
                Platform Rating
              </Text>
              <HStack w="full" alignItems="flex-end">
                <Text fontSize="32px" fontWeight={600} mt="20px">
                  --
                </Text>
              </HStack>
            </VStack>
            <VStack w="full" p="20px" bgColor="white" shadow="lg" borderRadius="12px">
              <Text w="100%" fontSize="18px" fontWeight={500}>
                Clients in progress
              </Text>
              <Text w="100%" fontSize="32px" fontWeight={600} mt="20px">
                {customerCount}
              </Text>
            </VStack>
            <VStack w="full" p="20px" bgColor="white" shadow="lg" borderRadius="12px">
              <Text w="100%" fontSize="18px" fontWeight={500}>
                Clients serviced
              </Text>
              <Text w="100%" fontSize="32px" fontWeight={600} mt="20px">
                0
              </Text>
            </VStack>
            <VStack w="full" p="20px" bgColor="white" shadow="lg" borderRadius="12px">
              <Text w="100%" fontSize="18px" fontWeight={500}>
                Install Cancellations
              </Text>
              <Text w="100%" fontSize="32px" fontWeight={600} mt="20px">
                0
              </Text>
            </VStack>
            {/* <VStack w="full" p="20px" bgColor='white' shadow='lg' borderRadius="12px">
              <Text w="100%" fontSize="18px" fontWeight={500}>
                Avg Time to Completion
              </Text>
              <HStack w="full" alignItems="flex-end">
                <Text fontSize="32px" fontWeight={600} mt="20px">
                  0
                </Text>
                <Text color="gray.500" mb="6px">
                  days
                </Text>
              </HStack>
            </VStack> */}
          </HStack>
          <HStack w="full" mt="40px">
            <VStack w="full">
              {/* <Text w="full" fontSize="28px" fontWeight={500}>
                Revenue
              </Text> */}
              <VStack w="full" bgColor="white" shadow="lg" p="20px" borderRadius="12px">
                <HStack w="full">
                  <VStack w="full">
                    <Box w="full" overflow="hidden">
                      <GradientLineChart
                        data={[
                          0, 0, 0, 0, 4500, 4500, 9000, 13500, 18000, 22500, 27000, 31500, 40500, 49500, 63000, 76500, 94500, 11250, 13500, 15750, 18450, 21150, 24300, 27900,
                          31500, 35550, 40050, 45000, 50400, 56250, 62550,
                        ]}
                      />
                    </Box>
                  </VStack>
                </HStack>
              </VStack>
            </VStack>
          </HStack>
        </VStack>
      ) : (
        <VStack w="full" minH="80vh">
          <Text></Text>
        </VStack>
      )}
    </Box>
  );
};

export default Home;
