import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Star,
  Clock,
  Shield,
  Award,
  Calendar,
  ChevronRight,
  X,
  MapPin,
} from 'lucide-react';
import { Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { UserUpdateOptions } from '../../API';
import { useListInstallers } from "../../hooks/userHooks";
import { useUserDataContext } from '../../Providers/LeadDataProvider';
import CSignUpModal from '../Components/CSignUpModal';
import { useAuth as useAuthentication } from '../../Providers/AuthenticationProvider';

const EquipmentSelector = ({ equipment, onSelect, type, currentSelection, isExpanded }) => {
 return (
   <motion.div
     initial={false}
     animate={{ height: isExpanded ? 'auto' : 0 }}
     className="overflow-hidden"
   >
     <div className="py-4 space-y-4">
       {equipment.map((item) => {
         const isSelected = currentSelection ? currentSelection?.id === item.id : false;
         return (
           <motion.div
             key={item.id}
             className={`rounded-lg p-4 cursor-pointer border transition-all
               ${isSelected 
                 ? 'border-green-500 bg-green-500/10' 
                 : 'border-white/10 bg-white/5 hover:bg-white/10'}`}
             onClick={() => onSelect(isSelected ? null : item)}
           >
             <div className="flex justify-between items-start">
               <div>
                 <div className="flex items-center space-x-2">
                   <h4 className="text-lg font-semibold text-blue-50">
                     {item.manufacturer} {item.seriesName}
                   </h4>
                   {isSelected && (
                     <div className="bg-green-500/20 text-green-500 px-2 py-1 rounded-md text-xs">
                       Added
                     </div>
                   )}
                 </div>
                 <div className="text-blue-50/70 text-sm mt-1">
                   {type === 'panel' ? (
                     `${item.pMax}W • ${item.efficiency}% Efficiency`
                   ) : (
                     `${item.storage_capacity} kWh • ${item.power_output}kW Power`
                   )}
                 </div>
               </div>
               {type === 'panel' && (
               <div className="text-right">
                 <div className="text-blue-50/50 text-sm">
                   {`${item.warrantyYears} Year Warranty`}
                 </div>
               </div>)}
             </div>
             {/* <div className="mt-4 grid md:grid-cols-2 gap-4">
               {item.features.slice(0, 2).map((feature, index) => (
                 <div key={index} className="flex items-center text-sm">
                   <CheckCircle className="w-4 h-4 text-green-500 mr-2" />
                   <span className="text-blue-50/70">{feature}</span>
                 </div>
               ))}
             </div> */}
           </motion.div>
         );
       })}
     </div>
   </motion.div>
 );
};

const InstallerComparison = () => {
  const [selectedInstaller, setSelectedInstaller] = useState(null);
  const [sortBy, setSortBy] = useState('price');
  const [installers, setInstallers] = useState(null);
  const navigate = useNavigate();
  const { data: installerList, loading: loadingInstallers, error: installersError } = useListInstallers();
  const { userData, setUserData } = useUserDataContext();
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  const [selectedInstallerForSignup, setSelectedInstallerForSignup] = useState(null);
  const { isAuthenticated } = useAuthentication();

  useEffect(() => {
    if(installerList && installerList.listInstallers && installerList.listInstallers.items && installerList.listInstallers.items.length > 0){
      const sortedInstallers = [...installerList.listInstallers.items];
      setInstallers(sortedInstallers.sort((a,b) => b.company_red_line - a.company_red_line));
    }
  }, [installerList])

  const [filters, setFilters] = useState({
    maxPrice: null,
    minRating: null,
    maxInstallTime: null
  });

  const filterInstallers = (filter) => {
    setSortBy(filter.target.value)
    const sortedInstallers = [...installers];
    
    switch(filter.target.value) {
      case 'price':
        setInstallers(sortedInstallers.sort((a,b) => a.company_red_line - b.company_red_line));
        break;
      case 'rating':
        setInstallers(sortedInstallers.sort((a,b) => b.installer_rating - a.installer_rating));
        break;
      case 'speed':
        setInstallers(sortedInstallers.sort((a,b) => a.average_install_time - b.average_install_time));
        break;
      default:
        setInstallers(sortedInstallers.sort((a,b) => b.company_red_line - a.company_red_line));
        break;
    }
  };

  // Example installer data structure for reference
  const exampleInstaller = {
    id: 1,
    name: "SolarPro Installations",
    rating: 4.8,
    reviews: 156,
    price: 25000,
    installTime: 14, // days
    experience: 12, // years
    completedInstalls: 1500,
    warrantyYears: 25,
    description: "Leading solar installer with...",
    certifications: ["NABCEP Certified", "Tesla Certified"],
    images: ["/installer1.jpg"],
    highlights: [
      "Premium Equipment Partners",
      "In-house Installation Teams",
      "24/7 Support"
    ],
    recentProjects: [
      { address: "123 Solar St", size: "8.4kW", completion: "Jan 2024" }
    ],
    equipment: {
      panels: [
        {
          id: 'lg-neon',
          brand: 'LG',
          model: 'NeON 2',
          wattage: 400,
          efficiency: 21.8,
          warranty: 25,
          price: 'premium',
          features: [
            'High efficiency in low light',
            'Enhanced performance warranty',
            'Sleek black design'
          ]
        },
        // Add more panel options...
      ],
      batteries: [
        {
          id: 'tesla-pw2',
          brand: 'Tesla',
          model: 'Powerwall 2',
          capacity: 13.5,
          power: 7.0,
          warranty: 10,
          price: 'premium',
          features: [
            'Integrated inverter',
            'Mobile app monitoring',
            'Weather-resistant design'
          ]
        },
        // Add more battery options...
      ]
    }
  
  };

  // const installers = [exampleInstaller]

  const handleInstallerChosen = async (chosenInstaller) => {
    const authenticated = await isAuthenticated();
    if (!authenticated) {
      setSelectedInstallerForSignup(chosenInstaller);
      setIsSignUpModalOpen(true);
      return;
    }

            setUserData((prevUserData) => {
              if (
                JSON.stringify(prevUserData) !==
                JSON.stringify({
                  ...prevUserData,
                  installerId: chosenInstaller.id,
                  installerSalesforceId: chosenInstaller.salesforceId,
                  updated_by: UserUpdateOptions.DYNAMO,
                })
              ) {
                return {
                  ...prevUserData,
                  installerId: chosenInstaller.id,
                  installerSalesforceId: chosenInstaller.salesforceId,
                  updated_by: UserUpdateOptions.DYNAMO,
                };
              }
              return prevUserData;
            });
            navigate("/bill-upload");
          };

  const DetailModal = ({ installer, onClose }) => {
    const [selectedPanel, setSelectedPanel] = useState(installer.available_panels[0]);
    const [selectedBattery, setSelectedBattery] = useState(userData.requires_battery && userData.requires_battery === true ? installer.available_batteries[0] : null);
    const [isEquipmentExpanded, setIsEquipmentExpanded] = useState(false);

    const handleAddBattery = () => {
      setUserData((prevUserData) => {
        if (
          JSON.stringify(prevUserData) !==
          JSON.stringify({
            ...prevUserData,
            requires_battery: true,
            updated_by: UserUpdateOptions.DYNAMO,
          })
        ) {
          return {
            ...prevUserData,
            requires_battery: true,
            updated_by: UserUpdateOptions.DYNAMO,
          };
        }
        return prevUserData;
      });
    setSelectedBattery(installer.available_batteries[0]);
    }

    const handleRemoveBattery = () => {
            setUserData((prevUserData) => {
              if (
                JSON.stringify(prevUserData) !==
                JSON.stringify({
                  ...prevUserData,
                  requires_battery: false,
                  updated_by: UserUpdateOptions.DYNAMO,
                })
              ) {
                return {
                  ...prevUserData,
                  requires_battery: false,
                  updated_by: UserUpdateOptions.DYNAMO,
                };
              }
              return prevUserData;
            });
      setSelectedBattery(null);
    }
  
    return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4"
      onClick={onClose}
    >
      <motion.div
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.95, opacity: 0 }}
        className="bg-[#121212] rounded-xl max-w-4xl w-full max-h-[90vh] overflow-y-auto"
        onClick={e => e.stopPropagation()}
      >
        {/* Header */}
        <div className="sticky top-0 bg-[#121212] p-6 border-b border-white/10">
          <div className="flex justify-between items-start mb-4">
            <div>
              <h2 className="text-2xl font-bold text-blue-50 mb-2">{installer.company_name}</h2>
              <div className="flex items-center space-x-4 text-blue-50/70">
                <span className="flex items-center">
                  <Star className="w-4 h-4 text-amber-500 mr-1" fill="currentColor" />
                  {installer.installer_rating} ({installer.reviews} reviews)
                </span>
                <span className="flex items-center">
                  <MapPin className="w-4 h-4 mr-1" />
                  Local to your area
                </span>
              </div>
            </div>
            <button
              onClick={onClose}
              className="text-blue-50/50 hover:text-blue-50 transition-colors"
            >
              <X className="w-6 h-6" />
            </button>
          </div>

          {/* Quick Stats */}
          <div className="grid grid-cols-2 gap-4">
            {[
              { icon: Calendar, label: "Years Experience", value: installer.years_in_business },
              // { icon: Users, label: "Installations", value: installer.completedInstalls },
              // { icon: Shield, label: "Warranty", value: `${installer.warrantyYears} Years` },
              { icon: Clock, label: "Avg. Install Time", value: `${installer.average_install_time} Days` }
            ].map((stat, index) => (
              <div key={index} className="bg-white/5 rounded-lg p-4">
                <stat.icon className="w-5 h-5 text-amber-500 mb-2" />
                <div className="text-xl font-bold text-blue-50">{stat.value}</div>
                <div className="text-sm text-blue-50/50">{stat.label}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="p-6 space-y-8">
          {/* Description */}
          <div>
            <h3 className="text-xl font-semibold text-blue-50 mb-4">About {installer.company_name}</h3>
            <p className="text-blue-50/70">{installer.company_about_text}</p>
          </div>

          {/* Certifications */}
          {/* <div>
            <h3 className="text-xl font-semibold text-blue-50 mb-4">Certifications</h3>
            <div className="flex flex-wrap gap-3">
              {installer.certifications.map((cert, index) => (
                <div key={index} className="bg-blue-500/10 text-blue-50 px-3 py-1 rounded-3xl text-sm">
                  {cert}
                </div>
              ))}
            </div>
          </div> */}

          {/* Key Highlights */}
          {/* <div>
            <h3 className="text-xl font-semibold text-blue-50 mb-4">Highlights</h3>
            <div className="grid md:grid-cols-2 gap-4">
              {installer.highlights.map((highlight, index) => (
                <div key={index} className="flex items-center space-x-3">
                  <CheckCircle className="w-5 h-5 text-green-500" />
                  <span className="text-blue-50/70">{highlight}</span>
                </div>
              ))}
            </div>
          </div> */}

          <div className="p-6 space-y-8">
      {/* Add this section for equipment selection */}
      <div className="space-y-6">
        <div className="flex justify-between items-center">
          <h3 className="text-xl font-semibold text-blue-50">Equipment Options</h3>
          <button
            onClick={() => setIsEquipmentExpanded(!isEquipmentExpanded)}
            className="text-amber-500/70 hover:text-amber-500 flex items-center"
          >
            {isEquipmentExpanded ? 'Hide' : 'Show'} Options
            <ChevronRight
              className={`ml-1 w-4 h-4 transform transition-transform
                ${isEquipmentExpanded ? 'rotate-90' : ''}`}
            />
          </button>
        </div>

        <div className="space-y-6">
          <div>
            <h4 className="text-lg font-semibold text-blue-50 mb-2">Solar Panels</h4>
            <EquipmentSelector
              equipment={installer.available_panels}
              type="panel"
              currentSelection={selectedPanel}
              onSelect={setSelectedPanel}
              isExpanded={isEquipmentExpanded}
            />
          </div>

          <div>
            <div className="flex justify-between items-center mb-2">
              <h4 className="text-lg font-semibold text-blue-50">Battery Storage</h4>
              {selectedBattery && (
                <button
                  onClick={handleRemoveBattery}
                  className="text-sm text-red-500/75 hover:text-red-500"
                >
                  Remove Battery
                </button>
              )}
            </div>
            <EquipmentSelector
              equipment={installer.available_batteries}
              type="battery"
              currentSelection={selectedBattery}
              onSelect={handleAddBattery}
              isExpanded={isEquipmentExpanded}
            />
          </div>
        </div>

        {/* Updated pricing section */}
        <div className="bg-white/5 rounded-lg p-4">
          <div className="flex justify-between items-center mb-4">
            <span className="text-blue-50">Total System Cost</span>
            <span className="text-2xl font-bold text-blue-50">
              ${ calculateTotalCost(((Number(installer.company_red_line) * 1000 * userData?.google_system_size + 400 * Number(userData?.google_system_size)) * 0.7), selectedPanel, selectedBattery)}
            </span>
          </div>
          <div className="space-y-2 text-sm">
            {/* ... pricing breakdown ... */}
          </div>
        </div>
      </div>
      </div>

          {/* Recent Projects */}
          {/* <div>
            <h3 className="text-xl font-semibold text-blue-50 mb-4">Recent Projects</h3>
            <div className="grid md:grid-cols-3 gap-4">
              {installer.recentProjects.map((project, index) => (
                <div key={index} className="bg-white/5 rounded-lg p-4">
                  <div className="text-blue-50 font-medium mb-1">{project.size} System</div>
                  <div className="text-blue-50/50 text-sm">{project.address}</div>
                  <div className="text-blue-50/50 text-sm">{project.completion}</div>
                </div>
              ))}
            </div>
          </div> */}

          {/* Call to Action */}
          <div className="bg-gradient-to-r from-amber-500/20 to-amber-600/20 rounded-lg p-6 text-center">
            <h3 className="text-xl font-semibold text-blue-50 mb-2">Ready to move forward?</h3>
            <p className="text-blue-50/70 mb-4">
              Choose {installer.company_name} as your installation partner
            </p>
            <button
              onClick={() => handleInstallerChosen(installer)}
              className="bg-amber-500 text-[#121212] px-6 py-3 rounded-lg font-semibold hover:bg-amber-400 transition-colors"
            >
              Select {installer.company_name}
            </button>
          </div>
        </div>
      </motion.div>
    </motion.div>
  )};

  if(!installers){
    return (<></>)
  }

  const handleSignupSuccess = () => {
    setIsSignUpModalOpen(false);
    if (selectedInstallerForSignup) {
      handleInstallerChosen(selectedInstallerForSignup);
    }
  };

  return (
    <div className="min-h-screen bg-[#121212] py-24 px-4">
      <div className="max-w-6xl mx-auto">
        {/* Header */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-blue-50 mb-4">
            Chose Your Install Company
          </h1>
          <p className="text-xl text-blue-50/70">
            Choose from our network of vetted, high-quality installers
          </p>
        </div>

        {/* Filters & Sorting */}
        <div className="bg-white/5 rounded-lg p-4 mb-8">
        <Box display='flex' flexDirection={{base: 'column', md: 'row'}} justifyContent='space-between' alignItems='center' >
            <div className="flex space-x-4">
              <select
                value={sortBy}
                onChange={(e) => filterInstallers(e)}
                className="bg-white/5 border-2 border-white/10 rounded-lg px-4 py-2 text-blue-50"
              >
                {/* <option value="recommended">Recommended</option> */}
                <option value="price">Lowest Price</option>
                <option value="rating">Highest Rated</option>
                <option value="speed">Fastest Installation</option>
              </select>
              {/* Add more filters as needed */}
            </div>
            <Box mt={{base: 2, md: 0}} className="text-blue-50/50">
              Showing {installers.length} qualified installers
            </Box>
          </Box>
        </div>

        {/* Installer Cards */}
        <div className="grid gap-6">
          {installers.map((installer) => (
            <motion.div
              key={installer.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-white/5 p-6 hover:bg-white/10 transition-colors  border-2 border-white/50 rounded-lg cursor-pointer"
              onClick={(e) => setSelectedInstaller(installer)}
            >
                   <Box display='flex' flexDirection={{base: 'column', md: 'row'}} justifyContent='space-between' >
                <div>
                  <h2 className="text-2xl font-bold text-blue-50 mb-2">
                    {installer.company_name}
                  </h2>
                  <Box display='flex' flexDirection={{base: 'column', md: 'row'}} gap={{base: 2, md: 6}} justifyContent={{base: 'flex-start', md: 'space-between'}} className="text-blue-50/70">
                    <span className="flex items-center">
                      <Star className="w-4 h-4 text-amber-500 mr-1" fill="currentColor" />
                      {installer.installer_rating}
                    </span>
                    <span className="flex items-center">
                      <Clock className="w-4 h-4 mr-1" />
                      {installer.average_install_time} days avg. install time
                    </span>
                    <span className="flex items-center">
                      <Award className="w-4 h-4 mr-1" />
                      {installer.years_in_business} years experience
                    </span>
                  </Box>
                </div>
                <Box mt={{base: 4, md: 0}} className="text-right">
                  <div className="text-3xl font-bold text-blue-50 mb-1">
                    ${userData.requires_battery && userData.requires_battery === true ? (((Number(installer.company_red_line) * 1000 * userData?.google_system_size + 400 * Number(userData?.google_system_size)) * 0.7 +
                                              installer.available_batteries[0].additional_cost).toLocaleString()) : (((Number(installer.company_red_line) * 1000 * userData?.google_system_size + 400 * Number(userData?.google_system_size)) * 0.7).toLocaleString())}
                  </div>
                  <div className="text-blue-50/50 text-sm">
                    Total cost after incentives
                  </div>
                </Box>
              </Box>

              {/* Key Differentiators */}
              {/* <div className="grid md:grid-cols-3 gap-4 mb-6">
                {installer.highlights.slice(0, 3).map((highlight, index) => (
                  <div key={index} className="flex items-center space-x-2 text-blue-50/70">
                    <CheckCircle className="w-4 h-4 text-green-500" />
                    <span>{highlight}</span>
                  </div>
                ))}
              </div> */}

              {/* Actions */}
              <div className="flex justify-between items-center mt-4">
                <button
                  onClick={() => setSelectedInstaller(installer)}
                  className="text-amber-500 hover:text-amber-400 transition-colors flex items-center"
                >
                  View Details
                  <ChevronRight className="w-4 h-4 ml-1" />
                </button>
                <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleInstallerChosen(installer);
                    }}
                  className="bg-amber-500 text-[#121212] px-6 py-2 rounded-lg font-semibold hover:bg-amber-400 transition-colors"
                >
                  Select Installer
                </button>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Quality Guarantee */}
        <div className="mt-12 bg-blue-500/10 rounded-lg p-6 flex items-start space-x-4">
          <Shield className="w-6 h-6 text-blue-400 flex-shrink-0" />
          <div>
            <h3 className="text-lg font-semibold text-blue-50 mb-1">
              Klaravia Quality Guarantee
            </h3>
            <p className="text-blue-50/70">
              All installation partners are thoroughly vetted, licensed, and insured. 
              We monitor performance and customer satisfaction to ensure the highest quality service.
            </p>
          </div>
        </div>

        {/* Detail Modal */}
        <AnimatePresence>
          {selectedInstaller && (
            <DetailModal
              installer={selectedInstaller}
              onClose={() => setSelectedInstaller(null)}
            />
          )}
        </AnimatePresence>
      </div>
      <CSignUpModal
        isOpen={isSignUpModalOpen}
        onClose={() => {
          setIsSignUpModalOpen(false);
          setSelectedInstallerForSignup(null);
        }}
        onSignUpSuccess={handleSignupSuccess}
        installer={selectedInstallerForSignup}
      />
    </div>
  );
};

const calculateTotalCost = (basePrice, panel, battery) => {
  let total = basePrice;
  if (panel.additional_cost) total += panel.additional_cost;
  if (battery) total += battery.additional_cost;
  return total.toLocaleString();
};

export default InstallerComparison;