import React, { useState } from "react";
import { Box, Button, Checkbox, FormControl, Heading, HStack, IconButton, Input, InputGroup, InputLeftElement, InputRightElement, Link, Stack, Text } from "@chakra-ui/react";
import { ViewOffIcon, ViewIcon, PhoneIcon } from "@chakra-ui/icons";
import { Auth } from "aws-amplify";
import InstallerVerification from "../Components/InstallerVerification";
const InstallerSignUp = () => {
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showVerify, setShowVerify] = useState(false);
  const [error, setError] = useState("");
  const [step, setStep] = useState(1);

  const handleClick = () => setShowPassword(!showPassword);

  const handleSignUp = async () => {
    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email, // email is required
          "custom:business_name": companyName,
          "custom:pool_group": "dev_installer", // Hardcoded pool group
          phone_number: phoneNumber, // phone number is in E.164 format
          // You can add other user attributes here
        },
      });
      // On successful sign up, you might want to redirect the user to a confirmation page
      setShowVerify(true);
      console.log("Sign-up successful!");
    } catch (error: any) {
      setError(error.message);
      console.error("Error during sign-up:", error);
    }
  };

  return (
    <Box w="full" minH="100vh" bgColor="#f4eee9" p="10px" pt="10vh" display="flex" justifyContent="center">
      <Box w={step === 2 ? "80vw" : "lg"} py={{ base: "2", md: "24" }} px={{ base: "0", sm: "8" }}>
        {showVerify ? (
          <InstallerVerification email={email} password={password} />
        ) : (
          <Stack spacing="8">
            <Stack lineHeight={1}>
              <Text w="full" textAlign="center" fontSize="4xl" fontWeight={500}>
                Klaravia
              </Text>
              <Text w="full" textAlign="center" fontSize="2xl" fontWeight={500}>
                Installer Portal
              </Text>
              <Stack spacing={{ base: "2", md: "3" }} textAlign="center" mt="40px">
                <Heading size={{ base: "xs", md: "sm" }}>Sign up for a solar installer account</Heading>
              </Stack>
            </Stack>
            <Box
              py={{ base: "8", sm: "8" }}
              px={{ base: "4", sm: "10" }}
              bg={{ base: "white", sm: "bg.surface" }}
              boxShadow={{ base: "none", sm: "md" }}
              borderRadius={{ base: "lg", sm: "xl" }}
            >
              <Stack spacing="6">
                <Stack spacing="5">
                  <FormControl>
                    <Input
                      id="company-name"
                      type="text"
                      placeholder="Company Name"
                      border="1px solid rgb(238, 239, 241)"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </FormControl>
                  <FormControl>
                    <Input id="email" type="email" placeholder="Email" border="1px solid rgb(238, 239, 241)" value={email} onChange={(e) => setEmail(e.target.value)} />
                  </FormControl>
                  <FormControl>
                    <InputGroup size="md">
                      <Input
                        pr="4.5rem"
                        type={showPassword ? "text" : "password"}
                        border="1px solid rgb(238, 239, 241)"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <InputRightElement width="3rem">
                        <IconButton h="1.75rem" aria-label="Password" size="sm" icon={showPassword ? <ViewOffIcon /> : <ViewIcon />} onClick={handleClick} />
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                  <FormControl>
                    <InputGroup>
                      <InputLeftElement pointerEvents="none">
                        <PhoneIcon color="gray.300" />
                      </InputLeftElement>
                      <Input id="phone" type="tel" placeholder="(***) *** ****" />
                    </InputGroup>
                  </FormControl>
                </Stack>
                <Text color="red.500">{error}</Text>
                <Button onClick={handleSignUp}>Sign Up</Button>
                <HStack justify="space-between">
                  <Checkbox defaultChecked>Remember me</Checkbox>
                  <Text>
                    Have an account?{" "}
                    <Link color="#4810a3" pl="4px" href="/installer/log-in">
                      Log in
                    </Link>
                  </Text>
                </HStack>
              </Stack>
            </Box>
          </Stack>
        )}
      </Box>
    </Box>
  );
};
export default InstallerSignUp;
