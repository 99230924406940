import React, { useEffect, useState } from "react";
import { useBreakpoint } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import doorSalesman from "../Blogs/BlogImages/doorSalesman.webp";
import solarTown from "../Blogs/BlogImages/solarTown.webp";
import questionInABottle from "../Blogs/BlogImages/questionInABottle.webp";
import solarCalc from "../Blogs/BlogImages/solarCalc.webp";
import homeVal from "../Blogs/BlogImages/aiHomeValueBlog.jpg";
import { BookOpen, User, Clock, ChevronRight, Filter } from "lucide-react";

const featuredBlog = {
  title: "Shining a Light on Solar",
  subTitle: "How Klaravia is Fighting for Homeowners",
  coverImage: doorSalesman,
  category: ["Solar Problems", "Financial Considerations"],
  link: "/blogs/shining-a-light-on-solar",
};

const blogs = [
  {
    title: "Shining a Light on Solar",
    subTitle: "How Klaravia is Fighting for Homeowners",
    coverImage: doorSalesman,
    category: ["Solar Problems", "Financial Considerations"],
    link: "/blogs/shining-a-light-on-solar",
  },
  {
    title: "To Solar or not to Solar?",
    subTitle: "The benefits and drawbacks of installing solar as a homeowner.",
    coverImage: solarTown,
    category: ["Solar Basics", "Financial Considerations"],
    link: "/blogs/to-solar-or-not-to-solar",
  },
  {
    title: "Top 10 Solar Questions Answered",
    subTitle: "Understand solar better by seeing what others have to ask.",
    coverImage: questionInABottle,
    category: ["Solar Basics", "Financial Considerations", "Environmental Impact"],
    link: "/blogs/top-10-solar-questions-answered",
  },
  {
    title: "Understanding Solar Roof Tax Incentives",
    subTitle: "Navigate the 2024 solar incentives and maximize your savings",
    coverImage: solarCalc,
    category: ["Financial Considerations", "Policy and Regulation"],
    link: "/blogs/understanding-solar-roof-tax-incentives",
  },
  {
    title: "Will Solar Increase My Home's Value?",
    subTitle: "Here's what you need to know",
    coverImage: homeVal,
    category: ["Financial Considerations", "Solar Basics"],
    link: "/blogs/will-solar-increase-my-home-value",
  },
  {
    title: "The Future of Solar Storage",
    subTitle: "Breaking down the latest innovations in battery technology",
    coverImage: solarTown,
    category: ["Technology", "Solar Innovation"],
    link: "/blogs/future-of-solar-storage",
  },
  // {
  //   title: "Solar Panel Maintenance 101",
  //   subTitle: "Essential tips to keep your solar system performing optimally",
  //   coverImage: solarTown,
  //   category: ["Maintenance", "Solar Basics"],
  //   link: "/blogs/solar-panel-maintenance-101",
  // },
  // {
  //   title: "Comparing Solar Financing Options",
  //   subTitle: "Loans vs. Leases vs. PPAs: Which is right for you?",
  //   coverImage: solarTown,
  //   category: ["Financial Considerations", "Solar Basics"],
  //   link: "/blogs/comparing-solar-financing-options",
  // },
  // {
  //   title: "Net Metering Explained",
  //   subTitle: "Understanding how solar credits work with your utility",
  //   coverImage: solarTown,
  //   category: ["Policy and Regulation", "Financial Considerations"],
  //   link: "/blogs/net-metering-explained",
  // },
  // {
  //   title: "Seasonal Solar Production",
  //   subTitle: "How weather affects your solar panel efficiency",
  //   coverImage: solarTown,
  //   category: ["Solar Basics", "Technology"],
  //   link: "/blogs/seasonal-solar-production",
  // },
  // {
  //   title: "Smart Home Integration with Solar",
  //   subTitle: "Maximizing efficiency through home automation",
  //   coverImage: solarTown,
  //   category: ["Technology", "Solar Innovation"],
  //   link: "/blogs/smart-home-solar-integration",
  // },
  // {
  //   title: "Commercial vs. Residential Solar",
  //   subTitle: "Key differences in scale, cost, and implementation",
  //   coverImage: solarTown,
  //   category: ["Solar Basics", "Financial Considerations"],
  //   link: "/blogs/commercial-vs-residential-solar",
  // },
];

const categories = [
  "All Categories",
  "Solar Basics",
  "Technology",
  "Solar Innovation",
  "Solar Problems",
  "Financial Considerations",
  "Installation Process",
  "Maintenance",
  "Energy Savings and Efficiency",
  "Environmental Impact",
  "Policy and Regulation",
  "Smart Home Integration",
  "Commercial Solar",
  "Battery Storage",
];

const BlogsIntro = () => {
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState("All Categories");
  const [filteredBlogs, setFilteredBlogs] = useState(blogs);
  const [featuredBlog, setFeaturedBlog] = useState(blogs[0]);
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const bp = useBreakpoint();

  const handleBlogClick = (link: string) => {
    navigate(link);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const filtered = selectedCategory === "All Categories" ? blogs : blogs.filter((blog) => blog.category.includes(selectedCategory));

    setFilteredBlogs(filtered);
    setFeaturedBlog(filtered[0] || null);
  }, [selectedCategory, blogs]);

  return (
    <div className="min-h-screen bg-[#121212] pt-24 pb-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header Section */}
        <div className="text-center mb-16">
          <span className="inline-flex items-center px-3 py-1 rounded text-sm font-medium bg-purple-500/10 text-purple-400">
            <BookOpen className="w-4 h-4 mr-2" />
            Educational Blogs
          </span>
          <h1 className="mt-6 text-3xl md:text-4xl lg:text-5xl font-bold text-white">Browse Our Resources</h1>
          <p className="mt-4 text-lg text-gray-400 max-w-2xl mx-auto">We're committed to providing you with high quality educational resources on solar.</p>
        </div>

        {/* Category Filter Section */}
        <div className="mb-12">
          {/* Mobile Filter Button */}
          <button className="md:hidden flex items-center space-x-2 text-white bg-white/10 px-4 py-2 rounded-lg mb-4" onClick={() => setShowMobileFilters(!showMobileFilters)}>
            <Filter className="w-4 h-4" />
            <span>{selectedCategory}</span>
          </button>

          {/* Mobile Filter Dropdown */}
          {showMobileFilters && (
            <div className="md:hidden absolute left-0 right-0 bg-[#121212] p-4 z-50 mt-2 mx-4 rounded-lg shadow-lg">
              <div className="grid grid-cols-1 gap-2">
                {categories.map((category) => (
                  <button
                    key={category}
                    onClick={() => {
                      setSelectedCategory(category);
                      setShowMobileFilters(false);
                    }}
                    className={`text-left px-4 py-2 rounded-lg transition-colors ${
                      selectedCategory === category ? "bg-yellow-500 text-[#121212]" : "text-white hover:bg-white/10"
                    }`}
                  >
                    {category}
                  </button>
                ))}
              </div>
            </div>
          )}

          {/* Desktop Category Pills */}
          <div className="flex-wrap gap-2 justify-center" style={{ display: bp === "sm" ? "none" : "flex" }}>
            {categories.map((category) => (
              <button
                key={category}
                onClick={() => setSelectedCategory(category)}
                className={`px-4 py-2 rounded-lg text-sm transition-colors ${
                  selectedCategory === category ? "bg-yellow-500 text-[#121212]" : "bg-white/10 text-white hover:bg-white/20"
                }`}
              >
                {category}
              </button>
            ))}
          </div>
        </div>

        {/* Results Counter */}
        <div className="mb-8 text-gray-400">
          Showing {filteredBlogs.length} {filteredBlogs.length === 1 ? "result" : "results"}
          {selectedCategory !== "All Categories" && <span> in {selectedCategory}</span>}
        </div>

        {/* Featured Post */}
        {featuredBlog && (
          <div onClick={() => handleBlogClick(featuredBlog.link)} className="group relative mb-16 cursor-pointer">
            <div className="overflow-hidden rounded-2xl bg-white/5 hover:bg-white/10 transition-colors duration-300">
              <div className="lg:flex">
                <div className="lg:w-7/12 relative overflow-hidden">
                  <div className="aspect-[16/9] lg:aspect-[4/3]">
                    <img
                      src={featuredBlog.coverImage}
                      alt={featuredBlog.title}
                      className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-500"
                    />
                  </div>
                </div>
                <div className="p-6 lg:p-8 lg:w-5/12 flex flex-col justify-between">
                  <div>
                    <div className="flex flex-wrap gap-2 mb-4">
                      {featuredBlog.category.map((cat) => (
                        <span key={cat} className="text-xs px-2 py-1 bg-white/10 rounded-lg text-gray-300">
                          {cat}
                        </span>
                      ))}
                    </div>
                    <h2 className="text-2xl font-bold text-white mb-4 group-hover:text-yellow-500 transition-colors">{featuredBlog.title}</h2>
                    <p className="text-gray-400 mb-6">{featuredBlog.subTitle}</p>
                  </div>
                  <div className="flex items-center justify-between text-sm text-gray-500">
                    <div className="flex items-center">
                      <User className="w-4 h-4 mr-2" />
                      Josiah Longenecker
                    </div>
                    <div className="flex items-center">
                      <Clock className="w-4 h-4 mr-2" />
                      5min read
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Other Posts Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 lg:gap-8">
          {filteredBlogs.slice(1).map((blog, index) => (
            <div key={index} onClick={() => handleBlogClick(blog.link)} className="group cursor-pointer">
              <div className="rounded-2xl overflow-hidden bg-white/5 hover:bg-white/10 transition-colors duration-300">
                <div className="relative overflow-hidden aspect-[16/9]">
                  <img src={blog.coverImage} alt={blog.title} className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-500" />
                </div>
                <div className="p-6">
                  <div className="flex flex-wrap gap-2 mb-3">
                    {blog.category.map((cat) => (
                      <span key={cat} className="text-xs px-2 py-1 bg-white/10 rounded-lg text-gray-300">
                        {cat}
                      </span>
                    ))}
                  </div>
                  <h3 className="text-xl font-bold text-white mb-3 group-hover:text-yellow-500 transition-colors">{blog.title}</h3>
                  <p className="text-sm text-gray-400 mb-4">{blog.subTitle}</p>
                  <div className="flex items-center text-yellow-500 text-sm font-medium">
                    Read More
                    <ChevronRight className="w-4 h-4 ml-1 group-hover:translate-x-1 transition-transform" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* No Results Message */}
        {filteredBlogs.length === 0 && (
          <div className="text-center py-12">
            <p className="text-gray-400 text-lg">No articles found in this category.</p>
            <button onClick={() => setSelectedCategory("All Categories")} className="mt-4 text-yellow-500 hover:text-yellow-400">
              View all articles
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogsIntro;
