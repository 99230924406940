import React, { useState } from "react";
import {
  Box,
  VStack,
  Text,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Heading,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { FileText, Download, Upload, Info, AlertCircle } from "lucide-react";
import { useAuth } from "../../Providers/AuthenticationProvider";
import PDFViewer from "../Components/PDFViewer";

const UserDocumentsPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: pictureIsOpen, onOpen: pictureOnOpen, onClose: pictureOnClose } = useDisclosure();
  const [pictureSrc, setPictureSrc] = useState<string | null>(null);
  const btnRef = React.useRef();
  const { userData } = useAuth();

  return (
    <Box w="full" minH="92vh" p={{ base: "10px", lg: "40px" }} className="bg-[#1f1f1f]" fontFamily="'eurostile', sans-serif">
      {(userData && userData.user_images && userData.user_images.length > 0) || (userData && userData.installer && userData.installer.user_agreement) ? (
        <VStack w="full" spacing={8}>
          <Text fontSize={{ base: "32px", lg: "54px" }} fontWeight="bold" className="text-blue-50">
            Your Documents
          </Text>
          <VStack w="full" alignItems="flex-start" spacing={6}>
            {userData && userData.final_payment_invoice && (
              <VStack w="full">
                <HStack align="center" spacing={4} w="full">
                  <FileText className="w-8 h-8 text-amber-500" />
                  <Text fontSize={{ base: "24px", lg: "32px" }} fontWeight="bold" className="text-blue-50">
                    Total Remaining Payment Invoice
                  </Text>
                </HStack>
                <VStack w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p="14px" spacing={4}>
                  <Text className="text-blue-50/70">
                    This is the invoice for the remainder of what you owe for your system. Please make a check out to Klaravia Inc for the amount indicated above.
                  </Text>
                  <Box w="full" className="rounded-lg shadow-lg cursor-pointer hover:shadow-lg">
                    <PDFViewer pdfUrl={userData?.installer?.user_agreement} />
                  </Box>
                </VStack>
              </VStack>
            )}
            {userData && userData.installer && userData.installer.user_agreement && (
              <VStack w="full">
                <HStack align="center" spacing={4} w="full">
                  <FileText className="w-8 h-8 text-amber-500" />
                  <Text fontSize={{ base: "24px", lg: "32px" }} fontWeight="bold" className="text-blue-50" my={2}>
                    Installer Agreement
                  </Text>
                </HStack>
                <VStack w="full" className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm" p="14px" spacing={4}>
                  <Text className="text-blue-50/70">
                    You can review your agreement with your installer here, but the actual agreement will be sent to {userData && userData.email ? userData.email : "your email"}{" "}
                    for you to sign by {userData.installer ? userData.installer.company_name : "your installer"}.
                  </Text>
                  <Box w="full" className="bg-white rounded-lg shadow-lg cursor-pointer hover:shadow-lg">
                    <PDFViewer pdfUrl={userData.installer.user_agreement} />
                  </Box>
                </VStack>
              </VStack>
            )}
            {userData && userData.user_images && userData.user_images.length > 0 && (
              <VStack w="full">
                <HStack align="center" spacing={4} w="full">
                  <Upload className="w-8 h-8 text-amber-500" />
                  <Text fontSize={{ base: "24px", lg: "32px" }} fontWeight="bold" className="text-blue-50" my={2}>
                    Your Uploads
                  </Text>
                </HStack>
                <HStack
                  w="full"
                  className="bg-white/5 border border-white/10 rounded-lg backdrop-blur-sm"
                  align="stretch"
                  gap="20px"
                  p="14px"
                  flexDirection={{ base: "column", lg: "row" }}
                >
                  {userData &&
                    userData.user_images &&
                    userData.user_images.map((e: string | null, i: number) => {
                      return (
                        <>
                          <VStack
                            p="20px"
                            gap="10px"
                            className="bg-black/60 rounded-lg shadow-lg cursor-pointer hover:shadow-lg"
                            onClick={() => {
                              setPictureSrc(e?.replace("&quot;", "") || "");
                              pictureOnOpen();
                            }}
                          >
                            <Image h="180px" w="160px" borderRadius="12px" src={e?.replace("[", "").replace("&quot;]", "") || "/api/placeholder/400/320"} />
                            <HStack align="center" spacing={2}>
                              <Text className="text-white">{`Upload ${i + 1}`}</Text>
                            </HStack>
                          </VStack>
                        </>
                      );
                    })}
                </HStack>
              </VStack>
            )}
          </VStack>
        </VStack>
      ) : (
        <VStack w="full" minH="70vh" maxW="1440px" justifyContent="center">
          <HStack align="center" spacing={4}>
            <AlertCircle className="w-12 h-12 text-amber-500" />
            <Text fontSize={{ base: "32px", lg: "54px" }} fontWeight="bold" className="text-blue-50">
              Nothing to see here...
            </Text>
          </HStack>
          <Text className="text-blue-50/70">You haven't uploaded any documents yet</Text>
        </VStack>
      )}
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        //@ts-ignore
        finalFocusRef={btnRef}
      >
        <DrawerContent fontFamily="'eurostile', sans-serif" className="bg-[#1f1f1f] text-blue-50">
          <DrawerCloseButton className="text-blue-50" />
          <DrawerHeader className="text-blue-50">
            <HStack align="center" spacing={4}>
              <Upload className="w-6 h-6" />
              <Text>Upload Documents</Text>
            </HStack>
          </DrawerHeader>

          <DrawerBody>
            <VStack align="start" spacing={6}>
              <Text className="text-blue-50/70">
                Welcome to our secure document upload portal! This feature allows you to effortlessly share important documents with our team, ensuring a smooth and efficient
                process towards installing your new solar panel system. Here's how you can get started:
              </Text>
              <VStack align="start" spacing={4}>
                <Heading fontSize="18px" className="text-blue-50">
                  Select Documents:
                </Heading>
                <Text className="text-blue-50/70">
                  Click the "Upload" button to select files from your computer or drag and drop files directly into the designated area. You can upload photos, PDFs, text
                  documents, and more.
                </Text>
              </VStack>
              <VStack align="start" spacing={4}>
                <Heading fontSize="18px" className="text-blue-50">
                  Review & Submit:
                </Heading>
                <Text className="text-blue-50/70">
                  Once selected, review your files to ensure they are correct and fully readable. You can upload multiple documents at once. When you're ready, click "Submit" to
                  securely send them to our team.
                </Text>
              </VStack>
              <VStack align="start" spacing={4}>
                <Heading fontSize="18px" className="text-blue-50">
                  Track Progress:
                </Heading>
                <Text className="text-blue-50/70">
                  After submission, you can view the status of each document in your account. We will review your documents promptly and reach out if additional information is
                  needed.
                </Text>
              </VStack>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      {/* TODO: implement fallback picture url */}
      <PictureModal src={pictureSrc || "/api/placeholder/800/600"} pictureIsOpen={pictureIsOpen} pictureOnClose={pictureOnClose} />
    </Box>
  );
};

export default UserDocumentsPage;

const PictureModal = ({ src, pictureIsOpen, pictureOnClose }: { src: string; pictureIsOpen: boolean; pictureOnClose: () => void }) => {
  return (
    <Modal isOpen={pictureIsOpen} onClose={pictureOnClose} isCentered>
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent w="full" maxW="800px" pt="40px" resize="both" bgColor="#121212">
        <ModalCloseButton className="text-white bg-[#121212]" />
        <ModalBody className="bg-[#121212]">
          <Image w="full" src={src} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
