import { Box } from "@chakra-ui/react";
import Calendar from "../Components/CalendarTest";
import { useAuth } from "../../Providers/AuthenticationProvider";

interface Event {
  date: any;
  title: string;
}

const UserCalendar: React.FC = () => {
  const { userData } = useAuth();

  const events: Event[] =
    userData?.installation_date && userData?.site_survey_date
      ? [
          { date: userData.site_survey_date, title: "Site Survey" },
          { date: userData.installation_date, title: "Installation" },
        ]
      : userData?.installation_date
      ? [{ date: userData.installation_date, title: "Installation" }]
      : userData?.site_survey_date
      ? [{ date: userData.site_survey_date, title: "Site Survey" }]
      : [];

  return (
    <Box w="full" minH="90vh" p={{ base: "4", lg: "8" }} className="bg-[#1f1f1f]">
      <Calendar events={events} />
    </Box>
  );
};

export default UserCalendar;
