import React, { useEffect, useState } from "react";
import { Box, Text, Button, FormControl, GridItem, FormLabel, Input, SimpleGrid, InputGroup, InputLeftElement, VStack, HStack } from "@chakra-ui/react";
import { PhoneIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { useUpdateUser } from "../../hooks/userHooks";
import { useAuth } from "../../Providers/AuthProvider";
import { v4 as uuidv4 } from "uuid";
import { useUserDataContext } from "../../Providers/LeadDataProvider";

type FormState = {
  firstName: string;
  lastName: string;
  birthDate: string;
  email: string;
  phoneNumber: string;
};

const PreApprovalForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [annualIncome, setAnnualIncome] = useState("");
  const [formState, setFormState] = useState<FormState>({
    firstName: "",
    lastName: "",
    birthDate: "",
    email: "",
    phoneNumber: "",
  });
  const navigate = useNavigate();
  const { cognitoUser, user } = useAuth();
  const { userData } = useUserDataContext();
  const { updateUser, data, loading, error: updateUserError } = useUpdateUser();

  useEffect(() => {
    setFormState({
      firstName,
      lastName,
      birthDate,
      email,
      phoneNumber,
    });
  }, [firstName, lastName, birthDate, email, phoneNumber]);

  useEffect(() => {
    if (!localStorage.getItem("clientId")) {
      localStorage.setItem("clientId", uuidv4());
    }
  }, []);

  const userId = localStorage.getItem("userId");

  const handleFormSubmit = () => {
    navigate(`/loan-message`);
    // console.warn("form data", userId);
    // createClient({
    //   variables: {
    //     input: {
    //       id: localStorage.getItem("clientId"),
    //       installerId: leadData.installerId,
    //       userId: userId,
    //       clientStatus: ClientStatus.ACTIVE,
    //       projectStatus: ProjectStatus.AWAITING_LOAN_APPROVAL,
    //     },
    //   },
    // });
    // updateUser({
    //   variables: {
    //     input: {
    //       id: userId,
    //       installerId: leadData.installerId,
    //     },
    //   },
    // });
  };

  const parseToNumber = (value: string) => {
    // Remove non-numeric characters and parse to a number
    const numericalValue = value.replace(/[^0-9.]+/g, "");
    return numericalValue ? Number(numericalValue) : 0;
  };

  const formatCurrency = (number: number) => {
    // Format as USD currency
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0, // Adjust for decimal precision if needed
    }).format(number);
  };

  const handleBlur = () => {
    // Format the value on blur
    const number = parseToNumber(annualIncome);
    setAnnualIncome(formatCurrency(number));
  };

  return (
    <Box w="full" minH="90vh" bg="#f4eee9" p={{ base: "10px", lg: "40px" }} fontFamily="'eurostile', sans-serif">
      <VStack w="full" pt="80px">
        <Text fontSize="30px" mb="20px" fontWeight="bold">
          Solar Loan Application
        </Text>
        <Box w={{ base: "full", lg: "70vw" }} borderRadius="10px" bgColor="white" p={{ base: "20px", lg: "60px" }} shadow="lg">
          {/* <SimpleGrid w="full" columns={6} spacing="40px" mt="20px"> */}
          <VStack w="full" gap="60px">
            <VStack w="full" spacing="20px">
              <Text w="full" fontSize="20px" fontWeight="bold">
                Personal Information
              </Text>
              <Box w="full" h="2px" bgColor="gray" mb="20px" />
              <HStack w="full" justifyContent={{ base: "center", lg: "space-between" }} flexDirection={{ base: "column", lg: "row" }}>
                <FormControl w={{ base: "full", lg: "30%" }}>
                  <FormLabel htmlFor="first-name" fontWeight={"normal"} color="gray.600">
                    Applicant's First name
                  </FormLabel>
                  <Input
                    id="first-name"
                    placeholder="First name"
                    borderRadius="0px"
                    border="none"
                    borderBottom="1px solid gray"
                    pl="0px"
                    sx={{
                      // Override focus styles
                      _focus: {
                        borderColor: "initial", // or any color you want
                        boxShadow: "none",
                      },
                    }}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </FormControl>

                <FormControl w={{ base: "full", lg: "30%" }}>
                  <FormLabel htmlFor="last-name" fontWeight={"normal"} color="gray.600">
                    Applicant's Last name
                  </FormLabel>
                  <Input
                    id="last-name"
                    placeholder="Last name"
                    borderRadius="0px"
                    border="none"
                    borderBottom="1px solid gray"
                    pl="0px"
                    sx={{
                      // Override focus styles
                      _focus: {
                        borderColor: "initial", // or any color you want
                        boxShadow: "none",
                      },
                    }}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </FormControl>

                <FormControl w={{ base: "full", lg: "30%" }}>
                  <FormLabel htmlFor="email" fontWeight={"normal"} color="gray.600">
                    Applicant's Email address
                  </FormLabel>
                  <Input
                    id="email"
                    type="email"
                    placeholder="Email"
                    borderRadius="0px"
                    border="none"
                    borderBottom="1px solid gray"
                    pl="0px"
                    sx={{
                      // Override focus styles
                      _focus: {
                        borderColor: "initial", // or any color you want
                        boxShadow: "none",
                      },
                    }}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {/* <FormHelperText>We&apos;ll never share your email.</FormHelperText> */}
                </FormControl>
              </HStack>

              <HStack w="full" justifyContent={{ base: "center", lg: "space-between" }} flexDirection={{ base: "column", lg: "row" }}>
                <FormControl w={{ base: "full", lg: "30%" }}>
                  <FormLabel htmlFor="phone" fontWeight={"normal"} color="gray.600">
                    Applicant's Phone Number
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <PhoneIcon color="gray.300" />
                    </InputLeftElement>
                    <Input
                      id="phone"
                      type="tel"
                      placeholder="(***) *** ****"
                      borderRadius="0px"
                      border="none"
                      borderBottom="1px solid gray"
                      sx={{
                        // Override focus styles
                        _focus: {
                          borderColor: "initial", // or any color you want
                          boxShadow: "none",
                        },
                      }}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </InputGroup>
                </FormControl>

                <FormControl w={{ base: "full", lg: "30%" }}>
                  <FormLabel htmlFor="birthdate" fontWeight={"normal"} color="gray.600">
                    Applicant's Birthdate
                  </FormLabel>
                  <Input
                    id="birthdate"
                    type="date"
                    borderRadius="0px"
                    border="none"
                    borderBottom="1px solid gray"
                    pl="0px"
                    sx={{
                      // Override focus styles
                      _focus: {
                        borderColor: "initial", // or any color you want
                        boxShadow: "none",
                      },
                    }}
                    onChange={(e) => setBirthDate(e.target.value)}
                  />
                </FormControl>
                <Box w="30%" display={{ base: "none", lg: "block" }} />
              </HStack>
            </VStack>

            <VStack w="full" spacing="20px">
              <Text w="full" fontSize="20px" fontWeight="bold">
                Employment Information
              </Text>
              <Box w="full" h="2px" bgColor="gray" mb="20px" />
              <HStack w="full" justifyContent={{ base: "center", lg: "space-between" }} flexDirection={{ base: "column", lg: "row" }}>
                <FormControl w={{ base: "full", lg: "30%" }}>
                  <FormLabel htmlFor="employer-name" fontWeight={"normal"}>
                    Employer Name
                  </FormLabel>
                  <Input
                    id="employer-name"
                    type="text"
                    placeholder="Employer Name"
                    borderRadius="0px"
                    border="none"
                    borderBottom="1px solid gray"
                    pl="0px"
                    sx={{
                      // Override focus styles
                      _focus: {
                        borderColor: "initial", // or any color you want
                        boxShadow: "none",
                      },
                    }}
                  />
                </FormControl>

                <FormControl w={{ base: "full", lg: "30%" }}>
                  <FormLabel htmlFor="employment-status" fontWeight={"normal"}>
                    Employment Status
                  </FormLabel>
                  <Input
                    id="employment-status"
                    type="text"
                    placeholder="Employment Status"
                    borderRadius="0px"
                    border="none"
                    borderBottom="1px solid gray"
                    pl="0px"
                    sx={{
                      // Override focus styles
                      _focus: {
                        borderColor: "initial", // or any color you want
                        boxShadow: "none",
                      },
                    }}
                  />
                </FormControl>

                <FormControl w={{ base: "full", lg: "30%" }}>
                  <FormLabel htmlFor="job-title" fontWeight={"normal"}>
                    Job Title
                  </FormLabel>
                  <Input
                    id="job-title"
                    type="text"
                    placeholder="Job Title"
                    borderRadius="0px"
                    border="none"
                    borderBottom="1px solid gray"
                    pl="0px"
                    sx={{
                      // Override focus styles
                      _focus: {
                        borderColor: "initial", // or any color you want
                        boxShadow: "none",
                      },
                    }}
                  />
                </FormControl>
              </HStack>

              <HStack w="full" justifyContent={{ base: "center", lg: "space-between" }} flexDirection={{ base: "column", lg: "row" }}>
                <FormControl w={{ base: "full", lg: "30%" }}>
                  <FormLabel htmlFor="annual-income" fontWeight={"normal"}>
                    Annual Income
                  </FormLabel>
                  <Input
                    id="annual-income"
                    type="text"
                    placeholder="$0.00"
                    value={annualIncome}
                    borderRadius="0px"
                    border="none"
                    borderBottom="1px solid gray"
                    pl="0px"
                    sx={{
                      // Override focus styles
                      _focus: {
                        borderColor: "initial", // or any color you want
                        boxShadow: "none",
                      },
                    }}
                    onChange={(e) => setAnnualIncome(e.target.value)}
                    onBlur={handleBlur}
                  />
                </FormControl>

                <FormControl w={{ base: "full", lg: "30%" }}>
                  <FormLabel htmlFor="last-4-of-ssn" fontWeight={"normal"}>
                    Last 4 of SSN
                  </FormLabel>
                  <Input
                    id="last-4-of-ssn"
                    type="text"
                    placeholder="****"
                    borderRadius="0px"
                    border="none"
                    borderBottom="1px solid gray"
                    pl="0px"
                    sx={{
                      // Override focus styles
                      _focus: {
                        borderColor: "initial", // or any color you want
                        boxShadow: "none",
                      },
                    }}
                  />
                </FormControl>
                <Box w="30%" display={{ base: "none", lg: "block" }} />
              </HStack>
            </VStack>
          </VStack>
        </Box>
        <VStack w="full">
          <Box w={{ base: "full", lg: "70vw" }} display="flex" justifyContent={{ base: "center", lg: "flex-end" }} mt="20px" mb="40px">
            <Button bgColor="black" color="white" _hover={{ bgColor: "rgba(0,0,0,.7)" }} onClick={handleFormSubmit}>
              Submit Application
            </Button>
          </Box>
        </VStack>
      </VStack>
    </Box>
  );
};
export default PreApprovalForm;
