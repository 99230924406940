import React, { useEffect, useState } from "react";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { Box, Button, CircularProgress, VStack, Text } from "@chakra-ui/react";
import { useUserDataContext } from "../../Providers/LeadDataProvider";
import { FaRegCircleCheck } from "react-icons/fa6";

// Load your publishable key from the Stripe Dashboard
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK || "");

// const placeMeasurementsOrder = async () => {
//   try {
//     const response = await axios.post("https://4zpask5mw0.execute-api.us-east-2.amazonaws.com/dev/place_measurement_order", {
//       OrderReports: [
//         {
//           ReportAddresses: [
//             {
//               Address: "5609 Windsor Ave",
//               City: "Sioux City",
//               State: "IA",
//               Zip: "51106-3934",
//               Country: "US",
//               Latitude: 42.4629753,
//               Longitude: -96.3333938,
//               AddressType: 1,
//               VerifierUsedId: null,
//               MapperUsedId: null,
//               VerificationResultTypeId: null,
//             },
//           ],
//           ReportAttibutes: [
//             {
//               Attribute: 9,
//               Value: "Ask",
//             },
//           ],
//           BuildingId: 4,
//           PrimaryProductId: 91,
//           DeliveryProductId: 8,
//           AddOnProductIds: null,
//           MeasurementInstructionType: 1,
//           ClaimNumber: null,
//           ClaimInfo: null,
//           BatchId: null,
//           CatId: null,
//           ChangesInLast4Years: false,
//           PONumber: null,
//           Comments: null,
//           ReferenceID: null,
//           InsuredName: null,
//           UpgradeFromReportId: null,
//           PolicyNumber: null,
//         },
//       ],
//       PromoCode: null,
//       PlaceOrderUser: null,
//       CreditCardData: null,
//     });

//     // Check if the request was successful
//     if (response.status !== 200) {
//       throw new Error(`API call failed with status: ${response.status}`);
//     }

//     return response.data;
//   } catch (error) {
//     // Handle any errors that occur during the fetch
//     console.error("Error calling the API:", error);
//     throw error; // Rethrow the error if you want to handle it further up the call stack
//   }
// };

const CheckoutForm = ({ chargeAmount }: { chargeAmount: number }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentIsLoading, setPaymentIsLoading] = useState<boolean>(false);
  const [paymentSuccessful, setPaymentSuccessful] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const { setUserData } = useUserDataContext();
  const charge = formatCentsToDollars(chargeAmount);

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    setPaymentIsLoading(true);
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}`,
      },
      redirect: "if_required",
    });

    if (error) {
      console.log("[error]", error);
      setErrorMessage(error.message);
      setPaymentIsLoading(false);
      return;
    }
    console.log("payment success");
    setPaymentSuccessful(true);
    setPaymentIsLoading(false);
    // placeMeasurementsOrder().then((res) => {
    //   if (res) {
    //     console.warn("Measurement order reportId::", res);
    //     // @ts-ignore
    //     setUserData((prevLeadData: User) => {
    //       if (
    //         JSON.stringify(prevLeadData) !==
    //         JSON.stringify({
    //           ...prevLeadData,
    //           id: user_id,
    //           measurementsOrderReportId: `${res}`,
    //         })
    //       ) {
    //         return {
    //           ...prevLeadData,
    //           id: user_id,
    //           measurementsOrderReportId: `${res}`,
    //         };
    //       }
    //       return prevLeadData;
    //     });
    //   }
    // });
  };

  return (
    <VStack minW="full" justifyContent="center">
      {paymentSuccessful ? (
        <VStack w="full">
          <Text fontWeight="bold" fontSize="26px" color="green.500">
            Payment Successful!
          </Text>
          <VStack w="full" p="20px">
            <FaRegCircleCheck color="#38A169" size="60px" />
          </VStack>
          {/* <Text w="80%" textAlign="center">
            We're getting to work on your final design. You will be notified you when it's ready.
          </Text> */}
        </VStack>
      ) : (
        <>
          <VStack
            w="full"
            alignItems="center"
            style={{ height: paymentIsLoading && !errorMessage ? "auto" : "0px", display: paymentIsLoading && !errorMessage ? "unset" : "none" }}
          >
            <CircularProgress isIndeterminate />
          </VStack>
          <form
            style={{ height: paymentIsLoading ? "0px" : "auto", width: paymentIsLoading ? "0px" : "100%", display: paymentIsLoading ? "none" : "unset" }}
            id="payment-form"
            onSubmit={handleSubmit}
          >
            <PaymentElement id="payment-element" />
            <Button w="full" mt="20px" disabled={!stripe} type="submit" id="submit" bgColor="#605ff6" color="white" _hover={{ bgColor: "#706ff0" }}>
              {`${chargeAmount === 10000 ? "Order" : "Submit Payment"} - ${charge}`}
            </Button>
            {/* {errorMessage && (
              <div id="error-message">
                <Text color="red.500">{errorMessage}</Text>
              </div>
            )} */}
          </form>
        </>
      )}
    </VStack>
  );
};

function formatCentsToDollars(cents: number) {
  if (typeof cents !== "number" || isNaN(cents)) {
    throw new Error("Input must be a valid number.");
  }

  let dollars = cents / 100;
  return dollars.toLocaleString("en-US", { style: "currency", currency: "USD" });
}

const StripeComponent = ({ chargeAmount }: { chargeAmount: number }) => {
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    // Fetch the client secret
    fetch("https://4zpask5mw0.execute-api.us-east-2.amazonaws.com/dev/create-stripe-payment-intent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ amount: chargeAmount, currency: "usd", automatic_payment_methods: { enabled: true } }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, []);

  const appearance: StripeElementsOptions["appearance"] = {
    theme: "stripe",
  };

  const options = {
    // passing the client secret obtained from the server
    clientSecret,
    appearance,
    layout: {
      type: "tabs",
      defaultCollapsed: false,
    },
  };

  return (
    <VStack w="full" maxW={{ base: "full", lg: "500px" }} bgColor="#efefef" p="20px" borderRadius="12px" shadow="rgba(0, 0, 0, 0.16) 0px 1px 4px">
      {clientSecret ? (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm chargeAmount={chargeAmount} />
        </Elements>
      ) : (
        <Box w="full" maxW={{ base: "full", lg: "500px" }} display="flex" justifyContent="center">
          <CircularProgress isIndeterminate />
        </Box>
      )}
    </VStack>
  );
};

export default StripeComponent;
