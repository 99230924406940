import React, { useEffect, useState } from "react";
import { ChevronRight, ChevronLeft, Home, Construction, Settings, Sliders, Sparkles, WandSparkles } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { Installer, UserUpdateOptions } from "../../API";
import { useUserDataContext } from "../../Providers/LeadDataProvider";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Providers/AuthenticationProvider";
import CSignUpModal from "./CSignUpModal";
import { useListInstallers } from "../../hooks/userHooks";

// Create a new component for the design path selection that matches the questionnaire style
const DesignPathComponent = ({ onPrevious }: any) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  const [selectedInstallerForSignup, setSelectedInstallerForSignup] = useState<Installer | null>(null);
  const { setUserData } = useUserDataContext();
  const { data: installers } = useListInstallers();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (installers?.listInstallers?.items) {
      const cheapestInstaller = [...installers.listInstallers.items].sort((a, b) => {
        if (!a || !b) return 0;
        if (!a.company_red_line) return 1;
        if (!b.company_red_line) return -1;
        return a.company_red_line - b.company_red_line;
      })[0];

      if (cheapestInstaller) {
        setSelectedInstallerForSignup(cheapestInstaller as Installer);
      }
    }
  }, [installers]);

  const handleSignupSuccess = () => {
    setIsSignUpModalOpen(false);
    if (selectedInstallerForSignup) {
      handleInstallerChosen(selectedInstallerForSignup);
    }
  };

  const handleInstallerChosen = async (chosenInstaller: Installer) => {
    const authenticated = await isAuthenticated();
    if (!authenticated) {
      setSelectedInstallerForSignup(chosenInstaller);
      setIsSignUpModalOpen(true);
      return;
    }
    // @ts-ignore
    setUserData((prevUserData) => {
      if (
        JSON.stringify(prevUserData) !==
        JSON.stringify({
          ...prevUserData,
          installerId: chosenInstaller.id,
          installerSalesforceId: chosenInstaller.salesforceId,
          updated_by: UserUpdateOptions.DYNAMO,
        })
      ) {
        return {
          ...prevUserData,
          installerId: chosenInstaller.id,
          installerSalesforceId: chosenInstaller.salesforceId,
          updated_by: UserUpdateOptions.DYNAMO,
        };
      }
      return prevUserData;
    });
    navigate("/bill-upload");
  };

  const handleSimpleSelected = async () => {
    if (installers?.listInstallers?.items) {
      const cheapestInstaller = [...installers.listInstallers.items].sort((a, b) => {
        if (!a || !b) return 0;
        if (!a.company_red_line) return 1;
        if (!b.company_red_line) return -1;
        return a.company_red_line - b.company_red_line;
      })[0];

      if (cheapestInstaller) {
        setSelectedInstallerForSignup(cheapestInstaller as Installer);
      }
    }

    const authenticated = await isAuthenticated();
    if (!authenticated) {
      setIsSignUpModalOpen(true);
      return;
    }
  };

  return (
    <div className="w-full space-y-8 py-8">
      <div>
        <h2 className="text-4xl font-bold text-blue-50 mb-4">Choose Your Design Experience</h2>
        <p className="text-blue-50/70">Select how you'd like to proceed with your solar system design</p>
      </div>

      <div className="grid md:grid-cols-2 gap-8">
        {/* Simple Path Card */}
        <motion.button
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={handleSimpleSelected}
          className="bg-white/5 backdrop-blur-sm rounded-lg p-8 border border-white/10 text-left transition-colors hover:bg-white/10 group"
        >
          <div className="flex items-center justify-between mb-6">
            <div className="bg-amber-500/10 rounded-full p-3">
              <WandSparkles className="w-8 h-8 text-amber-500" />
            </div>
            <ChevronRight className="w-6 h-6 text-white/0 group-hover:text-white/50 transition-all" />
          </div>
          <h3 className="text-2xl font-bold text-blue-50 mb-3">Simple</h3>
          <p className="text-blue-50/70 mb-6">Let us handle the details. We'll design an optimal system based on your needs.</p>
          <div className="space-y-3">
            {["Expert system design", "Optimized for your home", "Quick and easy process"].map((feature, index) => (
              <div key={index} className="flex items-center text-blue-50/60">
                <Sparkles className="w-4 h-4 mr-2 text-amber-500" />
                <span>{feature}</span>
              </div>
            ))}
          </div>
        </motion.button>

        {/* Advanced Path Card */}
        <motion.button
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={() => navigate("/loading-estimates")}
          className="bg-white/5 backdrop-blur-sm rounded-lg p-8 border border-white/10 text-left transition-colors hover:bg-white/10 group"
        >
          <div className="flex items-center justify-between mb-6">
            <div className="bg-blue-500/10 rounded-full p-3">
              <Settings className="w-8 h-8 text-blue-400" />
            </div>
            <ChevronRight className="w-6 h-6 text-white/0 group-hover:text-white/50 transition-all" />
          </div>
          <h3 className="text-2xl font-bold text-blue-50 mb-3">Advanced</h3>
          <p className="text-blue-50/70 mb-6">Customize your system on your own before ordering a professional design.</p>
          <div className="space-y-3">
            {["Full system customization", "Detailed specifications", "Interactive design process"].map((feature, index) => (
              <div key={index} className="flex items-center text-blue-50/60">
                <Sliders className="w-4 h-4 mr-2 text-blue-400" />
                <span>{feature}</span>
              </div>
            ))}
          </div>
        </motion.button>
      </div>

      {/* Navigation */}
      <div className="flex justify-between items-center pt-8">
        <button onClick={onPrevious} className="flex items-center text-blue-50/70 hover:text-blue-50 transition-colors">
          <ChevronLeft className="w-5 h-5 mr-2" />
          Previous
        </button>
      </div>
      <CSignUpModal
        isOpen={isSignUpModalOpen}
        onClose={() => {
          setIsSignUpModalOpen(false);
          setSelectedInstallerForSignup(null);
        }}
        onSignUpSuccess={handleSignupSuccess}
        installer={selectedInstallerForSignup}
      />
    </div>
  );
};

const questions = [
  {
    id: "foundation",
    question: "Does your home have a foundation?",
    description: "A proper foundation is essential for solar panel installation",
    options: [
      {
        id: "yes",
        label: "Yes",
        description: "My home has a solid foundation",
      },
      {
        id: "no",
        label: "No",
        description: "My home does not have a foundation",
      },
      {
        id: "UNKNOWN",
        label: "Not Sure",
        description: "I need help determining this",
      },
    ],
    icon: <Home className="w-8 h-8" />,
  },
  {
    id: "roof-type",
    question: "What type of roof do you have?",
    description: "The type of roof affects how we'll install your solar panels",
    options: [
      {
        id: "COMPOSITION_SHINGLES",
        label: "Composition Shingles",
        description: "Common asphalt shingles used in most residential homes",
      },
      {
        id: "S_TYPE_CONCRETE",
        label: "S-Type Concrete",
        description: "S-shaped concrete tiles popular in Mediterranean-style homes",
      },
      {
        id: "FLAT_CONCRETE_TILE",
        label: "Flat Concrete Tile",
        description: "Flat concrete tiles commonly used in modern architecture",
      },
      {
        id: "METAL",
        label: "Metal",
        description: "Steel, aluminum, or other metal roofing materials",
      },
      {
        id: "FLAT",
        label: "Flat",
        description: "Flat roof typically found on modern or commercial buildings",
      },
      {
        id: "CLAY",
        label: "Clay",
        description: "Traditional clay tiles often seen in Spanish-style homes",
      },
      {
        id: "ROCK",
        label: "Rock",
        description: "Stone-based roofing materials",
      },
      {
        id: "SLATE_OR_STONE",
        label: "Slate or Stone",
        description: "Natural slate or stone tiles known for durability",
      },
    ],
    icon: <Home className="w-8 h-8" />,
  },
  {
    id: "roof-condition",
    question: "What condition is your roof in?",
    description: "The condition of your roof affects solar panel installation",
    options: [
      {
        id: "EXCELLENT",
        label: "Excellent",
        description: "New or like-new condition (0-5 years old)",
      },
      {
        id: "GOOD",
        label: "Good",
        description: "Minor wear but no repairs needed (5-10 years old)",
      },
      {
        id: "POOR",
        label: "Poor",
        description: "Significant wear or damage (15+ years old)",
      },
    ],
    icon: <Construction className="w-8 h-8" />,
  },
  {
    id: "design-path",
    isCustomQuestion: true, // Add this flag to handle custom rendering
    component: DesignPathComponent, // We'll create this component below
  },
];

const fadeInUpVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const QuestionPage = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [direction, setDirection] = useState(1);
  const { setUserData } = useUserDataContext();
  const navigate = useNavigate();

  const currentQuestion = questions[currentQuestionIndex];
  const totalQuestions = questions.length;

  const handleOptionSelect = (optionId: string) => {
    setAnswers((prev) => ({
      ...prev,
      [currentQuestion.id]: optionId,
    }));

    switch (currentQuestion.id) {
      case "foundation":
        // @ts-ignore
        setUserData((prevUserData: UserWithoutTypeName) => {
          if (
            JSON.stringify(prevUserData) !==
            JSON.stringify({
              ...prevUserData,
              home_has_foundation: optionId === "yes" ? true : optionId === "no" ? false : "UNKNOWN",
              updated_by: UserUpdateOptions.DYNAMO,
            })
          ) {
            return {
              ...prevUserData,
              home_has_foundation: optionId === "yes" ? true : optionId === "no" ? false : "UNKNOWN",
              updated_by: UserUpdateOptions.DYNAMO,
            };
          }
          return prevUserData;
        });
        break;
      case "roof-condition":
        // @ts-ignore
        setUserData((prevUserData: UserWithoutTypeName) => {
          if (
            JSON.stringify(prevUserData) !==
            JSON.stringify({
              ...prevUserData,
              roof_condition: optionId,
              updated_by: UserUpdateOptions.DYNAMO,
            })
          ) {
            return {
              ...prevUserData,
              roof_condition: optionId,
              updated_by: UserUpdateOptions.DYNAMO,
            };
          }
          return prevUserData;
        });
        break;
      case "roof-type":
        // @ts-ignore
        setUserData((prevUserData: UserWithoutTypeName) => {
          if (
            JSON.stringify(prevUserData) !==
            JSON.stringify({
              ...prevUserData,
              roof_material: optionId,
              updated_by: UserUpdateOptions.DYNAMO,
            })
          ) {
            return {
              ...prevUserData,
              roof_material: optionId,
              updated_by: UserUpdateOptions.DYNAMO,
            };
          }
          return prevUserData;
        });
        break;
      default:
        break;
    }
  };

  const handleNext = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setDirection(1);
      setCurrentQuestionIndex((prev) => prev + 1);
    } else if (currentQuestionIndex === questions.length - 1) {
      navigate("/loading-estimates");
    }
    window.scrollTo(0, 0);
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setDirection(-1);
      setCurrentQuestionIndex((prev) => prev - 1);
    }
    window.scrollTo(0, 0);
  };

  const slideVariants = {
    enter: () => ({
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    }),
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: () => ({
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    }),
  };

  const renderQuestion = () => {
    const currentQuestion = questions[currentQuestionIndex];

    if (currentQuestion.isCustomQuestion) {
      return (
        <currentQuestion.component
          onSelect={(id: string) => {
            handleOptionSelect(id);
            // Handle completion or navigation here
          }}
          onPrevious={handlePrevious}
        />
      );
    }

    return (
      <section className="min-h-screen bg-[#121212] flex px-4 py-12">
        <div className="max-w-6xl mx-auto w-full">
          {/* Progress Bar */}
          <motion.div initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} className="mb-12">
            <div className="flex justify-between text-blue-50/60 text-sm mb-2">
              <span>
                Question {currentQuestionIndex + 1} of {totalQuestions}
              </span>
              <span>{Math.round(((currentQuestionIndex + 1) / totalQuestions) * 100)}% Complete</span>
            </div>
            <div className="h-1 bg-blue-50/10 rounded-xl">
              <motion.div
                className="h-full bg-amber-500 rounded-xl"
                initial={{ width: "0%" }}
                animate={{ width: `${((currentQuestionIndex + 1) / totalQuestions) * 100}%` }}
                transition={{ duration: 0.5 }}
              />
            </div>
          </motion.div>

          <div className="grid md:grid-cols-1 gap-16">
            {/* Question Content */}
            <AnimatePresence mode="wait">
              <motion.div
                key={currentQuestionIndex}
                initial="enter"
                animate="center"
                exit="exit"
                variants={slideVariants}
                transition={{
                  x: { type: "spring", stiffness: 300, damping: 30 },
                  opacity: { duration: 0.2 },
                }}
                className="space-y-8"
              >
                <div>
                  <h2 className="text-4xl font-bold text-blue-50 mb-4">{currentQuestion.question}</h2>
                  <p className="text-blue-50/70">{currentQuestion.description}</p>
                </div>

                {/* Options */}
                <div className="space-y-4">
                  {/* @ts-ignore */}
                  {currentQuestion.options.map((option) => (
                    <motion.button
                      key={option.id}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      onClick={() => handleOptionSelect(option.id)}
                      className={`w-full p-4 rounded-lg border-2 transition-all duration-300 text-left group
                      ${
                        // @ts-ignore
                        answers[currentQuestion.id] === option.id ? "border-amber-500 bg-amber-500/10" : "border-blue-50/10 hover:border-amber-500/50"
                      }`}
                    >
                      <div className="flex items-center space-x-4">
                        <div
                          className={`w-5 h-5 rounded-full border-2 flex items-center justify-center
                        ${
                          // @ts-ignore
                          answers[currentQuestion.id] === option.id ? "border-amber-500" : "border-blue-50/30 group-hover:border-amber-500/50"
                        }`}
                        >
                          {
                            // @ts-ignore
                            answers[currentQuestion.id] === option.id && <div className="w-2.5 h-2.5 rounded-full bg-amber-500" />
                          }
                        </div>
                        <div>
                          <p className="font-semibold text-blue-50">{option.label}</p>
                          <p className="text-sm text-blue-50/70">{option.description}</p>
                        </div>
                      </div>
                    </motion.button>
                  ))}
                </div>

                {/* Navigation */}
                <div className="flex justify-between items-center pt-8">
                  {currentQuestionIndex > 0 ? (
                    <button onClick={handlePrevious} className="flex items-center text-blue-50/70 hover:text-blue-50 transition-colors">
                      <ChevronLeft className="w-5 h-5 mr-2" />
                      Previous
                    </button>
                  ) : (
                    <div />
                  )}

                  <button
                    onClick={handleNext}
                    disabled={
                      // @ts-ignore
                      !answers[currentQuestion.id]
                    }
                    className={`px-8 py-3 rounded-lg font-semibold inline-flex items-center transition-all duration-300
                    ${
                      // @ts-ignore
                      answers[currentQuestion.id] ? "bg-amber-500 hover:bg-amber-400 text-[#121212] hover:translate-x-2" : "bg-blue-50/10 text-blue-50/30 cursor-not-allowed"
                    }`}
                  >
                    Continue
                    <ChevronRight className="ml-2 w-5 h-5" />
                  </button>
                </div>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </section>
    );
  };

  return (
    <section className="min-h-screen bg-[#121212] flex items-center px-4">
      <div className="max-w-6xl mx-auto w-full">
        {/* Progress bar */}
        <motion.div variants={fadeInUpVariants} className="mb-12">
          <div className="flex justify-between text-blue-50/60 text-sm mb-2">
            <span>
              Question {currentQuestionIndex + 1} of {questions.length}
            </span>
            <span>{Math.round(((currentQuestionIndex + 1) / questions.length) * 100)}% Complete</span>
          </div>
          <div className="h-1 bg-blue-50/10 rounded-3xl">
            <motion.div className="h-full bg-amber-500 rounded-3xl" style={{ width: `${((currentQuestionIndex + 1) / questions.length) * 100}%` }} />
          </div>
        </motion.div>

        {/* Question Content */}
        {renderQuestion()}
      </div>
    </section>
  );
};

export default QuestionPage;
