import React from "react";
import { Box, HStack, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const AdminNavBar = () => {
  const navigate = useNavigate();

  return (
    <Box w="full" h="80px" bgColor="black">
      <HStack gap="20px" h="80px" justifyContent="space-around" alignItems="center">
        <Button onClick={() => navigate("/admin")} variant="ghost" borderRadius="0" _hover={{ bgColor: "rgba(255,255,255,.2)" }} color="white">
          Dashboard
        </Button>
        <Button onClick={() => navigate("/admin-user-images")} variant="ghost" borderRadius="0" _hover={{ bgColor: "rgba(255,255,255,.2)" }} color="white">
          User Images
        </Button>
        <Button onClick={() => navigate("/admin-user-images")} variant="ghost" borderRadius="0" _hover={{ bgColor: "rgba(255,255,255,.2)" }} color="white">
          User Issues
        </Button>
      </HStack>
    </Box>
  );
};
export default AdminNavBar;
