import { Box, VStack, Text, Link } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import doorSalesman from "../Blogs/BlogImages/doorSalesman.webp";
import solarTown from "../Blogs/BlogImages/solarTown.webp";
import questionInABottle from "../Blogs/BlogImages/questionInABottle.webp";
import solarCalc from "../Blogs/BlogImages/solarCalc.webp";

const BlogTop10SolarQuestionsAnswered = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBlogLinkClick = (link: string) => {
    navigate(link);
  };

  return (
    <Box w="full" minH="90vh" pb="60px" pt="80px" bg="#1a1a1a" color="gray.400">
      <Box w="full" h={{ base: "200px", lg: "500px" }} bgImage={questionInABottle} bgSize="cover" bgRepeat="no-repeat" bgPosition="0px -50px" />
      <VStack w="full" gap="40px" align="stretch" p="20px">
        <VStack w="full">
          <br />
          <VStack w="full" mt="20px" lineHeight={1} maxW="800px">
            <Text w="full" fontWeight="bold" fontSize={{ base: "28px", lg: "32px" }}>
              Top 10 Solar Questions Answered
            </Text>
            <Text w="full" color="gray.300">
              Better understanding solar
            </Text>
          </VStack>
          <br />
          <Text fontSize="20px" maxW="800px">
            Are you considering making the switch to solar energy for your home? If so, you're not alone. With the rising cost of traditional energy sources and growing concerns
            about environmental sustainability, many homeowners are turning to solar power as a clean, renewable alternative. But before you take the leap into solar energy, it's
            natural to have questions. That's why we've put together the top 10 questions about residential solar roofs and provided answers to help shed some light on this
            eco-friendly solution.
          </Text>
          <br />
          <Text w="full" fontWeight="bold" fontSize="18px" maxW="800px">
            1. How do solar panels work?
          </Text>
          <Text fontSize="20px" maxW="800px">
            Solar panels, also known as photovoltaic (PV) panels, harness sunlight and convert it into electricity through a process called the “photovoltaic effect”. When sunlight
            hits the solar panels, the photons in the sunlight are absorbed by the solar cells, creating an electric current. An <b>inverter</b> takes this direct current
            electricity and converts it into an alternating current, allowing it to be usable for powering homes. The panels continuously generate electricity as long as they are
            exposed to sunlight, providing a clean, sustainable, and renewable energy source without relying on your utility company.
          </Text>
          <br />
          <VStack w="full" maxW="800px">
            <Text w="full" fontWeight="bold" fontSize="18px" maxW="800px">
              2. Do solar panels work in rainy or cloudy weather?
            </Text>
            <Text fontSize="20px">
              A common misconception is that solar roofs won’t generate power if there is limited sunlight. While solar panels work best in direct sunlight, they can still generate
              electricity on cold, cloudy, and even rainy or snowy days. Solar panels are designed to capture both direct and indirect sunlight, so even on overcast days, they can
              still produce electricity, albeit at a lower efficiency. It’s estimated that solar roof systems generate about 20% of their normal power output when sunlight isn’t
              piercing through the clouds.
            </Text>
          </VStack>
          <br />
          <VStack w="full" maxW="800px">
            <Text w="full" fontWeight="bold" fontSize="18px" maxW="800px">
              3. How much do solar panels cost?
            </Text>
            <Text fontSize="20px">
              The cost of a solar roof can vary depending on factors like the size of the system, the quality of the panels, and installation costs. On average, homeowners can
              expect to pay anywhere from $17,000 to $24,000 for a solar panel system according to a study by the Architectural Digest. However, there are often incentives and
              financing options available to help offset these costs, so it’s important to understand what resources are available to you. It is also important to compare and
              contrast several options before deciding which company installs your solar to ensure you’re getting the best combination of quality and price available in your area.
            </Text>
          </VStack>
          <br />
          <VStack w="full" maxW="800px">
            <Text w="full" fontWeight="bold" fontSize="18px" maxW="800px">
              4. Are there incentives available for installing solar panels?
            </Text>
            <Text fontSize="20px">
              Yes, there are several incentives available to homeowners who install solar panels, including the Federal Investment Tax Credit (ITC), state and local rebates, and
              net metering programs. Currently, the Federal ITC provides homeowners with a 30% tax credit on all eligible costs of installation. State and local incentives vary
              based on where you live. Visit{" "}
              <Link href="https://www.dsireusa.org" color="blue">
                https://www.dsireusa.org
              </Link>{" "}
              to learn more about what incentives are available in your area and how they can help make solar more affordable for you.
            </Text>
          </VStack>
          <br />
          <VStack w="full" maxW="800px">
            {/* TODO: add link to savings calc */}
            <Text w="full" fontWeight="bold" fontSize="18px" maxW="800px">
              5. How much can I save with solar panels?
            </Text>
            <Text fontSize="20px">
              The amount of money you can save with solar panels depends on your energy usage, the size of your solar system, and the cost of electricity in your area. On average,
              homeowners can save hundreds or even thousands of dollars per year on their energy bills by switching to solar power. However, adding a solar roof to your home isn’t
              free, as we mentioned above. To calculate your total lifetime savings, it’s important to consider the cost of installing and financing solar plus maintenance against
              your monthly energy bill savings plus the increase in your home value resulting from your renewable energy roof.
            </Text>
          </VStack>
          <br />
          <Text w="full" fontWeight="bold" fontSize="18px" maxW="800px">
            6. How long do solar panels last?
          </Text>
          <Text fontSize="20px" maxW="800px">
            Most solar panels come with a warranty of 25 years, but they can last much longer with proper maintenance. Constructed with hardy materials such as tempered glass,
            aluminum frames, and silicon solar cells, solar panels are designed to withstand harsh weather conditions like rain, snow, hail, and high winds. Many solar panels
            continue to produce electricity for 30 years or more, making them a reliable long-term investment in energy savings.
          </Text>
          <br />
          <VStack w="full" maxW="800px">
            <Text w="full" fontWeight="bold" fontSize="18px" maxW="800px">
              7. What happens if my solar panels produce more electricity than I use?
            </Text>
            <Text fontSize="20px">
              If your solar panels produce more electricity than you use, the excess energy is typically fed back into the grid through a process called net metering. In some
              cases, homeowners can receive credits for the excess energy they produce, which can offset future electricity bills. Additionally, homeowners who pair their solar
              roof with a battery storage system have excess solar production sent to charge the battery. Once it is fully charged, the excess production is sent back through the
              net metering process again. Some states and utilities set limits on net metering, so check with your localities to learn more.
            </Text>
          </VStack>
          <br />
          <VStack w="full" maxW="800px">
            <Text w="full" fontWeight="bold" fontSize="18px" maxW="800px">
              8. Do I need to replace my roof before installing solar panels?
            </Text>
            <Text fontSize="20px">
              It's not always necessary to replace your roof before installing solar panels, but it's something you should consider. If your roof is old or in poor condition, it
              may be a good idea to replace it before installing solar panels to avoid any potential issues down the road.
            </Text>
          </VStack>
          <br />
          <VStack w="full" maxW="800px">
            <Text w="full" fontWeight="bold" fontSize="18px" maxW="800px">
              9. What maintenance is required for solar panels?
            </Text>
            <Text fontSize="20px">
              Solar panels require minimal maintenance, typically just occasional cleaning to remove dirt and debris. Most solar panel systems come with warranties that cover any
              necessary repairs or replacements, so homeowners can enjoy worry-free energy production for years to come.
            </Text>
          </VStack>
          <br />
          <VStack w="full" maxW="800px">
            <Text w="full" fontWeight="bold" fontSize="18px" maxW="800px">
              10. Can I install solar panels myself?
            </Text>
            <Text fontSize="20px">
              While it is technically possible to install solar panels yourself, it's not recommended unless you have experience and expertise in electrical work and roofing. It's
              best to hire a professional solar installer who can ensure that your system is installed safely and correctly. Using a qualified solar installation company with good
              reviews and competitive pricing can ensure your rooftop solar is ready to produce efficient energy and withstand weather obstacles year-round.
            </Text>
          </VStack>
          <br />
          <Text fontSize="20px" maxW="800px">
            These are just a few of the most common questions homeowners have about residential solar roofs. If you're considering making the switch to solar energy, we encourage
            you to use the tools and resources available at Klaravia. Through our online solar marketplace, homeowners can design their roof, compare quotes from local installers,
            and find the best financing option for them all from the comfort of their home. No more pesky salesmen, high-pressure sales tactics, or dishonest literature. With
            Klaravia’s industry-transforming tools and guidance, you can enjoy the many benefits of clean, renewable solar energy for your home without the headache that normally
            comes alongside it.
          </Text>
          <br />
          <VStack maxW="800px" color="gray.500">
            <Text fontSize="16px">Additional Resources & References:</Text>
            <Text fontSize="14px">(Internal Revenue Service, 2023)</Text>
            <Text fontSize="14px">(Hawkins, 2024)</Text>
            <Text fontSize="14px">(DSIRE, n.d.)</Text>
          </VStack>
        </VStack>
        <br />
        <VStack w="full">
          <VStack w={{ base: "full", lg: "35vw" }} alignItems="center" gap="20px" maxW="800px">
            <VStack w="full" cursor="pointer" onClick={() => handleBlogLinkClick("/blogs/understanding-solar-roof-tax-incentives")}>
              <Box w="full" minH="250px" h="auto" bgImage={solarCalc} bgSize="cover" bgRepeat="no-repeat" bgPosition="top" />
              <VStack w="full" mt="10px" lineHeight={1} pb="10px">
                <Text w="full" color="gray.500">
                  Solar tax incentives
                </Text>
                <Text w="full" fontWeight="bold" fontSize="24px">
                  Understanding Solar Roof Tax Incentives
                </Text>
              </VStack>
            </VStack>

            <VStack w="full" cursor="pointer" onClick={() => handleBlogLinkClick("/blogs/shining-a-light-on-solar")}>
              <Box w="full" minH="250px" h="auto" bgImage={doorSalesman} bgSize="cover" bgRepeat="no-repeat" bgPosition="top" />
              <VStack w="full" mt="10px" lineHeight={1} pb="10px">
                <Text w="full" color="gray.500">
                  How Klaravia is Fighting for Homeowners
                </Text>
                <Text w="full" fontWeight="bold" fontSize="24px">
                  Shining a Light on Solar
                </Text>
              </VStack>
            </VStack>

            <VStack w="full" cursor="pointer" alignItems="center" onClick={() => handleBlogLinkClick("/blogs/to-solar-or-not-to-solar")}>
              <Box w="full" minH="250px" h="auto" bgImage={solarTown} bgSize="cover" bgRepeat="no-repeat" bgPosition="top" />
              <VStack w="full" mt="10px" lineHeight={1} pb="10px">
                <Text w="full" color="gray.500">
                  Does solar work for you?
                </Text>
                <Text w="full" fontWeight="bold" fontSize="24px">
                  To Solar or not to Solar: When Solar makes Sense
                </Text>
              </VStack>
            </VStack>
          </VStack>
        </VStack>
      </VStack>
    </Box>
  );
};
export default BlogTop10SolarQuestionsAnswered;
