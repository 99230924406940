import React, { useState } from "react";
import { Box, Button, Checkbox, Container, FormControl, FormLabel, HStack, IconButton, Input, InputGroup, InputRightElement, Link, Stack, Text, VStack } from "@chakra-ui/react";
import { Auth } from "aws-amplify";
import { ViewOffIcon, ViewIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Providers/AuthProvider";

const InstallerLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const auth = useAuth();
  const navigate = useNavigate();

  const handleClick = () => setShowPassword(!showPassword);

  const handleSignIn = async () => {
    try {
      const cognitoUser = await Auth.signIn(email, password);
      auth.setCognitoUser(cognitoUser);
      // User signed in successfully, redirect to dashboard or home page
      // useHistory from react-router-dom could be used here for redirection
      navigate(`/installer/${cognitoUser.attributes["custom:installerDynamoId"]}`);
    } catch (error: any) {
      setError(error.message);
      console.error("Error signing in", error);
    }
  };

  return (
    <Box w="full" minH="100vh" bgColor="#f4eee9" p="20px" pt="10vh">
      <Container maxW="lg" py={{ base: "12", md: "24" }} px={{ base: "0", sm: "8" }}>
        <Stack spacing="8">
          <Stack spacing="6">
            <VStack spacing={0} gap="0px">
              <Text w="full" textAlign="center" fontSize="5xl" fontWeight={500}>
                Klaravia
              </Text>
              <Text w="full" textAlign="center" fontSize="2xl" fontWeight={500}>
                Installer Portal Login
              </Text>
            </VStack>
          </Stack>
          <Box
            py={{ base: "4", sm: "8" }}
            px={{ base: "4", sm: "10" }}
            bg={{ base: "white", sm: "bg.surface" }}
            boxShadow={{ base: "none", sm: "md" }}
            borderRadius={{ base: "lg", sm: "xl" }}
          >
            <Stack spacing="6">
              <Stack spacing="5">
                <FormControl>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input id="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="password">Password</FormLabel>
                  <InputGroup size="md">
                    <Input
                      pr="4.5rem"
                      type={showPassword ? "text" : "password"}
                      border="1px solid rgb(238, 239, 241)"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <InputRightElement width="3rem">
                      <IconButton h="1.75rem" aria-label="Password" size="sm" icon={showPassword ? <ViewOffIcon /> : <ViewIcon />} onClick={handleClick} />
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
              </Stack>
              <HStack justify="space-between">
                <Checkbox defaultChecked>Remember me</Checkbox>
                <Button variant="text" size="sm">
                  Forgot password?
                </Button>
              </HStack>
              <Stack spacing="6">
                <Text color="red.500">{error}</Text>
                <Button onClick={handleSignIn}>Log in</Button>
                {/* <HStack>
                  <Divider />
                  <Text textStyle="sm" whiteSpace="nowrap" color="fg.muted">
                    or continue with
                  </Text>
                  <Divider />
                </HStack> */}
              </Stack>
            </Stack>
          </Box>
          <Text w="full" textAlign="center">
            Don't have an account?{" "}
            <Link href="/installer/sign-up" color="#4810a3">
              Sign up
            </Link>
          </Text>
        </Stack>
      </Container>
    </Box>
  );
};
export default InstallerLogin;
