import React from "react";
import { Box, Button, HStack, Icon, Input, Tooltip, VStack, Image, Text } from "@chakra-ui/react";
import { BsSliders } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { PiWarningFill } from "react-icons/pi";
import clientListSvg from "../Assets/clientList.svg";

const ClientList = () => {
  const navigate = useNavigate();
  const clients: any[] = [
    { name: "John Doe", address: "123 N Main St Naperville, IL", id: "123", stepIndex: 4, requiresIntervention: false },
    { name: "John Smith", address: "678 W Almon St Moscow, ID", id: "234", stepIndex: 2, requiresIntervention: false },
    { name: "Jane Doe", address: "345 S Main St Johannesburg, IN", id: "345", stepIndex: 1, requiresIntervention: true },
    { name: "Jane Smith", address: "910 E Sycamore St New York, NY", id: "456", stepIndex: 3, requiresIntervention: false },
    { name: "John Doe", address: "123 N Main St Naperville, IL", id: "123", stepIndex: 4, requiresIntervention: false },
    { name: "John Smith", address: "678 W Almon St Moscow, ID", id: "234", stepIndex: 2, requiresIntervention: false },
    { name: "Jane Doe", address: "345 S Main St Johannesburg, IN", id: "345", stepIndex: 1, requiresIntervention: false },
    { name: "Jane Smith", address: "910 E Sycamore St New York, NY", id: "456", stepIndex: 3, requiresIntervention: false },
    { name: "John Doe", address: "123 N Main St Naperville, IL", id: "123", stepIndex: 4, requiresIntervention: false },
    { name: "John Smith", address: "678 W Almon St Moscow, ID", id: "234", stepIndex: 2, requiresIntervention: true },
    { name: "Jane Doe", address: "345 S Main St Johannesburg, IN", id: "345", stepIndex: 1, requiresIntervention: false },
    { name: "Jane Smith", address: "910 E Sycamore St New York, NY", id: "456", stepIndex: 3, requiresIntervention: false },
    { name: "John Doe", address: "123 N Main St Naperville, IL", id: "123", stepIndex: 4, requiresIntervention: false },
    { name: "John Smith", address: "678 W Almon St Moscow, ID", id: "234", stepIndex: 2, requiresIntervention: false },
    { name: "Jane Doe", address: "345 S Main St Johannesburg, IN", id: "345", stepIndex: 1, requiresIntervention: false },
    { name: "Jane Smith", address: "910 E Sycamore St New York, NY", id: "456", stepIndex: 3 },
  ];

  const handleClientSelect = (e: any) => {
    console.log(e);
    navigate(`/installer/123/client_project_details/${e.id}`);
  };

  return (
    <>
      {clients.length > 0 ? (
        <Box w="100%" minH="94vh" bg="#f4eee9" p="8">
          <HStack w="100%" mb="40px">
            <Input placeholder="Search Clients" borderColor="black" w="200px" />
            <Button variant="outline" colorScheme="green" aria-label="Filter Results" leftIcon={<BsSliders />}>
              Filters
            </Button>
          </HStack>
          {clients.map((client, index) => (
            <Box
              key={index}
              fontFamily="'eurostile', sans-serif"
              bgColor="white"
              w="100%"
              border="1px"
              borderColor="gray.200"
              borderRadius="10px"
              my="10px"
              p="10px"
              onClick={() => handleClientSelect(client)}
              _hover={{ backgroundColor: "gray.100" }}
              cursor="pointer"
            >
              <Box display="flex" justifyContent="space-between">
                <VStack w="200px">
                  <Box w="100%" fontSize="16px" fontWeight="bold">
                    {client.name}
                  </Box>
                  <Box w="100%" fontSize="12px"></Box>
                </VStack>
                <VStack>
                  <Box w="100%" fontSize="16px" fontWeight="bold">
                    Phone Number
                  </Box>
                  <Box w="100%" fontSize="12px">
                    (835) 253 5552{" "}
                  </Box>
                </VStack>
                <VStack>
                  <Box w="100%" fontSize="16px" fontWeight="bold">
                    Email
                  </Box>
                  <Box w="100%" fontSize="12px">
                    john@gmail.com
                  </Box>
                </VStack>
                <VStack>
                  <Box w="100%" fontSize="16px" fontWeight="bold">
                    Install Date
                  </Box>
                  <Box w="100%" fontSize="12px">
                    10/24/24
                  </Box>
                </VStack>
                <HStack w="200px" justifyContent="flex-end">
                  {client.requiresIntervention && (
                    <Tooltip label="Account requires attention" fontSize="sm" shouldWrapChildren>
                      <Icon as={PiWarningFill} boxSize="30px" mt="5px" mr="20px" color="orange.300" />
                    </Tooltip>
                  )}
                </HStack>
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <Box w="100%" minH="94vh" p="4" bg="#f4eee9" fontFamily="'eurostile', sans-serif">
          <VStack w="full" minH="60vh" justifyContent="space-between">
            <VStack w="full" mt="6vh">
              <Text fontSize={{ base: "20px", md: "32px" }} w="full" textAlign="center" fontWeight="bold">
                You don't have any clients yet
              </Text>
              <Text w="full" textAlign="center" fontSize="18px" fontWeight="bold" color="gray.500">
                This is where your clients will appear
              </Text>
            </VStack>
            <Image w="80vw" maxW="500px" src={clientListSvg} mt="40px" />
          </VStack>
        </Box>
      )}
    </>
  );
};

export default ClientList;
